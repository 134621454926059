import { Box } from "@mui/material";
import * as Routes from "routes";
import { useHistory } from "react-router";
import { HubDashboardCard } from "pages/shared/hub-dashboard-card";

export const DashboardCardsUserManagement = () => {
  const history = useHistory();

  const rowOneContent = [
    {
      title: "Manage a Customer",
      subtitle: "Admin",
      actionName: "Search",
      actionFunc: () => history.push(Routes.adminAccount),
      actionDisabled: false,
    },
  ];

  return (
    <>
      <div>
        <Box style={boxCardStyle}>
          {rowOneContent.map((key) => {
            return (
              <HubDashboardCard
                key={key.title}
                title={key.title}
                subtitle={key.subtitle}
                actionName={key.actionName}
                actionFunc={key.actionFunc}
                cardStyle={rowOneStyle}
              />
            );
          })}
        </Box>
      </div>
    </>
  );
};

const boxCardStyle: any = {
  display: "flex",
  width: "100%",
  alignItems: "flex-start",
  justifyContent: "space-between",
  flexWrap: "wrap",
};

const rowOneStyle: any = {
  height: "100%",
  width: "100%",
  marginBottom: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "16px",
};
