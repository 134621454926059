import { IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { closeSnackbar, SnackbarKey } from "notistack";

interface CloseButtonProps {
  snackbarId: SnackbarKey;
}

const CloseButton = (props: CloseButtonProps) => {
  return (
    <IconButton onClick={() => closeSnackbar(props.snackbarId)}>
      <CancelIcon sx={{ color: "white" }} />
    </IconButton>
  );
};

export { CloseButton };
