import { useCallback, useState } from "react";
import {
  LngLatBoundsLike,
  MapLayerMouseEvent,
  MapRef,
  PointLike,
} from "react-map-gl";
import {
  Attributes,
  SelectAddressArgs,
  StepDemoConfig,
} from "../step-config-file/types";

const initialCoordinates = {
  latitude: -25.2744,
  longitude: 133.7751,
};

interface UseMapControlsProps {
  nextStepDemo: StepDemoConfig;
  mapRef: React.RefObject<MapRef>;
  setAttributes: React.Dispatch<React.SetStateAction<Attributes>>;
  setSelectedLayer: React.Dispatch<React.SetStateAction<string | null>>,
  setMarkerPosition: React.Dispatch<
    React.SetStateAction<{ longitude: number; latitude: number }>
  >;
  setShowMarker: React.Dispatch<React.SetStateAction<boolean>>;
  setDynamicZoom: React.Dispatch<React.SetStateAction<number>>;
  isInteractiveStep: boolean;
}

export const useMapControls = ({
  nextStepDemo,
  mapRef,
  setAttributes,
  setSelectedLayer,
  setMarkerPosition,
  setShowMarker,
  setDynamicZoom,
  isInteractiveStep,
}: UseMapControlsProps) => {
  const [coordinates, setCoordinates] = useState(initialCoordinates);

  const [minZoom, setMinZoom] = useState<number | undefined>();
  const [boundingBox, setboundingBox] = useState<
    LngLatBoundsLike | undefined
  >();

  const resetCompass = useCallback(() => {
    mapRef.current?.easeTo({
      bearing: 0,
      pitch: 0,
    });
  }, [mapRef]);

  const handleZoom = useCallback(
    (zoom: number) => {
      setDynamicZoom(zoom);
      mapRef.current?.flyTo({ zoom: zoom, duration: 500 });
    },
    [mapRef, setDynamicZoom]
  );

  const [selectedId, setSelectedId] = useState<string | null>(null); // Store one selected id
  const handleMarker = useCallback(() => {
    setShowMarker(false);
    setAttributes({
      geometry: {},
      properties: {},
      layer: {},
      source: "",
    });
  }, [setShowMarker, setAttributes]);

  const onClick = useCallback(
    (event: MapLayerMouseEvent) => {
      //
      const bbox: [PointLike, PointLike] = [
        [event.point.x - 5, event.point.y - 5],
        [event.point.x + 5, event.point.y + 5],
      ];

      const features = mapRef.current?.queryRenderedFeatures(event.point);
      let selectedFeature = null;
      let selectedLayer = null;
      // @ts-ignore
      if (features.length > 0) {
        selectedFeature = features?.find(
          (x) => x.layer.id === "flood-buildings"
        );
        if (selectedFeature) {
          setSelectedId(selectedFeature.properties?.building_pid as string);
          setSelectedLayer("flood-buildings");
        } else {
          selectedFeature = features?.find(
            (x) => x.layer.id === "flood-cadastre"
          );
          if (selectedFeature) {
            setSelectedId(selectedFeature.properties?.cadastre_pid as string);
            setSelectedLayer("flood-cadastre");
          } else {
            setSelectedId(null);
            setSelectedLayer(null);
          }
        }
      }

      setMarkerPosition({
        longitude: event.lngLat.lng,
        latitude: event.lngLat.lat,
      });

      setShowMarker(true);
      setAttributes(selectedFeature as Attributes);
    },
    [mapRef, setMarkerPosition, setShowMarker, setAttributes]
  );

  const moveToNextLocation = useCallback(
    ({
      longitude,
      latitude,
      geocoder_attributes,
      zoomLevel,
    }: SelectAddressArgs) => {
      setCoordinates({
        latitude: latitude,
        longitude: longitude,
      });
      setDynamicZoom(zoomLevel);
      geocoder_attributes["longitude"] = longitude.toString();
      geocoder_attributes["latitude"] = latitude.toString();

      mapRef.current?.flyTo({
        center: [longitude, latitude],
        duration: 5000,
        zoom: zoomLevel,
      });

      setTimeout(() => {
        setMinZoom(nextStepDemo.mapConfig.minZoom); // Example new minZoom
        setboundingBox(nextStepDemo.mapConfig.boundingBox);
      }, 5000);
    },
    [setCoordinates, setDynamicZoom]
  );

  const highlightedLayer = {
    highlightedLayerFlat: {
      id: "counties-highlighted-flat",
      type: "fill",

      paint: {
        "fill-outline-color": "#484896",
        "fill-color": "#6e599f",
        "fill-opacity": 0.7,
      },
      filter: selectedId
        ? ["==", "building_pid", selectedId]
        : ["==", "building_pid", ""], // Highlight only one feature
    },
    highlightedLayer3D: {
      id: "counties-highlighted-3d",
      type: "fill-extrusion",
      paint: {
        "fill-extrusion-color": "#484896",
        "fill-extrusion-height": ["get", "roof_height"],
        "fill-extrusion-opacity": 0.8,
      },
      filter: selectedId
        ? ["==", "building_pid", selectedId]
        : ["==", "building_pid", ""], // Highlight only one feature
    },
  };

  mapRef.current?.getMap().scrollZoom.disable();
  mapRef.current?.getMap().dragPan.disable();
  mapRef.current?.getMap().dragRotate.disable();
  mapRef.current?.getMap().touchPitch.disable();
  mapRef.current?.getMap().keyboard.disable();
  mapRef.current?.getMap().doubleClickZoom.disable();

  // // Enable Map Interactions
  if (isInteractiveStep) {
    mapRef.current?.getMap().scrollZoom.enable();
    mapRef.current?.getMap().dragPan.enable();
    mapRef.current?.getMap().dragRotate.enable();
    mapRef.current?.getMap().touchPitch.enable();
    mapRef.current?.getMap().keyboard.enable();
    mapRef.current?.getMap().doubleClickZoom.enable();
  }

  return {
    boundingBox,
    minZoom,
    coordinates,
    setCoordinates,
    resetCompass,
    handleZoom,
    handleMarker,
    onClick,
    moveToNextLocation,
    highlightedLayer,
  };
};
