import { IdentityState } from "contexts/identity-context";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { makeApiRequestToUrl } from "utils/api-client";

export async function fetchAdminUsage(
  isUserPage: boolean,
  identityState: IdentityState,
  accountId: string,
  startTime: number,
  endTime: number
) {
  try {
    dayjs.extend(utc);
    dayjs.extend(timezone);

    // Get the timezone string from the user's device
    const userTimezone = dayjs.tz.guess();
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/usage?customerId=${accountId}&offset=${userTimezone}&startTime=${startTime}&endTime=${endTime}`,
      "GET",
      isUserPage ? false : identityState
    );

    const responseData = await response.json(); // add type: Usage

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch usage data: ${err}`);
  }
}
