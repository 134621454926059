import AddIcon from "@mui/icons-material/Add";
import ExploreIcon from "@mui/icons-material/Explore";
import RemoveIcon from "@mui/icons-material/Remove";
import { IconButton, Paper } from "@mui/material";
interface ZoomControlProps {
  dynamicZoom: number;
  onZoom: (zoom: number) => void;
  resetCompass: () => void;
}

export const ZoomControl = (props: ZoomControlProps) => {
  return (
    <>
      <Paper
        sx={{
          width: "40px",
          height: "80px",
          display: "flex",
          flexDirection: "column",
          alignSelf: "flex-end",
        }}
      >
        <IconButton onClick={() => props.onZoom(props.dynamicZoom + 0.5)}>
          <AddIcon />
        </IconButton>
        <IconButton onClick={() => props.onZoom(props.dynamicZoom - 0.5)}>
          <RemoveIcon />
        </IconButton>
      </Paper>
      <Paper
        sx={{
          width: "40px",
          height: "40px",
          display: "flex",
          flexDirection: "column",
          alignSelf: "flex-end",
        }}
      >
        <IconButton onClick={() => props.resetCompass()}>
          <ExploreIcon />
        </IconButton>
      </Paper>
    </>
  );
};
