import { Expression } from "mapbox-gl";
import { LngLatBoundsLike } from "react-map-gl";
import { Coordinates, FormState, MapConfig, Style } from "./types";

// Constants
const MAX_EXTRUSION_HEIGHT = 100;
const DEFAULT_ROOF_COLOR = "#86806F";
export const EXTRUSION_OPACITY = 0.8;

// Global min and max values for color scaling // This can be a Dictionary where the key is the Style
const GLOBAL_MIN = 0;
const GLOBAL_MAX = 10;

export const getExtrusionHeight = (
  style: Style,
  formState: FormState
): Expression => {
  return ["get", "roof_height"];
};


// Helper functions for step configurations
export const createMapConfig = (
  zoomLevel: number,
  minZoom?: number,
  boundingBox?: LngLatBoundsLike
): MapConfig => ({
  zoomLevel, // use by flyTo
  minZoom,
  boundingBox,
});

export const createCoordinates = (
  latitude: number,
  longitude: number
): Coordinates => ({
  latitude,
  longitude,
});
