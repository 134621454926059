export enum ColorScheme {
  Green = "#00ff00",
  Red = "#ff0000",
}

export enum Style {
  five_year = "5",
  ten_year = "10",
  twenty_year = "20",
  fifty_year = "50",
  hundred_year = "100",
  two_hundred_year = "200",
  five_hundred_year = "500",
  thousand_year = "1000",
}

export enum Season {
  Spring = "Spring",
  Summer = "Summer",
  Autumn = "Autumn",
  Winter = "Winter",
}

// Interfaces
export interface FormState {
  style: Style;
}

// total_co2_kg_m2_yr_1_Autumn

import { Dispatch, SetStateAction } from "react";
import { LngLatBoundsLike } from "react-map-gl";

export type DemoStep = number;

export type SetDemoStep = Dispatch<SetStateAction<DemoStep>>;

export type Description = {
  paragraph_one?: string;
  paragraph_two?: string;
};
export type DataStep = {
  title?: string;
  description?: Description;
};

export type Coordinates = {
  latitude: number;
  longitude: number;
};

interface GeocoderAttributes {
  [key: string]: string;
}

export interface SelectAddressArgs {
  longitude: number;
  latitude: number;
  geocoder_attributes: GeocoderAttributes;
  zoomLevel: number;
}
export interface StepComponentProps {
  moveToNextLocation: (args: SelectAddressArgs) => void;
  nextStep: () => void;
  backStep: () => void;
  data: DataStep;
  coordinates: Coordinates;
  previousCoordinates: Coordinates;
  nextCoordinates: Coordinates;
  zoomLevel: number;
  previousZoomLevel: number;
  attributes: Attributes;
  id: number;
  selectedLayer: string | null;

  handleMarker: () => void;
  formState?: FormState;
  layer_name?: string;
}

export interface Attributes {
  geometry: Object;
  properties: Record<string, any>;
  layer: any;
  source: string;
}

export type MapConfig = {
  zoomLevel: number;
  minZoom?: number;
  compare?: boolean;
  boundingBox?: LngLatBoundsLike;
};

export interface GeoJsonDemoMapLayer {
  layerName: string;
  geojsonPath: string;
  createStyle: (formState: FormState) => object;
  beforeId?: string;
}

export type StepDemoConfig = {
  id: number;
  propertyDetails?: boolean;
  component: React.FC<StepComponentProps>;
  mapConfig: MapConfig;
  data: DataStep;
  layer: object;
  coordinates: Coordinates;
  geojsonLayers?: GeoJsonDemoMapLayer[];
};
