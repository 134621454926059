import React, { useState } from "react";
import { Layer, Source } from "react-map-gl";
import { App2 } from "../cards/feature-card";
import { WelcomeCard } from "../cards/welcome-card";
import { FormState, StepDemoConfig } from "./types";
import {
  createCoordinates,
  createMapConfig,
  getExtrusionHeight,
} from "./utils";
// const createLayerStyle = (formState: FormState, layer_name: string) => {
//   const { style } = formState;

//   return {
//     id: layer_name,
//     type: "fill-extrusion",
//     paint: {
//       "fill-extrusion-color": getColorExpression(style),
//       "fill-extrusion-height": extrude
//         ? getExtrusionHeight(style, formState)
//         : 0,
//       "fill-extrusion-opacity": EXTRUSION_OPACITY,
//     },
//   };
// };

// React components
interface MapLayerProps {
  geojsonPath: string;
  formState: FormState;
  layer_name: string;
  highlightedLayer: any;
  style: object;
  beforeId?: string;
}

export const MapLayer: React.FC<MapLayerProps> = React.memo(
  ({
    geojsonPath,
    formState,
    layer_name,
    highlightedLayer,
    style,
    beforeId,
  }) => {
    const [geoJsonData, setGeoJsonData] = useState<any>(null);
    const [error, setError] = useState<string | null>(null);

    // useEffect(() => {
    //   const fetchAndDecompress = async () => {
    //     try {
    //       const response = await fetch(geojsonPath);

    //       console.log("data");

    //       if (!response.ok) {
    //         console.log("uyyy");
    //         throw new Error(`HTTP error! status: ${response.status}`);
    //       }

    //       console.log("data 2");
    //       // const buffer = await response.arrayBuffer();

    //       // let jsonData;

    //       // // If it's gzip, decompress it
    //       // const decompressed = pako.ungzip(new Uint8Array(buffer), {
    //       //   to: "string",
    //       // });
    //       //jsonData = JSON.parse(decompressed);
    //       const data = await response.json();
    //       console.log("Setting geojson data: ", data);
    //       setGeoJsonData(data);

    //       console.log("data 3");

    //       setError(null);
    //     } catch (error) {
    //       console.error("Error fetching or processing GeoJSON:", error);
    //       // @ts-ignore
    //       setError(`Error: ${error.message}`);
    //     }
    //   };

    //   if (geojsonPath) {
    //     fetchAndDecompress();
    //   }
    // }, [geojsonPath]);

    // if (error) {
    //   return <div>Error loading map data: {error}</div>;
    // }

    // if (!geoJsonData) {
    //   return <div>Loading map data...</div>;
    // }

    return (
      <Source type="geojson" data={geojsonPath}>
        {/* @ts-ignore */}
        <Layer {...style} id={layer_name} beforeId={beforeId} />

        {/* {!formState.extrude ? (
          <Layer {...highlightedLayer.highlightedLayerFlat} />
        ) : (
          <Layer {...highlightedLayer.highlightedLayer3D} />
        )} */}
      </Source>
    );
  }
);

// Step configurations
export const stepsDemos: Record<number, StepDemoConfig> = {
  1: {
    id: 1,
    component: WelcomeCard,
    mapConfig: createMapConfig(4),
    data: {
      title: "Understanding Real-World Flood Risk in Brisbane",
    },
    coordinates: createCoordinates(-37.882122720000005, 145.169350594),
    layer: <></>,
  },
  2: {
    id: 2,
    component: App2,
    mapConfig: createMapConfig(
      15,
      10 //,

      // [138.452682, -35.05069049408381, 138.671882, -34.959873787897386]
      //  0
    ),
    data: {},
    coordinates: createCoordinates(-27.505958, 153.025326),
    layer: <></>,
    geojsonLayers: [
      {
        layerName: "flood-buildings",
        geojsonPath: "/demos/geojson/flood_hub_demo_buildings.geojson",
        createStyle: (formState: FormState) => {
          const { style } = formState;
          return {
            id: "flood-buildings",
            type: "fill-extrusion",
            paint: {
              "fill-extrusion-color": [
                "interpolate",
                ["linear"],
                ["get", `ffe_fluvial_water_depth_metres_${style}`],
                0,
                "hsla(3, 0%, 30%, 1)", // Green for low values
                0.001,
                "hsla(50, 100%, 50%, 1)", // Yellow for medium values
                10,
                "hsla(0, 100%, 50%, 1)", // Red for high values
              ],
              "fill-extrusion-height": getExtrusionHeight(style, formState),
              "fill-extrusion-base": 0,
              "fill-extrusion-opacity": 0.8,
            },
          };
        },
      },
      {
        layerName: "flood-cadastre",
        beforeId: "flood-buildings",
        geojsonPath: "/demos/geojson/flood_hub_demo_cadastre.geojson",
        createStyle: (formState: FormState) => {
          const { style } = formState;

          return {
            id: "flood-cadastre",
            type: "fill",
            paint: {
              "fill-color": [
                "interpolate",
                ["linear"],
                ["get", `fluvial_water_depth_metres_${style}`],
                0,
                "hsla(3, 0%, 30%, 1)", // Green for low values
                0.001,
                "hsla(200, 100%, 50%, 1)", // Yellow for medium values
                10,
                "hsla(255, 100%, 50%, 1)", // Red for high values
              ],
              "fill-outline-color": "hsla(0, 0%, 40%, 1)",
              "fill-opacity": 1,
            },
          };
        },
      },
    ],
  },
};
