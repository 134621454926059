import { PortalPlan } from "data/models";

const extractProductId = (
  availablePlans: PortalPlan[] | undefined,
  desiredPlan: string
) => {
  if (availablePlans === undefined) {
    return "";
  }

  return availablePlans.filter((plan) =>
    plan.name.toLowerCase().includes(desiredPlan.toLowerCase())
  )[0].productId;
};

const extractPlanField = (
  availablePlans: PortalPlan[] | undefined,
  productId: string,
  cadence: string,
  field: string
) => {
  if (availablePlans === undefined) {
    return "";
  }

  const selectedPlan: PortalPlan = availablePlans.filter(
    (plan) =>
      plan.productId === productId &&
      plan.interval.toLowerCase().includes(cadence.toLowerCase())
  )[0];

  return selectedPlan[field as keyof PortalPlan];
};

export { extractProductId, extractPlanField };
