import { Box, Card, CardContent, CardHeader, Typography } from "@mui/material";
import { StyledMarkdown } from "components/shared/styled-markdown/styled-markdown";
import { CaseStudies } from "data/models";

interface CatalogueEntryCaseStudiesProps {
  caseStudies?: CaseStudies[];
}

export const CatalogueEntryCaseStudies = (
  props: CatalogueEntryCaseStudiesProps
) => {
  if (props.caseStudies === undefined) {
    return <></>;
  }
  return (
    <Card>
      <CardHeader title="Business use-cases"></CardHeader>
      <CardContent>
        <Box>
          {props.caseStudies.map((c, i) => {
            return (
              <Box key={i}>
                <Typography fontWeight="bold" mb="10px" variant="h6">
                  {c.title}
                </Typography>
                <Typography mb="10px" variant="body1">
                  <StyledMarkdown>{c.description}</StyledMarkdown>
                </Typography>
              </Box>
            );
          })}
        </Box>
      </CardContent>
    </Card>
  );
};
