import {
  Alert,
  AlertTitle,
  Button,
  Card,
  CardActions,
  Skeleton,
  Typography,
} from "@mui/material";
import MUICardContent from "@mui/material/CardContent";
import styled from "@mui/material/styles/styled";
import { BaseState } from "common/models";
import { Empty } from "utils";

export interface DashboardCardProps extends BaseState {
  isPaymentMyob?: boolean;
  title: string;
  content: string;
  actionName: string;
  actionFunc: null | any;
  isEnterprise?: boolean;
}

type Props = {
  cardState: DashboardCardProps;
  disableActionBtn?: boolean;
};

const CardContent = styled(MUICardContent)(({ theme: { spacing } }) => ({
  padding: spacing(0),
}));

export const DashboardCard = ({ cardState, disableActionBtn }: Props) => {
  return (
    <Card
      id={`dashboard-card-${cardState.title
        .toLowerCase()
        .replaceAll(" ", "-")}`}
      style={{
        height: "130px",

        marginBottom: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
      sx={{
        width: "100%",
      }}
    >
      {cardState.isLoading ? (
        <CardContent style={{ width: "100%" }}>
          <Skeleton variant="rectangular" width="100%" />
          <Skeleton variant="text" width="100%" />
          <br></br>
          {cardState.actionFunc && !disableActionBtn ? (
            <Skeleton variant="text" width="20%" />
          ) : (
            <Empty></Empty>
          )}
        </CardContent>
      ) : (
        <>
          {cardState.isError ? (
            <>
              <CardContent style={{ width: "100%" }}>
                <Alert
                  severity="warning"
                  variant="outlined"
                  style={{
                    padding: "5px",
                  }}
                >
                  <AlertTitle>{cardState.message}</AlertTitle>
                </Alert>
              </CardContent>
            </>
          ) : cardState.isPaymentMyob ? (
            <>
              <CardContent style={{ width: "100%" }}>
                <Alert
                  severity="info"
                  variant="outlined"
                  style={{ padding: "5px" }}
                >
                  <Typography variant="body2">{cardState.message}</Typography>
                </Alert>
                <CardActions
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "18px 0px 0px 0px",
                    // gap: "8px",
                    alignSelf: "stretch",
                    // width: "100%",
                    // top: "84px",
                    height: "46px",
                    justifyContent: "flex-start",
                  }}
                >
                  <Button
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "0px",
                      borderRadius: "4px",
                    }}
                    onClick={cardState?.actionFunc}
                    size="small"
                  >
                    {cardState.actionName}
                  </Button>
                </CardActions>
              </CardContent>
            </>
          ) : (
            <>
              <CardContent>
                <Typography component="p" variant="h5" textAlign="left">
                  {cardState.content}
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  textAlign="left"
                  color="text.secondary"
                >
                  {cardState.title}
                </Typography>

                {cardState.actionFunc && !disableActionBtn ? (
                  <CardActions
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      padding: "18px 0px 0px 0px",
                      // gap: "8px",
                      alignSelf: "stretch",
                      // width: "100%",
                      // top: "84px",
                      height: "46px",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Button
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "0px",
                        borderRadius: "4px",
                      }}
                      onClick={cardState.actionFunc}
                      size="small"
                    >
                      {cardState.actionName}
                    </Button>
                  </CardActions>
                ) : (
                  <Empty></Empty>
                )}
              </CardContent>
            </>
          )}
        </>
      )}
    </Card>
  );
};
