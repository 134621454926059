import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import {
  Avatar,
  Box,
  Chip,
  Grid,
  Paper,
  Stack,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { stringAvatar } from "components/shared/shared";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { ClipConfiguration } from "pages/geoscape-data/explorer/clip/models";
import { statusColour } from "pages/geoscape-data/explorer/clip/steps/step-0-new-clip/clip-item";

interface AdminClipItemProps {
  clip: ClipConfiguration;
  onSelect: (clip: ClipConfiguration) => void;
  isSelected: boolean;
}

export const AdminClipItem = (props: AdminClipItemProps) => {
  const classes = useStyles();

  dayjs.extend(utc);
  return (
    <Paper
      variant="outlined"
      className={props.isSelected ? classes.itemSelected : classes.item}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        props.onSelect(props.clip);
      }}
    >
      <Box
        gap={1}
        display="flex"
        flexDirection="row"
        padding={"8px"}
        justifyContent="space-between"
        width="100%"
      >
        <Grid
          display="flex"
          flexDirection="column"
          // width="210px"
        >
          <Box width="160px">
            <Tooltip title={props.clip.name}>
              <Typography variant="subtitle1" noWrap>
                {props.clip.name}
              </Typography>
            </Tooltip>
          </Box>
          <Stack spacing={1} direction="row" alignItems="center">
            <Avatar
              sx={{
                color: "white",
                fontSize: 12,
                width: 24,
                height: 24,
                borderRadius: 1,
              }}
              variant="square"
              {...stringAvatar(props.clip.ownerName ?? "")}
            />
            <Typography variant="body2" noWrap>
              {props.clip.ownerName}
            </Typography>
          </Stack>
        </Grid>
        <Stack spacing={1} direction="column" justifyContent="space-between">
          <Chip
            color={statusColour(props.clip.status)}
            size="small"
            label={props.clip.status}
            variant="outlined"
          />
          <Stack spacing={1} direction="row" alignItems="center">
            <CalendarTodayIcon color="action"></CalendarTodayIcon>
            <Typography variant="subtitle2">
              {dayjs.utc(props.clip.date).local().format("YYYY-MM-DD")}
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    // width: "379px",
    minHeight: "68px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: 1,
    "&:hover": {
      background: theme.palette.action.hover,
    },
  },
  itemSelected: {
    // width: "379px",
    minHeight: "68px",
    display: "flex",
    flexDirection: "row",
    gap: 1,
    justifyContent: "space-between",
    background: theme.palette.action.selected,
  },
}));
