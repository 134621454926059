import { Box, Button } from "@mui/material";
import { useHistory } from "react-router";
import * as Routes from "routes";
import { AddressEntryForm } from "./address-entry-form";
import { CallCounter } from "./call-counter";
import { DemoControls } from "./demo-controls";
import {
  PredictiveAddressManager,
  usePredictiveAddressManager,
} from "./predictive-address-manager";
import { VisualiseJson } from "./visualise-json";
import { VisualiseMap } from "./visualise-map";

export const predictiveAddress = () => {
  const predictiveManager: PredictiveAddressManager =
    usePredictiveAddressManager();
  const history = useHistory();

  const jsonObj = predictiveManager.suggest ?? predictiveManager.address ?? {};
  //@ts-ignore
  const { basictransaction, ...objWithoutBasictransaction } = jsonObj;

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Button
        variant="contained"
        size="small"
        sx={{ alignSelf: "flex-end", top: "-55px" }}
        onClick={() => {
          history.push(Routes.demosPage);
        }}
      >
        Back
      </Button>
      <DemoControls {...predictiveManager}></DemoControls>
      <Box display="flex" flexDirection="row" gap={2}>
        <Box display="flex" flexDirection="column" width="100%" gap={2}>
          <AddressEntryForm {...predictiveManager} />
          <CallCounter {...predictiveManager} />
        </Box>
        <Box display="flex" flexDirection="column" width="100%" gap={2}>
          <VisualiseMap {...predictiveManager} />
          <VisualiseJson object={objWithoutBasictransaction} />
        </Box>
      </Box>
    </Box>
  );
};
