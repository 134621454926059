import { toTitleCase } from "utils/commons";

export function transformString(input: string): string {
  let result = "";
  let prevChar = "";

  for (let i = 0; i < input.length; i++) {
    const char = input[i];

    if (isUpperCaseNonNumericChar(char)) {
      result += handleUpperCaseChar(char, prevChar);
    } else {
      result += char;
    }

    prevChar = char;
  }

  const replacementPatterns: Record<string, string> = {
    Seifa: "SEIFA",
    "2021": " 2021",
    "2011": " 2011",
    "2016": " 2016",
    Sa1: "SA1",
    Sa2: "SA2",
    Sa3: "SA3",
    Sa4: "SA4",
    Mb: "MB",
    Irsad: "IRSD",
    Irsd: "IRSD",
    Ier: "IER",
    Ieo: "IEO",
    Gccsa: "GCCSA",
    Asgs: "ASGS",
    Main: "Main -",
    Remoteness: "Remoteness -",
    Geocode: "",
    Reverse: "",
  };

  result = toTitleCase(result);

  for (const [pattern, replacement] of Object.entries(replacementPatterns)) {
    result = result.replace(new RegExp(pattern, "g"), replacement);
  }

  return result;
}

function isUpperCaseNonNumericChar(char: string): boolean {
  return char === char.toUpperCase() && isNaN(parseInt(char));
}

function handleUpperCaseChar(char: string, prevChar: string): string {
  if (prevChar === prevChar.toLowerCase()) {
    return ` ${char.toUpperCase()}`;
  } else {
    return char;
  }
}

export const MANDATORY_ATTRIBUTES: { [key: string]: string[] } = {
  AddressProperties: ["addressId", "formattedAddress"],
  Geometry: ["latitude", "longitude"],
};

export interface Item {
  name: string;
  index?: number;
  property: string;
  attributeName: string;
  source: string;
  apiParameter?: string;
}
interface ItemIndex {
  name: string;
  index: number;
  property: string;
  attributeName: string;
  source: string;
}

export function addIndex(items: Item[]): ItemIndex[] {
  return items.map((item, index) => ({
    ...item,
    index: index + 1,
  }));
}
export const PROPERTIES_DEFINITION: { [key: string]: string } = {
  AddressProperties:
    "Provides a detailed breakdown of addresses, including unique IDs, type classifications, and geographical details. It covers everything from basic identification to specific elements like street names, unit types, and locality information, allowing for thorough address analysis and verification",

  GeocoderFeature: `Enhance your address verification with matching metadata to ensure accuracy and confidence. Match Codes detail the comparison of address elements, Match Score quantifies match accuracy on a scale of 0-100, and Match Quality labels the match's reliability as exact, confident, good, fair, or poor. These elements work together to clarify the precision and trustworthiness of each address match.`,

  Geometry: `Geocoding translates addresses into geographical coordinates, providing latitude and longitude details for precise location mapping. This process enables accurate positioning and analysis of addresses on a map.`,

  asgsMain2011: `Australian Statistical Geography Standard (ASGS) information for the address including meshblock and statistical areas 1-4`,
  asgsMain2016: `Australian Statistical Geography Standard (ASGS) information for the address including meshblock and statistical areas 1-4`,
  asgsMain2021: `Australian Statistical Geography Standard (ASGS) information for the address including meshblock and statistical areas 1-4`,

  asgsRemoteness2011: `Provides classifications of areas based on their access to services using the 2011 Australian Statistical Geography Standard. This property categorises regions from highly accessible urban centres to remote areas, facilitating targeted analysis and decision-making for service distribution and market planning. The attributes include categorical codes and names that simplify the classification and comparison of different regions.`,
  asgsRemoteness2016: `Provides classifications of areas based on their access to services using the 2011 Australian Statistical Geography Standard. This property categorises regions from highly accessible urban centres to remote areas, facilitating targeted analysis and decision-making for service distribution and market planning. The attributes include categorical codes and names that simplify the classification and comparison of different regions.`,

  asgsRemoteness2021: `The 2021 ASGS Remoteness classification enhances the understanding of geographic access across Australia, segmenting regions from urban centres to remote areas based on service accessibility. Polygon PID and State PID connect geographical data to detailed polygon shapes and state boundaries, crucial for spatial analysis.`,

  buildings:
    "Provides a list of building IDs that serve as essential references for various lookups and analyses. Related Building IDs returns a list of identifiers that can be integrated into broader data systems for enhanced connectivity and functionality across databases.",

  commonwealthElectorate:
    "Offers crucial identifiers for electoral divisions within Australia, facilitating a detailed analysis of electoral regions.",
  localGovernmentArea:
    "Provides identifiers useful for mapping and analysing council areas in Australia.",

  stateElectorate:
    "Access key identifiers and classifications for state electoral divisions in Australia. Supporting targeted strategies and informed decision-making for a range of political, legislative, and civic engagement projects.",

  stateElectorateBundle:
    "The Slate Electorate Bundle offers a detailed view of state electorates, incorporating historical and status changes that go beyond the static information found in standard state electorate records. This collection includes key events such as the gazetting, effectiveness, and retirement of electoral boundaries, along with the standard identifiers and classification codes. This enables a richer understanding of the evolution and current status of each state electorate, making it invaluable for those involved in political analysis, electoral planning, and historical research.",
  solarPanelBundle:
    "Details of addresses with or without photovoltaic solar panels. If available, associated attributes are provided such as area of the array and the estimated power production.",
  matchingMetadataReverseGeocode:
    "Enhance your address verification with matching metadata to ensure accuracy and confidence. Match Codes detail the comparison of address elements, Match Score quantifies match accuracy on a scale of 0-100, and Match Quality labels the match's reliability as exact, confident, good, fair, or poor. These elements work together to clarify the precision and trustworthiness of each address match.",
  matchingMetadataGeocode:
    "Enhance your address verification with matching metadata to ensure accuracy and confidence. Match Codes detail the comparison of address elements, Match Score quantifies match accuracy on a scale of 0-100, and Match Quality labels the match's reliability as exact, confident, good, fair, or poor. These elements work together to clarify the precision and trustworthiness of each address match.",
  queryMetadataReverseGeocode:
    "Captures and clarifies submitted queries. For geocoding, it shows both the original and parsed query, providing clarity on how input is interpreted for more accurate processing.",
  queryMetadataGeocode:
    "Captures and clarifies submitted queries. For geocoding, it shows both the original and parsed query, providing clarity on how input is interpreted for more accurate processing.",
};
