import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import LayersIcon from "@mui/icons-material/Layers";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
  ResponderProvided,
} from "react-beautiful-dnd";
import { ColorPicker } from "../color-picker/color-picker";
import { LayerManager } from "../layer-manager";
import { convertHexToRGBA } from "./layer-utils";
import { LayerConfiguration } from "./models";

interface LayersCardProps {
  layerManager: LayerManager;
  zoomToLayerFn: () => void;
}

export const LayersCard = (props: LayersCardProps) => {
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [expandedLayer, setExpandedLayer] = useState<LayerConfiguration | null>(
    null
  );
  const [opacity, setOpacity] = useState(0.5);
  const [thickness, setThickness] = useState(2);
  const [diameter, setDiameter] = useState(2);
  const [fill, setFill] = useState(true);

  const open = Boolean(anchorEl);
  const [color, setColor] = useState("#FF0000");

  const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    props.layerManager.reorderLayer(
      result.source.index,
      result.destination.index
    );
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        // Close the modal when the tab becomes hidden
        handleMoreClose();
      }
    };

    // Add event listener for visibility change
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Clean up the event listener
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const handleMoreClick = (
    event: React.MouseEvent<HTMLElement>,
    layer: LayerConfiguration
  ) => {
    setAnchorEl(event.currentTarget);
    setExpandedLayer(layer);
  };

  const handleMoreClose = () => {
    setAnchorEl(null);
  };

  const handleChangeColor = (layerId: string, color: string) => {
    props.layerManager.setLayerColour(layerId, color);
    setColor(color);
  };

  const handleChangeOpacity = (layerId: string, opacity: number) => {
    props.layerManager.setLayerOpacity(layerId, opacity);
    setOpacity(opacity);
  };

  const handleChangeThickness = (layerId: string, thickness: number) => {
    props.layerManager.setLayerThickness(layerId, thickness);
    setThickness(thickness);
  };

  const handleChangeDiameter = (layerId: string, diameter: number) => {
    props.layerManager.setLayerDiameter(layerId, diameter);
    setDiameter(diameter);
  };

  const handleChangeFill = (layerId: string, fill: boolean) => {
    props.layerManager.setLayerFill(layerId, fill);
    setFill(fill);
  };

  return (
    <>
      <ColorPicker
        onClose={handleMoreClose}
        open={open}
        anchorEl={anchorEl}
        color={color}
        opacity={opacity}
        thickness={thickness}
        diameter={diameter}
        fill={fill}
        expandedLayer={expandedLayer}
        onChangeColor={handleChangeColor}
        onChangeOpacity={handleChangeOpacity}
        onChangeThickness={handleChangeThickness}
        onChangeDiameter={handleChangeDiameter}
        onChangeFill={handleChangeFill}
        zoomToLayerFn={props.zoomToLayerFn}
      ></ColorPicker>
      {!expanded && (
        <Paper className={classes.minimized}>
          <IconButton onClick={() => setExpanded(!expanded)}>
            <LayersIcon />
          </IconButton>
        </Paper>
      )}
      {expanded && (
        <Card className={classes.expanded}>
          <CardHeader
            title="Layers"
            subheaderTypographyProps={{ variant: "body2" }}
            subheader="Manage visibility, order and colours"
            action={
              <IconButton
                onClick={() => {
                  setExpanded(false);
                }}
              >
                <ChevronRightIcon />
              </IconButton>
            }
          ></CardHeader>

          <CardContent
            sx={{ display: "flex", flexDirection: "column", gap: 1 }}
          >
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{
                      maxHeight: "30vh",
                      overflowY: "auto",
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap="8px"
                      mb={"20px"}
                    >
                      {props.layerManager.selectedLayers.map(
                        (selectedLayer, index) => (
                          <Draggable
                            key={selectedLayer.id}
                            draggableId={selectedLayer.id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <Tooltip
                                  title={selectedLayer.displayName}
                                  placement="left"
                                >
                                  <Box
                                    sx={{
                                      // width: "256px",
                                      height: "40px",
                                      bgcolor: convertHexToRGBA(
                                        selectedLayer.styles.colour,
                                        selectedLayer.styles.opacity
                                      ),
                                      borderColor: "text.primary",
                                      border: 1,
                                      borderRadius: "8px",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                    display="flex"
                                    flexDirection="row"
                                    gap={"10px"}
                                  >
                                    <IconButton
                                      onClick={() =>
                                        props.layerManager.toggleLayerVisibility(
                                          selectedLayer.id
                                        )
                                      }
                                    >
                                      {selectedLayer.visible ? (
                                        <VisibilityIcon />
                                      ) : (
                                        <VisibilityOffIcon />
                                      )}
                                    </IconButton>
                                    <Typography
                                      width="100px"
                                      fontSize="small"
                                      noWrap
                                      variant="body2"
                                    >
                                      {selectedLayer.displayName}
                                    </Typography>
                                    <Box marginLeft={"auto"}>
                                      <IconButton
                                        onClick={(e) => {
                                          handleMoreClick(e, selectedLayer);
                                        }}
                                      >
                                        <MoreHorizIcon />
                                      </IconButton>

                                      <IconButton
                                        onClick={() =>
                                          props.layerManager.removeLayer(
                                            selectedLayer.id
                                          )
                                        }
                                      >
                                        <CloseIcon />
                                      </IconButton>
                                    </Box>
                                  </Box>
                                </Tooltip>
                              </div>
                            )}
                          </Draggable>
                        )
                      )}
                    </Box>
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <Autocomplete
              value={null}
              blurOnSelect={true}
              size="small"
              onChange={(e, layerId) => {
                if (!layerId) {
                  return;
                }
                props.layerManager.addLayer(layerId);
              }}
              id="controllable-states-demo"
              options={props.layerManager.layers.map(
                (item) => item.displayName
              )}
              getOptionDisabled={(option) =>
                props.layerManager.selectedLayers
                  .map((sl) => sl.displayName)
                  .includes(option)
              }
              sx={{ width: "256px" }}
              renderInput={(params) => <TextField {...params} />}
            />
          </CardContent>
        </Card>
      )}
    </>
  );
};

const useStyles = makeStyles({
  expanded: {
    width: "290px",
    maxHeight: "100%",
    flexGrow: 1,
    alignSelf: "flex-end",
  },
  minimized: {
    width: "40px",
    height: "40px",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    alignSelf: "flex-end",
  },
});
