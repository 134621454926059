import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  GridColDef,
  GridRenderCellParams,
  GridValidRowModel,
  GridValueFormatter,
} from "@mui/x-data-grid-premium";
import { useCustomSnackbars } from "components/snackbars/useCustomSnackbars";
import { IdentityState } from "contexts/identity-context";
import { Dataset } from "data/models";
import { fetchAdminDatasetsLink } from "data/queries";
import {
  DisplayFormatMapping,
  DisplayStateMapping,
  ResponsiveButton,
  isDownloadable,
  renderStatusChip,
} from "pages/geoscape-admin/datasets/columns-rows";
import { formatDate } from "utils";
import { formatBytes } from "utils/commons";

const customColumns = (identityState: IdentityState): GridColDef[] => {
  const { enqueueLinkCorruptedSnackbar } = useCustomSnackbars();

  return [
    {
      flex: 1.5,
      field: "name",
      headerName: "Product",
    },
    {
      flex: 1.2,
      field: "release",
      headerName: "Release",
      valueFormatter(params) {
        const [year, month] = (params as string).split("-").map(Number);
        const monthName = new Date(year, month - 1).toLocaleString("default", {
          month: "long",
        });
        return `${monthName} ${year}`;
      },
    },
    {
      flex: 1,
      field: "region",
      headerName: "Region",
      valueFormatter(params) {
        return DisplayStateMapping[params as string] ??  params as string;
      },
    },
    {
      flex: 1,
      field: "format",
      headerName: "Format",
      valueFormatter(params) {
        return DisplayFormatMapping[params as string] ?? params as string;
      },
    },
    {
      flex: 1,
      field: "datum",
      headerName: "Datum",
    },
    {
      flex: 1,
      field: "size",
      headerName: "File Size",
      valueFormatter(params) {
        return formatBytes(params as number);
      },
    },
    {
      flex: 1,
      field: "publishDate",
      headerName: "Publish Date",
      valueFormatter(params) {
        if (params) return formatDate(params as string);
        return "";
      },
    },
    {
      flex: 1,
      field: "status",
      headerName: "Status",
      renderCell: (params: GridRenderCellParams) => renderStatusChip(params.row.status),
    },
    {
      flex: 1.2,
      field: "id",
      headerName: "Download",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <ResponsiveButton
            variant="contained"
            fullWidth
            disabled={!isDownloadable(params.row.status)}
            startIcon={<FileDownloadIcon />}
            onClick={async () => {
              try {
                const response = await fetchAdminDatasetsLink(
                  params.value,
                  identityState
                );

                const a = document.createElement("a");
                a.href = response;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(response);
              } catch (er) {
                enqueueLinkCorruptedSnackbar();
                // If this happens it will show and popup
              }
            }}
          >
            Download
          </ResponsiveButton>
        );
      },
    },
  ];
};

const datasetsToRows = (datasetList: Dataset[]): GridValidRowModel[] => {
  const rows = datasetList.map((key: Dataset) => {
    return {
      id: key.id,
      name: key.name,
      product: key.product,
      region: key.region,
      format: key.format,
      size: key.size,
      release: key.release,
      publishDate: key.publishDate,
      status: key.status,
      datum: key.datum,
    };
  });

  return rows;
};

export { customColumns, datasetsToRows };
