import { Box, Button, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { ClipConfigProps, ClipStepsControlProps } from "../../clip-menu";
import { ClipConfiguration } from "../../models";
import { HistoricClips } from "./historic-clips";
import { RecentClips } from "./recent-clips";

export const MAX_CLIPS = 5;

export const NewClip = (props: ClipConfigProps & ClipStepsControlProps) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={1}
      height="calc(86vh - 64px)"
    >
      {!showMore && (
        <>
          <Typography variant="h5">New</Typography>
          <TextField
            fullWidth
            inputProps={{ maxLength: 200 }}
            label="Name"
            value={props.clipConfig.name}
            onChange={(event) =>
              props.setClipConfig((prev: ClipConfiguration) => ({
                ...prev,
                name: event.target.value,
              }))
            }
            onKeyDown={(ev) => {
              if (ev.key === "Enter" && !!props.clipConfig.name) {
                props.draw.deleteAll();
                props.setActiveStep(
                  Math.min(props.activeStep + 1, props.maxSteps)
                );
                ev.preventDefault();
              }
            }}
            variant="outlined"
            size="small"
          />
          <Button
            sx={{ alignSelf: "flex-end" }}
            variant="text"
            disabled={!props.clipConfig.name}
            onClick={() => {
              props.draw.deleteAll();
              props.setActiveStep(
                Math.min(props.activeStep + 1, props.maxSteps)
              );
            }}
          >
            Create
          </Button>
          <Typography variant="h5">Recent</Typography>

          <RecentClips
            onSelect={(clip: ClipConfiguration) => {
              props.setActiveStep(clip.activeStep);
              props.setClipConfig(clip);
            }}
            mapRef={props.mapRef}
            maxClips={MAX_CLIPS}
            clips={props.clips}
            draw={props.draw}
          ></RecentClips>
          {!!props.clips.length && props.clips.length > MAX_CLIPS && (
            <Box alignSelf="flex-end" marginTop="auto" display="flex">
              <Button onClick={() => setShowMore(true)}>More</Button>
            </Box>
          )}
        </>
      )}
      {showMore && (
        <HistoricClips {...props} onExit={() => setShowMore(false)} />
      )}
    </Box>
  );
};
