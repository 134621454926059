import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, Card, Stack, TextField } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import ConfirmationModal from "components/modals/confirmation-modal";
import { ReactQueryErrorWrapper } from "components/shared/react-query-error-wrapper";
import CustomTable from "components/shared/table";
import { getActiveOrganisation, useIdentity } from "contexts/identity-context";
import * as QueryKeys from "data";
import { deleteBatchJobs } from "data/mutations";
import { fetchBatches } from "data/queries";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import * as Routes from "routes";
import { resolveIdentityId } from "utils/identity";
import { v4 as uuidv4 } from "uuid";
import { buildColumns } from "./columns-row";
import { ProcessBatchRow, SelectedNamesList } from "./utils";

export const BatchPage = () => {
  const history = useHistory();
  const queryClient = useQueryClient();

  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [selectedNames, setSelectedNames] = useState<string[]>([]);

  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  const [identityState] = useIdentity();
  const isUser = !getActiveOrganisation(identityState);
  const identityId = resolveIdentityId(identityState, isUser);

  const [searchQuery, setSearchQuery] = useState("");

  const batchesQuery = useQuery(
    [QueryKeys.batchesKey, identityId],
    () => fetchBatches(identityState),
    {
      refetchInterval: 5000,
    }
  );

  const batchJobDeleteMutation = useMutation(
    () => deleteBatchJobs(selectedIds, identityState),
    {
      onSuccess: async (response) => {
        await batchesQuery.refetch();
        setDeleteModalIsOpen(false);
      },
    }
  );

  const rows = batchesQuery.isSuccess
    ? batchesQuery.data
        .map((batchConfig: any) => ProcessBatchRow(batchConfig))
        .filter((batchConfig: any) => {
          return (
            batchConfig.displayName.toLowerCase().includes(searchQuery) ||
            batchConfig.id.toLowerCase().includes(searchQuery)
          );
        })
    : [];

  const columns: GridColDef[] = buildColumns({
    batchesQuery,
    identityId,
    identityState,
    queryClient,
  });

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          position: "absolute",
          top: "-50px",
          right: "0px",
        }}
      >
        <Button
          size="medium"
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() =>
            history.push(Routes.batchCreateBatch, { batchId: uuidv4() })
          }
        >
          New Job
        </Button>
      </Box>

      <ReactQueryErrorWrapper
        queries={[batchesQuery]}
        mutations={[batchJobDeleteMutation]}
      />

      <Stack
        sx={{
          gap: "16px",
        }}
      >
        <Card
          sx={{
            padding: "8px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <TextField
            size="small"
            id="batch-search"
            label="Search"
            variant="outlined"
            onChange={(event) =>
              setSearchQuery(event.target.value.toLowerCase())
            }
            value={searchQuery}
            sx={{ width: "300px" }}
          />
          <Button
            size="medium"
            variant="contained"
            endIcon={<DeleteIcon />}
            onClick={() => {
              setDeleteModalIsOpen(true);
            }}
            disabled={selectedIds.length > 0 ? false : true}
          >
            Delete Job
          </Button>

          <ConfirmationModal
            isLoading={
              batchJobDeleteMutation.isLoading || batchesQuery.isLoading
            }
            isError={batchJobDeleteMutation.isError}
            open={deleteModalIsOpen}
            header={`Confirm Delete`}
            body={`Are you sure you want to completely delete ${selectedIds.length} jobs?`}
            body2={
              "This will delete the input and output files along with all related batch job information."
            }
            leftButtonText={"Delete"}
            leftButtonColor="error"
            rightButtonText={"Cancel"}
            rightButtonColor="primary"
            handleLeftButton={() => batchJobDeleteMutation.mutate()}
            handleRightButton={() => setDeleteModalIsOpen(false)}
            handleClose={() => setDeleteModalIsOpen(false)}
            type={"Batch"}
            children={<SelectedNamesList selectedNames={selectedNames} />}
          />
        </Card>

        <CustomTable
          rows={rows}
          cols={columns}
          checkboxSelection={true}
          onSelectionModelChange={(ids) => {
            setSelectedIds(ids as string[]);

            const selectedRowsDataNames = ids.map(
              (selectedRowId) =>
                rows.find((row: any) => row.id === selectedRowId).displayName
            );
            setSelectedNames(selectedRowsDataNames);
          }}
          isRowSelectable={(params: any) => {
            return params.row.status !== "Running";
          }}
          isLoading={batchesQuery.isLoading}
          overlayText={"No batch jobs"}
        />
      </Stack>
    </Box>
  );
};
