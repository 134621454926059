import { bbox } from "@turf/turf";
import { Geometry } from "geojson";
import { MapRef } from "react-map-gl";
import { GeoJSONFeatureCollection } from "./clip/models";

const zoomToFeatureCollection = (mapRef: React.Ref<MapRef>, fc: GeoJSONFeatureCollection) => {
  if(fc.features.length === 0){
    return
  }
  const [minLng, minLat, maxLng, maxLat] = bbox(fc);
  // @ts-ignore
  mapRef.current?.fitBounds(
    [
      [minLng, minLat],
      [maxLng, maxLat],
    ],
    { padding: 200, duration: 1000 }
  );
}


const zoomToFeature = (mapRef: React.Ref<MapRef>, geometry: Geometry) =>{
    const [minLng, minLat, maxLng, maxLat] = bbox(geometry);

    // @ts-ignore
    mapRef.current?.fitBounds(
      [
        [minLng, minLat],
        [maxLng, maxLat],
      ],
      { padding: 200, duration: 1000 }
    );
  }

export { zoomToFeature, zoomToFeatureCollection };
