import { LoadingButton } from "@mui/lab";
import { Alert, Box } from "@mui/material";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { BaseState } from "common/models";
import ConfirmationModal from "components/modals/confirmation-modal";
import { BackgroundLetterAvatarsSquare } from "components/shared/shared";
import {
  GetUserIfLoggedIn,
  IdentityState,
  getActiveOrganisation,
  useIdentity,
} from "contexts/identity-context";
import { UserMembership } from "data/models";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { makeApiRequestPostToUrl, makeApiRequestToUrl } from "utils/api-client";

interface UserInvitationsState extends BaseState {
  accepted?: boolean;
  userInvitations: UserMembership[];
}

async function fetchUserInvitations(
  setState: Dispatch<SetStateAction<UserInvitationsState>>,
  identityState: IdentityState,
  setIdentityState: Dispatch<SetStateAction<IdentityState>>
) {
  setState((prevState) => {
    return {
      ...prevState,
      isError: false,
      isLoading: true,
    };
  });

  const response = await makeApiRequestToUrl(
    `${import.meta.env.VITE_DELIVERY_API_URL}`,
    "/user/membership?byEmail=true",
    "GET"
  );

  const userInvitations: UserMembership[] = await response.json();
  if (response.status !== 200) {
    setState((prevState) => {
      return {
        ...prevState,
        message: "An error occurred while fetching the invitations",
        isError: true,
        isLoading: false,
      };
    });
  } else {
    setState((prevState) => {
      return {
        ...prevState,
        userInvitations: userInvitations,
        isError: false,
        isLoading: false,
      };
    });
  }
}

export const UserInvitations = () => {
  const initialUserInvitationsState: UserInvitationsState = {
    message: "",
    isError: false,
    isLoading: false,
    userInvitations: [],
  };
  const [userInvitationsState, setUserInvitationsState] =
    useState<UserInvitationsState>(initialUserInvitationsState);

  const [identity, setIdentity] = useIdentity();

  const activeOrg = getActiveOrganisation(identity);

  useEffect(() => {
    fetchUserInvitations(setUserInvitationsState, identity, setIdentity);
  }, [identity, setIdentity]);

  // Modal confirmation rejected
  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const initialUserInvitation = {
    organisationApigeeDeveloperId: "",
    apigeeDeveloperId: "",
    status: "",
    organisationName: "",
    developerName: "",
    developerEmail: "",
    organisationEmail: "",
  };

  const [invitation, setInvitation] = useState<UserMembership>(
    initialUserInvitation
  );

  const handleDelete = (invitation: UserMembership) => {
    setInvitation(invitation);
    setOrganisationName(invitation.organisationName);

    setDeleteOpen(true);
  };
  const [organisationName, setOrganisationName] = useState("");

  // Delete Modal State
  const [deleteOpen, setDeleteOpen] = useState(false);

  async function acceptInvitation(
    invitation: UserMembership,
    userInvitationsState: UserInvitationsState,
    setUserInvitationsState: Dispatch<SetStateAction<UserInvitationsState>>,
    identityState: IdentityState,
    setIdentityState: Dispatch<SetStateAction<IdentityState>>
  ) {
    setUserInvitationsState((prevState) => {
      return {
        ...prevState,
        message: "",
        isError: false,
        isLoading: true,
      };
    });
    const response = await makeApiRequestPostToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      "/user/membership",
      "POST",
      {
        organisationApigeeDeveloperId: invitation.organisationApigeeDeveloperId,
      }
    );

    if (response.status !== 200) {
      const errorMessage = (await response.json()).messages.join(". ");
      setUserInvitationsState((prevState) => {
        return {
          ...prevState,
          message: errorMessage,
          isError: true,
          isLoading: false,
        };
      });
    } else {
      handleDeleteClose();
      const invitationList: UserMembership[] =
        userInvitationsState.userInvitations;
      const deleteIndex = invitationList.indexOf(invitation, 0);
      if (deleteIndex > -1) {
        invitationList.splice(deleteIndex, 1);
      }
      setUserInvitationsState((prevState) => {
        return {
          ...prevState,
          message: `Invitation from "${invitation.organisationName}" has been accepted`,
          isError: false,
          isLoading: false,
          userInvitations: invitationList,
          accepted: true,
        };
      });
      setIdentityState((prevIdentity: IdentityState) => {
        const user = GetUserIfLoggedIn(prevIdentity);
        if (user) {
          return {
            ...user,
            organisations: [
              ...user.organisations,
              {
                name: invitation.organisationName,
                id: invitation.organisationApigeeDeveloperId,
                active: false,
              },
            ],
          };
        } else {
          return prevIdentity;
        }
      });
    }
  }

  async function rejectInvitation(
    invitation: UserMembership,
    userInvitationsState: UserInvitationsState,
    setUserInvitationsState: Dispatch<SetStateAction<UserInvitationsState>>,
    identityState: IdentityState,
    setIdentityState: Dispatch<SetStateAction<IdentityState>>
  ) {
    setUserInvitationsState((prevState) => {
      return {
        ...prevState,
        message: "",
        isError: false,
        isLoading: true,
      };
    });
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/user/membership/${invitation.organisationApigeeDeveloperId}`,
      "DELETE"
    );

    if (response.status !== 200) {
      const errorMessage = (await response.json()).messages.join(". ");
      setUserInvitationsState((prevState) => {
        return {
          ...prevState,
          message: errorMessage,
          isError: true,
          isLoading: false,
        };
      });
    } else {
      handleDeleteClose();
      const invitationList: UserMembership[] =
        userInvitationsState.userInvitations;
      const deleteIndex = invitationList.indexOf(invitation, 0);
      if (deleteIndex > -1) {
        invitationList.splice(deleteIndex, 1);
      }
      setUserInvitationsState((prevState) => {
        return {
          ...prevState,
          message: `Invitation from "${invitation.organisationName}" has been rejected`,
          isError: false,
          isLoading: false,
          userInvitations: invitationList,
          accepted: true,
        };
      });
      setIdentityState((prevIdentity: IdentityState) => {
        const user = GetUserIfLoggedIn(prevIdentity);
        if (user) {
          return {
            ...user,
            organisations: [
              ...user.organisations,
              {
                name: invitation.organisationName,
                id: invitation.organisationApigeeDeveloperId,
                active: false,
              },
            ],
          };
        } else {
          return prevIdentity;
        }
      });
    }
  }

  const rows = userInvitationsState.userInvitations
    .filter((invitation) => invitation.status === "Pending")
    .map((invitation): any => {
      return {
        id: invitation.organisationName,
        invitation: invitation,
        organisationName: invitation.organisationName,
        actions: invitation.status,
      };
    })
    .sort((a, b) => {
      const nameA = a.organisationName.toUpperCase(); // ignore upper and lowercase
      const nameB = b.organisationName.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });

  const columns = [
    {
      flex: 3,
      field: "organisationName",
      headerName: "Organisation Name",
      disableColumnSelector: true,
      disableSelectionOnClick: true,
      hideable: false,
      renderCell: (params: any) => (
        <div
          style={{
            display: "flex",
            gap: "20px",
            alignItems: "center",
          }}
        >
          <BackgroundLetterAvatarsSquare
            name={params.row.organisationName}
            activeOrg={activeOrg}
            isLoading={userInvitationsState.isLoading}
          />
          {params.row.organisationName}
        </div>
      ),
    },
    {
      flex: 1,
      field: "actions",
      headerName: "Actions",

      renderCell: (params: any) => (
        <Box style={{ display: "flex", gap: "50px", margin: "5px" }}>
          <LoadingButton
            size="medium"
            disabled={
              params.row.invitation.status === "Accepted" ||
              userInvitationsState.isLoading
            }
            color={
              params.row.invitation.status === "Accepted"
                ? "secondary"
                : "success"
            }
            variant="outlined"
            onClick={() =>
              acceptInvitation(
                params.row.invitation,
                userInvitationsState,
                setUserInvitationsState,
                identity,
                setIdentity
              )
            }
          >
            {params.row.invitation.status === "Accepted"
              ? "Accepted"
              : "Accept"}
          </LoadingButton>

          <LoadingButton
            size="medium"
            disabled={
              params.row.invitation.status === "Accepted" ||
              userInvitationsState.isLoading
            }
            color={
              params.row.invitation.status === "Accepted" ? "error" : "error"
            }
            variant="outlined"
            onClick={() => {
              handleDelete(params.row.invitation);
            }}
          >
            {params.row.invitation.status === "Accepted"
              ? "Accepted"
              : "Reject"}
          </LoadingButton>
        </Box>
      ),
    },
  ];

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  return (
    <>
      <Box sx={{ m: "20px" }} />
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          height: "100%",
          position: "relative",
          // backgroundColor: "blue",
        }}
      >
        <Box
          hidden={!Boolean(userInvitationsState.message)}
          marginBottom={1}
          pt={2}
        >
          <Alert
            variant="filled"
            severity={
              userInvitationsState.isError
                ? "error"
                : userInvitationsState.accepted
                ? "success"
                : "info"
            }
          >
            {userInvitationsState.message}
          </Alert>
        </Box>

        <ConfirmationModal
          isLoading={userInvitationsState.isLoading}
          isError={userInvitationsState.isError}
          open={deleteOpen}
          // header={`Delete "${selectedKey?.name}"`}
          header={`Are you sure?`}
          body={`You won't be able to join "${organisationName}" again unless you receive another invitation.`}
          leftButtonText={"Yes, reject the invitation"}
          leftButtonColor="error"
          rightButtonText={"Cancel"}
          rightButtonColor="primary"
          handleClose={() => handleDeleteClose()}
          handleLeftButton={() => {
            rejectInvitation(
              invitation,
              userInvitationsState,
              setUserInvitationsState,
              identity,
              setIdentity
            );
          }}
          handleRightButton={() => handleDeleteClose()}
        ></ConfirmationModal>

        <DataGridPremium
          checkboxSelection={false}
          loading={userInvitationsState.isLoading}
          rows={rows}
          columns={columns}
          autoHeight={true}
          pageSizeOptions={[10, 25, 50, 100]}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pagination
        />
      </Box>
    </>
  );
};
