import { Dispatch, SetStateAction, useState } from "react";
import {
  CRS,
  Locality,
  LocalityResult,
  State,
  SuggestLocality,
} from "./models";

export interface PredictiveSuburbManager {
  maxResults: number;
  setMaxResults: Dispatch<SetStateAction<number>>;
  stateFilter: State[];
  setStateFilter: Dispatch<SetStateAction<State[]>>;
  crs: CRS;
  setCrs: Dispatch<SetStateAction<CRS>>;
  postcode: string;
  setPostcode: Dispatch<SetStateAction<string>>;
  debounce: number;
  setDebounce: Dispatch<SetStateAction<number>>;
  locality: Locality | null;
  setLocality: Dispatch<SetStateAction<Locality | null>>;
  prediction: LocalityResult | null;
  setPrediction: Dispatch<SetStateAction<LocalityResult | null>>;
  creditCounter: number;
  setCreditCounter: Dispatch<SetStateAction<number>>;
  callCounter: number;
  setCallCounter: Dispatch<SetStateAction<number>>;
  query: string;
  setQuery: Dispatch<SetStateAction<string>>;
  suggest: SuggestLocality | null;
  setSuggest: Dispatch<SetStateAction<SuggestLocality | null>>;
}

export const usePredictiveSuburbManager = (): PredictiveSuburbManager => {
  //Demo Controls States
  const [maxResults, setMaxResults] = useState(5);
  const [stateFilter, setStateFilter] = useState<State[]>([]);
  const [crs, setCrs] = useState<CRS>(
    "<https://www.opengis.net/def/crs/EPSG/0/4283>"
  );
  const [debounce, setDebounce] = useState(100);
  const [postcode, setPostcode] = useState("");

  // Locality Entry
  const [locality, setLocality] = useState<Locality | null>(null);
  const [prediction, setPrediction] = useState<LocalityResult | null>(null);

  const [creditCounter, setCreditCounter] = useState<number>(0);
  const [callCounter, setCallCounter] = useState<number>(0);
  const [query, setQuery] = useState("");
  const [suggest, setSuggest] = useState<SuggestLocality | null>(null);

  return {
    maxResults,
    setMaxResults,
    stateFilter,
    setStateFilter,
    crs,
    setCrs,
    postcode,
    setPostcode,
    debounce,
    setDebounce,
    locality,
    setLocality,
    prediction,
    setPrediction,
    creditCounter,
    setCreditCounter,
    callCounter,
    setCallCounter,
    query,
    setQuery,
    suggest,
    setSuggest,
  };
};
