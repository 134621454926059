const STATE_BOUNDARIES = "STATE BOUNDARIES";
const GEOSCAPE_PLANNING = "GEOSCAPE PLANNING";
const GEOSCAPE_PLANNING_INSIGHTS = "GEOSCAPE PLANNING INSIGHTS";
const GEOSCAPE_PLANNING_INSIGHTS_BASE = "GEOSCAPE PLANNING INSIGHTS - BASE";
const G_NAF = "G-NAF";
const G_NAF_CORE = "G-NAF CORE";
const GEOSCAPE_TREES = "GEOSCAPE TREES";
const GEOSCAPE_PROPERTY = "GEOSCAPE PROPERTY";
const GEOSCAPE_WARDS = "GEOSCAPE WARDS";
const ELECTORAL_BOUNDARIES = "ELECTORAL BOUNDARIES";
const POSTCODE_BOUNDARIES = "POSTCODE BOUNDARIES";
const ABS_BOUNDARIES = "ABS BOUNDARIES";
const GEOSCAPE_BUILDINGS = "GEOSCAPE BUILDINGS";
const TOWN_POINTS = "TOWN POINTS";
const GEOSCAPE_CADASTRE = "GEOSCAPE CADASTRE";
const GEOSCAPE_ROADS = "GEOSCAPE ROADS";
const GEOSCAPE_GREENSPACE = "GEOSCAPE GREENSPACE";
const GEOSCAPE_AIRPORTS = "GEOSCAPE AIRPORTS";
const GEOSCAPE_RAIL = "GEOSCAPE RAIL";
const GEOSCAPE_SURFACE_COVER = "GEOSCAPE SURFACE COVER";
const GEOSCAPE_HYDROLOGY = "GEOSCAPE HYDROLOGY";
const ADMINISTRATIVE_BOUNDARIES = "ADMINISTRATIVE BOUNDARIES";
const GEOSCAPE_LOCALITIES = "GEOSCAPE LOCALITIES";
const GEOSCAPE_LOCAL_GOVERNMENT_AREAS = "GEOSCAPE LOCAL GOVERNMENT AREAS";
const GEOSCAPE_SOLAR = "GEOSCAPE SOLAR";

const products = [
  ABS_BOUNDARIES,
  ADMINISTRATIVE_BOUNDARIES,
  ELECTORAL_BOUNDARIES,
  G_NAF,
  G_NAF_CORE,
  GEOSCAPE_AIRPORTS,
  GEOSCAPE_BUILDINGS,
  GEOSCAPE_CADASTRE,
  GEOSCAPE_GREENSPACE,
  GEOSCAPE_HYDROLOGY,
  GEOSCAPE_LOCAL_GOVERNMENT_AREAS,
  GEOSCAPE_LOCALITIES,
  GEOSCAPE_PLANNING,
  GEOSCAPE_PROPERTY,
  GEOSCAPE_RAIL,
  GEOSCAPE_ROADS,
  GEOSCAPE_SOLAR,
  GEOSCAPE_SURFACE_COVER,
  GEOSCAPE_TREES,
  GEOSCAPE_WARDS,
  POSTCODE_BOUNDARIES,
  STATE_BOUNDARIES,
  TOWN_POINTS,
];

type Product =
  | "STATE BOUNDARIES"
  | "GEOSCAPE PLANNING"
  | "GEOSCAPE PLANNING INSIGHTS"
  | "GEOSCAPE PLANNING INSIGHTS - BASE"
  | "G-NAF"
  | "G-NAF CORE"
  | "GEOSCAPE TREES"
  | "GEOSCAPE PROPERTY"
  | "GEOSCAPE WARDS"
  | "ELECTORAL BOUNDARIES"
  | "POSTCODE BOUNDARIES"
  | "ABS BOUNDARIES"
  | "GEOSCAPE BUILDINGS"
  | "TOWN POINTS"
  | "GEOSCAPE CADASTRE"
  | "GEOSCAPE ROADS"
  | "GEOSCAPE GREENSPACE"
  | "GEOSCAPE AIRPORTS"
  | "GEOSCAPE RAIL"
  | "GEOSCAPE SURFACE COVER"
  | "GEOSCAPE HYDROLOGY"
  | "ADMINISTRATIVE BOUNDARIES"
  | "GEOSCAPE LOCALITIES"
  | "GEOSCAPE LOCAL GOVERNMENT AREAS"
  | "GEOSCAPE SOLAR";

const AUSTRALIA = "AUSTRALIA";
const ALL_STATES = "ALL STATES";
const ACTNSW = "ACTNSW";
const ACT = "ACT";
const NSW = "NSW";
const NT = "NT";
const QLD = "QLD";
const SA = "SA";
const TAS = "TAS";
const VIC = "VIC";
const WA = "WA";
const OT = "OT";

const regions = [
  AUSTRALIA,
  ALL_STATES,
  ACTNSW,
  ACT,
  NSW,
  NT,
  OT,
  QLD,
  SA,
  TAS,
  VIC,
  WA,
];

const GDA94 = "GDA94";
const GDA2020 = "GDA2020";
const NODATUM = "NO DATUM";

const datumValues = [GDA94, GDA2020, NODATUM];

const GDB = "GDB";
const GEOJSON = "GEOJSON";
const SHP = "SHP";
const TAB = "TAB";
const LYNX = "LYNX";
const PUMP = "PUMP";
const GEOTIFF = "GEOTIFF";
const PSV = "PSV";

const formats = [GDB, GEOJSON, GEOTIFF, LYNX, PSV, PUMP, SHP, TAB];

const productCodeToNameMappings = new Map<string, string>([
  [STATE_BOUNDARIES, "State Boundaries"],
  [GEOSCAPE_PLANNING, "Geoscape Planning"],
  [GEOSCAPE_PLANNING_INSIGHTS, "Geoscape Planning Insights"],
  [GEOSCAPE_PLANNING_INSIGHTS_BASE, "Geoscape Planning Insights - Base"],
  [G_NAF, "G-NAF"],
  [G_NAF_CORE, "G-NAF Core"],
  [GEOSCAPE_TREES, "Geoscape Trees"],
  [GEOSCAPE_PROPERTY, "Geoscape Property"],
  [GEOSCAPE_WARDS, "Geoscape Wards"],
  [ELECTORAL_BOUNDARIES, "Electoral Boundaries"],
  [POSTCODE_BOUNDARIES, "Postcode Boundaries"],
  [ABS_BOUNDARIES, "ABS Boundaries"],
  [GEOSCAPE_BUILDINGS, "Geoscape Buildings"],
  [TOWN_POINTS, "Town Points"],
  [GEOSCAPE_CADASTRE, "Geoscape Cadastre"],
  [GEOSCAPE_ROADS, "Geoscape Roads"],
  [GEOSCAPE_GREENSPACE, "Geoscape Greenspace"],
  [GEOSCAPE_AIRPORTS, "Geoscape Airports"],
  [GEOSCAPE_RAIL, "Geoscape Rail"],
  [GEOSCAPE_SURFACE_COVER, "Geoscape Surface Cover"],
  [GEOSCAPE_HYDROLOGY, "Geoscape Hydrology"],
  [ADMINISTRATIVE_BOUNDARIES, "Administrative Boundaries"],
  [GEOSCAPE_LOCALITIES, "Geoscape Localities"],
  [GEOSCAPE_LOCAL_GOVERNMENT_AREAS, "Geoscape Local Government Areas"],
  [GEOSCAPE_SOLAR, "Geoscape Solar"],
]);

const explorerLayerIdToProductCodeMappings = new Map<string, string>([
  ["cadastre__cadastre", GEOSCAPE_CADASTRE],
  ["solar__solar", GEOSCAPE_SOLAR],
  ["state__state", STATE_BOUNDARIES],
  ["rail__railway_line", GEOSCAPE_RAIL],
  ["rail__railway_station", GEOSCAPE_RAIL],
  [
    "local_government_areas__local_government_areas",
    GEOSCAPE_LOCAL_GOVERNMENT_AREAS,
  ],
  ["airport__airport_points", GEOSCAPE_AIRPORTS],
  ["airport__airport_lines", GEOSCAPE_AIRPORTS],
  ["airport__airport_polygon", GEOSCAPE_AIRPORTS],
  ["gnaf__gnaf", G_NAF],
  ["country__country", ""],
  ["electoral__commonwealth", ELECTORAL_BOUNDARIES],
  ["electoral__state", ELECTORAL_BOUNDARIES],
  ["localities__localities", GEOSCAPE_LOCALITIES],
  ["postcodes__postcode_points", POSTCODE_BOUNDARIES],
  ["postcodes__postcode_polygons", POSTCODE_BOUNDARIES],
  ["wards__wards", GEOSCAPE_WARDS],
  ["roads__roads", GEOSCAPE_ROADS],
  ["buildings__buildings", GEOSCAPE_BUILDINGS],
  ["property__property", GEOSCAPE_PROPERTY],
  ["roads__roads", GEOSCAPE_ROADS],
  ["abs_2021_asgs_main__mesh_blocks", ABS_BOUNDARIES],
  ["abs_2021_asgs_main__statistical_area_1", ABS_BOUNDARIES],
  ["abs_2021_asgs_main__statistical_area_2", ABS_BOUNDARIES],
  ["abs_2021_asgs_main__statistical_area_3", ABS_BOUNDARIES],
  ["abs_2021_asgs_main__statistical_area_4", ABS_BOUNDARIES],
  ["abs_2021_asgs_main__greater_capital_city", ABS_BOUNDARIES],
  ["abs_2021_asgs_remoteness__remoteness_areas", ABS_BOUNDARIES],
  ["abs_2021_asgs_significant_urban__significant_urban", ABS_BOUNDARIES],
  ["abs_2021_asgs_significant_urban__section_of_state", ABS_BOUNDARIES],
  ["abs_2021_asgs_significant_urban__section_of_state_range", ABS_BOUNDARIES],
  ["abs_2021_asgs_significant_urban__urban_centre", ABS_BOUNDARIES],
  ["abs_2021_asgs_indigenous__indigenous_regions", ABS_BOUNDARIES],
  ["abs_2021_asgs_indigenous__indigenous_areas", ABS_BOUNDARIES],
  ["abs_2021_asgs_indigenous__indigenous_locations", ABS_BOUNDARIES],
]);

const productCodeToExplorerLayerIdMappings = new Map<string, string[] | null>([
  [STATE_BOUNDARIES, ["state__state"]],
  [GEOSCAPE_PLANNING, null],
  [GEOSCAPE_PLANNING_INSIGHTS, null],
  [GEOSCAPE_PLANNING_INSIGHTS_BASE, null],
  [G_NAF, ["gnaf__gnaf"]],
  [G_NAF_CORE, ["gnaf__gnaf"]],
  [GEOSCAPE_TREES, null],
  [GEOSCAPE_PROPERTY, ["property__property"]],
  [GEOSCAPE_WARDS, ["wards__wards"]],
  [ELECTORAL_BOUNDARIES, ["electoral__commonwealth", "electoral__state"]],
  [
    POSTCODE_BOUNDARIES,
    ["postcodes__postcode_points", "postcodes__postcode_polygons"],
  ],
  [ABS_BOUNDARIES, null],
  [GEOSCAPE_BUILDINGS, ["buildings__buildings"]],
  [TOWN_POINTS, null],
  [GEOSCAPE_CADASTRE, ["cadastre__cadastre"]],
  [GEOSCAPE_ROADS, ["roads__roads"]],
  [GEOSCAPE_GREENSPACE, null],
  [
    GEOSCAPE_AIRPORTS,
    [
      "airport__airport_points",
      "airport__airport_lines",
      "airport__airport_polygon",
    ],
  ],
  [GEOSCAPE_RAIL, ["rail__railway_line", "rail__railway_station"]],
  [GEOSCAPE_SURFACE_COVER, null],
  [GEOSCAPE_HYDROLOGY, null],
  [ADMINISTRATIVE_BOUNDARIES, null],
  [GEOSCAPE_LOCALITIES, ["localities__localities"]],
  [
    GEOSCAPE_LOCAL_GOVERNMENT_AREAS,
    ["local_government_areas__local_government_areas"],
  ],
  [GEOSCAPE_SOLAR, ["solar__solar"]],
  [ABS_BOUNDARIES, ["abs_2021_asgs_main__mesh_blocks"]],
]);

export {
  products,
  regions,
  datumValues,
  formats,
  productCodeToNameMappings,
  explorerLayerIdToProductCodeMappings,
  productCodeToExplorerLayerIdMappings,
};

export type { Product };
