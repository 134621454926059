import { replace } from "formik";
import { HSLColour } from "./models";
import { toTitleCase } from "utils/commons";

const replacementMappings = new Map<string, string>([
  ["Gnaf", "G-NAF"],
  ["Abs", "ABS"],
  ["Asgs", "ASGS"],
]);

export const humanizeLayerName = (
  layerId: string,
  sourceId: string
): string => {
  let prettyLayerName: string = toTitleCase(layerId.replaceAll("_", " "));
  let prettySourceName: string = toTitleCase(sourceId.replaceAll("_", " "));

  replacementMappings.forEach((value, key) => {
    prettyLayerName = prettyLayerName.replaceAll(key, value);
    prettySourceName = prettySourceName.replaceAll(key, value);
  });

  if (layerId.startsWith(sourceId)) {
    return prettyLayerName;
  }
  return `${prettySourceName} - ${prettyLayerName}`;
};

export const layerIdToPrettyFormat = (layerId: string): string => {
  return humanizeLayerName(
    layerId.split("__")[0].replaceAll("_", " "),
    layerId.split("__")[1].replaceAll("_", " ")
  );
};

export const getUniqueLayerId = (layerId: string, sourceId: string) => {
  return `${sourceId}__${layerId}`;
};

export const getRawLayerId = (layerId: string) => {
  return layerId.split("__")[1];
};

export const getRawSourceId = (layerId: string) => {
  return layerId.split("__")[0];
};

export const convertHexToRGBA = (hexCode: string, opacity = 1) => {
  let hex = hexCode.replace("#", "");

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  /* Backward compatibility for whole number based opacity values. */
  if (opacity > 1 && opacity <= 100) {
    opacity = opacity / 100;
  }

  return `rgba(${r},${g},${b},${opacity})`;
};

export function hexToHSL(hex: string): HSLColour {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!result) {
    console.error(`Couldnt parse hex string ${hex}`);
    return { h: 1, s: 1, l: 1 };
  }

  let r = parseInt(result[1], 16);
  let g = parseInt(result[2], 16);
  let b = parseInt(result[3], 16);

  r /= 255;
  g /= 255;
  b /= 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h,
    s,
    l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
      default:
        // Should never get here
        h = 1;
    }
    h /= 6;
    h *= 360;
    s *= 100;
    l *= 100;
  }

  const HSL: HSLColour = {
    h,
    s,
    l,
  };

  return HSL;
}
