import { GridColDef } from "@mui/x-data-grid-premium";

export const cols: GridColDef[] = [
  { field: "keyName", headerName: "API Key", width: 200 },
  { field: "addresses", headerName: "Addresses API", width: 110 },

  { field: "buildings", headerName: "Buildings API", width: 110 },
  { field: "batches", headerName: "Batches API", width: 110 },
  { field: "maps", headerName: "Maps API", width: 110 },
  { field: "landparcels", headerName: "Land Parcels API", width: 110 },
  { field: "predictive", headerName: "Predictive API", width: 110 },
  { field: "esri", headerName: "Esri Compatible Locator", width: 110 },
  {
    field: "clip",
    headerName: "Clip",
    width: 80,
  },
  {
    field: "adminbounds",
    headerName: "Administrative Boundaries API",
    width: 150,
  },
];