import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { QuoteSummary, QuoteSummaryItem } from "../../models";

interface SummaryTableProps {
  quoteSummary: QuoteSummary;
  showFeatures: boolean;
  showRate: boolean;
  showCredits: boolean;
  showTotal: boolean;
}

export const SummaryTable = (props: SummaryTableProps) => {
  return (
    <TableContainer sx={{ overflow: "visible" }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell padding="none" align="left" sx={{ fontWeight: "bold" }}>
              Dataset
            </TableCell>
            <TableCell padding="none" align="right" sx={{ fontWeight: "bold" }}>
              {props.showFeatures && "Features"}
            </TableCell>
            <TableCell padding="none" align="right" sx={{ fontWeight: "bold" }}>
              {props.showRate && "Rate"}
            </TableCell>
            <TableCell padding="none" align="right" sx={{ fontWeight: "bold" }}>
              {props.showCredits && "Credits"}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.quoteSummary.items.map((item: QuoteSummaryItem) => (
            <TableRow key={item.name}>
              <TableCell sx={{ padding: 0 }} align="left">
                {item.name}
              </TableCell>

              <TableCell sx={{ padding: 0 }} align="right">
                {props.showFeatures && item.features.toLocaleString()}
              </TableCell>

              <TableCell sx={{ padding: 0 }} align="right">
                {props.showRate && item.rate.toLocaleString()}
              </TableCell>

              <TableCell sx={{ padding: 0 }} align="right">
                {props.showCredits && item.credits.toLocaleString()}
              </TableCell>
            </TableRow>
          ))}
          {props.showTotal && (
            <TableRow>
              <TableCell sx={{ padding: 0 }} align="right">
                <Typography fontWeight="bold" variant="subtitle2">
                  {(props.showCredits || props.showFeatures) && "Total"}
                </Typography>
              </TableCell>

              <TableCell sx={{ padding: 0 }} align="right">
                <Typography fontWeight="bold" variant="subtitle2">
                  {props.showFeatures &&
                    props.quoteSummary.totalFeatures.toLocaleString()}
                </Typography>
              </TableCell>

              <TableCell sx={{ padding: 0 }} align="right">
                <Typography fontWeight="bold" variant="subtitle2">
                  {}
                </Typography>
              </TableCell>

              <TableCell sx={{ padding: 0 }} align="right">
                <Typography fontWeight="bold" variant="subtitle2">
                  {props.showCredits &&
                    props.quoteSummary.totalCredits.toLocaleString()}
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
