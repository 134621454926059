import { DateRange } from "contexts/models";
import { DefinedRange as MuiDefinedRange } from "mui-daterange-picker";

import dayjs from "dayjs";

export const timePeriodFromDateRange = (
  dateRange: DateRange
): [number, number] => {
  const endTime = dayjs().unix();
  var startTime = 0;
  switch (dateRange) {
    case DateRange.DAY: {
      startTime = dayjs().subtract(24, "hours").unix();
      break;
    }
    case DateRange.WEEK: {
      startTime = dayjs().subtract(7, "days").unix();
      break;
    }
    case DateRange.TWO_WEEKS: {
      startTime = dayjs().subtract(14, "days").unix();
      break;
    }
    case DateRange.MONTH: {
      startTime = dayjs().subtract(30, "days").unix();
      break;
    }
    case DateRange.THREE_MONTHS: {
      startTime = dayjs().subtract(90, "days").unix();
      break;
    }
  }
  return [startTime * 1000, endTime * 1000];
};

export const formatterTooltipHeader = (
  params: any,
  intervalHours: string
): string => {
  if (params.length === 0) return "";

  const { axisValue } = params[0];
  let tooltipHeader: string = axisValue;

  switch (intervalHours) {
    case "1h": {
      const nextHour = dayjs(axisValue, "ddd hh:mm A")
        .add(1, "hour")
        .format("hh:mm A");
      tooltipHeader = `${axisValue} - ${nextHour}`;
      break;
    }
    case "1d": {
      tooltipHeader = axisValue;
      break;
    }
    case "1w": {
      const endOfWeek = dayjs(axisValue).add(6, "day").format("D MMM");
      tooltipHeader = `${axisValue} - ${endOfWeek}`;
      break;
    }
    default: {
      tooltipHeader = axisValue;
      break;
    }
  }

  return tooltipHeader;
};

export const formatter = (params: any, intervalHours: string) => {
  var tooltipHeader;

  tooltipHeader = formatterTooltipHeader(params, intervalHours);

  const tooltipList = params
    .filter((param: any) => param.value)
    .map((param: any, index: number) => {
      const html = `<div style="display:flex;">
    <div id="rectangle" style="border-radius: 20px; width:20px; height:20px; background-color:${param.color}"></div>
    <div style="margin-left: 10px;">${param.seriesName}: ${param.value}</div></div>`;
      return html;
    });

  if (tooltipList.length > 0) {
    return tooltipHeader + "<hr/>" + tooltipList.join("");
  }
  return tooltipHeader;
};

export const unixDurationToDateRange = (
  startTime: number,
  endTime: number
): DateRange => {
  const one_hour = 1000 * 60 * 60;
  const one_day = one_hour * 24;
  const one_week = one_day * 7;
  var delta = endTime - startTime;

  if (delta <= one_day) {
    return DateRange.DAY;
  }
  if (delta <= one_week) {
    return DateRange.WEEK;
  }
  if (delta <= one_week * 2) {
    return DateRange.TWO_WEEKS;
  }
  if (delta <= one_day * 30) {
    return DateRange.MONTH;
  }

  return DateRange.THREE_MONTHS;
};

export const preparePreDefinedRanges = (): MuiDefinedRange[] => {
  let preDefinedRanges: MuiDefinedRange[] = [];
  for (let item in DateRange) {
    const startEnd = timePeriodFromDateRange(
      DateRange[item as keyof typeof DateRange]
    );
    preDefinedRanges.push({
      startDate: new Date(startEnd[0]),
      endDate: new Date(startEnd[1]),
      label: DateRange[item as keyof typeof DateRange],
    });
  }
  return preDefinedRanges;
};

export const resolveAppName = (
  appName: string,
  keyNameMapping: Record<string, string>
): string => {
  let keyName;
  if (appName === "") {
    keyName = "Service (No Key)";
  } else if (keyNameMapping[appName]) {
    keyName = keyNameMapping[appName];
  } else {
    keyName = `${appName} (Deleted)`;
  }
  return keyName;
};
