import { modes } from '@mapbox/mapbox-gl-draw';
import {lib} from '@mapbox/mapbox-gl-draw';
import { constants } from '@mapbox/mapbox-gl-draw';

const SimpleSelectModeOverride = {
  ...modes.simple_select,


  clickOnFeature: function(state, e) {
    // Mostly the same as the parent class (simple_select) but with some extra logic to go into 
    // special draw_circle mode when we want to edit a circle 
    var this$1$1 = this;
    
    // Stop everything
    lib.doubleClickZoom.disable(this);
    this.stopExtendedInteractions(state); 
    var isShiftClick = lib.CommonSelectors.isShiftDown(e);
    var selectedFeatureIds = this.getSelectedIds();
    var featureId = e.featureTarget.properties.id;
    var isFeatureSelected = this.isSelected(featureId);
    var feature = this.getFeature(featureId)
    // Click (without shift) on any selected feature but a point
    if (!isShiftClick && isFeatureSelected && feature.type !== constants.geojsonTypes.POINT) {

      // SPECIAL CIRCLE LOGIC
      if (feature.properties.isCircle === true){

        // We will just make a new circle so get rid of the current one
        this.deleteFeature(feature.id)
        
        return this.changeMode("draw_circle", {
          replaceFeature: feature,
          circleId: feature.id,
          e
        });
      }
      // Enter direct select mode
      return this.changeMode("direct_select", {
        featureId: featureId
      });
    }
  
    // Shift-click on a selected feature
    if (isFeatureSelected && isShiftClick) {
      // Deselect it
      this.deselect(featureId);
      this.updateUIClasses({ mouse: constants.cursors.POINTER });
      if (selectedFeatureIds.length === 1) {
        doubleClickZoom.enable(this);
      }
    // Shift-click on an unselected feature
    } else if (!isFeatureSelected && isShiftClick) {
      // Add it to the selection
      this.select(featureId);
      this.updateUIClasses({ mouse: constants.cursors.MOVE });
    // Click (without shift) on an unselected feature
    } else if (!isFeatureSelected && !isShiftClick) {
      // Make it the only selected feature
      selectedFeatureIds.forEach(function (id) { return this$1$1.doRender(id); });
      this.setSelected(featureId);
      this.updateUIClasses({ mouse: constants.cursors.MOVE });
    }
  
    // No matter what, re-render the clicked feature
    this.doRender(featureId);
}
  
};
  
export {SimpleSelectModeOverride as SimpleSelectMode};