export type Dataset = "gnaf" | "mailAddress" | "gnaflive";
export const Datasets: Dataset[] = ["gnaf", "mailAddress", "gnaflive"];
export type State = "ACT" | "NSW" | "QLD" | "VIC" | "TAS" | "WA" | "SA" | "NT" | "OT";

export const States: State[] = [
  "ACT",
  "NSW",
  "QLD",
  "VIC",
  "TAS",
  "WA",
  "SA",
  "NT",
  "OT",
];
export type CRS =
  | "<https://www.opengis.net/def/crs/EPSG/0/4283>"
  | "<https://www.opengis.net/def/crs/EPSG/0/7844>"
  | "<https://www.opengis.net/def/crs/EPSG/0/4326>"
  | "<https://www.opengis.net/def/crs/EPSG/0/4202>";

export const CRSValues: CRS[] = [
  "<https://www.opengis.net/def/crs/EPSG/0/4283>",
  "<https://www.opengis.net/def/crs/EPSG/0/7844>",
  "<https://www.opengis.net/def/crs/EPSG/0/4326>",
  "<https://www.opengis.net/def/crs/EPSG/0/4202>",
];
export type AddressType = "mailing" | "physical";
export const AddressTypes: AddressType[] = ["mailing", "physical"];

export const CRSMappings = new Map<CRS, string>([
  ["<https://www.opengis.net/def/crs/EPSG/0/4283>", "GDA94 (EPSG:4283)"],
  ["<https://www.opengis.net/def/crs/EPSG/0/7844>", "GDA2020 (EPSG:7844)"],
  ["<https://www.opengis.net/def/crs/EPSG/0/4326>", "WGS 84 (EPSG:4326)"],
  ["<https://www.opengis.net/def/crs/EPSG/0/4202>", "AGD66 (EPSG:4202)"],
]);

interface AddressResult {
  address: string;
  id: string;
  rank: number;
}

interface Suggest {
  suggest: AddressResult[];
  basictransaction: number;
}

type LiteralType = "Feature";

interface Properties {
  address_identifier: string;
  alias_principal: string;
  cadastral_identifier?: string | null;
  complex_level_number?: string | null;
  complex_level_type?: string | null;
  complex_level_type_description?: string | null;
  complex_unit_identifier?: string | null;
  complex_unit_type?: string | null;
  complex_unit_type_description?: string | null;
  formatted_address: string;
  geo_feature?: string | null;
  gnaf_locality_pid?: string | null;
  gnaf_street_locality_pid?: string | null;
  locality_aliases?: string | null;
  locality_name: string;
  locality_neighbours?: string | null;
  location_descriptor?: string | null;
  lot_identifier?: string | null;
  postal_delivery_number?: string | null;
  postal_delivery_type?: string | null;
  postcode?: string | null;
  primary_secondary: string;
  site_name?: string | null;
  state_territory: string;
  street_aliases?: string | null;
  street_name?: string | null;
  street_number_1?: string | null;
  street_number_2?: string | null;
  street_suffix?: string | null;
  street_type?: string | null;
  street_type_description?: string | null;
}

interface Geometry {
  coordinates: number[];
  type: string;
}

interface Address {
  address: {
    contributor: any[]; // Any type since it's not being used
    dataset: string;
    geometry: Geometry;
    id: string;
    properties: Properties;
    type: LiteralType;
  };
  basictransaction: number;
}

export type { Address, AddressResult, Geometry, Suggest };
