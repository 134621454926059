import { Card, Typography, useTheme } from "@mui/material";
import { useHistory } from "react-router-dom";

const ItemCard = ({ id, item }: any) => {
  const history = useHistory();

  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  const cardStyles = {
    borderRadius: "16px",
    padding: "48px 8px 32px 8px",
    width: "160px",
    height: "160px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    cursor: "pointer",
    // boxShadow: isDark ? "0px 0px 0px 1px #353535" : "0px 0px 0px 1px #E0E0E0",
    "&:hover": {
      backgroundColor: "#1976D214",
    },
  };

  const handleCardClick = () => {
    if (item.target) {
      item.target === "_blank"
        ? window.open(item.path, "_blank")
        : (window.location.href = item.path);
    } else {
      history.push(item.path);
    }
  };

  return (
    <Card
      id={`${id}-key-${item.id}`}
      key={item.id}
      sx={cardStyles}
      onClick={handleCardClick}
      elevation={1}
    >
      <div style={{ width: "36px", height: "36px" }}>{item.icon}</div>
      <Typography variant="h6">{item.title}</Typography>
    </Card>
  );
};

export default ItemCard;
