import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import Switch from "@mui/material/Switch";

import { DateRangePicker } from "../share-component/data-range-picker";
import { MultiSelectFormControl } from "../share-component/multi-select";

export const CustomDatasetFilters = ({
  values,
  handleChange,
  handleSubmit,
  definitions,
  time,
  setTime,
  setFieldValue,
  setQueryFilterState,
  resetForm,
  presetRange,
  setPresetRange,
  initialQueryFilterState,
  queryFilterState,
  localStorageKey,
}: any) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <TextField
          id="query"
          name="query"
          label="Search"
          type="text"
          value={values.query}
          onChange={(e: any) => {
            handleChange(e);
            handleSubmit();
          }}
          variant={"outlined"}
          fullWidth
        />

        <FormGroup
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "0px",
            gap: "16px",
          }}
        >
          <MultiSelectFormControl
            id={"name"}
            label={"Name"}
            size="small"
            options={definitions.isSuccess ? definitions.data.names : []}
            value={values.name}
            onChange={(e: any) => {
              handleChange(e);
              handleSubmit();
            }}
          />

          <DateRangePicker
            time={time}
            setTime={setTime}
            setFieldValue={setFieldValue}
            handleSubmit={handleSubmit}
            presetRange={presetRange}
            setPresetRange={setPresetRange}
            disabled={values.latestRelease}
            queryFilterState={queryFilterState}
          />

          <FormControlLabel
            control={
              <Switch
                checked={values.latestRelease}
                onChange={(e: any) => {
                  handleChange(e);
                  handleSubmit();
                }}
                id={"latestRelease"}
                name={"latestRelease"}
              />
            }
            label={`Latest Release`}
          />

          <Button
            variant="contained"
            size="small"
            color={"info"}
            id={"release"}
            name={"release"}
            onClick={(e) => {
              setTime({ startTime: 0, endTime: 0 });
              setFieldValue("release", "");
              localStorage.removeItem(localStorageKey);
              setQueryFilterState(initialQueryFilterState);
              resetForm();
              handleSubmit();
            }}
          >
            Clear Filters
          </Button>

          {/* </div> */}
        </FormGroup>
      </Box>
    </>
  );
};
