import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  InputLabel,
  Stack,
  Typography,
} from "@mui/material";
import { useCustomSnackbars } from "components/snackbars/useCustomSnackbars";
import { getActiveOrganisation, useIdentity } from "contexts/identity-context";
import * as QueryKeys from "data";
import { fetchAdminClipInvoice, fetchAdminClipSummary } from "data/queries";
import dayjs from "dayjs";
import { ClipConfiguration } from "pages/geoscape-data/explorer/clip/models";
import { SummaryTable } from "pages/geoscape-data/explorer/clip/steps/step-4-summary/summary-table";
import { useQuery } from "react-query";
import { openDownloadLinkCallback } from "utils/download";
import { resolveIdentityId } from "utils/identity";

interface AdminClipDetailsProps {
  clip: ClipConfiguration;
}

export const AdminClipDetails = (props: AdminClipDetailsProps) => {
  const [identityState] = useIdentity();

  const isUser = !getActiveOrganisation(identityState);
  const identityId = resolveIdentityId(identityState, isUser);

  const { enqueueQueryFailed } = useCustomSnackbars();

  const clipSummaryQuery = useQuery(
    [QueryKeys.clipSummaryKey, props.clip.id],
    () => fetchAdminClipSummary(props.clip.id),
    {
      onError: (error: Error) => {
        enqueueQueryFailed(error.toString());
      },
    }
  );

  const clipInvoiceQuery = useQuery(
    [QueryKeys.clipInvoiceKey, props.clip.id],
    () => fetchAdminClipInvoice(props.clip.id),
    {
      enabled: !!props.clip.invoiceId,
      onError: (error: Error) => {
        enqueueQueryFailed(error.toString());
      },
    }
  );

  return (
    <Card
      sx={{
        width: "calc(100% / 3)",
        height: 756,
        padding: "0px",
      }}
    >
      <CardHeader
        title="Clip Details"
        subheader={
          <Stack direction="row" alignItems="center">
            <Typography variant="caption" color="gray">
              {`Clip Id: ${props.clip.id}`}
            </Typography>
            <IconButton
              onClick={() => navigator.clipboard.writeText(props.clip.id)}
            >
              <ContentCopyIcon sx={{ fontSize: "15px", color: "gray" }} />
            </IconButton>
          </Stack>
        }
      />
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "661px",
          "&:last-child": {
            paddingBottom: 1,
          },
        }}
      >
        <Box
          display="flex"
          gap={2}
          flexDirection="column"
          overflow="auto"
          height="100%"
        >
          <Stack direction="column" spacing={2}>
            <Stack direction="row" spacing={4}>
              <Stack direction="column">
                <InputLabel>Account</InputLabel>
                <Typography variant="body2">{props.clip.ownerName}</Typography>
              </Stack>
              <Stack direction="column">
                <InputLabel>User</InputLabel>
                <Typography variant="body2">{props.clip.developer}</Typography>
              </Stack>
            </Stack>
            <Stack direction="row" spacing={10}>
              <Stack direction="column">
                <InputLabel>Date Created</InputLabel>
                <Typography variant="body2">
                  {dayjs(props.clip.date).format("YYYY-MM-DD - HH:mm:ss")}
                </Typography>
              </Stack>
              <Stack direction="column">
                <InputLabel>Date Modified</InputLabel>
                <Typography variant="body2">
                  {props.clip.lastEditedAt &&
                    dayjs(props.clip.lastEditedAt * 1000).format(
                      "YYYY-MM-DD - HH:mm:ss"
                    )}
                </Typography>
              </Stack>
            </Stack>
            <Stack direction="column">
              <InputLabel>Name</InputLabel>
              <Typography variant="body2">{props.clip.name}</Typography>
            </Stack>
            <Stack direction="row" spacing={10}>
              <Stack direction="column">
                <InputLabel>Format</InputLabel>
                <Typography variant="body2">{props.clip.format}</Typography>
              </Stack>
              <Stack direction="column">
                <InputLabel>Coordinate Reference System</InputLabel>
                <Typography variant="body2">{props.clip.crs}</Typography>
              </Stack>
            </Stack>
            {props.clip.clientReference && (
              <Stack direction="column">
                <InputLabel>Client Reference</InputLabel>
                <Typography variant="body2">
                  {props.clip.clientReference}
                </Typography>
              </Stack>
            )}
            {props.clip.notes && (
              <Stack direction="column">
                <InputLabel>Notes</InputLabel>
                <Typography variant="body2">{props.clip.notes}</Typography>
              </Stack>
            )}
          </Stack>

          {clipSummaryQuery.isSuccess && (
            <SummaryTable
              quoteSummary={clipSummaryQuery.data}
              showCredits={true}
              showFeatures={true}
              showRate={true}
              showTotal={true}
            ></SummaryTable>
          )}
          <Typography variant="h5">Invoice</Typography>
          {clipSummaryQuery.isSuccess && !props.clip.invoiceUrl && (
            <Typography variant="subtitle2">{`Credits: ${clipSummaryQuery.data.totalCredits.toLocaleString()}`}</Typography>
          )}
          {clipInvoiceQuery.isSuccess && props.clip.invoiceUrl && (
            <Typography variant="subtitle2">{`Dollars: $${(
              clipInvoiceQuery.data.totalDollars / 100
            ).toLocaleString("en-AU", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}`}</Typography>
          )}
        </Box>
        {props.clip.status === "Completed" && (
          <Box
            alignSelf="flex-end"
            marginTop="auto"
            paddingTop={1}
            display="flex"
          >
            <Button
              disabled={!clipInvoiceQuery.isSuccess}
              onClick={() => {
                window.open(clipInvoiceQuery.data?.invoicePdf, "_blank");
              }}
            >
              Download Invoice
            </Button>
            <Button
              disabled={props.clip.status !== "Completed"}
              variant="text"
              onClick={openDownloadLinkCallback(
                `${import.meta.env.VITE_DELIVERY_API_URL}/admin/clip/${
                  props.clip.id
                }/output`
              )}
            >
              Download
            </Button>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};
