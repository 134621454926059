import { productCodeToNameMappings } from "pages/geoscape-data/constants";
import { toTitleCase } from "utils/commons";

export function formatProductName(productNameRaw: string): string {
  const lookup = productCodeToNameMappings.get(productNameRaw);
  if (lookup) {
    return lookup;
  }

  return toTitleCase(productNameRaw);
}
