import { useCustomSnackbars } from "components/snackbars/useCustomSnackbars";
import * as QueryKeys from "data";
import { fetchAuthJWT, fetchMapsLayerJson } from "data/queries";
import { TileJSON } from "pages/geoscape-data/explorer/layers/models";
import { useState } from "react";
import { CircleLayer, Layer, Source } from "react-map-gl";
import { useQuery } from "react-query";

export const GnafSource = () => {
  const [gnafLayerJson, setGnafLayerJson] = useState<TileJSON | null>(null);
  const { enqueueQueryFailed } = useCustomSnackbars();

  const jwt = useQuery([QueryKeys.jwt], () => fetchAuthJWT(), {
    onError: (error: Error) => {
      enqueueQueryFailed(error.toString());
    },
  });

  const gnafLayerJsonQuery = useQuery(
    [QueryKeys.mapsLayerJson, "gnaf"],
    () => fetchMapsLayerJson(jwt.isSuccess ? jwt.data : "", "gnaf"),
    {
      enabled: !!jwt.data,
      retry: true,
      onSuccess: (json: TileJSON) => {
        setGnafLayerJson(json);
      },
    }
  );

  const gnafLayer: CircleLayer = {
    id: "gnaf",
    type: "circle",
    source: "gnaf",
    "source-layer": "gnaf",
    paint: {
      "circle-color": [
        "interpolate",
        ["exponential", 2],
        ["zoom"],
        14,
        "hsla(0, 0%, 30%, 0)", // Base width at zoom 14
        16,
        "hsla(0, 0%, 30%, 1)", // Same width at max zoom
      ],
      "circle-radius": 2,
    },
  };

  const addAuthToTileUrl = (tileUrl: string[]) => {
    return tileUrl.map((x) => `${x}?key=${jwt.data}`);
  };

  return (
    <>
      {gnafLayerJson && (
        <Source
          key="gnaf"
          id="gnaf"
          type="vector"
          maxzoom={15}
          tiles={addAuthToTileUrl(gnafLayerJson.tiles)}
        >
          <Layer {...gnafLayer} />
        </Source>
      )}
    </>
  );
};
