import * as QueryKeys from "data";
import { useIntercom } from "react-use-intercom";

import { Box, Card, CardContent, CardHeader, FormGroup } from "@mui/material";
import {
  IdentityState,
  getActiveOrganisation,
  useIdentity,
} from "contexts/identity-context";

import { makeStyles } from "@mui/styles";
import { fetchAdminDatasets, fetchDefinitions } from "data/queries";
import { useEffect, useState } from "react";
import {
  QueryClient,
  UseQueryResult,
  useQuery,
  useQueryClient,
} from "react-query";
import { resolveIdentityId } from "utils/identity";
import { customColumns, datasetsToRows } from "./columns-rows";

import {
  GridPaginationModel,
  GridRowClassNameParams,
} from "@mui/x-data-grid-premium";
import { ReactQueryErrorWrapper } from "components/shared/react-query-error-wrapper";
import CustomServerPaginatedTable from "components/shared/server-paginated-table";
import * as localStorageKeys from "data/local-storage-keys";
import { DatasetFiltersType, DatasetList } from "data/models";
import FilterComponent from "pages/geoscape-data/share-component/filter-component";
import { updateQueryAndFilters } from "pages/geoscape-data/utils";
import { useLocation } from "react-router-dom";
import { ChangeStatus } from "./change-status";
import { AdminDatasetFilters } from "./filters";
import usePaginatedTableQuery from "shared-hooks/use-paginated-table-query";


export const AdminDatasets = () => {
  const [identityState] = useIdentity();

  const isUser = !getActiveOrganisation(identityState);
  const identityId = resolveIdentityId(identityState, isUser);

  const urlLocationQuery = useLocation().search;

  const savedSearch = localStorage.getItem(
    localStorageKeys.searchAdminDatasets
  );

  let initialQueryState: DatasetFiltersType = {
    query: "",
    product: [],
    region: [],
    format: [],
    datum: [],
    release: "",
    latestRelease: false,
    licenced: false,
    notPublished: false,
  };


  const [queryState, setQueryState] =
    useState<DatasetFiltersType>(initialQueryState);

  const [loadingFilters, setLoadingFilters] = useState<boolean>(false);

  const [selectedIds, setSelectedIds] = useState<string[]>([]);


  const {
    paginationModel,
    handleChangePaginationModel,
    searchQuery,
  } = usePaginatedTableQuery<DatasetList>(
    (limit: number, offset: number) => {
      return fetchAdminDatasets(queryState, identityState, limit, offset);
    },
    QueryKeys.datasetsSearchQuery,
    identityId,
    queryState
  );


  const datasetDefinitions = useQuery([QueryKeys.definitionsKey], () =>
    fetchDefinitions(identityState)
  );

  const { show } = useIntercom();

  useEffect(() => {
    updateQueryAndFilters({
      savedSearch,
      setQueryFilterState: setQueryState,
      setLoadingFilters,
      urlLocationQuery,
      initialQueryFilterState: initialQueryState,
      definitions: datasetDefinitions,
    });
  }, [datasetDefinitions.isSuccess]);


  const useStyles = makeStyles({
    highlightedRow: {
      color: "grey",
    },
  });

  const classes = useStyles();

  const getCustomRowLicence = (params: GridRowClassNameParams): string => {
    if (params.row.licenced == false) {
      return classes.highlightedRow;
    }
    return "";
  };

  const customColumns_ = customColumns(identityState);

  const isSuccess = searchQuery.isSuccess;
  const isLoading = searchQuery.isLoading;
  const rows = searchQuery.data
    ? datasetsToRows(searchQuery.data)
    : [];

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap: "16px",
          position: "relative",
        }}
      >
        <ReactQueryErrorWrapper
          queries={[searchQuery, datasetDefinitions]}
          mutations={[]}
        />

        <Card>
          <CardHeader
            title="Search Datasets"
            style={{ padding: "5" }}
            variant="h5"
          />

          <CardContent
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",

              gap: "16px",
            }}
          >
            <FormGroup style={{ display: "flex", flexDirection: "row" }}>
              {loadingFilters ? (
                <Box style={{}}>
                  <h2>Loading...</h2>
                </Box>
              ) : (
                <FilterComponent
                  queryFilterState={queryState}
                  setQueryFilterState={setQueryState}
                  initialQueryFilterState={initialQueryState}
                  renderFilter={AdminDatasetFilters}
                  definitions={datasetDefinitions}
                  localStorageKey={localStorageKeys.searchAdminDatasets}
                />
              )}
            </FormGroup>
          </CardContent>
        </Card>
        <Card
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <CardContent
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <ChangeStatus
              queryState={queryState}
              selectedIds={selectedIds}
              setSelectedIds={setSelectedIds}
            />
          </CardContent>
        </Card>

        <CustomServerPaginatedTable
          rowCount={searchQuery.data?.links?.numberMatched ?? -1}
          getCustomRowClass={getCustomRowLicence}
          paginationModel={paginationModel}
          setPaginationModel={handleChangePaginationModel}
          rows={rows}
          cols={isLoading ? [] : customColumns_}
          isLoading={isLoading}
          overlayText={
            "We couldn't find any datasets that match your filter criteria."
          }
          checkboxSelection={true}
          onSelectionModelChange={(ids) => {
            setSelectedIds(ids as string[]);
          }}
        />
      </div>
    </>
  );
};
