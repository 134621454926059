import { useCustomSnackbars } from "components/snackbars/useCustomSnackbars";
import * as QueryKeys from "data";
import { fetchAuthJWT, fetchMapsLayerJson } from "data/queries";
import { Expression } from "mapbox-gl";
import { TileJSON } from "pages/geoscape-data/explorer/layers/models";
import { useState } from "react";
import {
  FillLayer,
  Layer,
  LineLayer,
  MapboxGeoJSONFeature,
  Source,
} from "react-map-gl";
import { useQuery } from "react-query";

interface EasementsSourceProps {
  intersectingEasements: MapboxGeoJSONFeature[] | undefined;
}

export const EasementsSource = (props: EasementsSourceProps) => {
  const [easementsLayerJson, setEasementsLayerJson] = useState<TileJSON | null>(
    null
  );
  const { enqueueQueryFailed } = useCustomSnackbars();

  const jwt = useQuery([QueryKeys.jwt], () => fetchAuthJWT(), {
    onError: (error: Error) => {
      enqueueQueryFailed(error.toString());
    },
  });

  const addAuthToTileUrl = (tileUrl: string[]) => {
    return tileUrl.map((x) => `${x}?key=${jwt.data}`);
  };

  const easementsLayerJsonQuery = useQuery(
    [QueryKeys.mapsLayerJson, "easements"],
    () => fetchMapsLayerJson(jwt.isSuccess ? jwt.data : "", "easements"),
    {
      enabled: !!jwt.data,
      retry: true,
      onSuccess: (json: TileJSON) => {
        setEasementsLayerJson(json);
      },
    }
  );

  const intersectingIds = props.intersectingEasements
    ?.filter((e) => e && !!e.properties && e.properties.easement_pid)
    .map((e) => e.properties?.easement_pid) ?? [""];

  const applySelectedEasementStyle = (value: string | number) => {
    return intersectingIds.flatMap((id) => [id, value]);
  };

  const matchExpressionColorLowZoom: Expression = [
    "match",
    ["get", "easement_pid"],
    ...applySelectedEasementStyle("hsla(0, 76%, 30%, 1)"),
    "hsla(0, 76%, 30%, 1)", // Default color for lines not matching
  ];

  const matchExpressionColorHighZoom: Expression = [
    "match",
    ["get", "easement_pid"],
    ...applySelectedEasementStyle("hsla(0, 76%, 30%, 1)"),
    "hsla(0, 76%, 30%, 1)", // Default color for lines not matching
  ];

  const matchExpressionBorderLowZoom: Expression = [
    "match",
    ["get", "easement_pid"],
    ...applySelectedEasementStyle(1.5),
    0.5,
  ];

  const matchExpressionBorderHighZoom: Expression = [
    "match",
    ["get", "easement_pid"],
    ...applySelectedEasementStyle(5),
    2,
  ];

  const easementsLineLayer: LineLayer = {
    id: "easements_line",
    type: "line",
    source: "easements",
    "source-layer": "easements_line",
    paint: {
      "line-color": [
        "interpolate",
        ["linear"],
        ["zoom"],
        14,
        matchExpressionColorLowZoom, // Base width at zoom 14
        16,
        matchExpressionColorHighZoom, // Same width at max zoom
      ],
      "line-width": [
        "interpolate",
        ["exponential", 2],
        ["zoom"],
        14,
        matchExpressionBorderLowZoom, // Base width at zoom 14
        16,
        matchExpressionBorderHighZoom, // Same width at max zoom
      ],
    },
  };

  const easementsPolygonLayer: FillLayer = {
    id: "easements_polygon",
    type: "fill",
    source: "easements",
    "source-layer": "easements_polygon",
    paint: {
      "fill-color": [
        "interpolate",
        ["linear"],
        ["zoom"],
        14,
        matchExpressionColorLowZoom, // Base width at zoom 14
        16,
        matchExpressionColorHighZoom, // Same width at max zoom
      ],
      "fill-outline-color": [
        "interpolate",
        ["linear"],
        ["zoom"],
        14,
        "hsla(0, 76%, 40%, 0.2)",
        16,
        "hsla(0, 76%, 40%, 0.5)",
      ],
    },
  };

  return (
    <>
      {easementsLayerJson && (
        <Source
          key="easements"
          id="easements"
          type="vector"
          maxzoom={15}
          tiles={addAuthToTileUrl(easementsLayerJson.tiles)}
        >
          <Layer {...easementsLineLayer} />
          <Layer {...easementsPolygonLayer} />
        </Source>
      )}
    </>
  );
};
