import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { Box } from "@mui/material";
import { bbox } from "@turf/turf";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { Ref, useState } from "react";
import { MapRef } from "react-map-gl";
import { ClipConfiguration } from "../../models";
import { ClipItem } from "./clip-item";

interface RecentClipsProps {
  onSelect: (clip: ClipConfiguration) => void;
  maxClips: number;
  clips: ClipConfiguration[];
  draw: MapboxDraw;
  mapRef: Ref<MapRef>;
}

export const RecentClips = (props: RecentClipsProps) => {
  const [selected, setSelected] = useState("");

  const handleSelect = (clip: ClipConfiguration) => {
    setSelected(clip.id);
    props.draw.deleteAll();
    props.draw.changeMode(MapboxDraw.constants.modes.STATIC);
    if (!props.draw) {
      return;
    }
    if (!clip.geometry || clip.geometry.features.length == 0) {
      return;
    }
    clip.geometry.features.map((f) => {
      props.draw.add(f);
    });

    const [minLng, minLat, maxLng, maxLat] = bbox(clip.geometry);

    //@ts-ignore
    props.mapRef.current?.fitBounds(
      [
        [minLng, minLat],
        [maxLng, maxLat],
      ],
      { padding: 20, duration: 1000 }
    );
  };
  dayjs.extend(utc);

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={1}
      sx={{
        overflowY: "auto", // Enable vertical scrolling
        overflowX: "hidden", // Hide horizontal scrolling
      }}
    >
      {props.clips
        .sort((a: ClipConfiguration, b: ClipConfiguration) =>
          dayjs.utc(a.date).isBefore(dayjs.utc(b.date)) ? 1 : -1
        )
        .slice(0, props.maxClips)
        .map((clip: ClipConfiguration, idx: number) => (
          <ClipItem
            isSelected={selected === clip.id}
            key={`clip-item-${idx}`}
            clip={clip}
            onOpen={(clip: ClipConfiguration) => props.onSelect(clip)}
            onSelect={handleSelect}
          ></ClipItem>
        ))}
    </Box>
  );
};
