import { Card, CardContent, CardHeader } from "@mui/material";
import React from "react";
import useDarkMode from "use-dark-mode";
// import { ReactQueryErrorWrapper } from "./ReactQueryErrorWrapper"; // Assume this is implemented elsewhere
import { ReactQueryErrorWrapper } from "components/shared/react-query-error-wrapper";
import { UsageGraphProps } from "./types";
import { useChartConfig } from "./useChartConfig";

export const UsageGraph: React.FC<UsageGraphProps> = (props) => {
  const darkMode = useDarkMode(false);
  const chartRef = useChartConfig(props, darkMode.value);

  return (
    <Card style={{ display: "flex", flexDirection: "column" }}>
      <CardHeader title="Credit Usage" subheader={props.subheader} />
      <CardContent sx={{ height: "100%", width: "100%", padding: "0" }}>
        <ReactQueryErrorWrapper queries={[props.usageQuery]} mutations={[]} />
        {props.isSuccess && (
          <div ref={chartRef} style={{ height: 500, width: "100%" }} />
        )}
      </CardContent>
    </Card>
  );
};
