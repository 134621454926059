import {
  CustomDatasetFilterType,
  DatasetFiltersType,
  DatasetDefinitions,
  CustomDatasetDefinitions,
} from "data/models";
import { UseQueryResult } from "react-query";

import { array, boolean, mixed, object, string } from "yup";

type CombinedDefinitionsType = DatasetDefinitions | CustomDatasetDefinitions;

interface updateQueryAndFilters {
  savedSearch: string | null;
  setQueryFilterState: any;
  setLoadingFilters: any;
  urlLocationQuery?: string;
  initialQueryFilterState: any;
  definitions: UseQueryResult<CombinedDefinitionsType, unknown>;
  enqueueLinkCorruptedSnackbar?: () => void;
}
export function updateQueryAndFilters({
  savedSearch,
  setQueryFilterState,
  setLoadingFilters,
  urlLocationQuery,
  initialQueryFilterState,
  definitions,
  enqueueLinkCorruptedSnackbar,
}: updateQueryAndFilters) {
  if (savedSearch) {
    setLoadingFilters(true);
    setFilters(savedSearch, setQueryFilterState);
  } else {
    setQueryFilterState(initialQueryFilterState);
    setLoadingFilters(false);
  }

  if (urlLocationQuery && definitions.isSuccess) {
    try {
      setLoadingFilters(true);

      const queryParsed = parseQueryString(urlLocationQuery);
      validateUrlFiltersAndSetQueryState(
        queryParsed,
        initialQueryFilterState,
        definitions.data,
        setQueryFilterState,
        setLoadingFilters,
        enqueueLinkCorruptedSnackbar
      );
    } catch (err) {}
  } else {
    setQueryFilterState(initialQueryFilterState);
  }

  setLoadingFilters(false);
}

export function parseQueryString(query: string): DatasetFiltersType {
  const params = new URLSearchParams(query);

  const release = params.get("release") ?? "";

  return {
    query: params.getAll("query")?.[0] ?? "",
    product:
      params
        .getAll("product")?.[0]
        ?.split(",")
        ?.map((item) => item.toUpperCase()) ?? [],
    region:
      params
        .getAll("region")?.[0]
        ?.split(",")
        ?.map((item) => item.toUpperCase()) ?? [],
    format:
      params
        .getAll("format")?.[0]
        ?.split(",")
        ?.map((item) => item.toUpperCase()) ?? [],
    datum:
      params
        .getAll("datum")?.[0]
        ?.split(",")
        ?.map((item) => item.toUpperCase()) ?? [],
    release: release === "latest" ? "" : release.toUpperCase(),
    latestRelease: release === "latest" ? true : false,
    licenced: params.get("licenced") === "true",
  };
}

const releaseFormatRegex = /^\d{4}-\d{2}-\d{2},\d{4}-\d{2}-\d{2}$/;

export async function validateUrlFiltersAndSetQueryState(
  filters: DatasetFiltersType,
  initialQueryState: DatasetFiltersType,
  definitions: CombinedDefinitionsType,
  setQueryState: any,
  setStatus: any,
  enqueueLinkCorruptedSnackbar?: () => void
) {
  const datasetFiltersSchema = object().shape({
    query: string(),
    product: array().of(mixed().oneOf(Object.values(definitions.products))),
    region: array().of(mixed().oneOf(Object.values(definitions.regions))),
    format: array().of(mixed().oneOf(Object.values(definitions.formats))),
    projection: array().of(mixed().oneOf(Object.values(definitions.datums))),
    release: string().matches(releaseFormatRegex, { excludeEmptyString: true }),
    latestRelease: boolean(),
    licenced: boolean(),
  });
  try {
    await datasetFiltersSchema.validate(filters);
    setQueryState(filters);
    localStorage.setItem("searchDatasets", JSON.stringify(filters));
    setStatus(false);
  } catch (err) {
    enqueueLinkCorruptedSnackbar && enqueueLinkCorruptedSnackbar();
    setQueryState(initialQueryState);
    setStatus(false);
  }
}

type CombinedDatasetFiltersType = CustomDatasetFilterType | DatasetFiltersType;

export async function setFilters(
  filtersString: string,
  setQueryFilterState: any
) {
  const parsedFilters = await JSON.parse(filtersString);

  setQueryFilterState(parsedFilters as CombinedDatasetFiltersType);
}

export function transformDateFormat(dateRange: string) {
  const [startDate, endDate] = dateRange.split(",");
  const [startYear, startMonth, startDay] = startDate.split("-");
  const [endYear, endMonth, endDay] = endDate.split("-");

  const formattedStartDate = `${startDay}/${startMonth}/${startYear}`;
  const formattedEndDate = `${endDay}/${endMonth}/${endYear}`;

  const formattedDateRange = `${formattedStartDate} - ${formattedEndDate}`;

  return formattedDateRange;
}
