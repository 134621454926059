// components/UsageCard.js
import { Card, CardContent, CardHeader, Skeleton } from "@mui/material";

const UsageCard = ({ isError, isLoading, isSuccess, totalCredits }: any) => (
  <Card
    style={{
      display: "flex",
      width: "20%",
    }}
  >
    {isError && (
      <CardHeader
        subheader="Total Credits"
        subheaderTypographyProps={{ variant: "body2" }}
        sx={{
          padding: "16px !important",
        }}
      />
    )}

    {isLoading && (
      <CardContent style={{ width: "100%" }}>
        <Skeleton variant="rectangular" height="56px" />
      </CardContent>
    )}

    {isSuccess && (
      <CardHeader
        variant="primary"
        title={totalCredits.toLocaleString("en-US", {
          minimumFractionDigits: 1,
        })}
        subheader="Total Credits"
        subheaderTypographyProps={{ variant: "body2" }}
        sx={{
          padding: "16px !important",
        }}
      />
    )}
  </Card>
);

export default UsageCard;
