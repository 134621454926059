import { Box, Typography } from "@mui/material";
import { GetUserIfLoggedIn, useIdentity } from "contexts/identity-context";
import { BookText, FileCode, NotebookText } from "lucide-react";
import ItemCard from "pages/geoscape-hub/dashboard/hub-card";
import { useHistory } from "react-router-dom";
import * as Routes from "routes";

export const DocsPage = () => {
  const history = useHistory();
  const redirectPath = localStorage.getItem("redirectPath");
  const query = localStorage.getItem("query");
  const [identityState] = useIdentity();
  const user = GetUserIfLoggedIn(identityState);

  if (redirectPath && query) {
    history.push({
      pathname: redirectPath,
      search: query,
    });

    localStorage.removeItem("redirectPath");
    localStorage.removeItem("query");
  }

  return <ActionMenu />;
};

const ActionMenu = () => {
  const actions = [
    {
      id: 1,
      title: "Dataset Docs",
      path: Routes.geoscapeDocumentation,
      icon: <BookText size={36} color="#1976D2" />,
      target: "_blank",
    },
    {
      id: 2,
      title: "Pre Release Reports",
      path: Routes.docsPreRelease,
      icon: <NotebookText size={36} color="#ED6C02" />,
      target: "_blank",
    },
    {
      id: 3,
      title: "API docs",
      path: Routes.docsApi,
      icon: <FileCode size={36} color="#9C27B0" />,
      target: "_blank",
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        justifyItems: "center",
        alignItems: "center",
      }}
    >
      <Typography
        // variant="h3"
        sx={{
          marginTop: "150px",
          fontSize: "48px",
          fontWeight: "700",
          textAlign: "center",
        }}
      >
        What are you learning today?
      </Typography>
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: "400",
          textAlign: "center",
        }}
      >
        If knowledge is power, then learning is a superpower.
      </Typography>

      <Box
        sx={{
          display: "flex",
          gap: "16px",
          justifyContent: "center",
          alignContent: "center",
          alignSelf: "stretch",
          flexWrap: "wrap",
        }}
      >
        {actions.map((item, id) => (
          <ItemCard item={item} id={id} />
        ))}
      </Box>
    </Box>
  );
};
