import { Source } from "react-map-gl";
import { LayerManager } from "../layer-manager";
import { getUniqueLayerId } from "./layer-utils";
import { BaseLayer } from "./layers/base-layer";
import { BorderHighlightLayer } from "./layers/border-highlight-layer";
import { InvisibleLayer } from "./layers/invisible-layer";
import { PrimaryHighlightLayer } from "./layers/primary-highlight-layer";
import { SecondaryHighlightLayer } from "./layers/secondary-highlight-layer";
import { LayerConfiguration, TileJSON, VectorLayer } from "./models";

export interface LayerProps {
  id: string;
  layer: VectorLayer;
  source: TileJSON;
  visible: boolean;
  layerConfig: LayerConfiguration;
  beforeId: string | null;
}

export const ExplorerDisplayLayers = (layerManager: LayerManager) => {
  const zLayers = [];
  for (let i = 0; i < 250; i++) {
    zLayers.push(<InvisibleLayer id={`z_${i}`} />);
  }

  return (
    <>
      <Source key={"Invisible"} id={"Invisible"} type={"vector"}>
        {zLayers}
      </Source>
      {layerManager.getSources().map((source: TileJSON) => (
        <Source
          key={source.description}
          id={source.description}
          type="vector"
          maxzoom={source.vector_layers[0].maxzoom}
          tiles={source.tiles}
        >
          {source.vector_layers.map((layer: VectorLayer) => {
            const selectedLayer: LayerConfiguration | undefined =
              layerManager.selectedLayers.find(
                (x) => x.id === getUniqueLayerId(layer.id, source.description)
              );

            return (
              <div key={getUniqueLayerId(layer.id, source.description)}>
                {selectedLayer && (
                  <>
                    <PrimaryHighlightLayer
                      id={layerManager.generateLayerId(
                        selectedLayer,
                        "primary"
                      )}
                      layerConfig={selectedLayer}
                      visible={selectedLayer.visible}
                      beforeId={layerManager.getLayerAfter(
                        selectedLayer,
                        "primary"
                      )}
                      layer={layer}
                      source={source}
                    ></PrimaryHighlightLayer>
                    <SecondaryHighlightLayer
                      id={layerManager.generateLayerId(
                        selectedLayer,
                        "secondary"
                      )}
                      layerConfig={selectedLayer}
                      visible={selectedLayer.visible}
                      beforeId={layerManager.getLayerAfter(
                        selectedLayer,
                        "secondary"
                      )}
                      layer={layer}
                      source={source}
                    ></SecondaryHighlightLayer>
                    <BorderHighlightLayer
                      id={layerManager.generateLayerId(selectedLayer, "border")}
                      layerConfig={selectedLayer}
                      visible={selectedLayer.visible}
                      beforeId={layerManager.getLayerAfter(
                        selectedLayer,
                        "border"
                      )}
                      layer={layer}
                      source={source}
                    ></BorderHighlightLayer>
                    <BaseLayer
                      id={layerManager.generateLayerId(selectedLayer, "base")}
                      visible={selectedLayer.visible}
                      layer={layer}
                      source={source}
                      beforeId={layerManager.getLayerAfter(
                        selectedLayer,
                        "base"
                      )}
                      layerConfig={selectedLayer}
                    ></BaseLayer>
                  </>
                )}
              </div>
            );
          })}
        </Source>
      ))}
    </>
  );
};
