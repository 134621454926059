import { Card, CardContent, Typography } from "@mui/material";

import { CreateOrganisation } from "./create-organisation";

export const Organisations = () => {
  return (
    // <Box>
    //   <Route exact path={Routes.userCreateOrganisation}>
    //     <Grid spacing={2} container paddingY={4}>
    //       <Grid item md={6}>
    <Card
      sx={{
        maxWidth: "600px",
        // width: "600px",
      }}
    >
      <CardContent>
        <Typography component="p" variant="h5" textAlign="left">
          New Org Details
        </Typography>
        <Typography variant="body2" textAlign="left" color="text.secondary">
          Details of the org being created
        </Typography>
        <CreateOrganisation />
      </CardContent>
    </Card>
    //     </Grid>
    //     <Grid item md={6}></Grid>
    //   </Grid>
    // </Route>
    // </Box>
  );
};
