import { useCustomSnackbars } from "components/snackbars/useCustomSnackbars";
import * as QueryKeys from "data";
import { fetchAuthJWT, fetchMapsIndex } from "data/queries";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { productCodeToExplorerLayerIdMappings } from "../constants";
import { hexCodes } from "./color-picker/color-picker";
import { guessFeaturePrimaryKey } from "./explorer-page";
import { getUniqueLayerId, humanizeLayerName } from "./layers/layer-utils";
import {
  Geometry,
  LayerConfiguration,
  Styles,
  TileJSON,
} from "./layers/models";

const tileJsonToLayerConfigurations = (
  tileJson: TileJSON[]
): LayerConfiguration[] => {
  let layerIndex = 0;
  return tileJson
    .map((item, index) => {
      return item.vector_layers.map((layer, idx) => {
        const colour = hexCodes[layerIndex % hexCodes.length];
        layerIndex += 1;

        return {
          id: getUniqueLayerId(layer.id, item.description),
          rawLayerId: layer.id,
          displayName: humanizeLayerName(layer.id, item.description),
          geometry: layer.geometry,
          styles: getDefaultStylesForGeometry(layer.geometry, colour),
          visible: true,
          primaryHighlightsFilter: false,
          secondaryHighlightsFilter: false,
          primaryKey: guessFeaturePrimaryKey(
            Object.keys(layer.fields)
          ) as string,
        };
      });
    })
    .flat();
};

const getDefaultStylesForGeometry = (
  geometry: Geometry,
  colour: string
): Styles => {
  switch (geometry) {
    case "Polygon":
      return {
        opacity: 0.5,
        colour: colour,
        fill: true,
        thickness: 2,
        type: "Polygon",
      };
    case "LineString":
      return {
        opacity: 0.5,
        colour: colour,
        thickness: 2,
        type: "LineString",
      };
    case "Point":
      return {
        opacity: 0.5,
        colour: colour,
        diameter: 2,
        type: "Point",
      };
  }
};

const reorder = (
  list: LayerConfiguration[],
  startIndex: number,
  endIndex: number
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export interface LayerManager {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  layers: LayerConfiguration[];
  selectedLayers: LayerConfiguration[];
  generateLayerId: (
    layer: LayerConfiguration,
    suffix: "base" | "secondary" | "primary" | "border"
  ) => string;
  setPrimaryHighlightFilter: (layerId: string, featureIds: string[]) => void;
  setSecondaryHighlightFilter: (layers: Record<string, string[]>) => void;
  reorderLayer: (sourceIdx: number, destIdx: number) => void;
  setLayerColour: (layerId: string, colour: string) => void;
  toggleLayerVisibility: (layerId: string) => void;
  setLayerOpacity: (layerId: string, opacity: number) => void;
  setLayerThickness: (layerId: string, thickness: number) => void;
  setLayerDiameter: (layerId: string, diameter: number) => void;
  setLayerFill: (layerId: string, fill: boolean) => void;

  removeLayer: (layerId: string) => void;
  addLayer: (layerId: string) => void;
  getLayer: (layerId: string) => LayerConfiguration | null;
  getLayerAfter: (
    layer: LayerConfiguration,
    suffix: LayerType
  ) => string | null;
  getMaxZLayersRequired: () => number;
  getSources: () => TileJSON[];
}

type LayerType = "base" | "primary" | "secondary" | "border";

export const useLayerManager = (): LayerManager => {
  const [selectedLayers, setSelectedLayers] = useState<LayerConfiguration[]>(
    []
  );
  const [layers, setLayers] = useState<LayerConfiguration[]>([]);

  const { enqueueQueryFailed, enqueueMutationFailed } = useCustomSnackbars();

  const jwt = useQuery([QueryKeys.jwt], () => fetchAuthJWT(), {
    onError: (error: Error) => {
      enqueueQueryFailed(error.toString());
    },
    refetchInterval: 2000,
    refetchIntervalInBackground: true,
  });

  const [tileJson, setTileJson] = useState<TileJSON[]>([]);

  const tileJsonQuery = useQuery(
    [QueryKeys.mapsIndexJson],
    () => fetchMapsIndex(jwt.isSuccess ? jwt.data : ""),
    {
      enabled: !!jwt.data,
      onSuccess: (tileJson: TileJSON[]) => {
        setTileJson(tileJson);
        setLayers(tileJsonToLayerConfigurations(tileJson));
      },
      retry: true,
    }
  );

  /**
   * Sets the outline for the feature currently selected in the attributes pane
   */
  const setPrimaryHighlightFilter = (layerId: string, featureIds: string[]) => {
    setSelectedLayers((prev) => {
      return prev.map((layer) => {
        if (layer.id !== layerId) {
          return { ...layer, primaryHighlightsFilter: false };
        }
        return {
          ...layer,
          primaryHighlightsFilter: ["in", layer.primaryKey, ...featureIds],
        };
      });
    });
  };

  /**
   * Sets the secondary highlight for showing all the currently selected features across all layers
   * @param layers A dict of {layerId: [...featureIds]}
   */
  const setSecondaryHighlightFilter = (layers: Record<string, string[]>) => {
    setSelectedLayers((prev) => {
      return prev.map((layer) => {
        if (!Object.keys(layers).includes(layer.id)) {
          return { ...layer, secondaryHighlightsFilter: false };
        }
        return {
          ...layer,
          secondaryHighlightsFilter: [
            "in",
            layer.primaryKey,
            ...layers[layer.id],
          ],
        };
      });
    });
  };

  /**
   * Updates saved layers in session storage with datasets passed from catalogue page
   * Called before loading layers from session storage
   */
  const resolveCatalogueLayers = () => {
    const datasetId = sessionStorage.getItem("catalogueToExplorer");
    if (!datasetId) return;

    const explorerLayerIds: string[] | null | undefined =
      productCodeToExplorerLayerIdMappings.get(datasetId);
    if (!explorerLayerIds) return;
    const storedArray =
      sessionStorage.getItem("selectedExplorerLayers") ?? "[]";
    const selectedLayers: LayerConfiguration[] = JSON.parse(storedArray);
    const selectedLayersIds: string[] = selectedLayers.map((layer) => layer.id);

    for (const layerId of explorerLayerIds) {
      // Check if the explorer layer is already added, if not:
      if (selectedLayersIds.indexOf(layerId) < 0) {
        const foundLayer = layers.find((layer) => layer.id === layerId);
        if (foundLayer) selectedLayers.push(foundLayer);
        sessionStorage.setItem(
          "selectedExplorerLayers",
          JSON.stringify(selectedLayers)
        );
      }
    }
    // clear sessionStorage after getting the datasetId
    sessionStorage.removeItem("catalogueToExplorer");
  };

  /**
   * Reorders how the layers are shown on the map and in the layer picker
   */
  const reorderLayer = (sourceIdx: number, destIdx: number) => {
    const items: LayerConfiguration[] = reorder(
      selectedLayers,
      sourceIdx,
      destIdx
    );

    setSelectedLayers(items);
    sessionStorage.setItem("selectedExplorerLayers", JSON.stringify(items));
  };

  /**
   * Set layer colour using a hex string ie '#FFFFFF'
   */
  const setLayerColour = (layerId: string, colour: string) => {
    const layers = selectedLayers.map((item) => {
      if (item.id === layerId) {
        item.styles.colour = colour;
      }
      return item;
    });
    setSelectedLayers(layers);
    sessionStorage.setItem("selectedExplorerLayers", JSON.stringify(layers));
  };

  /**
   * Set layer opacity as number between 0 and 1
   */
  const setLayerOpacity = (layerId: string, opacity: number) => {
    const layers = selectedLayers.map((item) => {
      if (item.id === layerId) {
        item.styles.opacity = opacity;
      }
      return item;
    });
    setSelectedLayers(layers);
    sessionStorage.setItem("selectedExplorerLayers", JSON.stringify(layers));
  };

  /**
   * Set layer thickness as number between 0 and 10
   */
  const setLayerThickness = (layerId: string, thickness: number) => {
    const layers = selectedLayers.map((item) => {
      if (
        item.id === layerId &&
        (item.styles.type == "LineString" || item.styles.type == "Polygon")
      ) {
        item.styles.thickness = thickness;
      }
      return item;
    });
    setSelectedLayers(layers);
    sessionStorage.setItem("selectedExplorerLayers", JSON.stringify(layers));
  };

  /**
   * Set layer diameter as number between 0 and 10
   */
  const setLayerDiameter = (layerId: string, diameter: number) => {
    const layers = selectedLayers.map((item) => {
      if (item.id === layerId && item.styles.type == "Point") {
        item.styles.diameter = diameter;
      }
      return item;
    });
    setSelectedLayers(layers);
    sessionStorage.setItem("selectedExplorerLayers", JSON.stringify(layers));
  };

  /**
   * Set layer diameter as number between 0 and 10
   */
  const setLayerFill = (layerId: string, fill: boolean) => {
    const layers = selectedLayers.map((item) => {
      if (item.id === layerId && item.styles.type == "Polygon") {
        item.styles.fill = fill;
      }
      return item;
    });
    setSelectedLayers(layers);
    sessionStorage.setItem("selectedExplorerLayers", JSON.stringify(layers));
  };

  /**
   * Toggles layer visibility
   */
  const toggleLayerVisibility = (layerId: string) => {
    const layers = selectedLayers.map((item) => {
      if (item.id === layerId) {
        item.visible = !item.visible;
      }
      return item;
    });
    setSelectedLayers(layers);
    sessionStorage.setItem("selectedExplorerLayers", JSON.stringify(layers));
  };

  /**
   * Remove a layer from the selectedLayers
   */
  const removeLayer = (layerId: string) => {
    const newArray = selectedLayers.filter((item) => item.id !== layerId);
    setSelectedLayers(newArray);
    sessionStorage.setItem("selectedExplorerLayers", JSON.stringify(newArray));
  };

  /**
   * Add a layer to selectedLayers
   */
  const addLayer = (layerId: string) => {
    const newSelectedLayers: LayerConfiguration[] = [];
    const addLayer = layers.find((item) => item.displayName == layerId);
    if (addLayer && !selectedLayers.map((i) => i.id).includes(addLayer.id)) {
      newSelectedLayers.push(addLayer);
    }

    let newLayers: LayerConfiguration[] = [];
    newLayers = [...selectedLayers, ...newSelectedLayers];
    setSelectedLayers((prev) => {
      return [...prev, ...newSelectedLayers];
    });
    sessionStorage.setItem("selectedExplorerLayers", JSON.stringify(newLayers));
  };

  /**
   * Returns a layer by layerId
   */
  const getLayer = (layerId: string) => {
    return selectedLayers.find((x) => x.id === layerId) ?? null;
  };
  // Load data from session storage when the component mounts
  useEffect(() => {
    if (layers.length < 1) return;

    resolveCatalogueLayers();
    const storedArray = sessionStorage.getItem("selectedExplorerLayers");
    if (storedArray) {
      setSelectedLayers(JSON.parse(storedArray));
    }
  }, [layers]);

  /**
   * Generates a layerId for use in the react-mapgl <Layer> component
   * Appends __primary or __secondary for highlight layers
   */
  const generateLayerId = (
    layer: LayerConfiguration,
    suffix: LayerType
  ): string => {
    if (suffix == "base") {
      return layer.id;
    }
    return `${layer.id}__${suffix}`;
  };

  /**
   * Used for passing to the `beforeId` parameter of the <Layer> component
   * Makes layers appear in the order of primaryHighlight, secondaryHighlight, base
   */
  const getLayerAfter = (layer: LayerConfiguration, suffix: LayerType) => {
    const primaryHighlightLayers = selectedLayers.map((x) =>
      generateLayerId(x, "primary")
    );
    const secondaryHighlightLayers = selectedLayers.map((x) =>
      generateLayerId(x, "secondary")
    );
    const borderLayers = selectedLayers.map((x) =>
      generateLayerId(x, "border")
    );
    const baseLayers = selectedLayers.map((x) => generateLayerId(x, "base"));

    const layerIds = [
      ...primaryHighlightLayers,
      ...secondaryHighlightLayers,
      ...interleaveArrays([borderLayers, baseLayers]),
    ];

    const thisLayerId = generateLayerId(layer, suffix);
    const thisLayerIndex: number = layerIds.indexOf(thisLayerId);

    if (thisLayerIndex < 0) return null;

    return `GROUP_z_${getMaxZLayersRequired() - (thisLayerIndex + 1)}`;
  };

  /**
   * Get maximum number of invisible Z layers we need to draw all possible vector layers
   * = Max possible layers * number of styling layers
   */
  const getMaxZLayersRequired = () => {
    let layerCount = 0;
    tileJson.forEach((source) => {
      source.vector_layers.forEach((layer) => {
        layerCount += 1;
      });
    });
    return layerCount * 4;
  };

  const interleaveArrays = (arrays: string[][]): string[] => {
    if (arrays.length == 0) {
      throw new Error("Must have at least 1 array");
    }
    if (arrays.length == 1) {
      return arrays[0];
    }

    let interleaved: string[] = [];
    const numArrays = arrays.length;

    arrays[0].map((val, idx) => {
      for (let i = 0; i < numArrays; i++) {
        interleaved.push(arrays[i][idx]);
      }
    });

    return interleaved;
  };

  const _getSources = () => {
    return tileJson.map((source) => {
      return {
        ...source,
        tiles: [`${source.tiles[0]}?key=${jwt.data}`],
      };
    });
  };

  const sources = useMemo(_getSources, [tileJson, jwt.data]);

  const isLoading: boolean = jwt.isLoading || tileJsonQuery.isLoading;
  const isSuccess: boolean = jwt.isSuccess && tileJsonQuery.isSuccess;
  const isError: boolean = jwt.isError || tileJsonQuery.isError;
  const getSources = () => sources;

  return {
    isLoading,
    isSuccess,
    isError,
    layers,
    selectedLayers,
    setPrimaryHighlightFilter,
    setSecondaryHighlightFilter,
    setLayerColour,
    setLayerOpacity,
    setLayerThickness,
    setLayerDiameter,
    setLayerFill,
    toggleLayerVisibility,
    reorderLayer,
    removeLayer,
    getLayer,
    addLayer,
    generateLayerId,
    getLayerAfter,
    getMaxZLayersRequired,
    getSources,
  };
};
