import CircleIcon from "@mui/icons-material/Circle";
import { List, ListItem, ListItemText } from "@mui/material";

const ProcessBatchRow = (row: any) => {
  let cost = row.batchInfo.estimatedCreditCost;
  if (row.batchInfo.actualCreditCost) {
    cost = row.batchInfo.actualCreditCost;
  }

  let progress = 0;
  if (row.batchInfo.progress) {
    if (row.status === "Running") {
      // In progress
      progress = +row.batchInfo.progress
        .replace("progress: ~", "")
        .replace(/%.*/, "");
    } else {
      // Finished
      progress = +row.batchInfo.progress
        .replace("progress: ", "")
        .replace("%", "");
    }
  }

  return {
    id: row.batchInfo.batchId,
    displayName: row.displayName,
    batchId: row.batchInfo.batchId,
    rowCount: row.batchInfo.totalRows,
    creditCost: cost,
    startDate: new Date(row.batchInfo.createdAt + "+00:00").getTime(), // Jank but lets us specify UTC timezone
    status: row.status,
    progress: progress,
    downloadPath: row.batchInfo.downloadPath,
    batchConfig: row,
  };
};

const isFailureStatus = (status: string) => {
  if (status == "Cancelled" || status == "Failed") {
    return true;
  }

  return false;
};

const statusColour = (status: string) => {
  switch (status) {
    case "Completed":
      return "success";
    case "Failed":
      return "error";
    case "Running":
      return "info";
    case "Draft":
      return "secondary";
    case "Cancelled":
      return "warning";
    default: {
      return "info";
    }
  }
};

const GEOCODER = "Geocoder";

const batchOutputReverseGeocode = {
  output: [
    {
      name: "Address Properties - Address Id",
      property: "AddressProperties",
      attributeName: "addressId",
      source: "$.features[0].properties.addressId",
    },
    {
      name: "Address Properties - Formatted Address",
      property: "AddressProperties",
      attributeName: "formattedAddress",
      source: "$.features[0].properties.formattedAddress",
    },
    {
      name: "Address Properties - Dataset",
      property: "AddressProperties",
      attributeName: "dataset",
      source: "$.features[0].properties.dataset",
    },
    {
      name: "Geometry - Longitude",
      property: "Geometry",
      attributeName: "longitude",
      source: "$.features[0].geometry.coordinates[0]",
    },
    {
      name: "Geometry - Latitude",
      property: "Geometry",
      attributeName: "latitude",
      source: "$.features[0].geometry.coordinates[1]",
    },

    {
      name: "Reverse Geocoder Feature - MatchType",
      property: "matchingMetadataReverseGeocode",
      attributeName: "matchType",
      source: "$.features[0].matchType",
    },
    {
      name: "Reverse Geocoder Feature - Match Distance",
      property: "matchingMetadataReverseGeocode",
      attributeName: "matchDistance",
      source: "$.features[0].matchDistance",
    },
    {
      name: "Reverse Geocoder Feature - Match Method",
      property: "matchingMetadataReverseGeocode",
      attributeName: "matchMethod",
      source: "$.features[0].matchMethod",
    },
  ],
  properties: {
    AddressProperties: {
      addressId: true,
      formattedAddress: true,
      dataset: true,
    },
    matchingMetadataReverseGeocode: {
      matchType: true,
      matchDistance: true,
      matchMethod: true,
    },
    Geometry: {
      latitude: true,
      longitude: true,
    },
  },
};

const batchOutputGeocode = {
  output: [
    {
      name: "Address Properties - Address Id",
      property: "AddressProperties",
      attributeName: "addressId",
      source: "$.features[0].properties.addressId",
    },
    {
      name: "Address Properties - Formatted Address",
      property: "AddressProperties",
      attributeName: "formattedAddress",
      source: "$.features[0].properties.formattedAddress",
    },
    {
      name: "Address Properties - Dataset",
      property: "AddressProperties",
      attributeName: "dataset",
      source: "$.features[0].properties.dataset",
    },
    {
      name: "Geocoder Feature - MatchType",
      property: "matchingMetadataGeocode",
      attributeName: "matchType",
      source: "$.features[0].matchType",
    },
    {
      name: "Geocoder Feature - Match Quality",
      property: "matchingMetadataGeocode",
      attributeName: "matchQuality",
      source: "$.features[0].matchQuality",
    },
    {
      name: "Geocoder Feature - Match Score",
      property: "matchingMetadataGeocode",
      attributeName: "matchScore",
      source: "$.features[0].matchScore",
    },
    {
      name: "Geometry - Longitude",
      property: "Geometry",
      attributeName: "longitude",
      source: "$.features[0].geometry.coordinates[0]",
    },
    {
      name: "Geometry - Latitude",
      property: "Geometry",
      attributeName: "latitude",
      source: "$.features[0].geometry.coordinates[1]",
    },
  ],
  properties: {
    AddressProperties: {
      addressId: true,
      formattedAddress: true,
      dataset: true,
    },
    matchingMetadataGeocode: {
      matchType: true,
      matchQuality: true,
      matchScore: true,
    },
    matchingMetadataReverseGeocode: {
      matchType: true,
      matchDistance: true,
    },
    Geometry: {
      latitude: true,
      longitude: true,
    },
  },
};

const SelectedNamesList = ({ selectedNames }: any) => {
  return (
    <List sx={{ paddingTop: 0, paddingBottom: 0 }}>
      {selectedNames.slice(0, 3).map((item: any, index: number) => (
        <ListItem
          sx={{
            paddingTop: 0,
            paddingBottom: 0,
            border: 0,
            marginBottom: -1,
          }}
          key={index}
        >
          <CircleIcon
            fontSize="small"
            sx={{
              marginRight: "8px",
              fontSize: "5px",
              color: "rgba(0, 0, 0, 0.60)",
            }}
          />
          <ListItemText
            sx={{ color: "rgba(0, 0, 0, 0.60)" }}
            primary={` ${item}`}
          />
        </ListItem>
      ))}
      <ListItem sx={{ color: "rgba(0, 0, 0, 0.60)" }}>
        {selectedNames.length > 3 ? (
          <b> +{selectedNames.length - 3} more... </b>
        ) : (
          <></>
        )}
      </ListItem>
    </List>
  );
};

export {
  GEOCODER,
  ProcessBatchRow,
  SelectedNamesList,
  batchOutputGeocode,
  batchOutputReverseGeocode,
  isFailureStatus,
  statusColour,
};
