import * as QueryKeys from "data";

// reactQuery
import { fetchAdminUserApiKeys, fetchSubscription } from "data/queries";
import { useQuery } from "react-query";
import { resolveIdentityId } from "utils/identity";

import { IdentityState } from "contexts/identity-context";
import { fetchAdminUsage } from "data/queries-admin";

export const useFetchSubscription = (
  isUser: boolean,
  identityState: IdentityState
) => {
  const identityId = resolveIdentityId(identityState, isUser);

  return useQuery([QueryKeys.subscription, identityId], () =>
    fetchSubscription(isUser, identityState)
  );
};

export const useFetchAdminUsageGraph = (
  isUser: boolean,
  identityState: IdentityState,
  accountId: string,
  startTime: number,
  endTime: number,
  enabled?: boolean
) => {
  const identityId = resolveIdentityId(identityState, isUser);

  return useQuery({
    queryKey: [QueryKeys.usageGraph, identityId, startTime, endTime, accountId],
    queryFn: () =>
      fetchAdminUsage(isUser, identityState, accountId, startTime, endTime),
    enabled: enabled,
  });
};

export const useFetchAdminApiKeys = (
  identityState: IdentityState,
  accountId: string
) => {
  return useQuery([QueryKeys.adminUserApiKeys, accountId], () =>
    fetchAdminUserApiKeys(accountId, identityState)
  );
};
