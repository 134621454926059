import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { Button, TextField } from "@mui/material";


import {
  CheckedItems,
  OutputFormat,
  SetCheckedItems,
  setOutputFormat,
} from "pages/geoscape-batch/models";
import { MANDATORY_ATTRIBUTES, addIndex, transformString } from "./utils";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-premium";

export const inputColumns: GridColDef[] = [
  {
    field: "index",
    headerName: "#",
    flex: 0,
    width: 50,

    cellClassName: "super-app-theme--cell-0",
  },
  {
    field: "name",
    headerName: "Name",
    width: 250,

    cellClassName: "super-app-theme--cell",
    renderCell: (params: GridRenderCellParams) => {
      return (
        <>
          <TextField
            id="outlined-basic"
            label="Column Name"
            size="small"
            value={transformString(params.value)}
            variant="outlined"
          />
        </>
      );
    },
  },
  {
    field: "apiParameter",
    headerName: "Api Paramer",
    width: 250,
    cellClassName: "super-app-theme--cell-2",

    renderCell: (params: GridRenderCellParams) => (
      <>
        <TextField
          id="outlined-basic"
          label={"Attribute"}
          size="small"
          value={params.value}
          variant="outlined"
          disabled={params.value == "Ignore"}
        />
      </>
    ),
  },
];

interface BuildColumnsParams {
  enrichmentRows: OutputFormat[];
  setEnrichmentRows: setOutputFormat;

  setCheckedItems: SetCheckedItems;
}

export const buildColumns = ({
  enrichmentRows,
  setEnrichmentRows,
  setCheckedItems,
}: BuildColumnsParams): GridColDef[] => {
  const handleDeleteColumn = (index: number) => {
    const updateEnrichmentRows = [...enrichmentRows];

    const deletedEnrichmentRow = updateEnrichmentRows.splice(index - 1, 1)[0];

    setCheckedItems((prev: CheckedItems) => ({
      ...prev,
      [deletedEnrichmentRow.property]: {
        ...prev[deletedEnrichmentRow.property],
        [`${deletedEnrichmentRow.attributeName}`]: false,
      },
    }));

    setEnrichmentRows(addIndex(updateEnrichmentRows));
  };

  return [
    {
      sortable: true,
      field: "index",
      headerName: "#",
      flex: 0,
      width: 50,
      editable: true,
      cellClassName: "super-app-theme--cell-0",
      // renderCell: (params: GridRenderCellParams) => {
      //   return params.value;
      // },
    },
    {
      field: "name",
      headerName: "Name",
      width: 220,
      editable: true,
      cellClassName: "super-app-theme--cell",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            <TextField
              id="outlined-basic"
              label="Column Name"
              size="small"
              value={params.value}
              variant="outlined"
            />
          </>
        );
      },
    },

    {
      field: "attributeName",
      headerName: "Attribute Name",
      width: 220,
      cellClassName: "super-app-theme--cell-2",

      renderCell: (params: GridRenderCellParams) => (
        <>
          <TextField
            id="outlined-basic"
            label={
              params.row?.property ? transformString(params.row?.property) : ""
            }
            size="small"
            value={transformString(params.value)}
            variant="outlined"
          />
        </>
      ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 60,
      cellClassName: "super-app-theme--cell-3",

      renderCell: (params: GridRenderCellParams) => {
        return (
          <Button
            variant="text"
            color="error"
            onClick={() => {
              handleDeleteColumn(params.row.index);
            }}
            disabled={MANDATORY_ATTRIBUTES[params.row.property]?.includes(
              params.row.attributeName
            )}
          >
            <DeleteIcon />
          </Button>
        );
      },
    },
  ];
};
