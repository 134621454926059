import { Layer } from "react-map-gl";
import { useEffect, useState } from "react";
import { LayerProps } from "../explorer-display-layers";
import { useTheme } from "@mui/material";

export function BorderHighlightLayer(props: LayerProps) {
  const theme = useTheme();

  return (
    <>
      {props.layerConfig.styles.type == "Polygon" && (
        <Layer
          key={props.id}
          layout={{
            visibility: props.layerConfig.visible ? "visible" : "none",
          }}
          beforeId={props.beforeId ?? ""}
          id={props.id}
          type="line"
          source={props.source.description}
          source-layer={props.layer.id}
          paint={{
            "line-color": props.layerConfig.styles.colour,
            "line-width": props.layerConfig.styles.thickness,
          }}
        />
      )}
    </>
  );
}
