import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Box, Paper, Typography } from "@mui/material";
import { Basemap } from "components/basemap/basemap";
import { useCustomSnackbars } from "components/snackbars/useCustomSnackbars";
import * as QueryKeys from "data";
import { fetchAuthJWT, fetchMapsLayerJson } from "data/queries";
import { requestTransformer } from "pages/geoscape-data/explorer/explorer-page";
import { TileJSON } from "pages/geoscape-data/explorer/layers/models";
import { ZoomControl } from "pages/geoscape-data/explorer/zoom-control";
import { useEffect, useRef, useState } from "react";
import {
  CircleLayer,
  Layer,
  LineLayer,
  MapRef,
  Marker,
  Popup,
  Source,
} from "react-map-gl";
import { useQuery } from "react-query";
import { PredictiveAddressManager } from "./predictive-address-manager";

export const VisualiseMap = (predictiveManager: PredictiveAddressManager) => {
  const mapRef = useRef<MapRef>();
  const initialZoom: number = 17;
  const addressZoom: number = 17;

  const [popupInfo, setPopupInfo] = useState(false);
  const [dynamicZoom, setDynamicZoom] = useState(initialZoom);

  const gnafLayer: CircleLayer = {
    id: "gnaf",
    type: "circle",
    source: "gnaf",
    "source-layer": "gnaf",
    paint: {
      "circle-color": "red",
      "circle-radius": 3,
    },
  };

  const cadastreLayer: LineLayer = {
    id: "cadastre",
    type: "line",
    source: "cadastre",
    "source-layer": "cadastre",
    paint: {
      "line-color": "purple",
      "line-width": 2,
    },
  };

  useEffect(() => {
    if (predictiveManager.address?.address.geometry) {
      mapRef.current?.flyTo({
        center: [
          predictiveManager.address.address.geometry.coordinates[0],
          predictiveManager.address.address.geometry.coordinates[1],
        ],
        duration: 2000,
        zoom: addressZoom,
        offset: [0, 0],
      });
    }
  }, [predictiveManager.address?.address.geometry]);

  const { enqueueQueryFailed } = useCustomSnackbars();

  const jwt = useQuery([QueryKeys.jwt], () => fetchAuthJWT(), {
    onError: (error: Error) => {
      enqueueQueryFailed(error.toString());
    },
    refetchInterval: 2000,
    refetchIntervalInBackground: true,
  });

  const addAuthToTileUrl = (tileUrl: string[]) => {
    return tileUrl.map((x) => `${x}?key=${jwt.data}`);
  };

  const cadastreLayerJsonQuery = useQuery(
    [QueryKeys.mapsLayerJson, "cadastre"],
    () => fetchMapsLayerJson(jwt.isSuccess ? jwt.data : "", "cadastre"),
    {
      enabled: !!jwt.data,
      retry: true,
      onSuccess: (json: TileJSON) => {
        setCadastreLayerJson(json);
      },
    }
  );

  const gnafLayerJsonQuery = useQuery(
    [QueryKeys.mapsLayerJson, "gnaf"],
    () => fetchMapsLayerJson(jwt.isSuccess ? jwt.data : "", "gnaf"),
    {
      enabled: !!jwt.data,
      retry: true,
      onSuccess: (json: TileJSON) => {
        setGnafLayerJson(json);
      },
    }
  );

  const [gnafLayerJson, setGnafLayerJson] = useState<TileJSON | null>(null);
  const [cadastreLayerJson, setCadastreLayerJson] = useState<TileJSON | null>(
    null
  );

  const resetCompass = () => {
    if (mapRef.current === undefined) return;
    mapRef.current.easeTo({
      bearing: 0,
      pitch: 0,
    });
  };

  const handleZoom = (zoom: number): void => {
    setDynamicZoom(zoom);
    mapRef.current?.flyTo({ zoom: zoom, duration: 500 });
  };

  return (
    <Paper
      elevation={0}
      sx={{
        width: "100%",
        height: 270,
      }}
    >
      {jwt.isSuccess && (
        <Basemap
          // @ts-ignore
          mapRef={mapRef}
          latitude={
            predictiveManager.address?.address.geometry
              ? predictiveManager.address.address.geometry.coordinates[0]
              : -35.32201813
          }
          longitude={
            predictiveManager.address?.address.geometry
              ? predictiveManager.address.address.geometry.coordinates[1]
              : 149.14668681
          }
          zoom={initialZoom}
          attributionControl={false}
          style={{
            width: "100%",
            height: "100%",
            borderRadius: 10,
          }}
          onZoom={(zoom) => {}}
          transformRequest={requestTransformer}
        >
          {predictiveManager.address?.address.geometry && (
            <Marker
              key={"selected-address"}
              longitude={
                predictiveManager.address.address.geometry.coordinates[0]
              }
              latitude={
                predictiveManager.address.address.geometry.coordinates[1]
              }
              onClick={() => setPopupInfo(true)}
              style={{
                position: "absolute",
                left: 0,
                top: 0,
              }}
            >
              <LocationOnIcon fontSize="large" color="error" />
            </Marker>
          )}
          {predictiveManager.address?.address.geometry &&
            popupInfo &&
            predictiveManager.address.address.properties.formatted_address && (
              <Popup
                anchor="top"
                longitude={
                  predictiveManager.address.address.geometry.coordinates[0]
                }
                latitude={
                  predictiveManager.address.address.geometry.coordinates[1]
                }
                onClose={() => setPopupInfo(false)}
              >
                <Typography color="black" variant="body2">
                  {
                    predictiveManager.address.address.properties
                      .formatted_address
                  }
                </Typography>
              </Popup>
            )}
          {gnafLayerJson && (
            <Source
              key="gnaf"
              id="gnaf"
              type="vector"
              maxzoom={15}
              tiles={addAuthToTileUrl(gnafLayerJson.tiles)}
            >
              <Layer {...gnafLayer} />
            </Source>
          )}
          {cadastreLayerJson && (
            <Source
              key="cadastre"
              id="cadastre"
              type="vector"
              maxzoom={16}
              tiles={addAuthToTileUrl(cadastreLayerJson.tiles)}
            >
              <Layer {...cadastreLayer} />
            </Source>
          )}
          <Box
            sx={{
              right: 16,
              top: 16,
              position: "absolute",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <ZoomControl
              resetCompass={resetCompass}
              dynamicZoom={dynamicZoom}
              onZoom={handleZoom}
            />
          </Box>
        </Basemap>
      )}
    </Paper>
  );
};
