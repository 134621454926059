import pako from "pako";
import React, { useEffect, useMemo, useState } from "react";
import { Layer, Source } from "react-map-gl";
import { App2 } from "../cards/feature-card";
import { WelcomeCard } from "../cards/welcome-card";
import { FormState, StepDemoConfig } from "./types";
import {
  EXTRUSION_OPACITY,
  createCoordinates,
  createMapConfig,
  getColorExpression,
  getExtrusionHeight,
} from "./utils";
const createLayerStyle = (formState: FormState, layer_name: string) => {
  const { extrude, style, style_key_word } = formState;

  return {
    id: layer_name,
    type: "fill-extrusion",
    paint: {
      "fill-extrusion-color": getColorExpression(style, style_key_word),
      "fill-extrusion-height": extrude
        ? getExtrusionHeight(style, formState)
        : 0,
      "fill-extrusion-opacity": EXTRUSION_OPACITY,
    },
  };
};

// React components
interface MapLayerProps {
  geojsonPath: string;
  formState: FormState;
  layer_name: string;
  highlightedLayer: any;
}

export const MapLayer: React.FC<MapLayerProps> = React.memo(
  ({ geojsonPath, formState, layer_name, highlightedLayer }) => {
    const layerStyle = useMemo(
      () => createLayerStyle(formState, layer_name),
      [formState, layer_name]
    );

    // highlightedLayer = !formState.extrude
    //   ? highlightedLayer.highlightedLayerFlat
    //   : highlightedLayer.highlightedLayer3D;

    const [geoJsonData, setGeoJsonData] = useState<any>(null);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
      const fetchAndDecompress = async () => {
        try {
          const response = await fetch(geojsonPath);

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const buffer = await response.arrayBuffer();

          let jsonData;

          // If it's gzip, decompress it
          const decompressed = pako.ungzip(new Uint8Array(buffer), {
            to: "string",
          });
          jsonData = JSON.parse(decompressed);

          setGeoJsonData(jsonData);
          setError(null);
        } catch (error) {
          console.error("Error fetching or processing GeoJSON:", error);
          // @ts-ignore
          setError(`Error: ${error.message}`);
        }
      };

      if (geojsonPath) {
        fetchAndDecompress();
      }
    }, [geojsonPath]);

    if (error) {
      return <div>Error loading map data: {error}</div>;
    }

    if (!geoJsonData) {
      return <div>Loading map data...</div>;
    }

    return (
      <Source type="geojson" data={geoJsonData}>
        {/* @ts-ignore */}
        <Layer {...layerStyle} />

        {!formState.extrude ? (
          <Layer {...highlightedLayer.highlightedLayerFlat} />
        ) : (
          <Layer {...highlightedLayer.highlightedLayer3D} />
        )}
      </Source>
    );
  }
);

// Step configurations
export const stepsDemos: Record<number, StepDemoConfig> = {
  1: {
    id: 1,
    component: WelcomeCard,
    mapConfig: createMapConfig(4),
    data: {
      title: "Welcome to Geoscape Emission Metric demo",
      description: {
        paragraph_one:
          "This is our emissions calculation prototype. You can explore average annual gas and electricity consumption and total CO2 emissions for each residential property, along with Geoscape Property Intelligence insights.",
        paragraph_two:
          "If you have any questions or feedback, please don’t hesitate to use the chatbox — we're here to help and eager to hear how this can support your reporting or net zero goals.",
      },
    },
    layer_name: "",
    coordinates: createCoordinates(-33.79, 151.2013),
    layer: <></>,
    geojsonPath: "",
  },
  2: {
    id: 2,
    component: App2,
    mapConfig: createMapConfig(
      16,
      5,

      [138.452682, -35.05069049408381, 138.671882, -34.959873787897386]
    ),
    data: {},
    layer_name: "buildings-layer",
    coordinates: createCoordinates(-35.005218, 138.562282),
    layer: <></>,
    geojsonPath: "/demos/geojson/emissions_metric.json.gz",
  },
};
