import BlockIcon from "@mui/icons-material/Block";
import CheckIcon from "@mui/icons-material/Check";
import { Box, Button } from "@mui/material";

import { Dataset, DatasetList } from "data/models";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { fetchDatasetsLink } from "data/queries";
import { formatBytes } from "utils/commons";

import { styled } from "@mui/styles";
import { formatDate } from "utils";

import {
  GridColDef,
  GridRenderCellParams,
  GridValidRowModel,
} from "@mui/x-data-grid-premium";
import { useCustomSnackbars } from "components/snackbars/useCustomSnackbars";
import { IdentityState } from "contexts/identity-context";
import { usePostHog } from "posthog-js/react";

const ResponsiveButton = styled(Button)({
  "@media (max-width: 1370)": {
    fontSize: "10px",
    // padding: '12px 24px',
  },
  "@media (max-width: 1500px)": {
    fontSize: "10px",
  },
  "@media (min-width: 1500px)": {
    fontSize: "12px",
  },
});

type Dictionary = {
  [key: string]: string;
};

const DisplayFormatMapping: Dictionary = {
  GDB: "GDB",
  GEOJSON: "GeoJSON",
  SHP: "Shapefile",
  TAB: "MapInfo TAB",
  LYNX: "LYNX",
  PUMP: "PUMP",
  GEOTIFF: "GEOTIFF",
  PSV: "Pipe-Separated Values",
};

const DisplayStateMapping: Dictionary = {
  "ALL STATES": "All States",
  AUSTRALIA: "Australia",
};

const customColumns = (identityState: IdentityState): GridColDef[] => {
  const { enqueueLinkCorruptedSnackbar } = useCustomSnackbars();
  const posthog = usePostHog();
  return [
    {
      flex: 1.5,
      field: "name",
      headerName: "Dataset",
    },
    {
      flex: 1.2,
      field: "release",
      headerName: "Release",
      valueFormatter(params) {
        const [year, month] = (params as string).split("-").map(Number);
        const monthName = new Date(year, month - 1).toLocaleString("default", {
          month: "long",
        });
        return `${monthName} ${year}`;
      },
    },
    {
      flex: 1,
      field: "region",
      headerName: "Region",
      valueFormatter(params) {
        return DisplayStateMapping[params as string] ?? (params as string);
      },
    },
    {
      flex: 1,
      field: "format",
      headerName: "Format",
      valueFormatter(params) {
        return DisplayFormatMapping[params as string] ?? (params as string);
      },
    },
    {
      flex: 1,
      field: "datum",
      headerName: "Datum",
    },
    {
      flex: 1,
      field: "size",
      headerName: "File Size",
      valueFormatter(params) {
        return formatBytes(params as number);
      },
    },

    {
      flex: 1,
      field: "publishDate",
      headerName: "Publish Date",
      valueFormatter(params) {
        return formatDate(params as string);
      },
    },
    {
      flex: 1,
      field: "licenced",
      headerName: "Licenced",
      renderCell: (params: GridRenderCellParams) =>
        params.value ? (
          <Box
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <CheckIcon />
          </Box>
        ) : (
          <Box
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <BlockIcon />
          </Box>
        ),
    },
    {
      flex: 1.2,
      field: "id",
      headerName: "Download",
      renderCell: (params: GridRenderCellParams) => {
        return params.row.licenced ? (
          <ResponsiveButton
            variant="contained"
            fullWidth
            // textSizeSmall
            startIcon={<FileDownloadIcon />}
            onClick={async () => {
              try {
                const response = await fetchDatasetsLink(
                  params.value,
                  identityState
                );

                const a = document.createElement("a");
                a.href = response;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(response);
                posthog.capture("user_downloaded_dataset", {
                  id: params.value,
                  name: params.row.name,
                  region: params.row.region,
                  release: params.row.release,
                  datum: params.row.datum,
                  format: params.row.format,
                });
              } catch (er) {
                enqueueLinkCorruptedSnackbar();
                // If this happens it will show and popup
              }
            }}
          >
            Download
          </ResponsiveButton>
        ) : (
          <ResponsiveButton
            variant="contained"
            fullWidth
            disabled
            startIcon={<FileDownloadIcon />}
          >
            Download
          </ResponsiveButton>
        );
      },
    },
  ];
};

const customersToRows = (datasetsList: DatasetList): GridValidRowModel[] => {
  const rows = datasetsList.datasets.map((key: Dataset) => {
    return {
      id: key.id,
      name: key.name,
      product: key.product,
      region: key.region,
      format: key.format,
      size: key.size,
      release: key.release,
      publishDate: key.publishDate,
      licenced: key.licenced,
      datum: key.datum,
    };
  });
  return rows;
};

export { customColumns, customersToRows };
