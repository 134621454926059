interface PlanDetails {
  overageRate: number;
  planCredits: number;
}

/**
 * Round down the overage dollar limit to the nearest dollar block of 1000 credits.
 * @param limit The dollar amount
 * @param overageRate The overage rate ($/credit)
 */
const roundDownOverageDollarLimit = (limit: number, overageRate: number) => {
  const ratePer1000Credits = overageRate * 1000;
  return limit - (limit % ratePer1000Credits);
};

/**
 * Round up the overage dollar limit to the nearest dollar block of 1000 credits.
 * @param limit The dollar amount
 * @param overageRate The overage rate ($/credit)
 */
const roundUpOverageDollarLimit = (limit: number, overageRate: number) => {
  const ratePer1000Credits = overageRate * 1000;
  if (limit % ratePer1000Credits === 0) {
    return limit;
  } else {
    return limit + (ratePer1000Credits - (limit % ratePer1000Credits));
  }
};

/**
 * Round down to the nearest lot of target
 * For example: roundDownToNearest(45, 10) = 40
 * @param value The value to be rounded
 * @param target The target to round to
 */
const roundDownToNearest = (value: number, target: number) => {
  return value - (value % target);
};

const roundDownOverageBudget = (
  overageBudget: number,
  planDetails: PlanDetails
) => {
  // Round down the dollar amount to the closest block of 1000 credits
  return overageBudget - (overageBudget % planDetails.overageRate);
};

const roundDownOverageCredit = (overageCredits: number) => {
  // Round down the credit amount to the closest block of 1000 credits
  return overageCredits - (overageCredits % 1000);
};

const calculateOverageCredits = (
  overageDollars: number = 0,
  planDetails: PlanDetails
) => {
  // Calculate how many overage credits the user will have
  return Math.floor((overageDollars / planDetails.overageRate) * 1000);
};

const calculateOverageDollars = (
  overageCredits: number = 0,
  planDetails: PlanDetails
) => {
  // Calculate how many overage dollars the user will have
  return Math.floor((overageCredits / 1000) * planDetails.overageRate);
};

export {
  roundDownOverageBudget,
  calculateOverageCredits,
  calculateOverageDollars,
  roundDownOverageCredit,
  roundDownOverageDollarLimit,
  roundUpOverageDollarLimit,
  roundDownToNearest,
};

export type { PlanDetails };
