import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Link,
  SxProps,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { Auth } from "aws-amplify";
import { BaseState } from "common/models";
import { Form, Formik, FormikConfig } from "formik";
import { useState } from "react";
import * as Routes from "routes";
import { InferType, object, string } from "yup";

import { useMessageState } from "./use-message-state";

export const ResendVerificationValidationSchema = object({
  email: string().email("Enter a valid email").required("Email is required"),
});

interface ResendVerificationValues {
  email: string;
}

interface ResendVerificationState extends BaseState {}

export const ResendVerification = () => {
  const [state, setState] = useState<ResendVerificationState>({
    isError: false,
    message: "",
    isLoading: false,
  });

  const [message, setMessage] = useMessageState({ type: null, text: "" });

  const setLoading = (isLoading: boolean) => {
    setState((currentState) => ({ ...currentState, isLoading }));
  };

  const onSubmit: FormikConfig<
    InferType<typeof ResendVerificationValidationSchema>
  >["onSubmit"] = async ({ email }) => {
    try {
      setLoading(true);
      await Auth.resendSignUp(email);
      setMessage({
        type: "success",
        text: `Verification link has been successfully resent to ${email}.`,
      });
    } catch (e: any) {
      if (e?.message.includes("resend_confirmation_failure")) {
        setMessage({
          type: "error",
          text: `Verification link could not be sent to ${email}. Please try again and contact support if the issue persists.`,
        });
      } else {
        setMessage({
          type: "error",
          text: `An error occurred in sending the verification link to ${email}. Please try again and contact support if the issue persists.`,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Formik<ResendVerificationValues>
        initialValues={{ email: "" }}
        onSubmit={onSubmit}
        validationSchema={ResendVerificationValidationSchema}
      >
        {({ values: { email }, handleChange, handleBlur, errors, touched }) => (
          <Form>
            <Card
              style={{
                padding: "0px",
                display: "flex",
                width: "410px",
                minWidth: "410px",
                maxWidth: "500px",
                flexDirection: "column",
                alignItems: "flex-start",
                borderRadius: "32px",
              }}
            >
              <CardHeader
                sx={{
                  padding: "32px 32px 0px 32px",
                }}
                titleTypographyProps={{ variant: "h5" }}
                title="Resend Verification"
                subheader={
                  "If you have lost your verification link, we can send another."
                }
              />

              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",

                  padding: "var(--4,32px) !important",
                  alignItems: "flex-start",
                  gap: "16px",
                  alignSelf: "stretch",
                }}
              >
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  label="Email"
                  type="text"
                  value={email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  disabled={state.isLoading}
                  sx={customStyle}
                  InputProps={{
                    sx: {
                      borderRadius: "8px",
                      height: "40px",
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                    },
                  }}
                  InputLabelProps={{
                    sx: {},
                  }}
                />

                {state.isLoading ? (
                  <Box width="100%" display="flex" justifyContent="center">
                    <CircularProgress />
                  </Box>
                ) : (
                  <>
                    {message.text === "" || !message.type ? null : (
                      <Alert
                        variant="outlined"
                        severity={message.type}
                        sx={{
                          borderRadius: "8px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          alignSelf: "stretch",
                        }}
                      >
                        <Typography variant="body2">{message.text}</Typography>
                      </Alert>
                    )}

                    <Button
                      variant="contained"
                      type="submit"
                      disabled={state.isLoading}
                      fullWidth
                      children="Resend"
                      sx={{ borderRadius: "8px", height: "36px" }}
                    />
                    <ButtonGroup
                      variant="text"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        alignSelf: "stretch",
                        borderRadius: "4px",
                        padding: "0px",
                      }}
                      aria-label="Basic button group"
                    >
                      <Button
                        LinkComponent={Link}
                        size="small"
                        color="primary"
                        href={Routes.signUp}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "4px",

                          padding: "4px 5px",
                          gap: "8px",
                          fontSize: "12px",
                        }}
                      >
                        Sign up
                      </Button>

                      <Button
                        LinkComponent={Link}
                        size="small"
                        color="primary"
                        href={Routes.signIn}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "4px",
                          //
                          // color: "var(--Dark-Primary-Main, #90CAF9)",
                          padding: "4px 5px",
                          gap: "8px",
                          fontSize: "12px",
                        }}
                      >
                        Sign In
                      </Button>

                      <Button
                        LinkComponent={Link}
                        size="small"
                        color="primary"
                        href={Routes.resetPassword}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "4px",

                          padding: "4px 5px",
                          gap: "8px",
                          fontSize: "12px",
                        }}
                      >
                        Password Reset
                      </Button>
                    </ButtonGroup>
                  </>
                )}
              </CardContent>
            </Card>
          </Form>
        )}
      </Formik>

      <Box>{/* <Link href="sign-in">Sign in</Link> */}</Box>
    </>
  );
};

const customStyle: SxProps<Theme> = {
  [`& .MuiInputLabel-root `]: {
    top: "-5px",
  },

  [`& .MuiInputLabel-shrink `]: {
    top: "0px",
  },
};
