import { PaletteMode } from "@mui/material";
import { LinkProps } from "@mui/material/Link";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import { createTheme } from "@mui/material/styles";
import ThemeProvider, {
  ThemeProviderProps,
} from "@mui/material/styles/ThemeProvider";
import React, { ComponentType } from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import useDarkMode from "use-dark-mode";

const theme = (mode: PaletteMode) =>
  createTheme({
    palette: {
      mode: mode,
    },
    components: {
      MuiLink: {
        defaultProps: {
          component: LinkBehavior,
        } as Partial<RouterLinkProps>,
        styleOverrides: {
          root: {
            color: "red", // Custom color specifically for Link text
            // padding: "16px",
            // borderRadius: "16px",
            // boxShadow: "none", // Flat style with no shadow
            // border: mode === "dark" ? "1px solid #353535" : "1px solid #E0E0E0", // Change as needed
          },
        },
      },

      // Apply card customizations
      MuiPaper: {
        styleOverrides: {
          root: {
            padding: "0px",
            borderRadius: "16px",
            boxShadow: "none", // Flat style with no shadow
            border: mode === "dark" ? "1px solid #353535" : "1px solid #E0E0E0",
          },
        },
      },

      // apply border raidus to tables
      MuiCard: {
        styleOverrides: {
          root: {
            padding: "0px",
            borderRadius: "16px",
            boxShadow: "none", // Flat style with no shadow
            border: mode === "dark" ? "1px solid #353535" : "1px solid #E0E0E0", // Change as needed
          },
        },
      },

      MuiCardHeader: {
        styleOverrides: {
          root: {
            padding: "16px !important",
            paddingBottom: "0px !important",
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            padding: "16px !important",
          },
        },
      },

      MuiSkeleton: {
        styleOverrides: {
          root: {
            borderRadius: "16px", // Match the card's rounded corners for visual consistency
            // padding: "0px",
            boxShadow: "none", // Flat style with no shadow
            border: mode === "dark" ? "1px solid #353535" : "1px solid #E0E0E0", // Change as needed
          },
        },
      },

      // @ts-ignore
      MuiDataGridPremium: {
        styleOverrides: {
          root: {
            borderRadius: "30px", // Match the card's rounded corners for visual consistency
            padding: "0px",
            boxShadow: "none", // Flat style with no shadow
            border: mode === "dark" ? "1px solid #353535" : "1px solid #E0E0E0", // Change as needed
            backgroundColor:
              mode === "dark" ? "rgba(255, 255, 255, 0.05)" : "white",
          },
          columnHeader: {
            backgroundColor:
              mode === "dark" ? "rgba(255, 255, 255, 0.05)" : "white",
          },
        },
      },

      // @ts-ignore
      MuiDataGrid: {
        styleOverrides: {
          root: {
            borderRadius: "16px", // Match the card's rounded corners for visual consistency
            padding: "4px",
            boxShadow: "none", // Flat style with no shadow
            border: mode === "dark" ? "1px solid #353535" : "1px solid #E0E0E0", // Change as needed
            backgroundColor:
              mode === "dark" ? "rgba(255, 255, 255, 0.05)" : "white",
          },
          columnHeader: {
            // borderRadius: "30px", //
            backgroundColor:
              mode === "dark" ? "rgba(255, 255, 255, 0.05)" : "white",
          },
          columnHeaderRow: {
            // borderRadius: "16px", //
          },
        },
      },

      // Apply button customizations
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "8px",
            boxShadow: "none", // Flat style with no shadow
          },
        },
      },

      MuiButtonGroup: {
        styleOverrides: {
          root: {
            borderRadius: "8px",
            boxShadow: "none", // Flat style with no shadow
          },
        },
      },
      // Apply input customizations
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: "8px",
            boxShadow: "none", // Flat style with no shadow
          },
        },
      },
      // Apply dropdown menu customizations
      MuiMenu: {
        styleOverrides: {
          paper: {
            borderRadius: "8px",
            boxShadow: "none", // Flat style with no shadow
            border: mode === "dark" ? "1px solid #353535" : "1px solid #E0E0E0", // Change as needed
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            borderRadius: "8px",
            // border: mode === "dark" ? "1px solid #353535" : "1px solid #E0E0E0", // Change as needed
          },
        },
      },

      MuiAlert: {
        styleOverrides: {
          root: {
            padding: "16px",
            borderRadius: "16px",
            boxShadow: "none", // Flat style with no shadow
            border: mode === "dark" ? "1px solid #353535" : "1px solid #E0E0E0", // Change as needed
            // backgroundColor: "#f0f0f0", // Custom background color for the Alert
          },
        },
      },

      MuiDialog: {
        styleOverrides: {
          root: {
            // padding: "16px",
            // borderRadius: "16px",
            // boxShadow: "none", // Flat style with no shadow
            // border: mode === "dark" ? "1px solid #353535" : "1px solid #E0E0E0", // Change as needed
            // Custom background color for the Alert
          },
          paper: {
            padding: "16px",
            borderRadius: "16px",
            boxShadow: "none", // Flat style with no shadow
            border: mode === "dark" ? "1px solid #353535" : "1px solid #E0E0E0", // Change as needed
            // Custom background color for the Alert
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            padding: "16px",
            borderRadius: "16px",
            // boxShadow: "none", // Flat style with no shadow
            // border: mode === "dark" ? "1px solid #353535" : "1px solid #E0E0E0", // Change as needed
            // backgroundColor: "#f0f0f0", // Custom background color for the Alert
          },
        },
      },
    },
    // No customizations for tables (keep default)
  });

// import {  } from 'react-router-dom/server';
export const GeoscapeDeveloperThemeProvider: ComponentType<
  Omit<ThemeProviderProps, "theme">
> = ({ children, ...props }) => {
  const { value: isDark } = useDarkMode();
  const mode = isDark ? "dark" : "light";
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider {...props} theme={theme(mode)}>
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

const LinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return (
    <RouterLink data-testid="custom-link" ref={ref} to={href} {...other} />
  );
});

const theme2 = (mode: PaletteMode) =>
  createTheme({
    palette: {
      mode: mode,
      // secondary: {
      //  main: '#999999',
    },

    components: {
      MuiLink: {
        defaultProps: {
          component: LinkBehavior,
        } as LinkProps,
      },
    },
  });

// theme.typography.h5 = {
// fontSize: '14px',
// "@media (min-width:900px)": {
//   fontSize: "12px",
// },

// "@media (min-width:1200px)": {
//   fontSize: "16px",
// },
// [theme.breakpoints.up('md')]: {
//   // fontSize: '2.4rem',
// },
// };

// Documentation
// https://mui.com/material-ui/customization/typography/
