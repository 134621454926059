import { Geometry, HSLColour, Styles } from "../models";

export const HSLToHSLString = (hsl: HSLColour) => {
  return `hsl(${hsl.h}, ${hsl.s}%, ${hsl.l}%)`;
};

export const highlightPaintForGeo = (
  color: string,
  opacity: number,
  geometry: Geometry,
  sizeFactor: number = 1
) => {
  switch (geometry) {
    case "Polygon":
    case "LineString":
      return {
        "line-color": color,
        "line-opacity": opacity,
        "line-width": 1 * sizeFactor,
      };
    case "Point":
      return {
        "circle-color": color,
        "circle-opacity": opacity,
        "circle-radius": 1 * sizeFactor,
      };
    default:
      return {
        "fill-color": color,
        "fill-opacity": opacity,
        "fill-outline-color": color,
      };
  }
};
export const paintForGeo = (color: string, styles: Styles) => {
  switch (styles.type) {
    case "Polygon":
      return {
        "fill-color": styles.fill ? color : "rgba(0,0,0,0)",
        "fill-opacity": styles.fill ? styles.opacity : 0,
        "fill-outline-color": "rgba(0,0,0,0)",
      };
    case "LineString":
      return {
        "line-color": color,
        "line-opacity": styles.opacity,
        "line-width": styles.thickness,
      };
    case "Point":
      return {
        "circle-color": color,
        "circle-opacity": styles.opacity,
        "circle-radius": styles.diameter,
      };
  }
};
export const highlightTypeForGeo = (
  geometry: Geometry
): "fill" | "line" | "circle" => {
  switch (geometry) {
    case "Polygon":
    case "LineString":
      return "line";
    case "Point":
      return "circle";
    default:
      return "line";
  }
};
export const typeForGeo = (geometry: Geometry): "fill" | "line" | "circle" => {
  switch (geometry) {
    case "Polygon":
      return "fill";
    case "LineString":
      return "line";
    case "Point":
      return "circle";
    default:
      return "fill";
  }
};
