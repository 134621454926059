import { JSONSchemaType } from "ajv";

interface VectorLayer {
  id: string;
  fields: { [key: string]: string };
  minzoom: number;
  maxzoom: number;
  geometry: Geometry;
}

interface TileJSON {
  tilejson: string;
  vector_layers: VectorLayer[];
  tiles: string[];
  attribution?: string;
  bounds?: number[];
  center?: number[];
  description: string;
}

interface Coordinate {
  latitude: number;
  longitude: number;
}

type Geometry = "Polygon" | "LineString" | "Point";

interface HSLColour {
  h: number;
  s: number;
  l: number;
}

interface BaseStyles {
  opacity: number;
  colour: string;
  type: Geometry;
}

interface PolygonStyles extends BaseStyles {
  thickness?: number;
  fill?: boolean;
  type: "Polygon";
}

interface LineStyles extends BaseStyles {
  thickness?: number;
  type: "LineString";
}

interface PointStyles extends BaseStyles {
  diameter?: number;
  type: "Point";
}

type Styles = PolygonStyles | LineStyles | PointStyles;

interface LayerConfiguration {
  id: string;
  rawLayerId: string;
  displayName: string;
  geometry: Geometry;
  visible: boolean;
  primaryHighlightsFilter: string[] | boolean;
  secondaryHighlightsFilter: string[] | boolean;
  primaryKey: string;
  styles: Styles;
}

export const layerConfigurationSchema: JSONSchemaType<LayerConfiguration> = {
  type: "object",
  properties: {
    id: { type: "string" },
    rawLayerId: { type: "string" },
    displayName: { type: "string" },
    geometry: { type: "string", enum: ["Polygon", "LineString", "Point"] },
    visible: { type: "boolean" },
    primaryHighlightsFilter: {
      anyOf: [
        { type: "array", items: { type: "string" } },
        { type: "boolean" },
      ],
    },
    secondaryHighlightsFilter: {
      anyOf: [
        { type: "array", items: { type: "string" } },
        { type: "boolean" },
      ],
    },
    primaryKey: { type: "string" },
    styles: {
      anyOf: [
        {
          type: "object",
          properties: {
            opacity: { type: "number" },
            colour: { type: "string" },
            type: { type: "string", const: "Polygon" },
            thickness: { type: "number", nullable: true },
            fill: { type: "boolean", nullable: true },
          },
          required: ["opacity", "colour", "type"],
          additionalProperties: false,
        },
        {
          type: "object",
          properties: {
            opacity: { type: "number" },
            colour: { type: "string" },
            type: { type: "string", const: "LineString" },
            thickness: { type: "number", nullable: true },
          },
          required: ["opacity", "colour", "type"],
          additionalProperties: false,
        },
        {
          type: "object",
          properties: {
            opacity: { type: "number" },
            colour: { type: "string" },
            type: { type: "string", const: "Point" },
            diameter: { type: "number", nullable: true },
          },
          required: ["opacity", "colour", "type"],
          additionalProperties: false,
        },
      ],
    },
  },
  required: [
    "id",
    "rawLayerId",
    "displayName",
    "geometry",
    "visible",
    "primaryHighlightsFilter",
    "secondaryHighlightsFilter",
    "primaryKey",
    "styles",
  ],
  additionalProperties: false,
};

export const layerConfigurationArraySchema: JSONSchemaType<
  LayerConfiguration[]
> = {
  type: "array",
  items: layerConfigurationSchema,
};

export type {
  TileJSON,
  VectorLayer,
  LayerConfiguration,
  Geometry,
  Coordinate,
  HSLColour,
  Styles,
};
