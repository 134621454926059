import { Card, CardContent, useTheme } from "@mui/material";
import ReactJson from "react-json-view";

interface VisualiseJsonProps {
  object: object;
}

export const VisualiseJson = (props: VisualiseJsonProps) => {
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  return (
    <Card>
      <CardContent
        sx={{
          "&:last-child": {
            paddingBottom: 2,
          },
        }}
      >
        <ReactJson
          style={{
            height: 230,
            overflow: "scroll",
          }}
          name={false}
          src={props.object}
          displayDataTypes={false}
          displayObjectSize={false}
          theme={isDark ? "bright" : "rjv-default"}
          enableClipboard={true}
        />
      </CardContent>
    </Card>
  );
};
