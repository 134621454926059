import CachedIcon from "@mui/icons-material/Cached";
import {
  Card,
  CardContent,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { PredictiveAddressManager } from "./predictive-address-manager";

export const CallCounter = (predictiveManager: PredictiveAddressManager) => {
  return (
    <Card>
      <CardContent
        sx={{
          "&:last-child": {
            paddingBottom: 2,
          },
        }}
      >
        <Stack direction="row" gap={2} justifyContent="center">
          <Typography variant="body1">{`Call counter: ${predictiveManager.callCounter}`}</Typography>
          <Typography variant="body1">{`Credit counter: ${predictiveManager.creditCounter.toLocaleString(
            "en-US",
            {
              minimumFractionDigits: 1,
            }
          )}`}</Typography>
          <IconButton
            sx={{
              width: 24,
              height: 24,
            }}
            onClick={() => {
              predictiveManager.setCallCounter(0);
              predictiveManager.setCreditCounter(0);
            }}
          >
            <CachedIcon />
          </IconButton>
        </Stack>
      </CardContent>
    </Card>
  );
};
