import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Pagination,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {
  ClipConfiguration,
  Status,
} from "pages/geoscape-data/explorer/clip/models";
import { statusColour } from "pages/geoscape-data/explorer/clip/steps/step-0-new-clip/clip-item";
import { Dispatch, SetStateAction, useState } from "react";
import { AdminClipItem } from "./admin-clip-item";
interface AdminClipSearchProps {
  clips: ClipConfiguration[];
  selectedClip?: ClipConfiguration;
  setSelectedClip: Dispatch<SetStateAction<ClipConfiguration | undefined>>;
}

export const AdminClipSearch = (props: AdminClipSearchProps) => {
  const MAX_CLIPS = 5;

  const statuses: Status[] = [
    "Draft",
    "Quoted",
    "Quoting",
    "Processing",
    "Completed",
    "Failed",
  ];

  const [search, setSearch] = useState("");
  const [filterStatuses, setFilterStatuses] = useState<Status[]>([]);
  dayjs.extend(utc);

  const accounts: string[] = props.clips
    .map((clip) => clip.ownerName)
    .filter(
      (ownerName: string | undefined): ownerName is string => ownerName !== null
    )
    .filter(
      (ownerName: string | undefined): ownerName is string =>
        ownerName !== undefined
    )
    .sort((a: string, b: string) => a.localeCompare(b))
    .filter(
      (value, index, currentValue) => currentValue.indexOf(value) === index
    );
  const [filterAccounts, setFilterAccounts] = useState<string[]>([]);

  const [page, setPage] = useState(1);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  let filteredClips: ClipConfiguration[] = props.clips
    .sort((a: ClipConfiguration, b: ClipConfiguration) =>
      dayjs.utc(a.date).isBefore(dayjs.utc(b.date)) ? 1 : -1
    )
    .filter(
      (clip) =>
        clip.name.toLowerCase().startsWith(search.toLowerCase()) ||
        clip.ownerName?.toLowerCase().startsWith(search.toLowerCase()) ||
        clip.id === search
    );

  if (filterStatuses.length > 0) {
    filteredClips = filteredClips.filter((clip) =>
      filterStatuses.includes(clip.status)
    );
  }

  if (filterAccounts.length > 0) {
    filteredClips = filteredClips.filter(
      (clip) => clip.ownerName && filterAccounts.includes(clip.ownerName)
    );
  }

  const paginatedClips: ClipConfiguration[] = filteredClips.slice(
    MAX_CLIPS * (page - 1),
    MAX_CLIPS * page
  );

  const handleStatusSelectChange = async (
    event: SelectChangeEvent<Status[]>
  ) => {
    const {
      target: { value },
    } = event;
    setFilterStatuses(
      // On autofill we get a stringified value.
      (typeof value === "string" ? value.split(",") : value) as Status[]
    );
    setPage(1);
  };

  const handleAccountSelectChange = async (
    event: SelectChangeEvent<string[]>
  ) => {
    const {
      target: { value },
    } = event;
    setFilterAccounts(
      // On autofill we get a stringified value.
      (typeof value === "string" ? value.split(",") : value) as string[]
    );
    setPage(1);
  };

  return (
    <Card
      sx={{
        padding: "0px",
        width: "calc(100% / 3)",
        height: 756,
      }}
    >
      <CardHeader title="Search" />
      <CardContent>
        <Box display="flex" flexDirection="column" gap={2} height="100%">
          <TextField
            onChange={(e) => {
              setSearch(e.target.value);
              setPage(1);
            }}
            label="Search"
            value={search}
            placeholder="Type here..."
            size="small"
            fullWidth
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={() => {
                    setSearch("");
                  }}
                >
                  <CloseIcon />
                </IconButton>
              ),
            }}
          ></TextField>
          <FormControl fullWidth size="small">
            <InputLabel id="status-select-label">Status</InputLabel>
            <Select
              fullWidth
              size="small"
              labelId="status-select-label"
              id="status-select"
              multiple
              value={filterStatuses}
              onChange={handleStatusSelectChange}
              input={
                <OutlinedInput id="select-multiple-status" label="Status" />
              }
              inputProps={{ IconComponent: () => null }}
              endAdornment={
                <IconButton
                  onClick={() => {
                    setFilterStatuses([]);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              }
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip
                      color={statusColour(value)}
                      size="small"
                      label={value}
                      variant="outlined"
                    />
                  ))}
                </Box>
              )}
            >
              {statuses.map((status) => (
                <MenuItem key={status} value={status}>
                  <Checkbox checked={filterStatuses.includes(status)} />
                  <ListItemText primary={status} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth size="small">
            <InputLabel id="account-select-label">Account</InputLabel>
            <Select
              fullWidth
              multiple
              size="small"
              labelId="account-select-label"
              id="account-select"
              value={filterAccounts}
              onChange={handleAccountSelectChange}
              inputProps={{ IconComponent: () => null }}
              endAdornment={
                <IconButton
                  onClick={() => {
                    setFilterAccounts([]);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              }
              input={
                <OutlinedInput id="select-multiple-accounts" label="Account" />
              }
              renderValue={(selected) => selected.join(", ")}
            >
              {accounts.map((account) => (
                <MenuItem key={account} value={account}>
                  <Checkbox checked={filterAccounts.includes(account)} />
                  <ListItemText primary={account} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box display="flex" flexDirection="column" gap={1}>
            {paginatedClips
              .slice(0, props.clips.length)
              .map((clip: ClipConfiguration, idx: number) => (
                <AdminClipItem
                  isSelected={clip.id === props.selectedClip?.id}
                  key={`clip-item-${idx}`}
                  clip={clip}
                  onSelect={(clip: ClipConfiguration) =>
                    props.setSelectedClip(clip)
                  }
                ></AdminClipItem>
              ))}
          </Box>
          <Box alignSelf="center" marginTop="auto" display="flex">
            <Pagination
              shape="rounded"
              page={page}
              onChange={handleChange}
              count={Math.ceil(filteredClips.length / MAX_CLIPS)}
            />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
