import { Box, Button, Link } from "@mui/material";
import { ReactQueryErrorWrapper } from "components/shared/react-query-error-wrapper";
import { getActiveOrganisation, useIdentity } from "contexts/identity-context";
import * as QueryKeys from "data";
import { fetchApiKeys } from "data/queries";
import { useQuery } from "react-query";
import * as Routes from "routes";
import { resolveIdentityId } from "utils/identity";
import { ApiKeyTable } from "./api-keys-table";

export const ApiKeysPage = () => {
  const [identityState] = useIdentity();
  const isUser = !getActiveOrganisation(identityState);
  const identityId = resolveIdentityId(identityState, isUser);

  const apiKeysQuery = useQuery([QueryKeys.apiKeys, identityId], () =>
    fetchApiKeys(isUser, identityState)
  );

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            position: "absolute",
            top: "-55px",
            right: "0px",
          }}
        >
          <Button
            href={Routes.developerApiKeysCreateKey}
            LinkComponent={Link}
            variant="contained"
          >
            Create API Key
          </Button>
        </Box>

        <Box
          style={{
            width: "100%",
            height: "100%",
            // overflow: "auto"
          }}
        >
          <ReactQueryErrorWrapper queries={[apiKeysQuery]} mutations={[]} />

          <ApiKeyTable
            data={apiKeysQuery.data}
            isLoading={apiKeysQuery.isLoading}
            apiKeyDetailsRoute={Routes.developerApiKeysEditKey}
          />
        </Box>
      </>
    </Box>
  );
};
