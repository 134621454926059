import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  Skeleton,
  Typography,
} from "@mui/material";
import CustomTable from "components/shared/table";
import { getActiveOrganisation, useIdentity } from "contexts/identity-context";
import * as QueryKeys from "data";
import { ReactQueryQueryError, fetchBatchSample } from "data/queries";
import {
  BatchConfigProps,
  StepperControlProps,
} from "pages/geoscape-batch/types";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { resolveIdentityId } from "utils/identity";

import { LoadingButton } from "@mui/lab";
import { ReactQueryErrorWrapper } from "components/shared/react-query-error-wrapper";

import { GridRowParams } from "@mui/x-data-grid-premium";
import { Basemap } from "components/basemap/basemap";
import { ZoomControl } from "pages/geoscape-data/explorer/zoom-control";
import { Layer, MapRef, Source, SymbolLayer } from "react-map-gl";
import { defineSampleRequest } from "../step-upload-input-file/upload-input-file-page";
import { defineColumns, extractRowData } from "./utils";

interface ViewSampleBatchJobProps {}

export const ViewSampleBatchJob = (
  props: ViewSampleBatchJobProps & BatchConfigProps & StepperControlProps
) => {
  // fetchBatchSample
  const [identityState] = useIdentity();
  const isUser = !getActiveOrganisation(identityState);

  const identityId = resolveIdentityId(identityState, isUser);

  // Maps stuff
  const mapRef = useRef<MapRef>();

  mapRef.current?.loadImage("/marker.png", (error, image: any) => {
    if (error) throw error;
    if (!mapRef.current?.hasImage("marker"))
      mapRef.current?.addImage("marker", image);
  });

  const initialZoom: number = 3.5;
  const addressZoom: number = 15;
  const [dynamicZoom, setDynamicZoom] = useState(initialZoom);

  const [coordinates, setCoordinates] = useState({
    latitude: -27.055646,
    longitude: 134.243199,
  });

  const handleZoom = (zoom: number): void => {
    setDynamicZoom(zoom);
    mapRef.current?.flyTo({ zoom: zoom, duration: 500 });
  };

  const dataLayer: SymbolLayer = {
    id: "point",
    type: "symbol",
    layout: {
      "icon-image": "marker",
      "icon-anchor": "bottom",
      "icon-allow-overlap": true,
    },
    paint: {
      "icon-color": "#FF0000",
      "icon-opacity": 0.8,
    },
  };

  // Define the sample request
  useEffect(() => {
    if (props.batchConfig.hubInfo.sample.request.length === 0) {
      defineSampleRequest(
        props.batchConfig.hubInfo.sample.rows,
        props.batchConfig,
        props.setBatchConfig
      );
    }
  }, [props.batchConfig.hubInfo.sample.rows]);

  // Fetch the batch config once it has been quoted
  const fetchBatchSamplesQuery = useQuery(
    [
      QueryKeys.batchesSampleResponse,
      props.batchConfig.batchInfo.batchId,
      identityId,
    ],
    () =>
      fetchBatchSample(
        props.batchConfig.batchInfo.batchId,
        {
          requests: props.batchConfig.hubInfo.sample.request,
          parameters: props.batchConfig.parameters,
        },
        props.batchConfig.type,
        identityState
      ),
    {
      enabled: props.batchConfig.hubInfo.sample.request.length !== 0,
      onSuccess: (data) => {
        props.setBatchConfig((prev) => ({
          ...prev,
          hubInfo: {
            ...prev.hubInfo,
            sample: {
              ...prev.hubInfo.sample,
              response: data,
            },
          },
        }));

        props.saveBatchConfig();
      },
    }
  );

  const resetCompass = () => {
    mapRef.current?.fitBounds(fetchBatchSamplesQuery.data.boundingBox, {
      duration: 2000,
    });
  };

  const handleRowClick = (params: GridRowParams) => {
    if (params.row.longitude === 0 || params.row.latitude === 0) {
      return;
    }

    mapRef.current?.flyTo({
      center: [params.row.longitude, params.row.latitude],
      duration: 2000,
      zoom: addressZoom,
    });
  };

  return (
    <Card
      sx={{
        padding: "16px",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      <Box>
        <Typography variant="h5">Preview</Typography>
        <Typography variant="subtitle1" sx={{ color: "grey" }}>
          View a sample of your batch job.
        </Typography>
      </Box>

      <ReactQueryErrorWrapper
        queries={[fetchBatchSamplesQuery]}
        mutations={[]}
      />

      {fetchBatchSamplesQuery.isError && (
        <Alert severity="error">
          <AlertTitle>There was an error sampling the batch job.</AlertTitle>
          {(fetchBatchSamplesQuery.error as ReactQueryQueryError).message}
        </Alert>
      )}

      {fetchBatchSamplesQuery.isLoading && (
        <Skeleton variant="rectangular" height={221} />
      )}

      {fetchBatchSamplesQuery.isLoading && (
        <Skeleton variant="rectangular" height={700} />
      )}

      {fetchBatchSamplesQuery.isSuccess && (
        <CustomTable
          rows={extractRowData(fetchBatchSamplesQuery.data)}
          cols={defineColumns(props.batchConfig.type)}
          isLoading={false}
          overlayText={"No sample data, is your input file empty?"}
          density="compact"
          hideFooter={true}
          handleOnRowClick={handleRowClick}
        />
      )}

      {props.batchConfig.hubInfo.sample.request.length === 0 && (
        <Alert severity="warning">
          If there are no results, there may be something wrong with your input
          file. Please re-upload your input file and try again before contacting
          support.
        </Alert>
      )}

      {fetchBatchSamplesQuery.isSuccess && (
        <Basemap
          // @ts-ignore
          mapRef={mapRef}
          latitude={coordinates.latitude}
          longitude={coordinates.longitude}
          zoom={initialZoom}
          onZoom={(zoom: any) => setDynamicZoom(zoom)}
          style={{
            width: "100%",
            height: "700px",
          }}
          minZoom={0}
          maxZoom={15}
        >
          {/* Show the sample addresses as markers on the map in a symbol layer */}
          <Source
            type="geojson"
            data={{
              ...fetchBatchSamplesQuery.data.featureCollection,
              features:
                fetchBatchSamplesQuery.data.featureCollection.features.filter(
                  (feature: any) => feature !== null
                ),
            }}
          >
            <Layer {...dataLayer} />
          </Source>
          <Box
            sx={{
              right: 32,
              top: 32,
              position: "absolute",
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              justifyContent: "flex-end",
            }}
          >
            <ZoomControl
              resetCompass={resetCompass}
              dynamicZoom={dynamicZoom}
              onZoom={handleZoom}
            />
          </Box>
        </Basemap>
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          gap: "16px",
        }}
      >
        <Button
          onClick={() => props.setActiveStep(Math.max(props.activeStep - 1, 0))}
        >
          Back
        </Button>

        <LoadingButton
          variant="contained"
          onClick={() => {
            props.saveBatchConfig(
              props.batchConfig,
              Math.min(props.activeStep + 1, props.maxSteps)
            );
          }}
          loading={props.isSaveBatchConfigLoading}
        >
          Continue
        </LoadingButton>
      </Box>
    </Card>
  );
};
