import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";

interface WelcomeCardProps {
  onExit: () => void;
  onExplore: () => void;
}

export const WelcomeCard = (props: WelcomeCardProps) => {
  return (
    <Card
      sx={{
        border: "none",
        boxShadow: "none",
        width: "660px",
        maxHeight: "100%",
        display: "flex",
        flexDirection: "column",
        alignSelf: "self-end",
        position: "fixed",
        left: "40%",
        bottom: "40%",
        borderRadius: "16px",
      }}
    >
      <CardHeader title="Welcome to Our Easement Data Prototype" />
      <CardContent>
        <Typography variant="body1" paragraph>
          Explore sample data from Albury, NSW, and Wodonga, VIC. This demo
          highlights the various types of easement data we offer, and how
          easements interact with parcels and the built environment. Feel free
          to navigate around and share your feedback.
        </Typography>
        <Typography variant="body1" paragraph>
          Get in touch if you'd like to experience the most comprehensive
          national easement dataset with our full product
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" color="error" onClick={props.onExit}>
          Exit
        </Button>
        <Button size="small" color="primary" onClick={props.onExplore}>
          Explore
        </Button>
      </CardActions>
    </Card>
  );
};
