import { styled, Typography } from "@mui/material";

import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import { GridColDef } from "@mui/x-data-grid-premium";

// No offset, No Max Width
export const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    slotProps={{
      popper: {
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -14],
            },
          },
        ],
      },
    }}
  />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
  },
});

// Function rendering cell with tooltip
export const RenderCellWithTooltipAndCopy = () => {
  return (params: any) => (
    <CustomTooltip title={params.value}>
      <Typography variant="body2" noWrap>
        {params.value}
      </Typography>
    </CustomTooltip>
  );
};

export const extractRowData = (sampleResponse: any) => {
  var rows: any[] = [];

  // Loop through each of the row indexes
  for (var i = 0; i < sampleResponse.rowIndexes.length; i++) {
    const feature = sampleResponse.featureCollection.features[i];

    // Check if the row has a result
    const hasResult = feature !== undefined && feature !== null;

    rows.push({
      id: sampleResponse.rowIndexes[i],
      rowIndex: sampleResponse.rowIndexes[i],
      rowNumber: sampleResponse.rowIndexes[i] + 1,
      query: sampleResponse.queries[i],
      formattedAddress: hasResult
        ? feature.properties.formattedAddress
        : "NO ADDRESS MATCHED THE QUERY",
      matchQuality: hasResult ? feature.matchQuality : "",
      matchScore: hasResult ? feature.matchScore : "",
      matchType: hasResult ? feature.matchType : "",
      matchDistance: hasResult ? feature.matchDistance : 0,
      matchMethod: hasResult ? feature.matchMethod : "",
      longitude: hasResult ? feature.geometry.coordinates[0] : 0,
      latitude: hasResult ? feature.geometry.coordinates[1] : 0,
    });
  }

  return rows;
};

export const defineColumns = (jobType: string) => {
  const columns: GridColDef[] = [
    { width: 100, field: "rowNumber", headerName: "Row Number" },
    {
      flex: 1,
      field: "query",
      headerName: "Query",
      renderCell: RenderCellWithTooltipAndCopy(),
    },
    {
      flex: 1,
      field: "formattedAddress",
      headerName: "Formatted Address",
      renderCell: RenderCellWithTooltipAndCopy(),
    },
  ];

  if (jobType == "addressGeocoder") {
    columns.push(
      { width: 100, field: "matchQuality", headerName: "Match Quality" },
      { width: 100, field: "matchScore", headerName: "Match Score" }
    );
  } else if (jobType == "reverseGeocoder") {
    columns.push(
      { width: 200, field: "matchType", headerName: "Match Type" },
      { width: 130, field: "matchDistance", headerName: "Match Distance" },
      { width: 200, field: "matchMethod", headerName: "Match Method" }
    );
  } else {
    console.error("Unknown batch job type");
  }

  return columns;
};
