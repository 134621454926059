import { TextField, Skeleton } from "@mui/material"
import { User } from "@sentry/react"
import { IdentityState } from "contexts/identity-context"
import { Organisation } from "data/models"
import { UseQueryResult } from 'react-query'

const userBillingInfo = (
    identityState: IdentityState,
) => {
    return (
        <>
            <TextField
                id="billingEmail"
                fullWidth
                disabled
                margin="normal"
                variant="outlined"
                label="Billing Email"
                inputProps={{ maxLength: 254 }}
                value={(identityState as User).email}
            />
        </>
    )
}

const organisationBillingInfo = (
    organisationData: UseQueryResult<Organisation>,
) => {
    if (organisationData.isLoading) {
        return (
            <>
                <Skeleton variant="rectangular" height={56} style={{ "marginTop": "16px", "marginBottom": "8px" }} />
                <Skeleton variant="rectangular" height={56} style={{ "marginTop": "16px", "marginBottom": "8px" }} />
                <Skeleton variant="rectangular" height={56} style={{ "marginTop": "16px", "marginBottom": "8px" }} />
            </>
        )
    }

    if (organisationData.isSuccess) {
        return (
            <>
                <TextField
                    id="billingEmail"
                    fullWidth
                    disabled
                    margin="normal"
                    variant="outlined"
                    label="Billing Email"
                    value={organisationData.data.email}
                />
                <TextField
                    id="companyName"
                    fullWidth
                    disabled
                    margin="normal"
                    variant="outlined"
                    label="Company Name"
                    value={organisationData.data.name}
                />
                <TextField
                    id="billingAddress"
                    fullWidth
                    disabled
                    margin="normal"
                    variant="outlined"
                    label="Billing Address"
                    value={organisationData.data.address}
                />
            </>
        )
    }
}

export {
    userBillingInfo,
    organisationBillingInfo
}