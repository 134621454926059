import axios from "axios";
import { IdentityState } from "contexts/identity-context";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { FileWithPath } from "react-dropzone";
import { makeApiRequestPostToUrl } from "utils/api-client";
import { PutResponse, UploadProgress, UploadProgressMap } from "./types";

interface SignedUploadResponse {
  uploadId: string;
  partUrls: string[];
  partSize: number;
}

export const getMultipartUploadData = async (
  objectKey: string,
  bucket: string,
  size: number,
  identity: IdentityState
): Promise<SignedUploadResponse> => {
  const response = await makeApiRequestPostToUrl(
    `${import.meta.env.VITE_DELIVERY_API_URL}`,
    `/signedUpload`,
    "POST",
    {
      bucket: bucket,
      key: objectKey,
      totalSize: size,
    },
    identity
  );

  return await response.json();
};

export const completeMultipartUpload = async (
  objectKey: string,
  bucket: string,
  uploadId: string,
  parts: PutResponse[],
  identity: IdentityState
): Promise<SignedUploadResponse> => {
  const response = await makeApiRequestPostToUrl(
    `${import.meta.env.VITE_DELIVERY_API_URL}`,
    `/completeUpload`,
    "POST",
    {
      bucket: bucket,
      key: objectKey,
      uploadId: uploadId,
      parts: parts,
    },
    identity
  );

  return await response.json();
};

const calculateProgress = (
  previousProgress: UploadProgress,
  fileSize: number,
  uploadStartTime: number
) => {
  const loaded = Object.values(previousProgress.parts)
    .map((x) => x.loaded)
    .reduce((a, b) => a + b, 0);

  const currentElapsedSeconds =
    1 + Math.round((Date.now() - uploadStartTime) / 1000);
  const bytesPerSecond = loaded / currentElapsedSeconds;
  const bytesRemaining = fileSize - loaded;
  const percentComplete = (loaded / fileSize) * 100;
  if (previousProgress.averagingWindow.length > 50) {
    previousProgress.averagingWindow.shift();
  }
  previousProgress.averagingWindow.push(bytesPerSecond);
  const SMOOTHING_FACTOR = 0.1;
  const averageBytesPerSecond =
    previousProgress.averagingWindow.reduce(
      (partialSum, a) => partialSum + a,
      0
    ) / previousProgress.averagingWindow.length;
  const smoothedBytesPerSecond =
    SMOOTHING_FACTOR * bytesPerSecond +
    (1 - SMOOTHING_FACTOR) * averageBytesPerSecond;
  const estimatedSecondsRemaining = Math.floor(
    bytesRemaining / smoothedBytesPerSecond
  );
  return {
    averagingWindow: previousProgress.averagingWindow,
    percentComplete: percentComplete,
    uploadDuration: currentElapsedSeconds,
    bytesPerSecond: bytesPerSecond,
    bytesRemaining: bytesRemaining,
    estimatedSecondsRemaining: estimatedSecondsRemaining,
    humanisedTimeRemaining:
      estimatedSecondsRemaining > 45
        ? dayjs.duration(estimatedSecondsRemaining, "seconds").humanize()
        : `${estimatedSecondsRemaining} seconds`,
  };
};

dayjs.extend(duration);
dayjs.extend(relativeTime);

export async function multiPartUpload(
  uploadData: SignedUploadResponse,
  file: FileWithPath,
  setUploadProgress: React.Dispatch<React.SetStateAction<UploadProgressMap>>,
  abortController: AbortController
): Promise<PutResponse[]> {
  const uploadStartTime = Date.now();

  const fileParts: Blob[] = [];
  for (let start = 0; start < file.size; start += uploadData.partSize) {
    const end = Math.min(start + uploadData.partSize, file.size);
    fileParts.push(file.slice(start, end));
  }

  const response_data = uploadData.partUrls.map(async (url, index) => {
    try {
      const response = await axios.put(url, fileParts[index], {
        headers: { "Content-Type": "" },
        signal: abortController.signal,
        onUploadProgress: (progressEvent) => {
          setUploadProgress((oldProgress: UploadProgressMap) => {
            const lastProgress = oldProgress[file.path as string];
            if (!lastProgress) {
              return { ...oldProgress };
            }
            const partProgress = lastProgress.parts[index] ?? {
              partNo: index,
              size: fileParts[index].size,
              loaded: 0,
            };

            partProgress.loaded = progressEvent.loaded;
            lastProgress.parts[index] = partProgress;

            const newProgress = {
              ...oldProgress,
              [file.path as string]: {
                parts: lastProgress.parts,
                ...calculateProgress(lastProgress, file.size, uploadStartTime),
              },
            };
            return newProgress;
          });
        },
      });
      return { partNo: index + 1, etag: response.headers.etag };
    } catch (error: any) {
      if (axios.isCancel(error)) {
        // Request was canceled
        console.log("Request canceled:", error.message);
      } else {
        // Request failed
        console.log("Error uploading data:", error);
      }
      throw error;
    }
  });

  return Promise.all(response_data);
}
