import { Box } from "@mui/material";

export const TailorPage = () => {
  return (
    <Box
      style={{
        display: "flex",
        // flexDirection: "column",
        gap: "24px",
        height: "700px",
        position: "relative",
        // backgroundColor: "blue",
      }}
    ></Box>
  );
};
