import * as echarts from "echarts";
import { useEffect, useRef } from "react";
// import { darkTheme } from "./themes"; // Assume this is implemented elsewhere
import { darkTheme } from "components/usage/dark";
import { formatter } from "components/usage/utils-usage";
import { UsageGraphProps } from "./types";
import { createCreditAmountSeries, formatDate } from "./utils";

export const useChartConfig = (props: UsageGraphProps, darkMode: boolean) => {
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (props.isSuccess && chartRef.current) {
      let chartInstance =
        echarts.getInstanceByDom(chartRef.current) ||
        echarts.init(chartRef.current, darkMode ? darkTheme : undefined);

      const option = createChartOption(props, darkMode);
      chartInstance.setOption(option, true);

      return () => {
        chartInstance.dispose();
      };
    }
  }, [props.isSuccess, props.endTime, props.startTime, darkMode]);

  return chartRef;
};

const createChartOption = (props: UsageGraphProps, darkMode: boolean) => {
  const { usageQuery, hideLegend, creditAmount } = props;
  const { data } = usageQuery;

  const showTitle =
    data?.appCreditsData.series.length === 0 ||
    props.isLoading ||
    props.isError;
  const showData =
    data &&
    data.appCreditsData.series.length > 0 &&
    !props.isLoading &&
    !props.isError;

  const titleText = props.isLoading
    ? "Loading the usage data"
    : props.isError
    ? "An error occurred while loading data"
    : "No usage data";

  return {
    title: {
      show: showTitle,
      textStyle: { color: "grey", fontSize: 20 },
      text: titleText,
      left: "center",
      top: "center",
    },
    tooltip: {
      renderMode: "html",
      appendToBody: true,
      trigger: "axis",
      //@ts-ignore
      formatter: (params: any) => formatter(params, data.intervalHours),
    },
    legend: hideLegend
      ? undefined
      : {
          type: "scroll",
          orient: "horizontal",
          bottom: 0,
          left: 0,
          data: data?.appNames,
        },
    xAxis: {
      show: showData,
      type: "category",
      data: data?.appCreditsData.dataTime.map((dateStr) =>
        formatDate(dateStr, data.intervalHours)
      ),
    },
    yAxis: {
      show: showData,
      max: creditAmount ? Math.floor(creditAmount * 1.1) : undefined,
    },
    series: creditAmount
      ? //@ts-ignore
        createCreditAmountSeries(data, creditAmount)
      : data?.appCreditsData.series,
  };
};
