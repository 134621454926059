import { Box, Card, CardContent, CardHeader, Typography } from "@mui/material";
import { Link } from "react-router-dom";

interface LegalLinkLinks {
  text: string;
  href: string;
}

interface LegalLink {
  name: string;
  links: LegalLinkLinks[];
}

const legalLinks: LegalLink[] = [
  {
    name: "Terms",
    links: [
      {
        text: "Geoscape General Terms",
        href: "https://geoscape.com.au/legal/geoscape-developer-terms",
      },
      {
        text: "Geoscape Specific API Terms",
        href: "https://geoscape.com.au/legal/geoscape-developer-specific-terms",
      },
    ],
  },
  {
    name: "End-User License Agreements",
    links: [
      {
        text: "Geoscape Data End User License Agreement (EULA)",
        href: "https://geoscape.com.au/legal/geoscape-developer-buildings-api-data-eula",
      },
      {
        text: "Mailpoint Mandatory End User Terms",
        href: "https://geoscape.com.au/legal/geoscape-developer-mailpoint-eula",
      },
    ],
  },
  {
    name: "Other",
    links: [
      {
        text: "Geoscape Data Copyright & Disclaimer",
        href: "https://geoscape.com.au/data-copyright-disclaimer/",
      },
      {
        text: "Geoscape Privacy Policy",
        href: "https://geoscape.com.au/privacy-policy/",
      },
    ],
  },
];

export const LegalPage = () => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        height: "100%",
        position: "relative",
      }}
    >
      {legalLinks.map((key: LegalLink) => {
        return (
          <Card
            key={key.name}
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <CardHeader title={key.name} variant="h5" />

            <CardContent>
              {key["links"].map((value: LegalLinkLinks) => {
                return (
                  <Typography
                    variant="body2"
                    color="text.primary"
                    key={value.text}
                  >
                    <Link
                      target="_blank"
                      style={{
                        textDecoration: "none",
                        color: "inherit",
                      }}
                      to={{
                        pathname: value.href,
                      }}
                    >
                      {value.text}
                    </Link>{" "}
                  </Typography>
                );
              })}
            </CardContent>
          </Card>
        );
      })}
    </Box>
  );
};
