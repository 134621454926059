import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";

interface KeyValueDetailsProps {
  properties: {
    [key: string]: any;
  };
  countText: string;
  header: string;
}

export const KeyValueDetails = (props: KeyValueDetailsProps) => {
  return (
    <Box width="100%">
      <Typography variant="body1">{props.countText}</Typography>
      <Typography variant="h6">{props.header}</Typography>
      <TableContainer>
        <Table size="small">
          <TableBody>
            {Object.keys(props.properties).map((key) => (
              <TableRow
                key={key}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell sx={{ padding: "4px" }} align="left">
                  {key}
                </TableCell>
                <TableCell sx={{ padding: "4px" }} align="left">
                  {props.properties[key]
                    ? props.properties[key].toString()
                    : ""}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
