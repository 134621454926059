import { LoadingButton } from "@mui/lab";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  Skeleton,
  Slider,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import PaymentMethodInfo from "components/settings/shared/billing-details/paymentMethodInfo";
import { useCustomSnackbars } from "components/snackbars/useCustomSnackbars";
import { getActiveOrganisation, useIdentity } from "contexts/identity-context";
import * as QueryKeys from "data";
import { Overage } from "data/models";
import { ReactQueryMutationError, updateOverageLimit } from "data/mutations";
import {
  fetchBillingUsage,
  fetchOverage,
  fetchSubscription,
} from "data/queries";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import * as Routes from "routes";
import {
  PlanDetails,
  roundDownOverageDollarLimit,
  roundUpOverageDollarLimit,
} from "utils/calculations";
import { resolveIdentityId } from "utils/identity";

interface UseOverageCreditsCardProps {
  sx: any;
  jobCreditCost: number;
  isLoading?: boolean;
  type: JobType;
  setIsEnoughCredits: React.Dispatch<React.SetStateAction<boolean>>;
}

export enum JobType {
  BATCH,
  CLIP,
}

export const UseOverageCreditsCard = (props: UseOverageCreditsCardProps) => {
  const [identityState] = useIdentity();
  const isUser = !getActiveOrganisation(identityState);
  const identityId = resolveIdentityId(identityState, isUser);
  const { show } = useIntercom();
  const theme = useTheme();
  const history = useHistory();

  let name = "job";
  if (props.type === JobType.BATCH) name = "job";
  if (props.type === JobType.CLIP) name = "clip";

  let action = "start";
  if (props.type === JobType.BATCH) action = "start";
  if (props.type === JobType.CLIP) action = "purchase";

  // VALUES
  interface Mark {
    value: number;
    label?: string;
  }

  const [marks, setMarks] = useState<Mark[]>([]);
  const [sliderMin, setSliderMin] = useState(0);
  const [sliderMax, setSliderMax] = useState(10000);
  const [sliderRequired, setSliderRequired] = useState(0);

  const [paymentMethod, setPaymentMethod] = useState<boolean>(false);

  const { enqueueQueryFailed, enqueueMutationFailed } = useCustomSnackbars();

  // QUERIES
  const overageQuery = useQuery(
    [QueryKeys.overage, identityId],
    () => fetchOverage(isUser, identityState),
    {
      onSuccess: (data) => {
        if (newOverageDollarLimit === 0) {
          setNewOverageDollarLimit(data.dollarsLimit);
        }
      },
      onError: (error: Error) => {
        enqueueQueryFailed(error.toString());
        enqueueMutationFailed(error.toString());
      },
    }
  );

  const [newOverageDollarLimit, setNewOverageDollarLimit] = useState<number>(
    overageQuery.isSuccess ? overageQuery.data.dollarsLimit : 0
  );

  const subscriptionQuery = useQuery([QueryKeys.subscription, identityId], () =>
    fetchSubscription(isUser, identityState)
  );

  const planDetails: PlanDetails = {
    overageRate: 1,
    planCredits: 1,
  };

  if (subscriptionQuery.isSuccess) {
    planDetails.overageRate = subscriptionQuery.data.activeSubscription.plan
      .overageRate
      ? subscriptionQuery.data.activeSubscription.plan.overageRate
      : 1;
    planDetails.planCredits =
      subscriptionQuery.data.activeSubscription.plan.creditAmount;
  }

  const queryClient = useQueryClient();
  const overageMutation = useMutation<Overage, ReactQueryMutationError>(
    () => {
      if (
        newOverageDollarLimit === undefined ||
        !overageQuery.isSuccess ||
        !subscriptionQuery.isSuccess
      ) {
        throw Error(
          "Unable to update overage, incomplete overage information."
        );
      }

      const clampedValue = _.clamp(newOverageDollarLimit, sliderMin, sliderMax);
      const roundedNewOverageDollarLimit = roundDownOverageDollarLimit(
        clampedValue,
        overageQuery.data.overageRate
      );

      return updateOverageLimit(
        roundedNewOverageDollarLimit,
        planDetails,
        overageQuery.data,
        isUser,
        identityState
      );
    },
    {
      onSuccess: (newOverage) => {
        queryClient.setQueryData([QueryKeys.overage, identityId], newOverage);
        setNewOverageDollarLimit(newOverage.dollarsLimit);
      },
    }
  );

  const billingUsageQuery = useQuery([QueryKeys.billingUsage, identityId], () =>
    fetchBillingUsage(isUser, identityState)
  );

  const isLoading =
    subscriptionQuery.isLoading ||
    billingUsageQuery.isLoading ||
    overageQuery.isLoading ||
    props.isLoading;

  const isSuccess =
    subscriptionQuery.isSuccess &&
    billingUsageQuery.isSuccess &&
    overageQuery.isSuccess;

  const isError =
    subscriptionQuery.isError ||
    billingUsageQuery.isError ||
    overageQuery.isError;

  const isEnterpriseOrMYOB =
    (subscriptionQuery.isSuccess &&
      subscriptionQuery.data.activeSubscription.plan.isEnterprise) ||
    (subscriptionQuery.isSuccess &&
      subscriptionQuery.data.activeSubscription.isMyob);

  const isProfessional =
    subscriptionQuery.data?.activeSubscription.plan.name.toLowerCase() ===
    "professional";

  // Calculate slightly differently for normal vs enterprise/MYOB
  var costOverageCredits = 0;
  if (isSuccess)
    costOverageCredits =
      (Math.max(
        billingUsageQuery.data.allowance -
          billingUsageQuery.data.creditsUsedTotal,
        0
      ) -
        props.jobCreditCost) *
      -1;
  const isOverageRequired = costOverageCredits > 0;

  const costOverageDollars = isSuccess
    ? costOverageCredits * overageQuery.data.overageRate
    : 0;

  var remainingCreditsBalance = 0;
  if (isSuccess) {
    remainingCreditsBalance =
      billingUsageQuery.data.allowance +
      overageQuery.data.creditsLimit -
      billingUsageQuery.data.creditsUsedTotal -
      props.jobCreditCost;

    if (isEnterpriseOrMYOB) {
      remainingCreditsBalance = Math.max(remainingCreditsBalance, 0);
    }
  }

  const isCreditsRemaining = remainingCreditsBalance >= 0;
  if (props.setIsEnoughCredits) props.setIsEnoughCredits(isCreditsRemaining);

  const insufficientCreditsColour = theme.palette.error.main;
  const sufficientCreditsColour = theme.palette.success.main;
  const overageNotificationColor = theme.palette.info.main;

  // TODO DS-4664: Would like to think of a more mature way to return these different objects.
  // This style of returning the element is required afaik if we want to return different types of parent elements
  // For example, card vs skeleton
  const loadingElement = <Skeleton variant="rectangular" height="100%" />;

  // Generate the slider marks
  useEffect(() => {
    if (overageQuery.isSuccess && isOverageRequired && !isEnterpriseOrMYOB) {
      var requiredAdditionalOverageLimit = isCreditsRemaining
        ? costOverageDollars
        : -remainingCreditsBalance * overageQuery.data.overageRate;
      requiredAdditionalOverageLimit = roundUpOverageDollarLimit(
        requiredAdditionalOverageLimit,
        overageQuery.data.overageRate
      );

      // Marks Key Points
      const startSliderMark = overageQuery.data.dollarsLimit;
      const incrementSliderMark = overageQuery.data.overageRate * 1000;
      const requiredSliderMark =
        overageQuery.data.dollarsLimit + requiredAdditionalOverageLimit;
      const endSliderMark = Math.min(
        overageQuery.data.dollarsLimit + requiredAdditionalOverageLimit * 2,
        subscriptionQuery.data?.activeSubscription.plan.name
          .toLowerCase()
          .includes("freemium")
          ? 9990
          : 10000
      );

      const sliderMarks = [];
      for (
        var i = startSliderMark;
        i <= endSliderMark;
        i += incrementSliderMark
      ) {
        const isShowLabel =
          i === startSliderMark ||
          i === requiredSliderMark ||
          i === endSliderMark;
        const sliderMark: Mark = {
          value: i,
          ...(isShowLabel && { label: `$${i.toLocaleString()}` }),
        };

        sliderMarks.push(sliderMark);
      }
      setMarks(sliderMarks);

      // Slider min and max
      if (sliderMarks.length > 0) {
        setSliderMin(sliderMarks[0].value);
        setSliderMax(sliderMarks[sliderMarks.length - 1].value);
        setSliderRequired(requiredSliderMark);
      }
    } else {
      setMarks([]);
    }
  }, [overageQuery.data, isOverageRequired]);

  // TODO DS-4664: Don't forget to account for enterprise and pro MYOB customers with infinite overage
  const successElement = (
    <Card
      sx={{
        ...props.sx,
        ...(isCreditsRemaining &&
          isOverageRequired &&
          !isEnterpriseOrMYOB && {
            borderColor: overageNotificationColor,
          }),
        ...(!isCreditsRemaining &&
          isOverageRequired &&
          !isEnterpriseOrMYOB && {
            borderColor:
              newOverageDollarLimit < sliderRequired
                ? insufficientCreditsColour
                : sufficientCreditsColour,
          }),
      }}
    >
      {/* We HAVE enough credits and overage is NOT required */}
      {/* We are ENTERPRISE/MYOB and overage is NOT required */}
      {(isCreditsRemaining || isEnterpriseOrMYOB) && !isOverageRequired && (
        <Box>
          <Typography variant="h5">
            {_.startCase(action)} {_.startCase(name)}
          </Typography>
          <Typography>
            When you are happy with the {name} configuration, please {action}{" "}
            the {name}.
            {props.type === JobType.BATCH &&
              `The estimated credit cost will be deducted from your credit balance. Upon successful completion of the ${name}, any unused credits will be refunded. No credits are charged for failed batch ${name}s.`}
          </Typography>
        </Box>
      )}

      {/* We are ENTERPRISE/MYOB and overage IS required */}
      {isEnterpriseOrMYOB && isOverageRequired && overageQuery.isSuccess && (
        <Box>
          <Typography variant="h5">
            {_.startCase(action)} {_.startCase(name)}
          </Typography>

          <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <Typography>
              Overage usage will be required to complete this {name}.
            </Typography>
            <Typography sx={{ fontWeight: "bold" }}>
              Overage Credits Needed: {costOverageCredits.toLocaleString()}
            </Typography>
            <Typography>
              Overage enables additional usage beyond your current plan's quota.
              You will be invoiced for any overage usage.
            </Typography>
          </Box>
        </Box>
      )}

      {/* We do NOT have enough credits and overage IS required */}
      {!isEnterpriseOrMYOB && isOverageRequired && overageQuery.isSuccess && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          {/* We have enough credits */}
          {isCreditsRemaining && (
            <Box>
              <Typography variant="h5" color={overageNotificationColor}>
                Overage Notification
              </Typography>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "16px" }}
              >
                <Typography>
                  Overage usage will be required to complete this clip. You have
                  sufficient overage limit to complete this order and no action
                  is required, however to maintain the same overage limit
                  buffer, you may wish to increase your overage limit by the
                  recommended amount.
                </Typography>
                <Typography sx={{ fontWeight: "bold" }}>
                  Additional Overage Recommended: $
                  {costOverageDollars.toLocaleString()} (
                  {(
                    costOverageDollars / overageQuery.data.overageRate
                  ).toLocaleString()}{" "}
                  Credits)
                </Typography>

                <Typography>
                  Overage enables additional usage beyond your current plan's
                  quota. You will only be charged for the actual credits used at
                  the end of your billing cycle.
                </Typography>
              </Box>
            </Box>
          )}

          {/* We do not have enough */}
          {!isCreditsRemaining && (
            <Box>
              <Typography
                variant="h5"
                color={
                  newOverageDollarLimit < sliderRequired
                    ? "error"
                    : sufficientCreditsColour
                }
              >
                Insufficient Balance
              </Typography>

              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "16px" }}
              >
                <Typography>
                  To complete this {name}, you{" "}
                  <span style={{ fontWeight: "bold" }}>must</span> increase your
                  overage limit by{" "}
                  <span style={{ fontWeight: "bold" }}>at least</span> the
                  following amount. We recommend that you increase your overage
                  limit beyond the required amount to ensure you have enough
                  overage credits for regular API usage after completing this
                  {name}.
                </Typography>
                <Typography sx={{ fontWeight: "bold" }}>
                  Additional Overage Required: $
                  {(
                    (isCreditsRemaining ? 1 : -1) *
                    remainingCreditsBalance *
                    overageQuery.data.overageRate
                  ).toLocaleString()}{" "}
                  (
                  {(
                    (isCreditsRemaining ? 1 : -1) * remainingCreditsBalance
                  ).toLocaleString()}{" "}
                  Credits)
                </Typography>
                <Typography>
                  Overage enables additional usage beyond your current plan's
                  quota. You will only be charged for the actual credits used at
                  the end of your billing cycle.
                </Typography>
              </Box>
            </Box>
          )}

          <PaymentMethodInfo
            isUserPage={isUser}
            setPaymentMethod={setPaymentMethod}
            hideTitle={true}
            style={{}}
            elevation={0}
          />

          <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
            <Slider
              step={null}
              value={newOverageDollarLimit}
              onChange={(event, value) =>
                typeof value === "number" ? setNewOverageDollarLimit(value) : 0
              }
              marks={marks}
              defaultValue={newOverageDollarLimit}
              valueLabelDisplay="auto"
              min={sliderMin}
              max={sliderMax}
              valueLabelFormat={(value: number) => `$${value.toLocaleString()}`}
              // If there are enough credits, it's info otherwise it's error until we choose a high enough new limit
              color={
                isCreditsRemaining
                  ? "info"
                  : newOverageDollarLimit < sliderRequired
                  ? "error"
                  : "success"
              }
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "8px",
              }}
            >
              <TextField
                id="budget"
                name="Budget"
                label="Budget"
                size="small"
                helperText={`Will be rounded down to the nearest $${
                  overageQuery.data.overageRate * 1000
                }`}
                value={newOverageDollarLimit}
                onChange={(event) => {
                  const newValue = +event.target.value;
                  setNewOverageDollarLimit(newValue);
                }}
                fullWidth
              />

              <TextField
                id="credit-budget"
                name="Credit Budget"
                label="Credit Budget"
                size="small"
                helperText={
                  "Will be rounded down to the nearest 1,000 credits."
                }
                value={newOverageDollarLimit / overageQuery.data.overageRate}
                onChange={(event) => {
                  // Convert to dollar amount
                  const newValue =
                    +event.target.value * overageQuery.data.overageRate;
                  setNewOverageDollarLimit(newValue);
                }}
                fullWidth
              />

              <Tooltip title={!paymentMethod ? "Requires payment method" : ""}>
                <span>
                  <LoadingButton
                    onClick={() => overageMutation.mutate()}
                    loading={overageMutation.isLoading}
                    disabled={!paymentMethod}
                  >
                    Save
                  </LoadingButton>
                </span>
              </Tooltip>
            </Box>

            {/* User is on freemium */}
            {!isProfessional && (
              <Alert
                variant="outlined"
                severity="info"
                action={
                  <Box>
                    <Button
                      size="small"
                      onClick={() =>
                        history.push(
                          isUser
                            ? Routes.userManageSubscription
                            : Routes.orgManageSubscription
                        )
                      }
                    >
                      Manage Subscription
                    </Button>
                  </Box>
                }
              >
                <AlertTitle>Prefer not to use Overage?</AlertTitle>
                Manage your subscription to adjust your plan
              </Alert>
            )}

            {/* User is on regular professional plan */}
            {isProfessional && (
              <Alert
                variant="outlined"
                severity="info"
                action={
                  <Box>
                    <Button size="small" onClick={() => show()}>
                      Contact Us
                    </Button>
                  </Box>
                }
              >
                <AlertTitle>Prefer not to use Overage?</AlertTitle>
                Talk to us about an Enterprise plan
              </Alert>
            )}
          </Box>
        </Box>
      )}
    </Card>
  );

  return isLoading ? loadingElement : successElement;
};
