import { Box, Paper } from "@mui/material";
import { bbox } from "@turf/turf";
import { Basemap } from "components/basemap/basemap";
import { useCustomSnackbars } from "components/snackbars/useCustomSnackbars";
import * as QueryKeys from "data";
import { fetchAuthJWT, fetchMapsLayerJson } from "data/queries";
import { requestTransformer } from "pages/geoscape-data/explorer/explorer-page";
import { TileJSON } from "pages/geoscape-data/explorer/layers/models";
import { ZoomControl } from "pages/geoscape-data/explorer/zoom-control";
import { useRef, useState } from "react";
import { FillLayer, Layer, LineLayer, MapRef, Source } from "react-map-gl";
import { useQuery } from "react-query";
import { PredictiveSuburbManager } from "./predictive-suburb-manager";

export const VisualiseMap = (
  predictiveSuburbManager: PredictiveSuburbManager
) => {
  const mapRef = useRef<MapRef>();
  const initialZoom: number = 17;
  const [dynamicZoom, setDynamicZoom] = useState(initialZoom);
  const [stateLayerJson, setStateLayerJson] = useState<TileJSON | null>(null);

  const localityLayer: FillLayer = {
    id: "data",
    type: "fill",
    paint: {
      "fill-color": "#00ffff",
      "fill-opacity": 0.2,
    },
  };

  const stateLayer: LineLayer = {
    id: "state",
    type: "line",
    source: "state",
    "source-layer": "state",
    paint: {
      "line-color": "#0f7ae4",
      "line-width": 1,
    },
  };

  const [minLng, minLat, maxLng, maxLat] = predictiveSuburbManager.locality
    ?.features[0].geometry
    ? bbox(predictiveSuburbManager.locality?.features[0].geometry)
    : // bounding box of Australia
      [96, -45, 168, -8];

  mapRef.current?.fitBounds(
    [
      [minLng, minLat],
      [maxLng, maxLat],
    ],
    { padding: 10, duration: 1000 }
  );

  const { enqueueQueryFailed } = useCustomSnackbars();

  const jwt = useQuery([QueryKeys.jwt], () => fetchAuthJWT(), {
    onError: (error: Error) => {
      enqueueQueryFailed(error.toString());
    },
  });

  const addAuthToTileUrl = (tileUrl: string[]) => {
    return tileUrl.map((x) => `${x}?key=${jwt.data}`);
  };

  const stateLayerJsonQuery = useQuery(
    [QueryKeys.mapsLayerJson, "state"],
    () => fetchMapsLayerJson(jwt.isSuccess ? jwt.data : "", "state"),
    {
      enabled: !!jwt.data,
      retry: true,
      onSuccess: (json: TileJSON) => {
        setStateLayerJson(json);
      },
    }
  );

  const resetCompass = () => {
    if (mapRef.current === undefined) return;
    mapRef.current.easeTo({
      bearing: 0,
      pitch: 0,
    });
  };

  const handleZoom = (zoom: number): void => {
    setDynamicZoom(zoom);
    mapRef.current?.flyTo({ zoom: zoom, duration: 500 });
  };

  return (
    <Paper
      elevation={0}
      sx={{
        width: "100%",
        height: 350,
      }}
    >
      {jwt.isSuccess && (
        <Basemap
          // @ts-ignore
          mapRef={mapRef}
          zoom={initialZoom}
          attributionControl={false}
          style={{
            width: "100%",
            height: "100%",
            borderRadius: 10,
          }}
          onZoom={(zoom) => {}}
          transformRequest={requestTransformer}
          bbox={[minLng, minLat, maxLng, maxLat]}
          bboxPadding={0}
        >
          {predictiveSuburbManager.locality?.features[0].geometry && (
            <Source
              type="geojson"
              data={
                predictiveSuburbManager.locality as GeoJSON.FeatureCollection
              }
            >
              <Layer {...localityLayer} />
            </Source>
          )}
          {stateLayerJson && (
            <Source
              key="state"
              id="state"
              type="vector"
              maxzoom={16}
              tiles={addAuthToTileUrl(stateLayerJson.tiles)}
            >
              <Layer {...stateLayer} />
            </Source>
          )}
          <Box
            sx={{
              right: 16,
              top: 16,
              position: "absolute",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <ZoomControl
              resetCompass={resetCompass}
              dynamicZoom={dynamicZoom}
              onZoom={handleZoom}
            />
          </Box>
        </Basemap>
      )}
    </Paper>
  );
};
