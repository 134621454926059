import { LoadingButton } from "@mui/lab";
import { Box, Button, Card } from "@mui/material";
import { Release } from "data/models";
import React from "react";
import { generatePath, useHistory } from "react-router";
import * as Routes from "routes";

interface NewReleaseButtonsProps {
  isLoadingCreateReleaseMutation: boolean;
  isLoadingLoadReleaseMutation: boolean;
  isLoadingDraftReleaseMutation: boolean;

  setCloseButton: React.Dispatch<React.SetStateAction<boolean>>;
  release: Release;
}

export const NewReleaseButtons = ({
  isLoadingCreateReleaseMutation,
  isLoadingLoadReleaseMutation,
  isLoadingDraftReleaseMutation,

  setCloseButton,
  release,
}: NewReleaseButtonsProps) => {
  const history = useHistory();
  const isEditing = true;
  let values = {
    name: "",
    year: "",
    month: "",
    products: [],
  };

  return (
    <>
      <Card
        style={{
          width: "100%",
          display: "flex",
          height: "70px",
          padding: "10px",
        }}
      >
        <Box
          sx={{
            display: `${isEditing ? "flex" : "none"}`,
            alignItems: "center",
            justifyContent: "flex-start",
            alignContent: "space-between",
            marginBottom: "50px",
            width: "100%",
            gap: "16px",
            height: "43px",
          }}
        >
          <>
            <LoadingButton
              variant="contained"
              size="small"
              disabled={
                isLoadingLoadReleaseMutation ||
                isLoadingCreateReleaseMutation ||
                isLoadingDraftReleaseMutation
              }
              onClick={() => {
                history.push(
                  generatePath(Routes.adminReleaseDatasets, {
                    releaseKey: release.key,
                  }),
                  release
                );
              }}
            >
              VIEW DATASETS
            </LoadingButton>
          </>
        </Box>
        <Box
          sx={{
            display: `${isEditing ? "flex" : "none"}`,
            alignItems: "center",
            justifyContent: "flex-end",
            alignContent: "space-between",
            marginBottom: "50px",
            width: "100%",
            gap: "16px",
            height: "43px",
          }}
        >
          <>
            <LoadingButton
              variant="contained"
              size="small"
              disabled={
                isLoadingLoadReleaseMutation || isLoadingCreateReleaseMutation
              }
              loading={isLoadingDraftReleaseMutation}
              onClick={() => {
                if (release?.staged?.length) {
                  history.push(Routes.adminReleases);
                } else {
                  setCloseButton(true);
                }
              }}
            >
              CLOSE
            </LoadingButton>
          </>
        </Box>
      </Card>
    </>
  );
};
