export enum ColorScheme {
  Green = "#00ff00",
  Red = "#ff0000",
}

export enum Style {
  RoofHeight = "roof_height",
  Co2Annually = "emitted_c02_kg_yr",
  RoofColour = "roof_colour",
  SolarPanel = "solar",
  Pool = "pool",
}

export enum Season {
  Spring = "Spring",
  Summer = "Summer",
  Autumn = "Autumn",
  Winter = "Winter",
}

// Interfaces
export interface FormState {
  extrude: boolean;
  people: number;
  season: Season;
  style: Style;
  style_key_word?: string;
}

// total_co2_kg_m2_yr_1_Autumn

import { Dispatch, SetStateAction } from "react";
import {
  CircleLayer,
  FillLayer,
  LineLayer,
  LngLatBoundsLike,
} from "react-map-gl";

export type DemoStep = number;

export type SetDemoStep = Dispatch<SetStateAction<DemoStep>>;

export type Description = {
  paragraph_one?: string;
  paragraph_two?: string;
};
export type DataStep = {
  title?: string;
  description?: Description;
};

export type Coordinates = {
  latitude: number;
  longitude: number;
};

interface GeocoderAttributes {
  [key: string]: string;
}

export interface SelectAddressArgs {
  longitude: number;
  latitude: number;
  geocoder_attributes: GeocoderAttributes;
  zoomLevel: number;
}
export interface StepComponentProps {
  moveToNextLocation: (args: SelectAddressArgs) => void;
  nextStep: () => void;
  backStep: () => void;
  data: DataStep;
  coordinates: Coordinates;
  previousCoordinates: Coordinates;
  nextCoordinates: Coordinates;
  zoomLevel: number;
  previousZoomLevel: number;
  attributes: Attributes;
  id: number;
  layer_name: string;
  handleMarker: () => void;

  formState?: FormState;
}

export interface Attributes {
  geometry: Object;
  properties: any;
  layer: any;
  source: string;
}

export type MapConfig = {
  zoomLevel: number;
  minZoom?: number;
  compare?: boolean;
  boundingBox?: LngLatBoundsLike;
};
export type StepDemoConfig = {
  id: number;
  propertyDetails?: boolean;
  component: React.FC<StepComponentProps>;
  mapConfig: MapConfig;
  data: DataStep;
  layer: object;
  layer_name: string;
  coordinates: Coordinates;
  geojsonPath: string;
  // layerStyle: FillLayer | LineLayer | CircleLayer;
  layerStyle?: any;
  geojsonPathMapOne?: string;
  geojsonPathMapTwo?: string;
  layerStyleOne?: FillLayer | LineLayer | CircleLayer;
  layerStyleTwo?: FillLayer | LineLayer | CircleLayer;
};

type SeasonalData<T> = {
  Autumn: T;
  Spring: T;
  Summer: T;
  Winter: T;
  Annual: T;
};

// Helper type for data that varies by number of people (1-5)
type ByPeopleCount<T> = {
  [K in "1" | "2" | "3" | "4" | "5"]: T;
};

interface ClimateData {
  // Electricity consumption
  elec_con: ByPeopleCount<SeasonalData<string>>;

  // Gas consumption
  gas_con: ByPeopleCount<SeasonalData<string>>;

  // Electricity net area
  elec_net_area: ByPeopleCount<SeasonalData<number>>;

  // Gas net area
  gas_net_area: ByPeopleCount<SeasonalData<number>>;

  // Electricity CO2 emissions
  elec_co2_kg_yr: ByPeopleCount<SeasonalData<number>>;

  // Gas CO2 emissions
  gas_co2_kg_yr: ByPeopleCount<SeasonalData<number>>;

  // Total CO2 emissions
  total_co2_kg_yr: ByPeopleCount<SeasonalData<number>>;

  // Total CO2 emissions per m2
  total_co2_kg_m2_yr: ByPeopleCount<SeasonalData<number>>;

  // Building details
  building_pid: string;
  date_created: string;
  date_modified: string;
  capture_date: string;
  building_review_date: string;
  building_source: string;
  quality_class: string;
  locality_pid: string;
  state: string;
  address_count: number;
  swimming_pool_adjacent: string;
  swimming_pool_review_date: string;
  solar_panel: string;
  solar_panel_review_date: string;
  planning_zone: string;
  roof_height: number;
  eave_height: number;
  ground_elevation: number;
  ground_elevation_source: string;
  primary_roof_material: string;
  roof_type: string;
  roof_colour: string;
  mesh_block_code: number;
  number_vertices: number;
  area: number;
  volume: number;
  geometry_quality: string | null;
  centroid_longitude: number;
  centroid_latitude: number;
  estimated_levels: number;
  roof_shape: string;
  tree_overhang: string;
  roof_slope: number;
  centroid_longitude_gda2020: number;
  centroid_latitude_gda2020: number;
  geometry: string;
  id: number;
  "geometry-2": string;
  "building_pid-2": string;
  climate: number;
  solar: string;
  "area-2": number;
  net_let_area: number;
  floors: number;
  "state-2": string;
  ci: number;
  pool: string;
}
