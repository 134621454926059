import { LoadingButton } from "@mui/lab";
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  Link,
  TextField,
} from "@mui/material";
import Alert, { AlertColor } from "@mui/material/Alert";
import { BaseState } from "common/models";
import { signUpUser } from "contexts/identity-context";
import { Form, Formik } from "formik";
import { replace } from "lodash/fp";
import { Dispatch, SetStateAction, useState } from "react";
import { useLocation } from "react-router-dom";
import { boolean as bool, object, ref, string } from "yup";

export const migrateValidationSchema = object({
  email: string().email("Enter a valid email").required("Email is required"),
  firstName: string().required("First Name is required."),
  lastName: string().required("Last Name is required."),
  password: string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required."),
  confirmPassword: string()
    .oneOf([ref("password"), null], "Passwords must match")
    .required("Confirm Password is required."),
  termsOfUse: bool().oneOf(
    [true],
    "In order to continue, you must accept Geoscape Hub's Terms of Use."
  ),
});

type SignUpValues = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
  termsOfUse: boolean;
};

interface SignUpState extends BaseState {
  messageSeverity?: AlertColor;
}

function trimmessage(message: string): string {
  return replace(
    "..",
    ".",
    replace("PreSignUp failed with error ", "", message)
  );
}

async function signUp(
  event: SignUpValues,
  setState: Dispatch<SetStateAction<SignUpState>>
) {
  setState((prevState) => {
    return {
      ...prevState,
      isLoading: true,
    };
  });
  try {
    const confirm = await signUpUser(
      event.email,
      event.firstName,
      event.lastName,
      event.password,
      ""
    );
    switch (confirm.state) {
      case "left": {
        setState((prevState) => {
          return {
            ...prevState,
            isLoading: false,
            message: `A verification email has been sent to: ${event.email}`,
            messageSeverity: "success",
          };
        });
        break;
      }
      case "right": {
        setState((prevState) => {
          const msg = confirm.value.message.includes(
            "The email address you provided is associated with another account."
          )
            ? "This user login has been migrated. Either sign in or reset your password using the links at the bottom of the page "
            : confirm.value.message;
          return {
            ...prevState,
            isLoading: false,
            message: trimmessage(msg),
            messageSeverity: "error",
          };
        });
        break;
      }
    }
  } finally {
    // just make sure that we've unlocked the page at the very least by setting isLoading to false
    setState((prevState) => {
      return {
        ...prevState,
        isLoading: false,
      };
    });
  }
}

export const Migrate = () => {
  const initialState = {
    isError: false,
    message: "",
    isLoading: false,
  };
  const [state, setState] = useState<SignUpState>(initialState);
  const search = useLocation().search;
  const email = new URLSearchParams(search).get("email");
  const defaultEmail = email !== null ? email : "";
  return (
    <>
      <Formik<SignUpValues>
        initialValues={{
          email: defaultEmail,
          firstName: "",
          lastName: "",
          password: "",
          confirmPassword: "",
          termsOfUse: false,
        }}
        onSubmit={(e) => signUp(e, setState)}
        validationSchema={migrateValidationSchema}
      >
        {({
          values: {
            email,
            firstName,
            lastName,
            password,
            confirmPassword,
            termsOfUse,
          },
          handleChange,
          handleBlur,
          errors,
          touched,
        }) => (
          <Form>
            <Box
              hidden={
                !Boolean(state.message) && !Boolean(state.messageSeverity)
              }
              marginBottom={1}
            >
              <Alert variant="filled" severity={state.messageSeverity}>
                {state.message}
              </Alert>
            </Box>
            <TextField
              id="email"
              name="email"
              label="Email"
              type="text"
              margin="normal"
              value={email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email}
              disabled={Boolean(defaultEmail)}
              variant="filled"
              fullWidth
            />
            <TextField
              id="firstName"
              name="firstName"
              label="First Name"
              type="text"
              margin="normal"
              value={firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.firstName && Boolean(errors.firstName)}
              helperText={touched.firstName && errors.firstName}
              disabled={state.isLoading}
              variant="filled"
              fullWidth
            />
            <TextField
              id="lastName"
              name="lastName"
              label="Last Name"
              type="text"
              margin="normal"
              value={lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.lastName && Boolean(errors.lastName)}
              helperText={touched.lastName && errors.lastName}
              disabled={state.isLoading}
              variant="filled"
              fullWidth
            />
            <TextField
              id="password"
              name="password"
              label="Password"
              type="password"
              margin="normal"
              value={password}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.password && Boolean(errors.password)}
              helperText={touched.password && errors.password}
              disabled={state.isLoading}
              variant="filled"
              fullWidth
            />
            <TextField
              id="confirmPassword"
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              margin="normal"
              value={confirmPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.confirmPassword && Boolean(errors.confirmPassword)}
              helperText={touched.confirmPassword && errors.confirmPassword}
              disabled={state.isLoading}
              variant="filled"
              fullWidth
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  id="termsOfUse"
                  name="termsOfUse"
                  checked={termsOfUse}
                  onChange={handleChange}
                />
              }
              label={
                <>
                  {"I agree to Geoscape Hub's "}
                  <a
                    href="https://geoscape.com.au/legal/geoscape-developer-terms"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    Terms of Use
                  </a>
                </>
              }
            />
            <FormHelperText error={true}>{errors["termsOfUse"]}</FormHelperText>

            <Box marginY={2}>
              <LoadingButton
                variant="contained"
                type="submit"
                fullWidth
                loading={state.isLoading}
              >
                Claim Login
              </LoadingButton>
            </Box>
          </Form>
        )}
      </Formik>
      <Divider />
      <Box>
        <Link href="sign-in">Sign in</Link>
        <Divider orientation="vertical" flexItem />
        <Link href="reset-password">Reset Password</Link>
      </Box>
    </>
  );
};
