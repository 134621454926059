import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Skeleton,
  Stack,
  useTheme,
} from "@mui/material";
import BackButton from "components/navigation/back-button";
import { ReactQueryErrorWrapper } from "components/shared/react-query-error-wrapper";
import { ShowMore } from "components/shared/show-more";
import { StyledMarkdown } from "components/shared/styled-markdown/styled-markdown";
import { useIdentity } from "contexts/identity-context";
import * as QueryKeys from "data";
import { DatasetDefinitions } from "data/models";
import {
  fetchCatalogueEntry,
  fetchDefinitions,
  fetchUserLicences,
} from "data/queries";
import { Product } from "pages/geoscape-data/constants";
import { useEffect, useState } from "react";
import { UseQueryResult, useQuery } from "react-query";
import { useParams } from "react-router";
import CustomButtonGroup from "./CustomButtons";
import { CatalogueEntryCaseStudies } from "./catalogue-entry-case-studies";
import { CatalogueEntryDataDictionary } from "./catalogue-entry-data-dictionary";
import { CatalogueEntryMetadata } from "./catalogue-entry-metadata";

export const CatalogueEntry = () => {
  let { productName }: any = useParams();
  const [identityState] = useIdentity();

  const [licenced, setLicenced] = useState<boolean>(false);
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  const catalogueEntryQuery = useQuery(
    [QueryKeys.catalogueEntryKey, productName],
    () => fetchCatalogueEntry(productName, identityState)
  );

  const userLicences = useQuery([QueryKeys.userLicences, productName], () =>
    fetchUserLicences(identityState)
  );

  const datasetDefinitions: UseQueryResult<DatasetDefinitions> = useQuery(
    [QueryKeys.definitionsKey],
    () => fetchDefinitions(identityState)
  );

  const isLoading: Boolean =
    catalogueEntryQuery.isLoading ||
    datasetDefinitions.isLoading ||
    userLicences.isLoading;
  const isError: Boolean =
    catalogueEntryQuery.isError ||
    datasetDefinitions.isError ||
    userLicences.isError;
  const isSuccess: Boolean =
    catalogueEntryQuery.isSuccess &&
    datasetDefinitions.isSuccess &&
    userLicences.isSuccess;

  useEffect(() => {
    const isProductLicensed = userLicences.data?.dataset_products.some(
      (product: any) => {
        return product.product_name === productName;
      }
    );

    if (isProductLicensed) {
      setLicenced(true);
    }
  }, [isSuccess]);

  var datasetId = "";
  if (isSuccess) {
    datasetId = (
      catalogueEntryQuery.data.id === "GEOSCAPE PLANNING INSIGHTS"
        ? "GEOSCAPE PLANNING INSIGHTS - BASE"
        : catalogueEntryQuery.data.id
    ) as Product;
  }

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          alignContent: "space-between",

          gap: "16px",
          height: "43px",
          position: "absolute",
          top: "-55px",
          right: "0px",
        }}
      >
        <BackButton />
      </Box>
      <Grid container columnSpacing={"16px"}>
        <Grid item xs={8}>
          <ReactQueryErrorWrapper
            queries={[catalogueEntryQuery, userLicences, datasetDefinitions]}
            mutations={[]}
          />

          {isLoading && <Skeleton variant="rectangular" height="500px" />}

          {isSuccess && (
            <Stack
              direction="column"
              sx={{
                gap: "16px",
              }}
            >
              <Card>
                <CardHeader
                  title={`About ${catalogueEntryQuery.data.displayProduct}`}
                  subheader={catalogueEntryQuery.data.subtitle}
                ></CardHeader>
                <CardContent>
                  <ShowMore>
                    <StyledMarkdown>
                      {catalogueEntryQuery.data.description}
                    </StyledMarkdown>
                  </ShowMore>
                </CardContent>
              </Card>
              <CatalogueEntryCaseStudies
                caseStudies={catalogueEntryQuery.data.caseStudies}
              />
              <CatalogueEntryDataDictionary
                dataDictionaries={catalogueEntryQuery.data.dataDictionaries}
              />
            </Stack>
          )}
        </Grid>
        <Grid item xs={4}>
          {isLoading && <Skeleton variant="rectangular" height="500px" />}
          {isSuccess && (
            <Stack
              direction={"column"}
              sx={{
                gap: "16px",
              }}
            >
              <Card>
                <CardHeader title="I want to..."></CardHeader>

                <CardContent style={{ textAlign: "center" }}>
                  <CustomButtonGroup
                    licenced={licenced}
                    displayProduct={productName}
                  />
                </CardContent>
              </Card>
              <CatalogueEntryMetadata
                datasetId={datasetId}
                image={`data:image/png;base64,${
                  isDark
                    ? catalogueEntryQuery.data.imageDark
                    : catalogueEntryQuery.data.imageLight
                }`}
                metadata={catalogueEntryQuery.data.metadata}
                definitionsMetadata={
                  datasetDefinitions.data?.metadata[datasetId]
                }
                latestReleaseReportLink={
                  catalogueEntryQuery.data.latestReleaseReportLink
                }
                productDescriptionLink={
                  catalogueEntryQuery.data.productDescriptionLink
                }
              />
            </Stack>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
