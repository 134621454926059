import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  Box,
  Card,
  CardContent,
  CardHeader,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import * as QueryKeys from "data";
import { fetchAddressById, fetchPredictive } from "data/queries";
import _ from "lodash";
import { HTMLAttributes, SyntheticEvent, useCallback, useState } from "react";
import { useQuery } from "react-query";
import { Address, AddressResult } from "./models";
import { PredictiveAddressManager } from "./predictive-address-manager";

const CustomPaperComponent = (
  predictiveManager: HTMLAttributes<HTMLElement>
) => {
  return (
    <Paper {...predictiveManager}>
      {predictiveManager.children}
      <Box display="flex" justifyContent="flex-end" m={1} gap={0.5}>
        <Typography variant="caption">Powered by</Typography>
        <Typography fontWeight="bold" variant="caption">
          Geoscape
        </Typography>
      </Box>
    </Paper>
  );
};

export const AddressEntryForm = (
  predictiveManager: PredictiveAddressManager
) => {
  const [addressId, setAddressId] = useState("");
  const [reason, setReason] = useState<AutocompleteInputChangeReason>("input");

  const debouncedSearch = useCallback(
    _.debounce((searchTerm: string) => {
      predictiveManager.setQuery(searchTerm);
    }, predictiveManager.debounce),
    [predictiveManager.debounce]
  );

  const onSearch = (
    event: SyntheticEvent,
    newQuery: string,
    reason: AutocompleteInputChangeReason
  ) => {
    setReason(reason);
    debouncedSearch(newQuery);
  };

  const onSelectAddress = (
    event: SyntheticEvent,
    addressResult: AddressResult | null,
    reason: AutocompleteChangeReason
  ) => {
    if (reason === "clear" && event.type === "click") {
      predictiveManager.setAddress(null);
      predictiveManager.setPrediction(null);
    }
    predictiveManager.setSuggest(null);
    if (addressResult) setAddressId(addressResult.id);
  };

  const predictiveQuery = useQuery(
    [QueryKeys.predictiveAddress, predictiveManager.query],
    () =>
      fetchPredictive(
        predictiveManager.query,
        predictiveManager.dataset.join(","),
        predictiveManager.excludeAliases,
        predictiveManager.addressType.join(","),
        predictiveManager.stateFilter.join(","),
        predictiveManager.maxResults
      ),
    {
      enabled: !!predictiveManager.query && reason === "input",
      onSuccess: (response) => {
        predictiveManager.setSuggest(response);
        predictiveManager.setCallCounter(predictiveManager.callCounter + 1);
        predictiveManager.setCreditCounter(
          predictiveManager.creditCounter + response.basictransaction
        );
      },
    }
  );

  const addressByIdQuery = useQuery(
    [QueryKeys.addressById, addressId, predictiveManager.crs],
    () => fetchAddressById(addressId, predictiveManager.crs),
    {
      enabled: !!addressId,
      onSuccess: (address: Address) => {
        predictiveManager.setAddress(address);
        predictiveManager.setCallCounter(predictiveManager.callCounter + 1);
        predictiveManager.setCreditCounter(
          predictiveManager.creditCounter + address.basictransaction
        );
      },
    }
  );

  const highlightText = (text: string, query: string) => {
    const parts = text.split(new RegExp(`(${query})`, "gi"));
    return parts.map((part, index) => (
      <span
        key={index}
        style={{
          fontWeight:
            part.toLowerCase() === query.toLowerCase() ? 900 : "normal",
          color:
            part.toLowerCase() === query.toLowerCase()
              ? "text.primary"
              : "inherit",
        }}
      >
        {part}
      </span>
    ));
  };

  const constructAddressLine1 = (address: Address) => {
    const p = address.address.properties;
    return [p.street_number_1, p.street_number_2, p.street_name, p.street_type]
      .filter((v) => !!v)
      .join(" ")
      .trim();
  };

  const constructAddressLine2 = (address: Address) => {
    const p = address.address.properties;
    return [
      p.site_name,
      p.complex_unit_type,
      p.complex_unit_identifier,
      p.complex_level_type,
      p.complex_level_number,
    ]
      .filter((v) => !!v)
      .join(" ")
      .trim();
  };

  return (
    <Card>
      <CardHeader title="Address Form" />
      <CardContent
        sx={{
          "&:last-child": {
            paddingBottom: 2,
          },
        }}
      >
        <Autocomplete
          size="small"
          autoComplete
          clearOnBlur={false}
          PaperComponent={CustomPaperComponent}
          inputValue={predictiveManager.prediction?.address}
          onChange={onSelectAddress}
          onInputChange={onSearch}
          options={predictiveQuery.data ? predictiveQuery.data.suggest : []}
          filterOptions={(x) => x}
          noOptionsText="No addresses"
          getOptionLabel={(option: AddressResult) =>
            typeof option === "string" ? option : option.address
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Address Search"
              helperText="Start typing to get auto-complete suggestions from the API"
            />
          )}
          renderOption={(predictiveManager, option, { inputValue }) => (
            <li {...predictiveManager}>
              <Typography>
                {highlightText(option.address, inputValue)}
              </Typography>
            </li>
          )}
        />
        <Box display="flex" flexDirection="column" gap={2} mt={1}>
          <TextField
            fullWidth
            size="small"
            name="addressLine1"
            label="Address Line 1"
            type="text"
            InputLabelProps={{ shrink: true }}
            value={
              predictiveManager.address
                ? constructAddressLine1(predictiveManager.address)
                : ""
            }
            disabled={!predictiveManager.address}
          />
          <TextField
            fullWidth
            size="small"
            name="addressLine2"
            label="Address Line 2"
            type="text"
            InputLabelProps={{ shrink: true }}
            value={
              predictiveManager.address
                ? constructAddressLine2(predictiveManager.address)
                : ""
            }
            disabled={!predictiveManager.address}
          />
          <TextField
            fullWidth
            size="small"
            name="suburb"
            label="Suburb"
            type="text"
            InputLabelProps={{ shrink: true }}
            value={
              predictiveManager.address?.address.properties.locality_name ?? ""
            }
            disabled={!predictiveManager.address}
          />
          <Stack direction="row" spacing={1}>
            <TextField
              fullWidth
              size="small"
              name="stateTerritory"
              label="State/Territory"
              type="text"
              InputLabelProps={{ shrink: true }}
              value={
                predictiveManager.address?.address.properties.state_territory ??
                ""
              }
              disabled={!predictiveManager.address}
            />
            <TextField
              fullWidth
              size="small"
              name="postcode"
              label="Postcode"
              type="text"
              InputLabelProps={{ shrink: true }}
              value={
                predictiveManager.address?.address.properties.postcode ?? ""
              }
              disabled={!predictiveManager.address}
            />
          </Stack>
        </Box>
      </CardContent>
    </Card>
  );
};
