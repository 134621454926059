import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";

import style from "./styled-markdown.module.css";

interface StyledMarkdownProps {
  children?: string;
}

export const StyledMarkdown = (props: StyledMarkdownProps) => {
  return (
    <Markdown
      className={style.markdown}
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]}
    >
      {props.children}
    </Markdown>
  );
};
