import { UploadFile } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import { useIdentity } from "contexts/identity-context";
import { useS3FileUploadDropper } from "pages/geoscape-admin/custom-datasets/custom-hook/customHooks";

import { BatchConfig, Format, OutputFormat } from "pages/geoscape-batch/models";
import {
  BatchConfigProps,
  GeocoderSearchTerms,
  InputBatchConfigFormat,
  StepperControlProps,
  UploadInputFileProps,
} from "pages/geoscape-batch/types";
import { useEffect, useState } from "react";
import { FileWithPath } from "react-dropzone";
import { toTitleCase } from "utils/commons";
import FileUploaded from "./dropFile";
import MappingTable from "./mapping-table";
import {
  CustomAlert,
  getValidation,
  useDeleteObjectMutation,
  useProcessUploadedFile,
} from "./utils";
import * as Papa from "papaparse";
import { LoadingButton } from "@mui/lab";

const GEOCODERSEARCHTERMS: GeocoderSearchTerms = {
  addressGeocoder: {
    Ignore: "Ignore",
    Address: "address",
    "Complex UnitType": "complexUnitType",
    "Complex Unit Identifier": "complexUnitIdentifier",
    "Complex Level Type": "complexLevelType",
    "Complex Level Number": "complexLevelNumber",
    "Street Number 1": "streetNumber1",
    "Street Number 2": "streetNumber2",
    "Street Name": "streetName",
    "Street Type": "streetType",
    "Street Suffix": "streetSuffix",
    "Site Name": "siteName",
    "Lot Identifier": "lotIdentifier",
    "Locality Name": "localityName",
    "State Territory": "stateTerritory",
    Postcode: "postcode",
  },
  reverseGeocoder: {
    Ignore: "Ignore",
    Latitude: "latitude",
    Longitude: "longitude",
  },
};

// Extract the sample inputs from the sample rows stored in the batch config
export const defineSampleRequest = (
  sampleRows: any[],
  batchConfig: BatchConfig,
  setBatchConfig: React.Dispatch<React.SetStateAction<BatchConfig>>
) => {
  const sampleRequests: any[] = [];

  for (let rowIndex = 0; rowIndex < sampleRows.length; rowIndex++) {
    const sampleRequest: any = {
      rowIndex: rowIndex,
      inputs: [],
    };

    batchConfig.input?.format?.forEach((pair: any) => {
      const newInput = {
        apiParameter: pair["apiParameter"],
        name: pair["name"],
        value: sampleRows[rowIndex][pair["name"]],
      };

      // Make sure that the row is valid (i.e. not empty file)
      if (sampleRows[rowIndex][pair["name"]] !== undefined) {
        sampleRequest.inputs.push(newInput);
      }
    });

    if (sampleRequest.inputs.length != 0) {
      sampleRequests.push(sampleRequest);
    }
  }

  setBatchConfig((prev) => ({
    ...prev,
    hubInfo: {
      ...prev.hubInfo,
      sample: {
        ...prev.hubInfo.sample,
        request: sampleRequests,
        response: [],
      },
    },
  }));
};

// Extract the sample rows from the file and store in the batch config
export const extractSampleRows = (
  files: FileWithPath[],
  batchConfig: BatchConfig,
  setBatchConfig: React.Dispatch<React.SetStateAction<BatchConfig>>
) => {
  const numberOfSamples = 5;

  const file = files[0];
  const sampleRows: any[] = [];

  // TODO DS-4504: Can use step to get row by row for big files
  Papa.parse(file, {
    header: true,
    complete: (results) => {
      const data: any[] = results.data;

      for (
        let rowIndex = 0;
        rowIndex < Math.min(numberOfSamples, data.length);
        rowIndex++
      ) {
        sampleRows.push(data[rowIndex]);
      }

      setBatchConfig((prev) => ({
        ...prev,
        hubInfo: {
          ...prev.hubInfo,
          sample: {
            ...prev.hubInfo.sample,
            rows: sampleRows,
            request: [],
            response: [],
          },
        },
      }));
    },
  });

  return sampleRows;
};

export const UploadInputFile = (
  props: UploadInputFileProps & BatchConfigProps & StepperControlProps
) => {
  const searchPatterns = GEOCODERSEARCHTERMS[props.batchConfig.type];

  const batchId = props.batchConfig.batchInfo.batchId;
  const [identityState] = useIdentity();

  const [headers, setHeaders] = useState<InputBatchConfigFormat[]>(
    props.batchConfig.hubInfo?.csvHeaders.length > 0
      ? props.batchConfig.hubInfo.csvHeaders
      : []
  );

  const [rowCount, setRowCount] = useState(0);

  let validateColumns: boolean = !getValidation(props.batchConfig);

  const maxFileSize = 105 * 1024 * 1024; // 100 MB file size limit

  const {
    uploadProgress,
    isUploading,
    haveUploaded,
    files,
    removeFile,
    getRootProps,
    getInputProps,
    uploadFiles,
    s3UploadFailed,
  } = useS3FileUploadDropper({
    maxFiles: 1,
    maxSize: maxFileSize,
    prefixFileName: `${batchId}-`,
    acceptExtension: { "text/csv": [".csv"] },
  });

  let filesData: FileWithPath[] =
    files && files.length > 0 ? files : props.batchConfig.hubInfo?.files;

  const [isEmptyFile, setIsEmptyFile] = useState(false);

  const deleteObjectMutation = useDeleteObjectMutation(
    filesData,
    removeFile,
    props,
    identityState,
    setHeaders,
    setIsEmptyFile
  );

  useEffect(() => {
    updateBatchConfig();
  }, [haveUploaded]);

  const csvHeadersLoaded = props.batchConfig.hubInfo.csvHeaders.length > 0;
  useEffect(() => {
    props.saveBatchConfig();
  }, [csvHeadersLoaded]);

  useEffect(() => {
    props.setBatchConfig((prev: BatchConfig) => ({
      ...prev,
      isLoading: isUploading || deleteObjectMutation.isLoading,
    }));
  }, [isUploading, deleteObjectMutation.isLoading]);

  const isError: boolean = deleteObjectMutation.isError;

  useProcessUploadedFile(
    files,
    uploadFiles,
    setHeaders,
    setRowCount,
    props.batchConfig,
    props.setBatchConfig,
    setIsEmptyFile
  );

  const updateBatchConfig = () => {
    let filteredParameters = headers.filter(
      (obj) => obj.apiParameter !== "Ignore"
    );

    // Mapping Api Parameter names
    const updatedArray = filteredParameters.map((item) => {
      return {
        ...item,
        apiParameter: searchPatterns[item.apiParameter] || "",
      };
    });

    const inputNamesSet = new Set(
      props.batchConfig.hubInfo.csvHeaders.map((param: Format) => param.name)
    );

    const modifiedOutput = props.batchConfig?.output?.format?.map(
      (item: OutputFormat): OutputFormat => {
        let modifiedName = item.name;
        if (inputNamesSet.has(item.name)) {
          modifiedName = toTitleCase(item.name);

          if (inputNamesSet.has(modifiedName)) {
            modifiedName = item.name.toLowerCase();
          }
        }

        return {
          ...item,
          name: modifiedName,
        };
      }
    );

    props.setBatchConfig((prev: BatchConfig) => ({
      ...prev,
      hubInfo: {
        ...prev.hubInfo,
        csvHeaders: headers,
        files: filesData,
      },
      input: {
        ...prev.input,
        format: updatedArray,
        file: `s3://${import.meta.env.VITE_BATCH_UI_BUCKET_NAME}/${batchId}-${
          filesData[0]?.path
        }`,
      },
      output: {
        ...prev.output,
        format: modifiedOutput,
      },
    }));
  };

  return (
    <>
      <Card
        sx={{
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          gap: "32px",
        }}
      >
        <Box>
          <Typography variant="h5">Input File</Typography>
          <Typography variant="subtitle1" sx={{ color: "grey" }}>
            Upload your CSV file
          </Typography>

          {!isUploading &&
            (!haveUploaded || (haveUploaded && isEmptyFile)) &&
            filesData?.length === 0 && (
              <Box
                style={{
                  border: "dotted",
                  borderColor: "grey",
                  margin: "8px",
                  padding: "8px",
                  textAlign: "center",
                }}
                {...getRootProps({ className: "dropzone" })}
              >
                <input disabled={true} {...getInputProps()} />
                <UploadFile color="info" />
                <Typography>Click to upload or drag and drop files</Typography>
                <Typography variant="body2">csv (max. 100 MB)</Typography>
              </Box>
            )}

          {filesData?.length > 0 && (
            <FileUploaded
              filesData={filesData}
              isUploading={isUploading}
              haveUploaded={haveUploaded}
              uploadProgress={uploadProgress}
              deleteObjectMutation={deleteObjectMutation}
              batchId={batchId}
              props={props}
              s3UploadFailed={s3UploadFailed}
              updateBatchConfig={updateBatchConfig}
            />
          )}
        </Box>
      </Card>

      {isEmptyFile && (
        <Alert severity="error" variant="filled">
          <AlertTitle>Your file is empty.</AlertTitle>
          We could not find any data rows in your file. Please delete the
          current file and upload again
        </Alert>
      )}

      <CustomAlert props={props} files={files} />

      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <CardHeader
          title={"Input File Mapping"}
          subheader={
            "Map your files columns to Geoscape search terms. Select Ignore for columns that are not part of the address to be matched."
          }
        />

        <CardContent>
          <MappingTable
            isError={isError}
            deleteObjectMutation={deleteObjectMutation}
            headers={headers}
            SEARCHTERMS={searchPatterns}
            setHeaders={setHeaders}
            batchConfig={props.batchConfig}
            setBatchConfig={props.setBatchConfig}
            updateBatchConfig={updateBatchConfig}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: "16px",
            }}
          >
            <Button
              onClick={() => {
                updateBatchConfig();
                props.setActiveStep(Math.max(props.activeStep - 1, 0));
              }}
            >
              Back
            </Button>

            <LoadingButton
              variant="contained"
              onClick={() => {
                updateBatchConfig();

                props.saveBatchConfig(
                  props.batchConfig,
                  Math.min(props.activeStep + 1, props.maxSteps)
                );
              }}
              loading={props.isSaveBatchConfigLoading}
              disabled={validateColumns || s3UploadFailed}
            >
              Continue
            </LoadingButton>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};
