import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Collapse,
  Link,
  ListItemButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import { CSSObject, styled, Theme, useTheme } from "@mui/material/styles";
import withStyles from "@mui/styles/withStyles";
import * as React from "react";

const openedMixin = (theme: Theme): CSSObject => ({
  width: "228px",
  borderRadius: "0px",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),

  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: "72px",
  borderRadius: "0px",
});

export const DrawerSideBarHeader = styled("div")(({ theme }) => ({
  display: "flex",
}));

export const DrawerSideBarFooter = styled("div")(({ theme }) => ({
  display: "flex",
}));

const drawerWidth = "228px";
export const DrawerSideBar = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  flexShrink: 0,
  padding: "8px",
  whiteSpace: "nowrap",
  boxSizing: "border-box",

  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),

  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

type MenuItem = {
  link: string;
  target?: string;
  Icon?: React.ElementType;
  name: string;
  subMenus?: MenuItem[];
};

type Props = {
  sideMenu: MenuItem[];
  currentRoute: string;
  open: boolean;
};
export const RenderMenu = ({ sideMenu, currentRoute, open }: Props) => {
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  // Track open state for each submenu using their indices

  const storedSubMenus = JSON.parse(
    localStorage.getItem("openSubMenus") || "{}"
  );

  const [openSubMenus, setOpenSubMenus] =
    React.useState<Record<number, boolean>>(storedSubMenus);

  React.useEffect(() => {
    localStorage.setItem(`openSubMenus`, JSON.stringify(openSubMenus));
  }, [openSubMenus]);

  const handleClick = (index: number) => {
    setOpenSubMenus((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const handleClick2 = (event: any, subItem: any) => {
    // Open a new page
    if (subItem.target === "_blank" && subItem.link) {
      event.preventDefault();
      window.open(subItem.link, "_blank", "noopener,noreferrer");
    }
  };
  const isItemSelected = (item: MenuItem, index: number) => {
    if (item.link === currentRoute) return true;
    if (item.subMenus && !openSubMenus[index]) {
      return item.subMenus.some((subItem) => subItem.link === currentRoute);
    }
    if (item.subMenus && !open) {
      return item.subMenus.some((subItem) => subItem.link === currentRoute);
    }
    return false;
  };

  return (
    <List
      className="menu-list"
      sx={{
        display: "block",
        width: "226px",
        padding: "8px",
      }}
    >
      {sideMenu.map((item: MenuItem, index: number) => (
        <>
          <ListItemButton
            selected={isItemSelected(item, index)}
            href={item.subMenus ? undefined : item.link}
            onClick={() => (item.subMenus ? handleClick(index) : undefined)}
            component={item.target ? ListItemButton : Link}
            target={item.target}
            className="menu-list-item-buttom"
            sx={{
              justifyContent: "center",
              padding: "4px 16px 4px 16px",

              height: "40px",
              width: open ? "212px" : "56px",
              justifyItems: "center",
              borderRadius: "8px",

              gap: "16px",
            }}
            id={`menuitem-${item.name.toLowerCase().replaceAll(" ", "-")}`}
          >
            {item.Icon && !item.subMenus && (
              <Tooltip
                title={
                  open ? (
                    ""
                  ) : (
                    <ListItemText
                      primaryTypographyProps={{
                        variant: "body2",
                      }}
                      primary={item.name}
                    />
                  )
                }
                arrow
                placement="right"
              >
                <item.Icon size={24} />
              </Tooltip>
            )}
            {item.Icon && item.subMenus && (
              <CustomTooltip
                sx={{
                  backgroundColor: "white",
                  padding: "0",
                }}
                title={
                  open ? (
                    ""
                  ) : (
                    <Paper
                      sx={{
                        padding: "8px",
                        paddingBottom: "0px",
                        borderRadius: "8px",
                        width: "140px",
                      }}
                    >
                      <Typography fontSize="12px">{item.name}</Typography>

                      <List dense>
                        {item.subMenus.map((subItem, index) => (
                          <ListItemButton
                            selected={currentRoute === subItem.link}
                            key={`${subItem.name}-${index}`}
                            onClick={(e) => handleClick2(e, subItem)}
                            href={subItem.link}
                            component={Link}
                            sx={{
                              padding: "0px",
                              paddingLeft: "10px",
                              paddingBottom: "0px",
                              position: "relative",
                              borderRadius: "8px",
                            }}
                          >
                            <ListItemText
                              primaryTypographyProps={{
                                fontSize: "14px",
                              }}
                              primary={subItem.name}
                            />
                          </ListItemButton>
                        ))}
                      </List>
                    </Paper>
                  )
                }
                placement="right-start"
                style={{
                  border: 0,
                  padding: 0,
                }}
              >
                <item.Icon size={24} />
              </CustomTooltip>
            )}
            {/* { false && */}
            <ListItemText
              primaryTypographyProps={{
                variant: "body1",
              }}
              primary={item.name}
              sx={{
                display: open ? "inline" : "none",
              }}
            />
            {item.subMenus && open && (
              <div>{openSubMenus[index] ? <ExpandLess /> : <ExpandMore />}</div>
            )}
          </ListItemButton>

          {item.subMenus && open && (
            <Collapse
              in={openSubMenus[index]}
              timeout="auto"
              unmountOnExit
              className="sub-menu-collapse"
            >
              {item.subMenus.map((subItem: MenuItem, subIndex: number) => (
                <ListItemButton
                  selected={currentRoute === subItem.link}
                  key={`${subItem.name}-${subIndex}`}
                  className="sub-menu-list-item-buttom"
                  sx={{
                    padding: "0px",
                    paddingLeft: "67px",
                    height: "36px",
                    position: "relative",
                    borderRadius: "8px",
                    "&::before": {
                      content: '""',
                      position: "absolute",
                      top: 0,
                      left: 28,
                      height: "100%",
                      width: "1px",
                      backgroundColor: isDark ? "white" : "#000",
                    },
                  }}
                  href={subItem.link}
                  component={subItem.target ? "a" : Link}
                  target={subItem.target}
                >
                  <ListItemText
                    primaryTypographyProps={{
                      variant: "body1",
                    }}
                    primary={subItem.name}
                  />
                </ListItemButton>
              ))}
            </Collapse>
          )}
        </>
      ))}
    </List>
  );
};

const CustomTooltip = withStyles({
  tooltip: {
    backgroundColor: "transparent",
  },
})(Tooltip);
