import { useCustomSnackbars } from "components/snackbars/useCustomSnackbars";
import * as QueryKeys from "data";
import { fetchAuthJWT, fetchMapsLayerJson } from "data/queries";
import { TileJSON } from "pages/geoscape-data/explorer/layers/models";
import { useState } from "react";
import { FillLayer, Layer, Source } from "react-map-gl";
import { useQuery } from "react-query";

export const BuildingsSource = () => {
  const [buildingsLayerJson, setBuildingsLayerJson] = useState<TileJSON | null>(
    null
  );

  const { enqueueQueryFailed } = useCustomSnackbars();

  const jwt = useQuery([QueryKeys.jwt], () => fetchAuthJWT(), {
    onError: (error: Error) => {
      enqueueQueryFailed(error.toString());
    },
  });

  const addAuthToTileUrl = (tileUrl: string[]) => {
    return tileUrl.map((x) => `${x}?key=${jwt.data}`);
  };

  const buildingsLayerJsonQuery = useQuery(
    [QueryKeys.mapsLayerJson, "buildings"],
    () => fetchMapsLayerJson(jwt.isSuccess ? jwt.data : "", "buildings"),
    {
      enabled: !!jwt.data,
      retry: true,
      onSuccess: (json: TileJSON) => {
        setBuildingsLayerJson(json);
      },
    }
  );

  const buildingsLayer: FillLayer = {
    id: "buildings",
    type: "fill",
    source: "buildings",
    "source-layer": "buildings",
    paint: {
      "fill-color": [
        "interpolate",
        ["linear"],
        ["zoom"],
        14,
        "hsla(0, 0%, 50%, 0)",
        16,
        "hsla(0, 0%, 50%, 0.5)",
      ],
      "fill-outline-color": [
        "interpolate",
        ["linear"],
        ["zoom"],
        14,
        "hsla(0, 0%, 40%, 0)",
        16,
        "hsla(0, 0%, 40%, 1)",
      ],
    },
  };

  return (
    <>
      {buildingsLayerJson && (
        <Source
          key="buildings"
          id="buildings"
          type="vector"
          maxzoom={15}
          minzoom={14}
          tiles={addAuthToTileUrl(buildingsLayerJson.tiles)}
        >
          <Layer {...buildingsLayer} />
        </Source>
      )}
    </>
  );
};
