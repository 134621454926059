import { Button, Chip } from "@mui/material";

import { Dataset, DatasetList, DatasetStatus } from "data/models";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { fetchAdminDatasetsLink } from "data/queries";
import { formatBytes } from "utils/commons";

import { styled } from "@mui/styles";
import { formatDate } from "utils";

import { GridColDef, GridRenderCellParams, GridValidRowModel } from "@mui/x-data-grid-premium";
import { useCustomSnackbars } from "components/snackbars/useCustomSnackbars";
import { number } from "yup";

const ResponsiveButton = styled(Button)({
  "@media (max-width: 1370)": {
    fontSize: "10px",
    // padding: '12px 24px',
  },
  "@media (max-width: 1500px)": {
    fontSize: "10px",
  },
  "@media (min-width: 1500px)": {
    fontSize: "12px",
  },
});

type Dictionary = {
  [key: string]: string;
};

const DisplayFormatMapping: Dictionary = {
  GDB: "GDB",
  GEOJSON: "GeoJSON",
  SHP: "Shapefile",
  TAB: "MapInfo TAB",
  LYNX: "LYNX",
  PUMP: "PUMP",
  GEOTIFF: "GEOTIFF",
  PSV: "Pipe-Separated Values",
};

const DisplayStateMapping: Dictionary = {
  "ALL STATES": "All States",
  AUSTRALIA: "Australia",
};

const renderStatusChip = (status: DatasetStatus) => {
  if (status === "loading") return <Chip label={"Loading"} color="secondary" />;
  else if (status === "internal")
    return <Chip label={"Internal"} color="info" />;
  else if (status === "published")
    return <Chip label={"Published"} color="success" />;
  else if (status === "pending_published")
    return <Chip label={"Pending Published"} color="primary" />;
  else return <Chip label={status} />;
};

export const isDownloadable = (status: DatasetStatus) => {
  return status === "published" || status === "internal";
};

const customColumns = (identityState: any): GridColDef[] => {
  const { enqueueLinkCorruptedSnackbar } = useCustomSnackbars();

  return [
    {
      flex: 1.5,
      field: "name",
      headerName: "Dataset",
    },
    {
      flex: 1.2,
      field: "release",
      headerName: "Release",
      valueFormatter(params) {
        const [year, month] = (params as string).split("-").map(Number);
        const monthName = new Date(year, month - 1).toLocaleString("default", {
          month: "long",
        });
        return `${monthName} ${year}`;
      },
    },
    {
      flex: 1,
      field: "region",
      headerName: "Region",
      valueFormatter(params) {
        return DisplayStateMapping[params as string] ?? (params as string);
      },
    },
    {
      flex: 1,
      field: "format",
      headerName: "Format",
      valueFormatter(params) {
        return DisplayFormatMapping[params as string] ?? params as string;
      },
    },
    {
      flex: 1,
      field: "datum",
      headerName: "Datum",
    },
    {
      flex: 1,
      field: "size",
      headerName: "File Size",
      valueFormatter(params) {
        return formatBytes(params as number);
      },
    },

    {
      flex: 1,
      field: "publishDate",
      headerName: "Publish Date",
      valueFormatter(params) {
        if (params) return formatDate(params as string);
        return "";
      },
    },
    {
      flex: 1,
      field: "status",
      headerName: "Status",
      renderCell: (params: GridRenderCellParams) => renderStatusChip(params.row.status),
    },
    {
      flex: 1.2,
      field: "id",
      headerName: "Download",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <ResponsiveButton
            variant="contained"
            fullWidth
            disabled={!isDownloadable(params.row.status)}
            startIcon={<FileDownloadIcon />}
            onClick={async () => {
              try {
                const response = await fetchAdminDatasetsLink(
                  params.value,
                  identityState
                );

                const a = document.createElement("a");
                a.href = response;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(response);
              } catch (er) {
                enqueueLinkCorruptedSnackbar();
                // If this happens it will show and popup
              }
            }}
          >
            Download
          </ResponsiveButton>
        );
      },
    },
  ];
};

const datasetsToRows = (datasetsList: DatasetList): GridValidRowModel[] => {
  const rows = datasetsList.datasets.map((key: Dataset) => {
    return {
      id: key.id,
      name: key.name,
      product: key.product,
      region: key.region,
      format: key.format,
      size: key.size,
      release: key.release,
      publishDate: key.publishDate,
      status: key.status,
      datum: key.datum,
    };
  });
  return rows;
};

export {
  DisplayFormatMapping,
  DisplayStateMapping,
  ResponsiveButton,
  customColumns,
  datasetsToRows,
  renderStatusChip,
};
