import { Box, Card, CardContent, CardHeader, Chip, Stack } from "@mui/material";
import { StyledMarkdown } from "components/shared/styled-markdown/styled-markdown";
import { useState } from "react";

interface DataDictionaryProps {
  dataDictionaries?: { [key: string]: string };
}

export const CatalogueEntryDataDictionary = (props: DataDictionaryProps) => {
  if (props.dataDictionaries === undefined) {
    return <></>;
  }
  const [selected, setSelected] = useState<string>(
    Object.keys(props.dataDictionaries).sort()[0]
  );

  return (
    <Card>
      <CardHeader title="Data Dictionary"></CardHeader>
      <CardContent>
        <Box overflow="scroll">
          <Stack gap={1} mb="15px" direction="row">
            {Object.keys(props.dataDictionaries)
              .sort()
              .map((key) => {
                return (
                  <Chip
                    key={key}
                    sx={{ gap: 10 }}
                    label={key}
                    variant="outlined"
                    color={key == selected ? "primary" : "default"}
                    onClick={() => setSelected(key)}
                  />
                );
              })}
          </Stack>
        </Box>
        <StyledMarkdown>{props.dataDictionaries[selected]}</StyledMarkdown>
      </CardContent>
    </Card>
  );
};
