import { object, string } from "yup";

const nameValidationSchema = string()
  .required("Name is required")
  .max(60, "Name must be less than 60 characters")
  .matches(
    /^[A-Za-z0-9_\- ]*$/,
    "Can only contain letters, numbers, spaces, dashes and underscores."
  );

const notesValidationSchema = string()
  .notRequired()
  .max(200, "Notes must be less than 200 characters");

const createApiKeyValidationSchema = object({
  name: nameValidationSchema,
  notes: notesValidationSchema,
});

const updateApiKeyValidationSchema = object({
  displayName: nameValidationSchema,
  notes: notesValidationSchema,
});

export { createApiKeyValidationSchema, updateApiKeyValidationSchema };
