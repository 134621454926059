import { ContentCopy } from "@mui/icons-material";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  Chip,
  LinearProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import { useCustomSnackbars } from "components/snackbars/useCustomSnackbars";
import { IdentityState } from "contexts/identity-context";
import * as QueryKeys from "data";
import { stopBatchJob } from "data/mutations";
import { usePostHog } from "posthog-js/react";
import { QueryClient, UseQueryResult, useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import * as Routes from "routes";
import { formatTimestamp } from "utils";
import { LinearProgressWithLabel } from "./linear-progress-with-label";
import { BatchConfig } from "./models";
import { isFailureStatus, statusColour } from "./utils";

interface BuildColumnsArgs {
  batchesQuery: UseQueryResult<any, unknown>;
  identityId: string;
  identityState: IdentityState;
  queryClient: QueryClient;
}

export const buildColumns = ({
  batchesQuery,
  identityId,
  identityState,
  queryClient,
}: BuildColumnsArgs): GridColDef[] => {
  const history = useHistory();

  const { enqueueMutationFailed } = useCustomSnackbars();
  const posthog = usePostHog();

  const stopJobMutation = useMutation(
    [QueryKeys.batchesStopJob, identityId],
    (batchId: string) => stopBatchJob(batchId, identityState),
    {
      onSuccess: async (response) => {
        await batchesQuery.refetch();
      },
      onError: (error: Error) => {
        enqueueMutationFailed(error.toString());
      },
    }
  );

  const updateCancellingJob = (batchId: string) => {
    const updatedBatchConfigs = batchesQuery.data.map(
      (batchConfig: BatchConfig) => {
        if (batchConfig.batchInfo.batchId === batchId) {
          return { ...batchConfig, status: "Cancelling..." };
        }
        return batchConfig;
      }
    );
    queryClient.setQueryData(
      [QueryKeys.batchesKey, identityId],
      updatedBatchConfigs
    );
  };

  return [
    {
      flex: 1,
      field: "displayName",
      headerName: "Batch Name",
      renderCell: (params: any) => (
        <Tooltip title={params.row.displayName}>
          {params.row.displayName}
        </Tooltip>
      ),
    },
    {
      flex: 1,
      field: "batchId",
      headerName: "Batch ID",
      renderCell: (params: any) => (
        <Tooltip title={params.row.batchId}>
          <Chip
            label={params.row.batchId}
            deleteIcon={<ContentCopy />}
            onDelete={() => navigator.clipboard.writeText(params.row.batchId)}
          />
        </Tooltip>
      ),
    },
    {
      flex: 1,
      field: "rowCount",
      headerName: "Row Count",
    },
    {
      flex: 1,
      field: "creditCost",
      headerName: "Credit Cost",

      renderCell: (params: any) => (
        <>
          {/* Cancelled or Failed Job */}
          {isFailureStatus(params.row.status) && <>0</>}

          {/* Running, Completed, or Draft Job */}
          {!isFailureStatus(params.row.status) && (
            <>
              {params.row.creditCost}
              <Box color="grey" sx={{ marginLeft: "3px" }}>
                {params.row.status !== "Completed" ? "(estimated)" : ""}
              </Box>
            </>
          )}
        </>
      ),
    },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 140,

      renderCell: (params: any) => formatTimestamp(params.row.startDate),
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,

      renderCell: (params: any) => (
        <>
          {params.row.status === "Running" && (
            <Chip
              label={params.row.status}
              color={statusColour(params.row.status)}
              onDelete={() => {
                updateCancellingJob(params.row.batchId);
                stopJobMutation.mutate(params.row.batchId);
              }}
            />
          )}
          {params.row.status !== "Running" && (
            <Chip
              label={params.row.status}
              color={statusColour(params.row.status)}
            />
          )}
        </>
      ),
    },
    {
      flex: 1,
      field: "progress",
      headerName: "Progress",

      renderCell: (params: any) => {
        if (params.row.status === "Queued") {
          return (
            <Box sx={{ display: "flex", width: "100%", paddingTop: "16px" }}>
              <Box sx={{ width: "100%", mr: 1 }}>
                <LinearProgress
                  color="info"
                  sx={{ height: "20px", borderRadius: 1 }}
                />
              </Box>
              <Box sx={{ minWidth: 35 }}>
                <Typography
                  variant="body2"
                  color="text.secondary"
                >{``}</Typography>
              </Box>
            </Box>
          );
        } else {
          return (
            <LinearProgressWithLabel
              value={
                isFailureStatus(params.row.status) ? 0 : params.row.progress
              }
              status={params.row.status}
            />
          );
        }
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 115,

      renderCell: (params: any) => (
        <Box>
          {(params.row.status === "Draft" ||
            params.row.status === "Quoted") && (
            <Button
              variant="contained"
              onClick={() => {
                history.push(Routes.batchCreateBatch, {
                  batchId: params.row.batchId,
                  seedBatchConfig: params.row.batchConfig,
                });
              }}
            >
              <EditIcon />
            </Button>
          )}

          {params.row.status !== "Draft" && params.row.status != "Quoted" && (
            <Button
              variant="contained"
              disabled={params.row.status !== "Completed"}
              onClick={() => {
                const a = document.createElement("a");
                a.href = params.row.downloadPath;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(params.row.downloadPath);
                posthog.capture("user_downloaded_batch_job", {
                  id: params.batchId,
                  name: params.row.displayName,
                  rowCount: params.row.rowCount,
                  creditCost: params.row.creditCost,
                });
              }}
            >
              <DownloadIcon />
            </Button>
          )}
        </Box>
      ),
    },
  ];
};
