import { Button, FormControlLabel, FormGroup, TextField } from "@mui/material";
import Switch from "@mui/material/Switch";
import { DateRangePicker } from "pages/geoscape-data/share-component/data-range-picker";

// import { DateRangePicker } from "../share-component/data-range-picker";
// import { MultiSelectFormControl } from "../share-component/multi-select";

export const CustomDatasetFilters = ({
  values,
  handleChange,
  handleSubmit,
  definitions,
  time,
  setTime,
  setFieldValue,
  setQueryFilterState,
  resetForm,
  presetRange,
  setPresetRange,
  initialQueryFilterState,
  queryFilterState,
  localStorageKey,
}: any) => {
  return (
    <FormGroup
      sx={{
        display: "flex",
        flexDirection: "row",
        // justifyContent: "space-between",
        gap: "16px",
        // width: "0%",
      }}
    >
      <TextField
        id="query"
        name="query"
        label="Search"
        type="text"
        value={values.query}
        onChange={(e: any) => {
          handleChange(e);
          handleSubmit();
        }}
        variant="outlined"
        size="small"
        sx={{
          width: "250px",
        }}
      />

      <DateRangePicker
        time={time}
        setTime={setTime}
        setFieldValue={setFieldValue}
        handleSubmit={handleSubmit}
        presetRange={presetRange}
        setPresetRange={setPresetRange}
        disabled={values.latestRelease}
        queryFilterState={queryFilterState}
      />

      <FormControlLabel
        control={
          <Switch
            checked={values.latestRelease}
            onChange={(e: any) => {
              handleChange(e);
              handleSubmit();
            }}
            id={"latestRelease"}
            name={"latestRelease"}
          />
        }
        label={`Latest`}
      />

      <Button
        variant="contained"
        size="small"
        id={"release"}
        name={"release"}
        onClick={(e) => {
          setTime({ startTime: 0, endTime: 0 });
          setFieldValue("release", "");
          localStorage.removeItem(localStorageKey);
          setQueryFilterState(initialQueryFilterState);
          resetForm();
          handleSubmit();
        }}
      >
        Clear Filters
      </Button>
    </FormGroup>
  );
};
