import { Box, Typography } from "@mui/material";
import { GetUserIfLoggedIn, useIdentity } from "contexts/identity-context";
import {
  BookOpenText,
  BookText,
  Bot,
  CodeXml,
  Download,
  Map,
  SquareScissors,
} from "lucide-react";
import { useHistory } from "react-router-dom";
import * as Routes from "routes";
import ItemCard from "./hub-card";

export const HubPage = () => {
  const history = useHistory();
  const redirectPath = localStorage.getItem("redirectPath");
  const query = localStorage.getItem("query");
  const [identityState] = useIdentity();
  const user = GetUserIfLoggedIn(identityState);

  if (redirectPath && query) {
    history.push({
      pathname: redirectPath,
      search: query,
    });

    localStorage.removeItem("redirectPath");
    localStorage.removeItem("query");
  }

  return <ActionMenu />;
};

const ActionMenu = () => {
  const actions = [
    {
      id: 1,
      title: "Explore Data",
      path: Routes.dataExplorer,
      icon: <Map size={36} color="#4CAF50" />,
    },
    {
      id: 2,
      title: "Create a Clip",
      path: Routes.dataExplorerClip,
      icon: <SquareScissors size={36} color="#ED6C02" />,
    },
    {
      id: 3,
      title: "Data Catalogue",
      path: Routes.dataCatalogue,
      icon: <BookText size={36} color="#00BCD4" />,
    },
    {
      id: 4,
      title: "Download Data",
      path: Routes.dataDownload,
      icon: <Download size={36} color="#1976D2" />,
    },
    {
      id: 5,
      title: "Create a Batch",
      path: Routes.batchBatches,
      icon: <Bot size={36} color="#E91E63" />,
    },
    {
      id: 6,
      title: "Create an App",
      path: Routes.developerApiKeys,
      icon: <CodeXml size={36} color="#9C27B0" />,
    },
    {
      id: 7,
      title: "Read the Docs",
      path: Routes.docsPage,
      icon: <BookOpenText size={36} color="#607D8B" />,
    },
  ];

  const history = useHistory();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        justifyItems: "center",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          marginTop: "150px",
          fontSize: "48px",
          fontWeight: "700",
          textAlign: "center",
        }}
      >
        What would you like to do today?
      </Typography>
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: "400",
          textAlign: "center",
        }}
      >
        There's more to hub but these are great places to start.
      </Typography>

      <Box
        sx={{
          display: "flex",
          gap: "16px",
          justifyContent: "center",
          alignContent: "center",
          alignSelf: "stretch",
          flexWrap: "wrap",
        }}
      >
        {actions.map((item, id) => (
          <ItemCard item={item} id={id} />
        ))}
      </Box>
    </Box>
  );
};
