import * as QueryKeys from "data";

import {
  Alert,
  AlertTitle,
  Button,
  Card,
  CardContent,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { stripeUrl, supportURL } from "common/const";
import { ReactQueryErrorWrapper } from "components/shared/react-query-error-wrapper";
import { useIdentity } from "contexts/identity-context";
import { PaymentMethod } from "data/models";
import { addPaymentMethod, ReactQueryMutationError } from "data/mutations";
import { fetchPaymentMethod, fetchSubscription } from "data/queries";
import { Dispatch, SetStateAction, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { resolveIdentityId } from "utils/identity";
import { EditPaymentMethodDialogue } from "./editPaymentDialogue";

interface PaymentMethodInfoProps {
  isUserPage: boolean;
  style?: React.CSSProperties;
  elevation?: number;
  setPaymentMethod?: Dispatch<SetStateAction<boolean>>;
  hideTitle?: boolean;
}

const PaymentMethodInfo = (props: PaymentMethodInfoProps) => {
  const [identityState] = useIdentity();
  const identityId = resolveIdentityId(identityState, props.isUserPage);
  const stripe = useStripe();
  const stripeElements = useElements();

  const [editingCC, setEditingCC] = useState<boolean>(false);

  const queryClient = useQueryClient();
  const paymentMethodQuery = useQuery(
    [QueryKeys.paymentMethod, identityId],
    () => fetchPaymentMethod(props.isUserPage, identityState),
    {
      onSuccess: (paymentMethod) => {
        if (paymentMethod && props.setPaymentMethod) {
          props.setPaymentMethod(true);
        }
      },
    }
  );
  const paymentMethodMutation = useMutation<
    PaymentMethod,
    ReactQueryMutationError
  >(
    () =>
      addPaymentMethod(
        stripe,
        stripeElements,
        identityState,
        setEditingCC,
        props.isUserPage
      ),
    {
      onSuccess: (newPaymentMethod) => {
        queryClient.setQueryData(
          [QueryKeys.paymentMethod, identityId],
          newPaymentMethod
        );
      },
    }
  );
  const subscriptionQuery = useQuery([QueryKeys.subscription, identityId], () =>
    fetchSubscription(props.isUserPage, identityState)
  );

  const handleOnClose = () => {
    paymentMethodMutation.reset();
    setEditingCC(false);
  };

  // const isEnterprise = subscriptionQuery.data.activeSubscription.plan.isEnterprise
  let isEnterprise: boolean =
    subscriptionQuery.isSuccess &&
    subscriptionQuery.data.activeSubscription.plan.isEnterprise;

  const isPaymentMyob =
    (subscriptionQuery.isSuccess &&
      subscriptionQuery.data.activeSubscription.plan.isEnterprise) ||
    (subscriptionQuery.isSuccess &&
      subscriptionQuery.data.activeSubscription.isMyob);

  return (
    <>
      <Card>
        <CardContent>
          {!props.hideTitle && (
            <Typography variant="h5">Payment Method</Typography>
          )}

          <ReactQueryErrorWrapper
            queries={[paymentMethodQuery, subscriptionQuery]}
            mutations={[paymentMethodMutation]}
          />

          {/* We are still trying to get the payment details */}
          {paymentMethodQuery.isLoading && (
            <>
              <Skeleton
                variant="rectangular"
                height={56}
                style={{ marginTop: "16px", marginBottom: "8px" }}
              ></Skeleton>
              <Skeleton
                variant="rectangular"
                height={56}
                style={{ marginTop: "16px", marginBottom: "8px" }}
              ></Skeleton>
            </>
          )}

          {/* There are payment details for this account */}
          {paymentMethodQuery.isSuccess && paymentMethodQuery.data && (
            <>
              <TextField
                fullWidth
                disabled
                margin="normal"
                variant="outlined"
                label="Card Number"
                value={"**** **** **** " + paymentMethodQuery.data.last4}
              ></TextField>
              <TextField
                fullWidth
                disabled
                margin="normal"
                variant="outlined"
                label="Expiration"
                value={
                  String(paymentMethodQuery.data.expireMonth).padStart(2, "0") +
                  "/" +
                  paymentMethodQuery.data.expireYear
                }
              ></TextField>
            </>
          )}

          {/* We were unable to find any payment details */}
          {paymentMethodQuery.isSuccess && paymentMethodQuery.data === null && (
            <Alert
              severity="warning"
              style={{ marginTop: "32px", marginBottom: "28px" }}
            >
              <AlertTitle>No payment method currently configured</AlertTitle>
              Click the update card button below to add a payment method
            </Alert>
          )}

          {subscriptionQuery.isLoading && (
            <>
              <Skeleton
                variant="rectangular"
                height={36}
                width={120}
              ></Skeleton>
            </>
          )}

          {subscriptionQuery.isSuccess && isPaymentMyob && (
            <>
              <Alert severity="info">
                You are unable to change your payment information on {""}
                {isEnterprise
                  ? " an enterprise subscription plan."
                  : "a professional subscription plan."}{" "}
                {""}
                Please contact{" "}
                <Link target="_blank" to={{ pathname: supportURL }}>
                  support
                </Link>{" "}
                to change your payment method.
              </Alert>
            </>
          )}

          {subscriptionQuery.isSuccess &&
            !subscriptionQuery.data.activeSubscription.plan.isEnterprise &&
            !subscriptionQuery.data.activeSubscription.isMyob && (
              <>
                <Button onClick={() => setEditingCC(true)}>UPDATE CARD</Button>
              </>
            )}
        </CardContent>
      </Card>

      <EditPaymentMethodDialogue
        open={editingCC}
        handleOnClose={handleOnClose}
        stripeUrl={stripeUrl}
        paymentMethodMutation={paymentMethodMutation}
      />
    </>
  );
};

export default PaymentMethodInfo;
