import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
} from "@mui/material";

export const HubDashboardCard = ({
  title,
  subtitle,
  actionFunc,
  actionName,
  actionDisabled,
  cardStyle,
}: any) => {
  return (
    <Card style={cardStyle}>
      <>
        <CardContent style={{ padding: " 0" }}>
          <Typography component="p" variant="h5" textAlign="left">
            {title}
          </Typography>
          <Typography
            component="p"
            variant="body2"
            textAlign="left"
            color="text.secondary"
          >
            {subtitle}
          </Typography>
        </CardContent>
        <CardActions
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "18px 0px 0px 0px",
            alignSelf: "stretch",
            height: "46px",
            justifyContent: "flex-start",
          }}
        >
          <Button
            disabled={actionDisabled}
            style={{
              display: "flex",
              justifyContent: "flex-start",
            }}
            onClick={actionFunc}
            size="small"
          >
            {actionName}
          </Button>
        </CardActions>
      </>
    </Card>
  );
};
