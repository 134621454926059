import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { MapboxGeoJSONFeature } from "react-map-gl";

interface EasementsTableProps {
  intersectingEasements: MapboxGeoJSONFeature[];
}
export const EasementsTable = (props: EasementsTableProps) => {
  return (
    <Box width="100%">
      <Typography variant="h6">Easements on Parcel</Typography>
      <TableContainer style={{ maxHeight: "200px", overflow: "auto" }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell
                padding="none"
                align="left"
                sx={{ fontWeight: "bold", padding: "4px" }}
              >
                Easement PID
              </TableCell>
              {props.intersectingEasements[0].geometry.type == "LineString" && (
                <TableCell
                  padding="none"
                  align="left"
                  sx={{ fontWeight: "bold", padding: "4px" }}
                >
                  Easement Line PID
                </TableCell>
              )}
              {props.intersectingEasements[0].geometry.type == "Polygon" && (
                <TableCell
                  padding="none"
                  align="left"
                  sx={{ fontWeight: "bold", padding: "4px" }}
                >
                  Easement Polygon PID
                </TableCell>
              )}
              <TableCell
                padding="none"
                align="left"
                sx={{ fontWeight: "bold", padding: "4px" }}
              >
                Contributer ID
              </TableCell>
              <TableCell
                padding="none"
                align="left"
                sx={{ fontWeight: "bold", padding: "4px" }}
              >
                State
              </TableCell>
              {props.intersectingEasements[0].geometry.type == "LineString" && (
                <TableCell
                  padding="none"
                  align="left"
                  sx={{ fontWeight: "bold", padding: "4px" }}
                >
                  Length
                </TableCell>
              )}
              {props.intersectingEasements[0].geometry.type == "Polygon" && (
                <TableCell
                  padding="none"
                  align="left"
                  sx={{ fontWeight: "bold", padding: "4px" }}
                >
                  Area
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.intersectingEasements.map((easement) => (
              <>
                {easement.properties && (
                  <TableRow
                    key={
                      easement.geometry.type == "LineString"
                        ? easement.properties.easement_line_pid
                        : easement.properties.easement_polygon_pid
                    }
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell sx={{ padding: "4px" }} align="left">
                      {easement.properties.easement_pid}
                    </TableCell>
                    {easement.geometry.type == "LineString" && (
                      <TableCell sx={{ padding: "4px" }} align="left">
                        {easement.properties.easement_line_pid}
                      </TableCell>
                    )}
                    {easement.geometry.type == "Polygon" && (
                      <TableCell sx={{ padding: "4px" }} align="left">
                        {easement.properties.easement_polygon_pid}
                      </TableCell>
                    )}
                    <TableCell sx={{ padding: "4px" }} align="left">
                      {easement.properties.contributor_id}
                    </TableCell>
                    <TableCell sx={{ padding: "4px" }} align="left">
                      {easement.properties.state}
                    </TableCell>
                    {easement.geometry.type == "LineString" && (
                      <TableCell sx={{ padding: "4px" }} align="left">
                        {`${easement.properties.length} m`}
                      </TableCell>
                    )}
                    {easement.geometry.type == "Polygon" && (
                      <TableCell sx={{ padding: "4px" }} align="left">
                        {`${easement.properties.area} m²`}
                      </TableCell>
                    )}
                  </TableRow>
                )}
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
