import * as QueryKeys from "data";

import { Box, Card } from "@mui/material";
import { User, useIdentity } from "contexts/identity-context";

import {
  fetchAdminCustomDatasetsSearch,
  fetchAdminCustomDefinitions,
} from "data/queries";
import { useEffect, useState } from "react";
import {
  QueryClient,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";

import * as localStorageKeys from "data/local-storage-keys";
import { CustomDatasetFilterType, CustomDatasetList } from "data/models";
import { updateQueryAndFilters } from "pages/geoscape-data/utils";
import { customColumns, datasetsToRows } from "./columns-rows";

import { LoadingButton } from "@mui/lab";
import {
  GridPaginationModel,
  GridRowClassNameParams,
} from "@mui/x-data-grid-premium";
import { ReactQueryErrorWrapper } from "components/shared/react-query-error-wrapper";
import CustomServerPaginatedTable from "components/shared/server-paginated-table";
import { manageCustomDatasetsStatus } from "data/mutations";
import FilterComponent from "pages/geoscape-data/share-component/filter-component";
import { AdminCustomDatasetFilters } from "./admin-custom-datasets-filters";
import usePaginatedTableQuery from "shared-hooks/use-paginated-table-query";

export const AdminCustomDatasetsSearch = () => {
  const [identityState] = useIdentity();

  const savedSearch = localStorage.getItem(
    localStorageKeys.searchAdminCustomDatasets
  );

  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  let initialQueryFilterState: CustomDatasetFilterType = {
    name: [],
    query: "",
    product: [],
    release: "",
    latestRelease: false,
    customerName: [],
    notPublished: false,
  };

  const [queryFilterState, setQueryFilterState] =
    useState<CustomDatasetFilterType>(initialQueryFilterState);

  const [loadingFilters, setLoadingFilters] = useState<boolean>(false);

 
  const {
    paginationModel,
    handleChangePaginationModel,
    searchQuery,
    queryKey,
  } = usePaginatedTableQuery<CustomDatasetList>(
    (limit: number, offset: number) => {
      return fetchAdminCustomDatasetsSearch(
        queryFilterState,
        identityState,
        limit,
        offset
      );
    },
    QueryKeys.adminSearchUserQuery,
    (identityState as User).cognitoSub,
    queryFilterState
  );

  const queryClient = useQueryClient();
  const customDatasetsManageMutation = useMutation(
    (values: any) => manageCustomDatasetsStatus(values, identityState),
    {
      onSuccess: () => {
        searchQuery.refetch();
      },
    }
  );

  const definitions = useQuery([QueryKeys.adminCustomDatasetDefinitions], () =>
    fetchAdminCustomDefinitions(identityState)
  );

  useEffect(() => {
    updateQueryAndFilters({
      savedSearch: savedSearch,
      setQueryFilterState,
      setLoadingFilters,
      initialQueryFilterState,
      definitions: definitions,
    });
  }, []);

  const getCustomRowLicence = (params: GridRowClassNameParams): string => {
    return "";
  };

  const customColumns_ = customColumns(identityState, queryKey, queryClient);

  const isSuccess = searchQuery.isSuccess;
  const isLoading = searchQuery.isLoading;
  const rowsData = searchQuery.data ? datasetsToRows(searchQuery.data) : [];

  return (
    <Box>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap: "16px",
          position: "relative",
        }}
      >
        <ReactQueryErrorWrapper
          queries={[searchQuery, definitions]}
          mutations={[customDatasetsManageMutation]}
        />

        <Card
          sx={{
            padding: "16px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <FilterComponent
            queryFilterState={queryFilterState}
            setQueryFilterState={setQueryFilterState}
            initialQueryFilterState={initialQueryFilterState}
            renderFilter={AdminCustomDatasetFilters}
            definitions={definitions}
            localStorageKey={localStorageKeys.searchAdminCustomDatasets}
          />
        </Card>
        <Card
          sx={{
            padding: "16px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "16px",
            }}
          >
            <LoadingButton
              variant="contained"
              loading={customDatasetsManageMutation.isLoading}
              onClick={() =>
                customDatasetsManageMutation.mutate({
                  ids: selectedIds,
                  status: "internal",
                })
              }
            >
              Unpublish
            </LoadingButton>
            <LoadingButton
              variant="contained"
              loading={customDatasetsManageMutation.isLoading}
              onClick={() =>
                customDatasetsManageMutation.mutate({
                  ids: selectedIds,
                  status: "published",
                })
              }
            >
              Publish
            </LoadingButton>
          </Box>
        </Card>

        <CustomServerPaginatedTable
          rowCount={searchQuery.data?.links?.numberMatched ?? -1}
          paginationModel={paginationModel}
          setPaginationModel={handleChangePaginationModel}
          getCustomRowClass={getCustomRowLicence}
          rows={isSuccess ? rowsData : []}
          cols={isLoading ? [] : customColumns_}
          isLoading={isLoading}
          overlayText={
            "We couldn't find any datasets that match your filter criteria."
          }
          checkboxSelection={true}
          onSelectionModelChange={(ids) => {
            setSelectedIds(ids as string[]);
          }}
        />
      </div>
    </Box>
  );
};
