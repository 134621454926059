import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as Routes from "routes";

import { useHistory } from "react-router-dom";
import { StepComponentProps } from "../step-config-file/types";

const useStyles = makeStyles({
  minimized: {
    width: "40px",
    height: "40px",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    alignSelf: "flex-end",
  },
});

export const WelcomeCard = (props: StepComponentProps) => {
  const history = useHistory();
  return (
    <>
      <Card
        style={{
          border: "none",
          boxShadow: "none",
          width: "660px",
          maxHeight: "100%",
          display: "flex",
          flexDirection: "column",
          alignSelf: "self-end",
          position: "fixed",
          left: "35%",
          bottom: "100px",
          borderRadius: "16px",
        }}
      >
        <CardHeader
          title={props.data.title}
          titleTypographyProps={{ variant: "h6" }}
          sx={{ paddingBottom: "0px !important", fontWeight: 500 }}
        />
        <CardContent
          sx={{
            padding: "16px !important",
          }}
        >
          <Typography variant="body1" paragraph>
            See flood risk through new eyes. Our interactive demonstration
            reveals how Brisbane's buildings and communities could be affected
            by flooding, using sophisticated modelling that goes beyond
            traditional risk assessments.
          </Typography>
          <Typography variant="h6">What makes this different?</Typography>
          <Typography variant="body1" paragraph>
            Most flood maps show you potential water levels. We take it further
            by incorporating First Floor Elevation data – meaning we can show
            you how flooding actually impacts buildings, not just the land they
            sit on.
          </Typography>
          <Typography variant="h6">What you'll see?</Typography>
          <Typography variant="body1">
            Explore detailed flood scenarios ranging from 5-year to 1000-year
            flood events. This fluvial (river) flooding model demonstrates:
            <ul>
              <li>
                How different areas of Brisbane respond to various flood
                intensities
              </li>
              <li>
                Which structures are truly at risk, based on their elevation
              </li>
              <li>
                The real-world implications of flooding on buildings and
                infrastructure
              </li>
            </ul>
            By considering First Floor Elevation, we provide a more accurate
            picture of flood vulnerability. This isn't just about where water
            might go - it's about understanding genuine impact on Brisbane's
            built environment. Watch as the model transforms raw data into
            actionable insights, helping planners, property owners, and
            communities make more informed decisions about flood preparedness.
          </Typography>
        </CardContent>
        <CardActions sx={{ padding: "8px" }}>
          <Button
            size="small"
            color="error"
            onClick={() => {
              history.push(Routes.hub);
            }}
          >
            Exit
          </Button>
          <Button
            size="small"
            color="primary"
            onClick={() => {
              props.moveToNextLocation({
                ...props.nextCoordinates,
                geocoder_attributes: {},
                zoomLevel: props.zoomLevel,
              });
              props.nextStep();
            }}
          >
            Explore
          </Button>
        </CardActions>
      </Card>
    </>
  );
};
