import {
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  Stack,
  Tooltip,
} from "@mui/material";
import { DataMapping } from "./choose-data";
import { Attribute } from "../../models";
import InfoIcon from "@mui/icons-material/Info";

interface AttributeTreeProps {
  dataMappings?: DataMapping[];
  selected: Attribute[];
  onChange: (checked: boolean, id: string, parentId?: string) => void;
}

export const AttributeTree = (props: AttributeTreeProps) => {
  const isSelected = (selected: Attribute[], id: string, parentId?: string) => {
    if (parentId) {
      const parent = selected.find((s) => s.key == parentId);
      if (parent) {
        //@ts-ignore
        return parent.attributes.indexOf(id) > -1;
      }
      return false;
    } else {
      const item = selected.find((s) => s.key == id);
      return !!item;
    }
  };

  const isParentSelected = (parentId: string) => {
    return isSelected(props.selected, parentId, undefined);
  };

  return (
    <Box display="flex" flexDirection="column" ml={3}>
      {props.dataMappings?.map((dataMapping, idx: number) => (
        <Box key={`data-tree-${idx}`}>
          <FormControlLabel
            sx={{ mr: 1 }}
            label={dataMapping.name}
            control={
              <Checkbox
                sx={{ padding: "4px" }}
                checked={isSelected(props.selected, dataMapping.id, undefined)}
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>,
                  checked: boolean
                ) => props.onChange(checked, dataMapping.id, undefined)}
              />
            }
          />
          {dataMapping.credits && (
            <Chip size="small" label={`${dataMapping.credits} Credits`} />
          )}
          <Box display="flex" flexDirection="column" ml={3}>
            {dataMapping.children?.map((child) => (
              <Stack direction="row">
                <FormControlLabel
                  sx={{ mr: 1 }}
                  label={child.name}
                  disabled={!isParentSelected(dataMapping.id)}
                  control={
                    <Checkbox
                      sx={{ padding: "4px" }}
                      checked={isSelected(
                        props.selected,
                        child.id,
                        dataMapping.id
                      )}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                        checked: boolean
                      ) => props.onChange(checked, child.id, dataMapping.id)}
                    />
                  }
                />
                {dataMapping.credits && (
                  <Chip size="small" label={`${child.credits} Credits`} />
                )}
                {child.tooltip && (
                  <Tooltip title={child.tooltip} placement="top">
                    <InfoIcon color="primary" />
                  </Tooltip>
                )}
              </Stack>
            ))}
          </Box>
        </Box>
      ))}
    </Box>
  );
};
