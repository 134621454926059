import {
  Modal,
  Typography,
  Button,
  Divider,
  Card,
  CardActions,
  CardContent,
  Alert,
  CardHeader,
  LinearProgress,
} from "@mui/material";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 1,
  display: "flex",
  flexDirection: "column",
};

const styleBatch = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
};

interface ModalProps {
  isLoading: boolean;
  open: boolean;
  header: string;
  body: string;
  body2?: string;
  isError?: boolean;
  leftButtonText: string;
  rightButtonText: string;
  leftButtonDisabled?: boolean;
  rightButtonDisabled?: boolean;
  errorTitle?: string;
  errorMessage?: string;
  leftButtonColor:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
  rightButtonColor:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
  handleLeftButton: any;
  handleRightButton: any;
  handleClose: () => void;
  type?: string;
  children?: React.ReactNode;
}

export default function ConfirmationModal(props: ModalProps) {
  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {props.isLoading ? (
        loadingModal()
      ) : (
        <>{props.isError ? errorModal(props) : baseModal(props)}</>
      )}
    </Modal>
  );
}

function errorModal(props: ModalProps) {
  const errorTitle = props.errorTitle ? props.errorTitle : "Error";
  const errorMessage = props.errorMessage
    ? props.errorMessage
    : "An error occurred while processing the request.";

  return (
    <Card sx={style}>
      <CardHeader title={errorTitle}></CardHeader>
      <CardContent>
        <Alert severity="error">{errorMessage}</Alert>
        {props?.children}
      </CardContent>
      <CardActions style={{ justifyContent: "flex-end", flexGrow: 1 }}>
        <Button
          onClick={props.handleClose}
          variant="outlined"
          color="primary"
          size="medium"
        >
          Close
        </Button>
      </CardActions>
    </Card>
  );
}

function baseModal(props: ModalProps) {
  return (
    <Card sx={style}>
      {props.type == "Batch" ? (
        <>
          <CardHeader
            title={props.header}
            subheader={props.body}
            sx={{ paddingBottom: 0 }}
          />

          {props?.children}
          <CardContent>
            <Typography
              id="modal-modal-description"
              variant="body1"
              color="rgba(0, 0, 0, 0.60)"
            >
              <b> {props.body2}</b>
              <br />
            </Typography>
          </CardContent>
          <Divider />
        </>
      ) : (
        <CardContent>
          <Typography variant="h5" component="h5">
            {props.header}
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            variant="body2"
          >
            {props.body}
          </Typography>
          {props?.children}
        </CardContent>
      )}

      <Divider />
      <CardActions style={{ justifyContent: "flex-end", flexGrow: 1 }}>
        <Button
          disabled={props.leftButtonDisabled ? props.leftButtonDisabled : false}
          onClick={props.handleLeftButton}
          variant="outlined"
          color={props.leftButtonColor}
          size="medium"
        >
          {props.leftButtonText}
        </Button>
        <Button
          disabled={
            props.rightButtonDisabled ? props.rightButtonDisabled : false
          }
          onClick={props.handleRightButton}
          variant="outlined"
          color={props.rightButtonColor}
          size="medium"
        >
          {props.rightButtonText}
        </Button>
      </CardActions>
    </Card>
  );
}
function loadingModal() {
  return (
    <Card sx={style}>
      <CardHeader title="Processing"></CardHeader>
      <CardContent style={{ justifyContent: "center" }}>
        <LinearProgress />
        <Typography sx={{ mt: 2 }} variant="body2">
          Please wait while your request is being processed.
        </Typography>
      </CardContent>
    </Card>
  );
}
