
const formatPrice = (price: number, maximumFractionDigits: number=2) => {
    return price.toLocaleString('en-AU', {currency: 'AUD', style: 'currency', maximumFractionDigits: maximumFractionDigits});
}

const formatInterval = (interval: string): string => {
    interval = interval.toLowerCase()
    const options: {[key: string]: string} = {
        "month": "Monthly",
        "annual": "Annually",
        "year": "Yearly",
    }
    return options[interval]
}


const sentenceCase = (str: string) => {
    const sentenceCase = str
      .split(" ")
      .map((w) => w[0].toUpperCase() + w.substring(1).toLowerCase())
      .join(" ");
    return sentenceCase;
  };
  
export {
    formatPrice,
    formatInterval,
    sentenceCase
}
