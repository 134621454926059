import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as Routes from "routes";

import { useHistory } from "react-router-dom";
import { StepComponentProps } from "../step-config-file/types";

const useStyles = makeStyles({
  minimized: {
    width: "40px",
    height: "40px",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    alignSelf: "flex-end",
  },
});

export const WelcomeCard = (props: StepComponentProps) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <>
      <Card
        style={{
          border: "none",
          boxShadow: "none",
          width: "450px",
          maxHeight: "100%",
          display: "flex",
          flexDirection: "column",
          alignSelf: "self-end",
          position: "fixed",
          left: "40%",
          bottom: "30%",
        }}
      >
        <CardHeader
          title={props.data.title}
          titleTypographyProps={{ variant: "h6" }}
        />
        <CardContent>
          <Typography variant="body1" paragraph>
            {props.data.description?.paragraph_one}
          </Typography>
          <Typography variant="body1" paragraph>
            {props.data.description?.paragraph_two}
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            size="small"
            color="error"
            style={{ marginRight: 8 }}
            onClick={() => {
              history.push(Routes.demosPage);
            }}
          >
            Exit
          </Button>
          <Button
            size="small"
            color="primary"
            // variant="contained"
            onClick={() => {
              props.moveToNextLocation({
                ...props.nextCoordinates,
                geocoder_attributes: {},
                zoomLevel: props.zoomLevel,
              });
              props.nextStep();
            }}
          >
            Explore
          </Button>
        </CardActions>
      </Card>
    </>
  );
};
