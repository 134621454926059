import {
  Box,
  Card,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import GeoscapeDeveloperSvg from "icons/hub-logo-signIn.svg?react";

interface CallToActionItem {
  heading: string;
  text?: string;
  icon?: any;
}

export const CallToActionList = (props: {
  callToActions: CallToActionItem[];
}) => {
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";
  return (
    <Card
      sx={{
        // height: "500px",
        width: "432px",
        padding: "16px",
        border: "none",
        boxShadow: "none",
        background: isDark ? "black" : "white",
      }}
    >
      <Box
        display="flex"
        justifyContent="start"
        sx={{
          backgroundColor: isDark ? "black" : "white",
          paddingBottom: "16px",
        }}
      >
        <GeoscapeDeveloperSvg
          fill={isDark ? "white" : "black"}
          style={{ width: "228", height: "52px", padding: "0px" }}
        />
      </Box>
      <Typography variant="h6">
        Start now with our Freemium offer. No credit card required!
      </Typography>
      <List>
        {props.callToActions.map((callToActionItem) => (
          <ListItem sx={{ padding: "16px" }}>
            {callToActionItem.icon !== false ? (
              <>
                <ListItemAvatar>{callToActionItem.icon}</ListItemAvatar>
                <ListItemText
                  primary={callToActionItem.heading}
                  secondary={callToActionItem.text}
                />
              </>
            ) : (
              ""
            )}
            {/* <Box>
              <Typography variant="h6">{callToActionItem.heading}</Typography>
              <Typography>{callToActionItem.text}</Typography>
            </Box> */}
          </ListItem>
        ))}
      </List>
    </Card>
    // </Box>
  );
};
