import {
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import * as Routes from "routes";
import { FormState, Style } from "../step-config-file/types";
// import { FormState } from "./types";

interface FormComponentProps {
  formState: FormState;
  onFormChange: (newState: Partial<FormState>) => void;
}

export const ActionCard: React.FC<FormComponentProps> = ({
  formState,
  onFormChange,
}) => {
  // This Action Card controls a state that modify the layer style
  const history = useHistory();
  const { showNewMessage } = useIntercom();

  const handleSelectChange = useCallback(
    (event: SelectChangeEvent<number | string>) => {
      // This update a
      const { name, value } = event.target;

      let updatedState: Partial<FormState> = {
        [name]: name === "people" ? Number(value) : value,
      };

      console.log("updatedState", updatedState);
      onFormChange(updatedState);
    },
    [formState, onFormChange]
  );

  interface MenuItemData {
    key: string;
    value: string;
  }

  // MenuItemData This contains the properties that will be needed to be mapped from the Emisison metrics Geo-dataset
  const menuItems: MenuItemData[] = [
    { key: "5 Year", value: Style.five_year },
    { key: "10 Year", value: Style.ten_year },
    { key: "20 Year", value: Style.twenty_year },
    { key: "50 Year", value: Style.fifty_year },
    { key: "100 Year", value: Style.hundred_year },
    { key: "200 Year", value: Style.two_hundred_year },
    { key: "500 Year", value: Style.five_hundred_year },
    { key: "1000 Year", value: Style.thousand_year },
  ];

  return (
    <Card sx={{ borderRadius: 2, margin: "0px" }}>
      <CardContent
        sx={{
          width: 170,
          margin: "0px",
          border: "px solid #ccc",
        }}
      >
        <FormControl fullWidth sx={{ mb: 1.5 }}>
          <InputLabel>Flood</InputLabel>
          <Select
            name="style"
            value={formState.style}
            label="Style By"
            onChange={handleSelectChange}
            size="small"
          >
            {menuItems.map((s) => (
              <MenuItem value={s.value}>{s.key}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          variant="outlined"
          color="warning"
          size="small"
          fullWidth
          onClick={() => {
            history.push(Routes.hub);
          }}
          sx={{
            border: "none",
            state: "enabled",
            justifyContent: "flex-start",
            padding: "0px",
            mb: 1,
            ":hover": {
              backgroundColor: "none",
              border: "none",
            },
            ":focus": {
              backgroundColor: "none",
              border: "none",
            },
          }}
        >
          EXIT
        </Button>

        <Button
          variant="outlined"
          color="primary"
          size="small"
          fullWidth
          onClick={() =>
            showNewMessage(`I’d like to talk to someone about “Emissions Data"`)
          }
          sx={{
            border: "none",
            justifyContent: "flex-start",
            padding: "0px",
            mb: 0,
            ":hover": {
              backgroundColor: "none",
              border: "none",
            },
            ":focus": {
              backgroundColor: "none",
              border: "none",
            },
          }}
        >
          ASK A QUESTION
        </Button>
      </CardContent>
    </Card>
  );
};
