import { DateRange } from "@mui/x-date-pickers-pro/models";
import { PickersShortcutsItem } from "@mui/x-date-pickers/PickersShortcuts";
import dayjs from "dayjs";

export const shortcutsItems: PickersShortcutsItem<DateRange<dayjs.Dayjs>>[] = [
  {
    label: "Today",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("day"), today.endOf("day")];
    },
  },
  {
    label: "Yesterday",
    getValue: () => {
      const yesterday = dayjs().subtract(1, "day");
      return [yesterday.startOf("day"), yesterday.endOf("day")];
    },
  },
  {
    label: "Last 7 Days",
    getValue: () => {
      const today = dayjs();
      return [today.subtract(6, "day"), today];
    },
  },
  {
    label: "Last 14 Days",
    getValue: () => {
      const today = dayjs();
      return [today.subtract(13, "day"), today];
    },
  },
  {
    label: "Last 30 Days",
    getValue: () => {
      const today = dayjs();
      return [today.subtract(29, "day"), today];
    },
  },
  {
    label: "Last Month",
    getValue: () => {
      const today = dayjs();
      const startOfLastMonth = today.subtract(1, "month").startOf("month");
      const endOfLastMonth = today.subtract(1, "month").endOf("month");
      return [startOfLastMonth, endOfLastMonth];
    },
  },
  {
    label: "Last Quarter",
    getValue: () => {
      const today = dayjs();
      const startOfLastQuarter = today
        .subtract(1, "quarter")
        .startOf("quarter");
      const endOfLastQuarter = today.subtract(1, "quarter").endOf("quarter");

      return [startOfLastQuarter, endOfLastQuarter];
    },
  },
  {
    label: "Current Month",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("month"), today.endOf("day")];
    },
  },
  {
    label: "Current Quarter",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("quarter"), today.endOf("day")];
    },
  },
  {
    label: "Year to Date",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("year"), today.endOf("day")];
    },
  },
];
