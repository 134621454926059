import { Box, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";

interface ShowMoreProps {
  buttonShowMoreLabel?: string;
  buttonShowLessLabel?: string;
  buttonVariant?: "text" | "outlined" | "contained";
  buttonColor?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
  buttonSize?: "small" | "medium" | "large";
  lines?: number;
  showButton?: boolean;
  children: React.ReactNode;
}

export const ShowMore = (props: ShowMoreProps) => {
  const [showMore, setShowMore] = useState(false);

  const buttonShowMoreLabel: string = props.buttonShowMoreLabel ?? "Show More";
  const buttonShowLessLabel: string = props.buttonShowLessLabel ?? "Show Less";
  const buttonVariant = props.buttonVariant ?? "text";
  const buttonColor = props.buttonColor ?? "primary";
  const buttonSize = props.buttonSize ?? "small";
  const lines = props.lines ?? 5;
  const showButton = props.showButton ?? true;

  const useStyles = makeStyles({
    showMore: {},
    showLess: {
      display: "-webkit-box",
      overflow: "hidden",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: lines,
    },
  });

  const classes = useStyles();

  return (
    <Box>
      <Box className={showMore ? classes.showMore : classes.showLess}>
        {props.children}
      </Box>
      {showButton && (
        <Button
          onClick={() => setShowMore(!showMore)}
          variant={buttonVariant}
          size={buttonSize}
          sx={{ mt: 2 }}
          color={buttonColor}
        >
          {showMore ? buttonShowLessLabel : buttonShowMoreLabel}
        </Button>
      )}
    </Box>
  );
};
