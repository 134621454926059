import { Typography } from "@mui/material";

import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 240,
    },
  },
};

export const MultiSelectFormControl = ({
  id,
  label,
  options,
  value,
  onChange,
}: any) => {
  return (
    <FormControl
      className="datasets-filer-name"
      size="small"
      style={{
        marginLeft: "0px",
        maxWidth: "238px",
        marginRight: "16px",
        flex: 1,
      }}
    >
      <InputLabel id={`${id}-label`}>{label} </InputLabel>
      <Select
        labelId={`${id}-label`}
        id={id}
        name={id}
        multiple
        value={value}
        onChange={onChange}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) => selected.join(", ")}
        MenuProps={MenuProps}
      >
        {options.map((name: string) => {
          return (
            <MenuItem key={name} value={name}>
              <Checkbox
                checked={value.indexOf(name) > -1}
                style={{ paddingLeft: "0px" }}
              />
              <ListItemText
                primary={
                  <Typography
                    component="div"
                    variant="body1"
                    style={{ whiteSpace: "pre-line" }}
                  >
                    {name}
                  </Typography>
                }
              />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
