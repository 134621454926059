const padZero = (value: string): string =>
  value.length < 2 ? `0${value}` : value;

export const formatDateTime = (date: Date): string => {
  /**
   * Format an date into a string in the format:
   * dd/mm/yyyy - hh:mm
   * Pads with zeros to fill; time in 24 hours
   */
  const month = padZero(`${date.getMonth() + 1}`);
  const day = padZero(`${date.getDate()}`);
  const year = date.getFullYear();
  const hour = padZero(`${date.getHours()}`);
  const minutes = padZero(`${date.getMinutes()}`);
  return `${[day, month, year].join(`/`)} - ${hour}:${minutes}`;
};

export function formatDate(date: string) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const [year, month, day] = date.split("-");
  const monthIndex = parseInt(month) - 1;

  const formattedDate = `${parseInt(day)} ${months[monthIndex]}, ${parseInt(
    year
  )}`;
  return formattedDate;
}

/**
 * Convert the millisecond Epoch timestamp to a human readable form (i.e. "Feb 02, 2024").
 * @param timestamp Epoch time in milliseconds
 * @returns
 */
export const formatTimestamp = (timestamp: number): string => {
  if (timestamp === -1) {
    return "Never";
  }

  return new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  }).format(timestamp);
};



export const formatTimestampBatchJob = (timestamp: string) => {
  const seconds = +timestamp.split(":")[2];
  const minutes = +timestamp.split(":")[1] + (seconds > 0 ? 1 : 0);
  const hours = +timestamp.split(":")[0];

  if (hours == 0 && minutes == 0) {
    return "1 minute";
  }

  var formattedTimestamp = (hours ? `${hours} hour${hours > 1 ? "s" : ""} ` : "") +
    (minutes ? `${minutes} minute${minutes > 1 ? "s" : ""}` : "");
  return formattedTimestamp;
};