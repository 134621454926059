import { Box } from "@mui/material";
import * as Routes from "routes";
import { useHistory } from "react-router";
import { HubDashboardCard } from "pages/shared/hub-dashboard-card";

export const DashboardCardsReleaseManagement = () => {
  const history = useHistory();

  const rowOneContent = [
    {
      title: "Create a new Release",
      subtitle: "Admin",
      actionName: "Create",
      actionFunc: () => history.push(Routes.adminCreateRelease),
      actionDisabled: false,
    },
    {
      title: "List Releases",
      subtitle: "Admin",
      actionName: "List",
      actionFunc: () => history.push(Routes.adminReleases),
      actionDisabled: false,
    },
    {
      title: "View and Manage Individual Dataset",
      subtitle: "Admin",
      actionName: "View & Manage",
      actionFunc: () => history.push(Routes.adminDatasets),
      actionDisabled: false,
    },
  ];

  return (
    <>
      <div>
        <Box style={boxCardStyle}>
          {rowOneContent.map((key) => {
            return (
              <HubDashboardCard
                key={key.title}
                title={key.title}
                subtitle={key.subtitle}
                actionName={key.actionName}
                actionFunc={key.actionFunc}
                actionDisabled={key.actionDisabled}
                cardStyle={rowOneStyle}
              />
            );
          })}
        </Box>
      </div>
    </>
  );
};

const boxCardStyle: any = {
  display: "flex",
  width: "100%",
  alignItems: "flex-start",
  gap: "20px",
  justifyContent: "space-between",
  flexWrap: "wrap",
};

const rowOneStyle: any = {
  height: "100%",
  marginBottom: "auto",
  marginTop: "20px",
  display: "flex",
  flexGrow: 1,
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "16px",
};
