import circle from '@turf/circle';
import length from '@turf/length';
import { modes } from '@mapbox/mapbox-gl-draw';
import {center, area} from "@turf/turf"

import { getDisplayMeasurements } from '../utils/util'

function circleFromTwoVertexLineString(geojson) {
  const center = geojson.geometry.coordinates[0];
  const radiusInKm = length(geojson);

  return circle(center, radiusInKm);
}

export const CircleMode = {
  ...modes.draw_line_string,
  _onSetup: modes.draw_line_string.onSetup,
  onSetup: function(opts){

    const isUpdate = !!opts.replaceFeature
    let newLine = null

    if(isUpdate){
      const feature = opts.replaceFeature
      // changeMode opts expects a featureId of an existing feature so we make a new radius line to edit in draw_circle mode
      const from = center(feature).geometry.coordinates

      const radiusLineFeature = this.newFeature({type: "Feature", properties: {}, geometry: { type: "LineString", coordinates: [from]}})
      radiusLineFeature.id = feature.properties.radiusLineId
      this.addFeature(radiusLineFeature)
      newLine = this._onSetup({featureId: radiusLineFeature.id, from})
      newLine.line.properties.relatedCircleId = opts.circleId

    }else{
      newLine = this._onSetup()
    }
    newLine.line.properties.frame = 0

    return newLine
  },

  clickAnywhere: function (state, e) {
    // this ends the drawing after the user creates a second point, triggering this.onStop
    if (state.currentVertexPosition === 1) {
      state.line.addCoordinate(0, e.lngLat.lng, e.lngLat.lat);
      return this.changeMode('simple_select', { featureIds: [state.line.id] });
    }

    state.line.updateCoordinate(
      state.currentVertexPosition,
      e.lngLat.lng,
      e.lngLat.lat
    );
    if (state.direction === 'forward') {
      state.currentVertexPosition += 1;
      state.line.updateCoordinate(
        state.currentVertexPosition,
        e.lngLat.lng,
        e.lngLat.lat
      );
    } else {
      state.line.addCoordinate(0, e.lngLat.lng, e.lngLat.lat);
    }

    return null;
  },

  onStop: function (state) {


    this.activateUIButton();

    // check to see if we've deleted this feature
    if (this.getFeature(state.line.id) === undefined) return;

    // remove last added coordinate
    state.line.removeCoordinate('0');

    if (state.line.isValid()) {
      const lineGeoJson = state.line.toGeoJSON();
      const newGeo = circleFromTwoVertexLineString(lineGeoJson)

      // If we are editing an existing circle, the relatedCircleId will be populated in onSetup
      const isUpdate = !!state.line.properties.relatedCircleId

      const circleFeature = this.newFeature(newGeo);
      if (isUpdate){
        // If its an update we still create a new circle, but give it the same ID as the old one
        circleFeature.id = state.line.properties.relatedCircleId
      }
      circleFeature.properties.isCircle = true;
      circleFeature.properties.radiusLineId = state.line.id;
      state.line.properties.relatedCircleId = circleFeature.id
      this.addFeature(circleFeature);
      if(isUpdate){
        this.map.fire('draw.update',{
          features: [circleFeature.toGeoJSON()]
        })
      }else {
        this.map.fire('draw.create', {
          features: [circleFeature.toGeoJSON()]
        });
      }

      // We can recreate the line later if we want to edit again
      this.deleteFeature(state.line.id, { silent: true });

      
    } else {
      this.deleteFeature([state.line.id], { silent: true });
      this.changeMode('simple_select', {}, { silent: true });
    }
  },

  toDisplayFeatures: function (state, geojson, display) {


    // Horrible hack to stop the ghost circle appearing when mouseMove fires in the same frame as the click
    // Also if the line isnt long enough to do anything yet
    state.line.properties.frame += 1
    if(geojson.geometry.coordinates.length < 2 || geojson.geometry.type !== "LineString"){
      display(geojson);
      return null
    }



    display({
      type: 'Feature',
      properties: {
        active: 'true'
      },
      geometry: {
        type: 'Point',
        coordinates: geojson.geometry.coordinates[0]
      }
    });

    // displays the line as it is drawn
    geojson.properties.active = 'true';
    display(geojson);

    
    const displayMeasurements = getDisplayMeasurements(geojson);

    const circleFeature = circleFromTwoVertexLineString(geojson);

    circleFeature.properties = {
      active: 'true'
    };


    // create custom feature for the current pointer position
    const currentVertex = {
      type: 'Feature',
      properties: {
        meta: 'currentPosition',
        radius: `${displayMeasurements.metric} \n ${(area(circleFeature) / 1000000).toLocaleString('en-AU', {maximumFractionDigits: 1})} km2`,
        parent: state.line.id
      },
      geometry: {
        type: 'Point',
        coordinates: geojson.geometry.coordinates[1]
      }
    };

    display(currentVertex);

    if(state.line.properties.frame > 5){
      display(circleFeature);
    }

    return null;
  }
};

