import { number } from "yup";
import { ChangeType, ProductIds } from "./types";
import { string } from "yup";
import { object } from "yup";
import { FutureSubscription, PaymentMethod } from "data/models";

const updateAdminUserSubscriptionValidationSchema = object({
  subscriptionCadence: string().oneOf(["month", "year"]),
  method: string().oneOf(["None", "Stripe", "MYOB"]),
  creditAllowance: number().min(20_000).max(500_000_000),
  overageLimitDollars: number().min(0).max(10_000),
  overageLimitCredits: number().min(0).max(1_000_000),
  rateLimit: number().min(2).max(1_000),
});

const isDisabledTerm = (productId: string, productIds: ProductIds) => {
  // Term is not editable if the plan is freemium or enterprise
  return (
    productId === productIds.freemium || productId === productIds.enterprise
  );
};

const isDisabledRateLimit = (productId: string, productIds: ProductIds) => {
  // Rate limit is not editable if the plan is not enterprise
  return productId !== productIds.enterprise;
};

const isDisabledAllowance = (productId: string, productIds: ProductIds) => {
  // Allowance is not editable if the plan is not enterprise
  return productId !== productIds.enterprise;
};

const isDisabledBudgetDollars = (
  productId: string,
  method: string,
  productIds: ProductIds
) => {
  return method === "None" || productId === productIds.enterprise;
};

const isDisabledBudgetCredits = (
  productId: string,
  method: string,
  productIds: ProductIds
) => {
  return method === "None" || productId === productIds.enterprise;
};

const isDisabledChargeOverage = (overageUsageCredits: number) => {
  return overageUsageCredits === 0;
};

const isDisabledApply = (
  paymentService: string,  // 
  paymentMethod: string,
  changeType: ChangeType,
  nextSub: FutureSubscription,
  isEnterprise: boolean,
  paymentServiceChanged: boolean,
  
) => {

  // paymentMethod - The customer has a Card or not
  // paymentService - Selected in the UI
  // TODO: Should probably check the payment method based on the expiry date as well


  if (nextSub) {
    return true;
  } // Subscription scheduled

  if (isEnterprise) {
    return false
  };

  if (paymentServiceChanged && paymentService === "myob") {
    return false
  }; // Pro Myob to Freemiun None & No payment method


  // if (paymentServiceChanged && paymentService === "None") {
  //   return true
  // }; // This case is for Freemiun Stripe to Freemiun None - Affect Any Downgrade to Freemiun paymentService None


  if (paymentServiceChanged && paymentService === "stripe" && paymentMethod === "No payment method") {
    return true
  };   // Pro Myob to Pro Stripe & No payment method

  if (paymentServiceChanged) {
    return false
  }; // Pro Myob to Pro Stripe



  if (paymentService === "stripe" && paymentMethod === "No payment method") {
    return true;
  } // Any Upgrade

  if (changeType === "same" ) {
    return true;
  }

  
  // Downgrade -> False


  return false;
};

export {
  updateAdminUserSubscriptionValidationSchema,
  isDisabledTerm,
  isDisabledRateLimit,
  isDisabledAllowance,
  isDisabledBudgetDollars,
  isDisabledBudgetCredits,
  isDisabledChargeOverage,
  isDisabledApply,
};
