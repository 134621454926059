import { Box } from "@mui/material";
import OverageInfo from "components/settings/shared/overage/overageInfo";

export const Overage = () => {

  return (
    <Box>
      <OverageInfo isUserPage={false} />
    </Box>
  );
};
