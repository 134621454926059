import { useCustomSnackbars } from "components/snackbars/useCustomSnackbars";
import * as QueryKeys from "data";
import { fetchAuthJWT, fetchMapsLayerJson } from "data/queries";
import { Expression } from "mapbox-gl";
import { TileJSON } from "pages/geoscape-data/explorer/layers/models";
import { useState } from "react";
import { FillLayer, Layer, LineLayer, Source } from "react-map-gl";
import { useQuery } from "react-query";

interface CadastreSourceProps {
  cadastrePid: string;
}

export const CadastreSource = (props: CadastreSourceProps) => {
  const [cadastreLayerJson, setCadastreLayerJson] = useState<TileJSON | null>(
    null
  );
  const { enqueueQueryFailed } = useCustomSnackbars();

  const jwt = useQuery([QueryKeys.jwt], () => fetchAuthJWT(), {
    onError: (error: Error) => {
      enqueueQueryFailed(error.toString());
    },
  });

  const cadastreLayerJsonQuery = useQuery(
    [QueryKeys.mapsLayerJson, "cadastre"],
    () => fetchMapsLayerJson(jwt.isSuccess ? jwt.data : "", "cadastre"),
    {
      enabled: !!jwt.data,
      retry: true,
      onSuccess: (json: TileJSON) => {
        setCadastreLayerJson(json);
      },
    }
  );

  const matchExpressionColorLowZoom: Expression = [
    "match",
    ["get", "cadastre_pid"],
    ...[props.cadastrePid, "hsla(0, 0%, 40%, 1)"],
    "hsla(0, 0%, 40%, 0)", // Default color for lines not matching
  ];

  const matchExpressionColorHighZoom: Expression = [
    "match",
    ["get", "cadastre_pid"],
    ...[props.cadastrePid, "hsla(0, 0%, 40%, 1)"],
    "hsla(0, 0%, 40%, 0.5)", // Default color for lines not matching
  ];

  const matchExpressionBorderLowZoom: Expression = [
    "match",
    ["get", "cadastre_pid"],
    ...[props.cadastrePid, 1],
    0.5,
  ];

  const matchExpressionBorderHighZoom: Expression = [
    "match",
    ["get", "cadastre_pid"],
    ...[props.cadastrePid, 4],
    1,
  ];

  const cadastreLineLayer: LineLayer = {
    id: "cadastre_line",
    type: "line",
    source: "cadastre",
    "source-layer": "cadastre",
    paint: {
      "line-color": [
        "interpolate",
        ["linear"],
        ["zoom"],
        14,
        matchExpressionColorLowZoom, // Base width at zoom 14
        16,
        matchExpressionColorHighZoom, // Same width at max zoom
      ],
      "line-width": [
        "interpolate",
        ["exponential", 2],
        ["zoom"],
        14,
        matchExpressionBorderLowZoom, // Base width at zoom 14
        16,
        matchExpressionBorderHighZoom, // Same width at max zoom
      ],
    },
  };

  const cadastreInvisibleFillLayer: FillLayer = {
    id: "cadastre_fill",
    type: "fill",
    source: "cadastre",
    "source-layer": "cadastre",
    paint: {
      "fill-color": "yellow",
      "fill-opacity": 0, // Invisible
    },
  };

  const addAuthToTileUrl = (tileUrl: string[]) => {
    return tileUrl.map((x) => `${x}?key=${jwt.data}`);
  };

  return (
    <>
      {cadastreLayerJson && (
        <Source
          key="cadastre"
          id="cadastre"
          type="vector"
          maxzoom={16}
          minzoom={14}
          tiles={addAuthToTileUrl(cadastreLayerJson.tiles)}
        >
          <Layer {...cadastreLineLayer} />
          <Layer {...cadastreInvisibleFillLayer} />
        </Source>
      )}
    </>
  );
};
