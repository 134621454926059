import { Box, Button, Chip, IconButton } from "@mui/material";

import { CustomDataset, CustomDatasetList, DatasetStatus } from "data/models";

import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { fetchAdminCustomDatasetsLink } from "data/queries";
import { formatBytes } from "utils/commons";

import { styled } from "@mui/styles";
import { deleteCustomDataset } from "data/mutations";
import { QueryClient } from "react-query";
import { formatDate } from "utils";

import { GridColDef, GridRenderCellParams, GridValidRowModel } from "@mui/x-data-grid-premium";
import { useCustomSnackbars } from "components/snackbars/useCustomSnackbars";

const ResponsiveButton = styled(Button)({
  "@media (max-width: 1370)": {
    fontSize: "10px",
    // padding: '12px 24px',
  },
  "@media (max-width: 1500px)": {
    fontSize: "10px",
  },
  "@media (min-width: 1500px)": {
    fontSize: "12px",
  },
});

type Dictionary = {
  [key: string]: string;
};

const DisplayFormatMapping: Dictionary = {
  GDB: "GDB",
  GEOJSON: "GeoJSON",
  SHP: "Shapefile",
  TAB: "MapInfo TAB",
  LYNX: "LYNX",
  PUMP: "PUMP",
  GEOTIFF: "GEOTIFF",
  PSV: "Pipe-Separated Values",
};

const DisplayStateMapping: Dictionary = {
  "ALL STATES": "All States",
  AUSTRALIA: "Australia",
};

const renderStatusChip = (status: DatasetStatus) => {
  if (status === "loading") return <Chip label={"Loading"} color="secondary" />;
  else if (status === "internal")
    return <Chip label={"Internal"} color="info" />;
  else if (status === "published")
    return <Chip label={"Published"} color="success" />;
  else if (status === "pending_published")
    return <Chip label={"Pending Published"} color="primary" />;
  else return <Chip label={status} />;
};

export const isDownloadable = (status: DatasetStatus) => {
  return status === "published" || status === "internal";
};

const customColumns = (
  identityState: any,
  searchQueryKey: any,
  queryClient: QueryClient
): GridColDef[] => {
  const { enqueueLinkCorruptedSnackbar } = useCustomSnackbars();

  return [
    {
      flex: 1.5,
      field: "name",
      headerName: "Dataset",
    },
    {
      flex: 1.5,
      field: "customerName",
      headerName: "Customer",
    },
    {
      flex: 1,
      field: "region",
      headerName: "Region",
      valueFormatter(params) {
        return DisplayStateMapping[params]
          ? DisplayStateMapping[params]
          : params;
      },
    },
    {
      flex: 1,
      field: "format",
      headerName: "Format",
      valueFormatter(params) {
        return DisplayFormatMapping[params]
          ? DisplayFormatMapping[params]
          : params;
      },
    },
    {
      flex: 1,
      field: "datum",
      headerName: "Datum",
    },
    {
      flex: 1.2,
      field: "release",
      headerName: "Release Date",
      valueFormatter(params) {
        if (params) {
          const [year, month] = (params as string).split("-").map(Number);

          const monthName = new Date(year, month - 1).toLocaleString(
            "default",
            {
              month: "long",
            }
          );
          return `${monthName} ${year}`;
        }
        return "";
      },
    },
    {
      flex: 1,
      field: "publishDate",
      headerName: "Publish Date",
      valueFormatter(params) {
        if (params) return formatDate(params as string);
        return "";
      },
    },
    {
      flex: 0.8,
      field: "version",
      headerName: "Version",

      renderCell: (params: GridRenderCellParams) => {
        if (params.value) return <Chip label={params.value} />;
        return "";
      },
    },

    {
      flex: 1,
      field: "size",
      headerName: "File Size",
      valueFormatter(params) {
        return formatBytes(params as number);
      },
    },
    {
      flex: 1,
      field: "creator",
      headerName: "Creator",
    },

    {
      flex: 1,
      field: "status",
      headerName: "Status",
      renderCell: (params: GridRenderCellParams) =>
        renderStatusChip(params.row.status),
    },
    {
      flex: 1.2,
      field: "id",
      headerName: "Actions",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Box sx={{ display: "block", gap: 0 }}>
            <IconButton
              color="info"
              onClick={async () => {
                try {
                  const response = await fetchAdminCustomDatasetsLink(
                    params.value,
                    identityState
                  );

                  const a = document.createElement("a");
                  a.href = response;
                  document.body.appendChild(a);
                  a.click();
                  document.body.removeChild(a);
                  window.URL.revokeObjectURL(response);
                } catch (er) {
                  enqueueLinkCorruptedSnackbar();
                  // If this happens it will show and popup
                }
              }}
            >
              <FileDownloadIcon />
            </IconButton>

            <IconButton
              disabled={params.row.status != "internal"}
              color="error"
              onClick={async () => {
                try {
                  const response = await deleteCustomDataset(
                    params.value,
                    identityState
                  );

                  if (response.id != null) {
                    queryClient.setQueryData(searchQueryKey, (oldData: any) => {
                      return {
                        ...oldData,
                        datasets: oldData.datasets.filter((dataset: any) => {
                          return dataset.id !== params.value;
                        }),
                      };
                    });
                  }
                } catch (er) {
                  enqueueLinkCorruptedSnackbar();
                  // If this happens it will show and popup
                }
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];
};

const datasetsToRows = (
  datasetsList: CustomDatasetList
): GridValidRowModel[] => {
  const rows = datasetsList.datasets
    .map((key: CustomDataset) => {
      return {
        id: key.id,
        name: key.name,
        product: key.product,
        region: key.region,
        version: key.version,
        creator: key.creator,
        format: key.format,
        size: key.size,
        release: key.release,
        publishDate: key.publishDate,
        status: key.status,
        datum: key.datum,
        customerName: key.customerName,
      };
    })
    .sort((a, b) => (a.name < b.name ? -1 : 1));

  return rows;
};

export {
  customColumns,
  datasetsToRows,
  DisplayFormatMapping,
  DisplayStateMapping,
  renderStatusChip,
  ResponsiveButton,
};

