import type { CognitoUser } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import { UserMembership } from "data/models";
import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useCookies } from "react-cookie";
import { IntercomProps } from "react-use-intercom";
import { Either, left, right } from "utils";
import { makeApiRequestToUrl } from "utils/api-client";

export interface User {
  firstName: string;
  lastName: string;
  email: string;
  isAdmin: boolean;
  organisations: Organisation[];
  cognitoSub: string; // the OAUTH2.0 cognito subject
}

export interface Organisation {
  name: string;
  id: string; // needed for making API calls on behalf of the org // APIGEE ID
  active: boolean;
  abn?: string;
}

// represents the state of identify of a user using the portal, who they are and what they're acting as
export type IdentityState = User | false | "loading";

export const GetUserIfLoggedIn = (
  identityState: IdentityState
): User | false => {
  if (identityState === "loading" || identityState === false) {
    return false;
  }
  return identityState;
};

export const isAdminIdentity = (identityState: IdentityState): boolean => {
  const user = GetUserIfLoggedIn(identityState);
  if (user !== false && !getActiveOrganisation(identityState)) {
    return user.isAdmin;
  }
  return false;
};

export const UserContext = createContext<IdentityState>(false);

export const switchedOrganisationCookieName = "switched-organisation";

export function useIdentity(): [
  IdentityState,
  Dispatch<SetStateAction<IdentityState>>
] {
  // @ts-ignore: might be able to fix this later, need to more understand context typing (could be createContext<[S, D?]>)
  const [userState, setUserState] = useContext(UserContext) as [
    IdentityState,
    Dispatch<SetStateAction<IdentityState>>
  ];

  return [userState, setUserState];
}

// need figure out what type children is meant to be and assign it. Node? ChildNode? ReactChildren? Element? WHAT ARE YOU?!
export const IdentityProvider = (children: any) => {
  const [identityState, setIdentityState] = useState<IdentityState>("loading");
  const [cookies] = useCookies([switchedOrganisationCookieName]);
  const organisationCookie: Organisation | undefined =
    cookies[switchedOrganisationCookieName];
  const detectSignedInUser = async (): Promise<void> => {
    const signedInUser = await getSignedInUser(organisationCookie);
    if (signedInUser.state === "left") {
      setIdentityState(signedInUser.value);
    } else {
      setIdentityState(false);
    }
  };

  // asynchronously set the user if one is already signed in
  useEffect(() => {
    detectSignedInUser();
    // ideally should be cancelling the above async function if effect gets disposed
    // typically done by returning a function that handles cleanup
  }, []);

  const value = React.useMemo(
    () => [identityState, setIdentityState],
    [identityState]
  );
  return <UserContext.Provider value={value} {...children} />;
};

export type Props = {
  children: ReactNode;
  context: IdentityState;
};

const convertToUser = (
  cognitoUser: CognitoUser,
  organisations: Organisation[],
  savedOrganisation?: Organisation
): User => {
  const dynamicUser = cognitoUser as any;
  const isAdmin =
    dynamicUser.signInUserSession.accessToken.payload.hasOwnProperty(
      "cognito:groups"
    ) &&
    dynamicUser.signInUserSession.accessToken.payload["cognito:groups"][0] ===
      "admin";
  return {
    firstName: dynamicUser.attributes.given_name,
    lastName: dynamicUser.attributes.family_name,
    email: dynamicUser.attributes.email,
    cognitoSub: dynamicUser.attributes.sub,
    isAdmin: isAdmin,
    organisations: setActiveOrganisation(organisations, savedOrganisation),
  };
};

export async function getSignedInUser(
  organisation?: Organisation
): Promise<Either<User, Error>> {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const organisationsResponse = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      "/user/membership",
      "GET"
    );
    const userInvitation: UserMembership[] = await organisationsResponse.json();
    const organisations: Organisation[] = userInvitation
      .filter((o) => o.status === "Accepted")
      .map((o) => {
        return {
          name: o.organisationName,
          id: o.organisationApigeeDeveloperId,
          active: false,
        };
      });

    return left(convertToUser(cognitoUser, organisations, organisation));
  } catch (error: any) {
    return right(error);
  }
}

export async function isAdmin() {
  const cognitoUser = await Auth.currentAuthenticatedUser();
  const admin =
    cognitoUser.signInUserSession.accessToken.payload["cognito:groups"] &&
    cognitoUser.signInUserSession.accessToken.payload["cognito:groups"][0] ===
      "admin";

  return admin;
}

export const signUpUser = async (
  email: string,
  firstName: string,
  lastName: string,
  password: string,
  recaptchaToken: string
): Promise<Either<boolean, Error>> => {
  try {
    await Auth.signUp({
      username: email.toLowerCase(),
      password: password,
      attributes: {
        given_name: firstName,
        family_name: lastName,
        "custom:dev_t_and_c": String(true),
      },
      validationData: {
        recaptcha_token: recaptchaToken,
      },
    });
    return left(true);
  } catch (error: any) {
    return right(error);
  }
};

export const convertToHubUser = async (
  cognitoUser: CognitoUser
): Promise<User> => {
  const userInvitationResponse = await makeApiRequestToUrl(
    `${import.meta.env.VITE_DELIVERY_API_URL}`,
    "/user/membership",
    "GET"
  );
  const userInvitations: UserMembership[] = await userInvitationResponse.json();
  const organisations = userInvitations
    .filter((o) => o.status === "Accepted")
    .map((o) => {
      return {
        name: o.organisationName,
        id: o.organisationApigeeDeveloperId,
        active: false,
      };
    });
  return convertToUser(cognitoUser, organisations);
};

export const signOut = async (
  removeCookie: (
    name: typeof switchedOrganisationCookieName,
    options?: any
  ) => void
): Promise<Either<boolean, Error>> => {
  try {
    await Auth.signOut();
    removeCookie(switchedOrganisationCookieName);
    return left(true);
  } catch (error: any) {
    return right(error);
  }
};

export const getActiveOrganisation = (
  identity: IdentityState
): Organisation | false => {
  const user = GetUserIfLoggedIn(identity);
  if (!user) {
    return false;
  }
  const org = user.organisations.find((o) => o.active);
  if (org) {
    return org;
  }
  return false;
};

const setActiveOrganisation = (
  organisations: Organisation[],
  organisation?: Organisation
): Organisation[] => {
  if (!organisation) {
    return organisations;
  }
  const f = (o: Organisation, s: Organisation): Organisation => {
    return {
      name: o.name,
      id: o.id,
      active: o.id === s.id,
    };
  };
  return organisations.map((o) => f(o, organisation));
};

export const switchOrganisation = async (
  organisation: Organisation,
  identityState: IdentityState,
  setUserState: Dispatch<SetStateAction<IdentityState>>,
  setCookie: (
    name: typeof switchedOrganisationCookieName,
    value: any,
    options: any
  ) => void,
  update: (props?: Partial<IntercomProps>) => void
) => {
  const user = GetUserIfLoggedIn(identityState);
  if (!user) {
    return;
  }
  update({
    company: {
      companyId: organisation.id,
      name: organisation.name,
    },
    customAttributes: {
      current_organisation: organisation.name,
    },
  });
  setUserState({
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    cognitoSub: user.cognitoSub,
    isAdmin: user.isAdmin, // TODO
    organisations: setActiveOrganisation(user.organisations, organisation),
  });
  setCookie(switchedOrganisationCookieName, organisation, {
    path: "/",
  });
};
