import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router-dom";
import { DemoItem } from "./demos-page";

export const DemoCard = (props: DemoItem) => {
  const classes = useStyles();

  const history = useHistory();

  return (
    <Card className={classes.item}>
      <CardActionArea
        onClick={() => {
          if (props.public) {
            history.push(props.path);
          }
        }}
        disabled={!props.public}
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <CardMedia
          sx={{ height: "100%", width: 250 }}
          component="img"
          src={props.thumbnail}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              padding: "16px",
              paddingBottom: "0px",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <CardHeader
              sx={{
                padding: "0px !important",
              }}
              title={props.title}
            ></CardHeader>
            <Typography variant="body1">{props.type}</Typography>
          </Box>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
              {props.categories.map((type: any) => (
                <Chip label={type} color="primary" size="small" />
              ))}
            </Box>

            <Typography variant="body2">{props.description}</Typography>
          </CardContent>
        </Box>
      </CardActionArea>
    </Card>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    padding: "0px",
    height: "250px",
    "&:hover": {
      background: "theme.palette.action.hover",
    },
  },
}));
