import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CardHeader,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Typography,
} from "@mui/material";
import { Release, ReleaseEventSummary } from "data/models";
import { productCodeToNameMappings } from "pages/geoscape-data/constants";
import { UseMutationResult } from "react-query";
import { formatBytes } from "utils/commons";
import { formatProductName } from "./utils";

interface BeLoadedCardProps {
  isLoadingCreateReleaseMutation: boolean;
  isLoadingLoadReleaseMutation: boolean;
  release?: Release;
  loadReleaseToInternal: UseMutationResult<Release, unknown, string, unknown>;
  cardStyle: React.CSSProperties;
}


export const BeLoadedCard = ({
  isLoadingCreateReleaseMutation,
  release,
  loadReleaseToInternal,
  isLoadingLoadReleaseMutation,
  cardStyle,
}: BeLoadedCardProps) => {
  let totalSize = 0;
  let totalFiles = 0;
  return (
    <>
      <Card sx={cardStyle}>
        <CardHeader
          title={"Loader"}
          subheader="List of Products to be loaded"
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            padding: "20px",
          }}
        >
          <Typography variant="body1">Product</Typography>
          <List>
            <ListItem
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              {isLoadingCreateReleaseMutation && (
                <Skeleton
                  style={{ margin: "-17px" }}
                  variant="rectangular"
                  width={300}
                  height={42}
                />
              )}
              {!isLoadingCreateReleaseMutation &&
                release &&
                release.staged?.map((product: ReleaseEventSummary) => {
                  totalSize += product.size;
                  totalFiles += product.success_count;
                  return (
                    <>
                      <ListItemText
                        primary={
                          <Typography
                            component="div"
                            variant="body2"
                            style={{ whiteSpace: "pre-line" }}
                          >
                            {formatProductName(product.product)} -{" "}
                            {product.success_count} Files -{" "}
                            {formatBytes(product.size)}
                          </Typography>
                        }
                      />
                    </>
                  );
                })}
            </ListItem>
            {!isLoadingCreateReleaseMutation &&
              release?.staged &&
              release?.staged.length > 0 && (
                <ListItemText
                  primary={
                    <Typography
                      component="div"
                      variant="body2"
                      style={{ whiteSpace: "pre-line" }}
                    >
                      {`Total - ${totalFiles} Files - ${formatBytes(
                        totalSize
                      )}`}
                    </Typography>
                  }
                />
              )}
          </List>
          <LoadingButton
            sx={{
              alignSelf: "flex-start",
            }}
            variant="text"
            size="small"
            type="submit"
            disabled={release?.status !== "staged"}
            loading={isLoadingLoadReleaseMutation}
            onClick={(e: any) => {
              release && loadReleaseToInternal.mutate(release.key);
            }}
          >
            Load Datasets
          </LoadingButton>
        </Box>
      </Card>
    </>
  );
};
