import {
  Box,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Slider,
  Stack,
  Switch,
} from "@mui/material";
import {
  AddressType,
  AddressTypes,
  CRS,
  CRSMappings,
  CRSValues,
  Dataset,
  Datasets,
  State,
  States,
} from "./models";
import { PredictiveAddressManager } from "./predictive-address-manager";

export const DemoControls = (predictiveManager: PredictiveAddressManager) => {
  const handleDatasetChange = async (event: SelectChangeEvent<Dataset[]>) => {
    const {
      target: { value },
    } = event;
    predictiveManager.setDataset(
      // On autofill we get a stringified value.
      (typeof value === "string" ? value.split(",") : value) as Dataset[]
    );
  };

  const handleCrsChange = async (event: SelectChangeEvent<CRS>) => {
    const {
      target: { value },
    } = event;
    predictiveManager.setCrs(value as CRS);
  };

  const handleResultsChange = (event: Event, newValue: number | number[]) => {
    predictiveManager.setMaxResults(newValue as number);
  };

  const handleAliasesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    predictiveManager.setExcludeAliases(event.target.checked);
  };

  const handleStateChange = async (event: SelectChangeEvent<State[]>) => {
    const {
      target: { value },
    } = event;
    predictiveManager.setStateFilter(
      // On autofill we get a stringified value.
      (typeof value === "string" ? value.split(",") : value) as State[]
    );
  };

  const handleDebounceChange = (event: Event, newValue: number | number[]) => {
    predictiveManager.setDebounce(newValue as number);
  };

  const debounceValueFormat = (value: number) => {
    return `${value}ms`;
  };

  const handleAddressTypeChange = async (
    event: SelectChangeEvent<AddressType[]>
  ) => {
    const {
      target: { value },
    } = event;
    predictiveManager.setAddressType(
      // On autofill we get a stringified value.
      (typeof value === "string" ? value.split(",") : value) as AddressType[]
    );
  };

  return (
    <Card>
      <CardContent
        sx={{
          "&:last-child": {
            paddingBottom: 2,
          },
        }}
      >
        <Stack direction="row" spacing={2}>
          <Stack direction="column" spacing={2} width="100%">
            <FormControl size="small" fullWidth>
              <InputLabel id="dataset-label">Dataset Filter</InputLabel>
              <Select
                fullWidth
                size="small"
                labelId="dataset-label"
                id="dataset"
                multiple
                value={predictiveManager.dataset}
                onChange={handleDatasetChange}
                input={<OutlinedInput label="Dataset Filter" />}
                renderValue={(selected) => selected.join(", ")}
              >
                {Datasets.map((dataset) => (
                  <MenuItem key={dataset} value={dataset}>
                    <Checkbox
                      checked={predictiveManager.dataset.includes(dataset)}
                    />
                    <ListItemText primary={dataset} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl size="small" fullWidth>
              <InputLabel id="crs-label" size="small">
                CRS
              </InputLabel>
              <Select
                fullWidth
                size="small"
                labelId="crs-label"
                id="crs"
                value={predictiveManager.crs}
                onChange={handleCrsChange}
                input={<OutlinedInput label="CRS" />}
              >
                {CRSValues.map((crs) => (
                  <MenuItem key={crs} value={crs}>
                    <ListItemText
                      sx={{ mt: 0, mb: 0 }}
                      primary={CRSMappings.get(crs)}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Stack direction="column" spacing={2} width="100%">
            <Box display="flex" flexDirection="column">
              <InputLabel sx={{ alignSelf: "center" }}>
                Maximum Number of Results
              </InputLabel>
              <Slider
                size="small"
                value={predictiveManager.maxResults}
                marks
                step={1}
                valueLabelDisplay="auto"
                onChange={handleResultsChange}
                min={1}
                max={20}
              />
            </Box>
            <FormControlLabel
              control={
                <Switch
                  checked={predictiveManager.excludeAliases}
                  onChange={handleAliasesChange}
                />
              }
              label="Exclude Aliases"
            />
          </Stack>
          <Stack direction="column" spacing={2} width="100%">
            <FormControl size="small" fullWidth>
              <InputLabel id="state-label">State Filter</InputLabel>
              <Select
                fullWidth
                size="small"
                labelId="state-label"
                id="state-filter"
                multiple
                value={predictiveManager.stateFilter}
                onChange={handleStateChange}
                input={<OutlinedInput label="State Filter" />}
                renderValue={(selected) => selected.join(", ")}
              >
                {States.map((state) => (
                  <MenuItem key={state} value={state}>
                    <Checkbox
                      checked={predictiveManager.stateFilter.includes(state)}
                    />
                    <ListItemText primary={state} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box display="flex" flexDirection="column">
              <InputLabel sx={{ alignSelf: "center" }}>
                Client De-bounce
              </InputLabel>
              <Slider
                size="small"
                value={predictiveManager.debounce}
                marks
                step={100}
                valueLabelDisplay="auto"
                onChange={handleDebounceChange}
                min={100}
                max={1000}
                valueLabelFormat={debounceValueFormat}
              />
            </Box>
          </Stack>
          <Stack direction="column" spacing={2} width="100%">
            <FormControl size="small" fullWidth>
              <InputLabel id="state-label">Address Type Filter</InputLabel>
              <Select
                fullWidth
                size="small"
                labelId="address-type-label"
                id="address-type-filter"
                multiple
                value={predictiveManager.addressType}
                onChange={handleAddressTypeChange}
                input={<OutlinedInput label="Address Type Filter" />}
                renderValue={(selected) => selected.join(", ")}
              >
                {AddressTypes.map((addressType) => (
                  <MenuItem key={addressType} value={addressType}>
                    <Checkbox
                      checked={predictiveManager.addressType.includes(
                        addressType
                      )}
                    />
                    <ListItemText primary={addressType} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};
