import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

interface AttributesKeyValueTableProps {
  rows: {
    attribute: string;
    value: string;
  }[];
}

export const AttributesKeyValueTable = (
  props: AttributesKeyValueTableProps
) => {
  return (
    <TableContainer
      style={{ maxHeight: "200px", overflow: "auto" }}
      onMouseDown={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell
              padding="none"
              align="left"
              sx={{ fontWeight: "bold", padding: "4px" }}
            >
              Attribute
            </TableCell>
            <TableCell
              padding="none"
              align="left"
              sx={{ fontWeight: "bold", padding: "4px" }}
            >
              Value
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row) => (
            <TableRow
              key={row.attribute}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              <TableCell sx={{ padding: "4px" }} align="left">
                {row.attribute}
              </TableCell>
              <TableCell sx={{ padding: "4px" }} align="left">
                {row.value}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
