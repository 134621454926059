import AccountBoxIcon from "@mui/icons-material/AccountBox";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Paper,
  Stack,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { openDownloadLinkCallback } from "utils/download";
import { ClipConfiguration, Status } from "../../models";
import { MapThumbnail } from "./map-thumbnail";

interface ClipItemProps {
  clip: ClipConfiguration;
  onOpen: (clip: ClipConfiguration) => void;
  onSelect: (clip: ClipConfiguration) => void;
  isSelected: boolean;
}

export const ClipItem = (props: ClipItemProps) => {
  const classes = useStyles();
  const geometry =
    props.clip.geometry && props.clip.geometry.features.length > 0
      ? props.clip.geometry
      : undefined;

  dayjs.extend(utc);
  return (
    <Paper
      variant="outlined"
      className={props.isSelected ? classes.itemSelected : classes.item}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        props.onSelect(props.clip);
      }}
    >
      <MapThumbnail geometry={geometry} />

      <Grid
        gap={1}
        display="flex"
        flexDirection="row"
        padding={1}
        justifyContent="space-between"
        width="296px"
      >
        <Grid display="flex" flexDirection="column" width="210px">
          <Box width="160px">
            <Tooltip title={props.clip.name}>
              <Typography variant="subtitle1" noWrap>
                {props.clip.name}
              </Typography>
            </Tooltip>
          </Box>
          <Stack spacing={1} direction="row" alignItems="center">
            <AccountBoxIcon color="action"></AccountBoxIcon>
            <Typography variant="body2">{props.clip.developer}</Typography>
          </Stack>
          <Stack spacing={1} direction="row" alignItems="center">
            <CalendarTodayIcon color="action"></CalendarTodayIcon>
            <Typography variant="subtitle2">
              {dayjs.utc(props.clip.date).local().format("YYYY-MM-DD")}
            </Typography>
          </Stack>
        </Grid>
        <Stack
          spacing={1}
          direction="column"
          justifyContent="space-between"
          sx={{ paddingRight: "8px", paddingTop: "8px" }}
        >
          <Chip
            color={statusColour(props.clip.status)}
            size="small"
            label={props.clip.status}
            variant="outlined"
          />
          <Stack direction={"row"} alignSelf="flex-end">
            {props.clip.status === "Completed" && (
              <IconButton
                color="primary"
                onClick={openDownloadLinkCallback(
                  `${import.meta.env.VITE_DELIVERY_API_URL}/clip/${
                    props.clip.id
                  }/output`
                )}
              >
                <FileDownloadIcon />
              </IconButton>
            )}
            <Button
              size="small"
              variant="text"
              onClick={() => props.onOpen(props.clip)}
            >
              Open
            </Button>
          </Stack>
        </Stack>
      </Grid>
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  photo: {
    width: "100px",
    height: "100px",
    textAlign: "center",
    alignContent: "center",
    backgroundColor: theme.palette.action.hover,
  },
  item: {
    width: "396px",
    minHeight: "100px",
    display: "flex",
    flexDirection: "row",
    gap: 1,
    "&:hover": {
      background: theme.palette.action.hover,
    },
  },
  itemSelected: {
    width: "396px",
    minHeight: "100px",
    display: "flex",
    flexDirection: "row",
    gap: 1,
    background: theme.palette.action.selected,
  },
}));

export const statusColour = (status: Status) => {
  switch (status) {
    case "Completed":
      return "success";
    case "Failed":
      return "error";
    case "Processing":
      return "info";
    case "Draft":
      return "secondary";
    case "Quoting":
      return "warning";
    case "Quoted":
      return "primary";
    default: {
      return "info";
    }
  }
};
