import { Chip } from "@mui/material";
import { Stack } from "@mui/system";
import { Dispatch, SetStateAction } from "react";

interface ProductChipsProps {
  products: string[];
  selectedProduct: string;
  setSelectedProduct: Dispatch<SetStateAction<string>>;
}

export const ProductChips = ({
  products,
  selectedProduct,
  setSelectedProduct,
}: ProductChipsProps) => {
  return (
    <Stack direction={"row"} sx={{ flexWrap: "wrap" }}>
      {products.map((p) => {
        return (
          <Chip
            sx={{ margin: "2px" }}
            key={p}
            label={p}
            variant="filled"
            color={p == selectedProduct ? "primary" : "default"}
            onClick={() => {
              setSelectedProduct(p);
            }}
          ></Chip>
        );
      })}
    </Stack>
  );
};
