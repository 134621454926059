import DeleteIcon from "@mui/icons-material/Delete";
import { LoadingButton } from "@mui/lab";
import { Box, Card, LinearProgress, Typography } from "@mui/material";
import { FileUploadedProps } from "pages/geoscape-batch/types";
import { FileWithPath } from "react-dropzone";
import { formatBytes } from "utils/commons";

const FileUploaded = ({
  filesData,
  isUploading,
  haveUploaded,
  uploadProgress,
  deleteObjectMutation,
  batchId,
  props,
  s3UploadFailed,
  updateBatchConfig,
}: FileUploadedProps) => {
  const batchConfigFileUploaded = props.batchConfig.hubInfo?.files.length > 0;

  const file = props.batchConfig?.hubInfo?.files[0];

  const filesSelected = filesData.map((file: FileWithPath, index: number) => {
    if (!file.path) {
      return null;
    }

    return (
      <div key={`files-selected-${index}`}>
        {s3UploadFailed ? (
          <Typography color="red">
            "Please refresh the page and upload the file again. If the error
            persists, please contact support."
          </Typography>
        ) : (
          <>
            <Card
              key={file.path}
              style={{ display: "flex", padding: "8px", marginTop: "8px" }}
            >
              <Box style={{ width: "100%", marginRight: "8px" }}>
                <Typography>{file.path}</Typography>
                {(isUploading || haveUploaded) && file.path ? (
                  <>
                    <Typography variant="body2">
                      {formatBytes(file.size)} -{" "}
                      {uploadProgress[file.path].percentComplete === 100
                        ? "COMPLETE"
                        : `about ${
                            uploadProgress[file.path].humanisedTimeRemaining
                          } remaining`}
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      color={
                        uploadProgress[file.path].percentComplete === 100
                          ? "success"
                          : "info"
                      }
                      value={uploadProgress[file.path].percentComplete}
                    />
                  </>
                ) : (
                  <Typography variant="body2">
                    {formatBytes(file.size)}
                  </Typography>
                )}
              </Box>

              <Box style={{ marginTop: "4px" }}></Box>
            </Card>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "8px",
              }}
            >
              <Typography color="orange">
                *If you navigate away at any time, you will have to reupload
                these files.
              </Typography>
              <LoadingButton
                loading={isUploading || deleteObjectMutation.isLoading}
                onClick={async () => {
                  deleteObjectMutation.mutate(
                    batchId + "-" + filesData[0].path ?? ""
                  );
                }}
                variant="contained"
                endIcon={<DeleteIcon />}
              >
                Delete
              </LoadingButton>
            </Box>
          </>
        )}
      </div>
    );
  });

  const fileLoadedFromProps = () => {
    return (
      <>
        <Card style={{ display: "flex", padding: "8px", marginTop: "8px" }}>
          <Box style={{ width: "100%", marginRight: "8px" }}>
            <Typography>{file.path}</Typography>

            <Typography variant="body2">
              {formatBytes(file.size)} - "COMPLETE"
            </Typography>
            <LinearProgress
              variant="determinate"
              color={"success"}
              value={100}
            />
          </Box>

          <Box style={{ marginTop: "4px" }}></Box>
        </Card>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "8px",
          }}
        >
          <Typography color="orange">
            *If you navigate away at any time, you will have to reupload these
            files.
          </Typography>
          <LoadingButton
            loading={isUploading || deleteObjectMutation.isLoading}
            onClick={async () => {
              deleteObjectMutation.mutate(batchId + "-" + file.path ?? "");
            }}
            variant="contained"
            endIcon={<DeleteIcon />}
          >
            Delete
          </LoadingButton>
        </Box>
      </>
    );
  };

  return (
    <>
      <>{batchConfigFileUploaded ? fileLoadedFromProps() : filesSelected}</>
    </>
  );
};

export default FileUploaded;
