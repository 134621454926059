import { Button, Card, CardActions, CardHeader, Modal } from "@mui/material";

interface DeleteConfirmationModelProps {
  onDelete: () => void;
  open: boolean;
  onClose: () => void;
}

const style: React.CSSProperties = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 468,
  backgroundColor: "background.paper",
  padding: 1,
  display: "flex",
  flexDirection: "column",
};

export const DeleteConfirmationModel = (
  props: DeleteConfirmationModelProps
) => {
  return (
    <Modal open={props.open} onClose={props.onClose}>
      <Card style={style}>
        <CardHeader
          title="Confirm MFA Deletion"
          subheader="Are you sure you want to delete your current MFA method? Geoscape recommends you protect your account with MFA. You can setup MFA again later from your account settings."
        ></CardHeader>
        <CardActions
          sx={{
            padding: 2,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Button
            variant="contained"
            color="warning"
            fullWidth
            onClick={props.onDelete}
          >
            Delete MFA
          </Button>
          <Button variant="text" fullWidth onClick={props.onClose}>
            Cancel
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};
