import { Box, Button, Pagination, TextField, Typography } from "@mui/material";
import { ClipConfigProps, ClipStepsControlProps } from "../../clip-menu";
import { useState } from "react";
import { RecentClips } from "./recent-clips";
import { ClipConfiguration } from "../../models";
import { MAX_CLIPS } from "./new-clip";

interface HistoricClipsProps {
  onExit: () => void;
}

export const HistoricClips = (
  props: ClipConfigProps & ClipStepsControlProps & HistoricClipsProps
) => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const filteredClips: ClipConfiguration[] = props.clips.filter((clip) =>
    clip.name.toLowerCase().startsWith(search.toLowerCase())
  );

  const paginatedClips: ClipConfiguration[] = filteredClips.slice(
    MAX_CLIPS * (page - 1),
    MAX_CLIPS * page
  );

  return (
    <>
      <Typography variant="h5">Search</Typography>
      <TextField
        onChange={(e) => {
          setSearch(e.target.value);
          setPage(1);
        }}
        label="Search"
        size="small"
      ></TextField>
      <Box justifyContent="flex-start">
        <Button onClick={props.onExit}>Back</Button>
      </Box>
      <RecentClips
        onSelect={(clip: ClipConfiguration) => {
          props.setActiveStep(clip.activeStep);
          props.setClipConfig(clip);
        }}
        maxClips={props.clips.length}
        clips={paginatedClips}
        mapRef={props.mapRef}
        draw={props.draw}
      ></RecentClips>
      {!search && (
        <Box alignSelf="center" marginTop="auto" display="flex">
          <Pagination
            shape="rounded"
            page={page}
            onChange={handleChange}
            count={Math.ceil(props.clips.length / MAX_CLIPS)}
          />
        </Box>
      )}
    </>
  );
};
