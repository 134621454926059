import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import ShieldIcon from "@mui/icons-material/Shield";
import TagIcon from "@mui/icons-material/Tag";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Chip,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { DefinitionsMetadata, Metadata } from "data/models";
import dayjs from "dayjs";
import { productCodeToExplorerLayerIdMappings } from "pages/geoscape-data/constants";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { formatTimestamp } from "utils/format-date";
import { viewDatasetInExplorer } from "../catalogue-page";

interface CatalogueEntryMetadataProps {
  image: any;
  metadata: Metadata;
  definitionsMetadata?: DefinitionsMetadata;
  latestReleaseReportLink: string;
  productDescriptionLink: string;
  datasetId: string;
}

const getRows = (
  metadata: Metadata,
  definitionsMetadata: DefinitionsMetadata
) => {
  return [
    {
      icon: <AddCircleOutlineIcon color="action" />,
      key: "Date Added:",
      value: (
        <Typography fontWeight={600} variant="body1">
          {formatTimestamp(metadata.dateAdded)}
        </Typography>
      ),
    },
    {
      icon: <AccessTimeIcon color="action" />,
      key: "Last Updated:",
      value: (
        <Typography fontWeight={600} variant="body1">
          {dayjs(definitionsMetadata.latestRelease).format("MMM YYYY")}
        </Typography>
      ),
    },
    {
      icon: <AutoAwesomeMotionIcon color="action" />,
      key: "Data Type:",
      value: (
        <Typography fontWeight={600} variant="body1">
          {metadata.dataType.join(", ")}
        </Typography>
      ),
    },
    {
      icon: <TagIcon color="action" />,
      key: "Feature Count:",
      value: (
        <Typography fontWeight={600} variant="body1">
          {metadata.totalFeatureCount.toLocaleString()}
        </Typography>
      ),
    },
    {
      icon: <AutorenewIcon color="action" />,
      key: "Updated:",
      value: (
        <Typography fontWeight={600} variant="body1">
          {metadata.updateFrequency}
        </Typography>
      ),
    },
    {
      icon: <ShieldIcon color="action" />,
      key: "Licence:",
      value: (
        <Typography fontWeight={600} variant="body1">
          {metadata.license}
        </Typography>
      ),
    },
  ];
};

export const CatalogueEntryMetadata = (props: CatalogueEntryMetadataProps) => {
  const { show } = useIntercom();

  if (props.definitionsMetadata === undefined) {
    return <></>;
  }

  const history = useHistory();
  return (
    <Card>
      <CardActionArea
        disabled={!productCodeToExplorerLayerIdMappings.get(props.datasetId)}
        onClick={() => viewDatasetInExplorer(props.datasetId, history)}
      >
        <CardMedia src={props.image} sx={{ height: 400 }} component="img" />
      </CardActionArea>
      <CardContent>
        {getRows(props.metadata, props.definitionsMetadata).map((row) =>
          renderMetadataItem(row.icon, row.key, row.value)
        )}
        <Typography mt="20px" variant="h5">
          Links
        </Typography>
        <Stack m={2} gap={1} direction="column">
          <RouterLink
            target="_blank"
            to={{
              pathname: props.latestReleaseReportLink,
            }}
          >
            Latest Release Report
          </RouterLink>
          <RouterLink
            target="_blank"
            to={{ pathname: props.productDescriptionLink }}
          >
            Product Description
          </RouterLink>
          <Link onClick={() => show()}>Support</Link>
        </Stack>
        <Typography mt="20px" variant="h5">
          Formats
        </Typography>
        <Stack mt="20px" direction="row" spacing={1}>
          {props.definitionsMetadata?.formats.map((format) => (
            <Chip
              key={format}
              label={format}
              variant="outlined"
              color="primary"
            />
          ))}
        </Stack>
        <Typography mt="20px" variant="h5">
          Datums
        </Typography>
        <Stack mt="20px" direction="row" spacing={1}>
          {props.definitionsMetadata?.datums.map((datum) => (
            <Chip
              key={datum}
              label={datum}
              variant="outlined"
              color="primary"
            />
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
};

const renderMetadataItem = (icon: React.ReactNode, key: string, value: any) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: 32,
      }}
      key={key}
    >
      {icon}
      <Typography width={120} pl={1} alignSelf="center" variant="body1">
        {key}
      </Typography>
      <Box textAlign="left" justifyContent="flex-start" alignSelf="center">
        {value}
      </Box>
    </Box>
  );
};
