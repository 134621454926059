import { Card, CardContent, Skeleton } from "@mui/material";
import React from "react";

interface UsageSkeletonProps {
  isLoading: boolean;
}

export const UsageSkeleton: React.FC<UsageSkeletonProps> = ({ isLoading }) => {
  if (!isLoading) return null;

  return (
    <Card>
      <CardContent>
        <Skeleton variant="rectangular" width="100%" height="300px" />
      </CardContent>
    </Card>
  );
};
