import * as QueryKeys from "data";

import { Box, Card, CardContent, CardHeader, FormGroup } from "@mui/material";
import { getActiveOrganisation, useIdentity } from "contexts/identity-context";

import { makeStyles } from "@mui/styles";
import { fetchDatasets, fetchDefinitions } from "data/queries";
import { useEffect, useState } from "react";
import { UseQueryResult, useQuery } from "react-query";
import { resolveIdentityId } from "utils/identity";
import { customColumns, customersToRows } from "./columns-rows";

import {
  DatasetDefinitions,
  DatasetFiltersType,
  DatasetList,
} from "data/models";
import { useLocation } from "react-router-dom";

import { GridRowClassNameParams } from "@mui/x-data-grid-premium";
import { ReactQueryErrorWrapper } from "components/shared/react-query-error-wrapper";
import CustomServerPaginatedTable from "components/shared/server-paginated-table";
import { useCustomSnackbars } from "components/snackbars/useCustomSnackbars";
import * as localStorageKeys from "data/local-storage-keys";
import usePaginatedTableQuery from "shared-hooks/use-paginated-table-query";
import FilterComponent from "../share-component/filter-component";
import { updateQueryAndFilters } from "../utils";
import { DatasetFilters } from "./filters";

export const DownloadPage = () => {
  const [identityState] = useIdentity();

  const isUser = !getActiveOrganisation(identityState);
  const identityId = resolveIdentityId(identityState, isUser);

  const urlLocationQuery = useLocation().search;

  const savedSearch = localStorage.getItem(localStorageKeys.searchDatasets);
  const { enqueueLinkCorruptedSnackbar } = useCustomSnackbars();

  let initialQueryFilterState: DatasetFiltersType = {
    query: "",
    product: [],
    region: [],
    format: [],
    datum: [],
    release: "",
    latestRelease: false,
    licenced: false,
  };

  const [queryFilterState, setQueryFilterState] = useState<DatasetFiltersType>(
    initialQueryFilterState
  );
  const [loadingFilters, setLoadingFilters] = useState<boolean>(false);

  const {
    paginationModel,
    handleChangePaginationModel,
    searchQuery,
  } = usePaginatedTableQuery<DatasetList>(
    (limit: number, offset: number) => {
      return fetchDatasets(queryFilterState, identityState, limit, offset);
    },
    QueryKeys.datasetsSearchQuery,
    identityId,
    queryFilterState
  );

  const datasetDefinitions: UseQueryResult<DatasetDefinitions, unknown> =
    useQuery([QueryKeys.definitionsKey], () => fetchDefinitions(identityState));

  useEffect(() => {
    if (!datasetDefinitions.isSuccess) {
      return;
    }
    updateQueryAndFilters({
      savedSearch,
      setQueryFilterState,
      setLoadingFilters,
      urlLocationQuery,
      initialQueryFilterState,
      definitions: datasetDefinitions,
      enqueueLinkCorruptedSnackbar,
    });
  }, [datasetDefinitions.isSuccess]);

  const useStyles = makeStyles({
    highlightedRow: {
      color: "grey",
    },
  });

  const classes = useStyles();

  const getCustomRowLicence = (params: GridRowClassNameParams): string => {
    if (params.row.licenced == false) {
      return classes.highlightedRow;
    }
    return "";
  };

  const customColumns_ = customColumns(identityState);

  const isLoading = searchQuery.isLoading;

  const rows = searchQuery.data ? customersToRows(searchQuery.data) : [];

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap: "16px",
          position: "relative",
        }}
      >
        <ReactQueryErrorWrapper
          queries={[searchQuery, datasetDefinitions]}
          mutations={[]}
        />

        <Card>
          <CardHeader title="Search Datasets" variant="h5" />

          <CardContent
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <FormGroup style={{ display: "flex", flexDirection: "row" }}>
              {loadingFilters ? (
                <Box
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "16px",
                  }}
                >
                  <h2>Loading...</h2>
                </Box>
              ) : (
                <FilterComponent
                  queryFilterState={queryFilterState}
                  setQueryFilterState={setQueryFilterState}
                  initialQueryFilterState={initialQueryFilterState}
                  renderFilter={DatasetFilters}
                  definitions={datasetDefinitions}
                  localStorageKey={localStorageKeys.searchDatasets}
                />
              )}
            </FormGroup>
          </CardContent>
        </Card>

        <CustomServerPaginatedTable
          rowCount={searchQuery.data?.links?.numberMatched ?? -1}
          rows={rows}
          getCustomRowClass={getCustomRowLicence}
          paginationModel={paginationModel}
          setPaginationModel={handleChangePaginationModel}
          cols={customColumns_}
          isLoading={isLoading}
          overlayText={
            "We couldn't find any datasets that match your filter criteria."
          }
        />
      </div>
    </>
  );
};
