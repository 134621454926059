import GeoscapeDeveloperSvg from "icons/hub-logo-signIn.svg?react";
import { Box, Container, LinearProgress, useTheme } from "@mui/material";

export const ApplicationLoading = () => {
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  return (
    <Box
      display="flex"
      alignItems="center"
      minHeight="100%"
      height="100%"
      overflow="auto"
    >
      <Container>
        <Container maxWidth="sm">
          <Box display="flex" justifyContent="center" paddingY={5}>
            <GeoscapeDeveloperSvg
              fill={isDark ? "white" : "black"}
              scale={0.2}
            />
          </Box>
          <Box display="flex" justifyContent="center" paddingY={5}>
            <LinearProgress style={{ width: "100%" }} />
          </Box>
        </Container>
        <Box display="flex"></Box>
      </Container>
    </Box>
  );
};
