import { CognitoUser } from "@aws-amplify/auth";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  TextField,
  Typography,
} from "@mui/material";
import { Auth } from "aws-amplify";
import { supportURL } from "common/const";
import { ReactQueryMutationError } from "data/mutations";
import { ChangeEvent, useState } from "react";
import { useMutation } from "react-query";

interface MfaLoginProps {
  user: CognitoUser | null;
  onSubmit: (cognitoUser: CognitoUser) => Promise<void>;
}

export const MfaLogin = (props: MfaLoginProps) => {
  const [mfaCode, setMfaCode] = useState("");

  const [challengeName, setChallengeName] = useState(props.user?.challengeName);
  const authenticatedUserMutation = useMutation(
    () => Auth.currentAuthenticatedUser(),
    {
      onSuccess: async (cognitoUser) => {
        await props.onSubmit(cognitoUser);
      },
    }
  );

  const confirmSignInMutation = useMutation(
    () => {
      return Auth.confirmSignIn(
        props.user,
        mfaCode,
        challengeName as "SMS_MFA" | "SOFTWARE_TOKEN_MFA" | null
      );
    },
    {
      onSuccess: async (cognitoUser) => {
        authenticatedUserMutation.mutate();
      },
    }
  );

  const onCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const code = event.target.value;
    const validCode = code.replace(/[^0-9]/g, "");
    setMfaCode(validCode);
  };

  const isMfaLoading =
    confirmSignInMutation.isLoading || authenticatedUserMutation.isLoading;

  return (
    <Card>
      <CardHeader title="Multi Factor Authentication"></CardHeader>
      <CardContent sx={{ pb: 0 }}>
        {challengeName === "SOFTWARE_TOKEN_MFA" && (
          <Typography variant="body1">
            To continue, please enter the six digit code generated by your
            authenticator app.
          </Typography>
        )}
        {challengeName === "SMS_MFA" && (
          <Typography variant="body1">
            We just sent you a message via SMS with your authentication code.
            Enter the code below to verify your identity.
          </Typography>
        )}
        <TextField
          inputProps={{ maxLength: 6 }}
          sx={{ mt: 2 }}
          label="Verification Code"
          variant="outlined"
          size="small"
          fullWidth
          value={mfaCode}
          onChange={onCodeChange}
          onKeyDown={(ev) => {
            if (ev.key === "Enter" && mfaCode.length === 6)
              confirmSignInMutation.mutate();
          }}
        />
        {confirmSignInMutation.isError && (
          <Alert severity="error">
            {(confirmSignInMutation.error as ReactQueryMutationError).message}
          </Alert>
        )}
      </CardContent>
      <CardActions
        sx={{
          padding: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <LoadingButton
          disabled={mfaCode.length !== 6}
          loading={isMfaLoading}
          variant="contained"
          fullWidth
          onClick={() => confirmSignInMutation.mutate()}
        >
          Verify
        </LoadingButton>
        <Button
          fullWidth
          variant="text"
          component="a"
          href={supportURL}
          target="_blank"
          rel="noopener noreferrer"
        >
          Contact Support
        </Button>
      </CardActions>
    </Card>
  );
};
