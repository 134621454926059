import {
  Box,
  Button,
  Card,
  CardHeader,
  List,
  ListItem,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { ProductList } from "./product-list";
import { UseMutationResult } from "react-query";
import { Release } from "data/models";
import { LoadingButton } from "@mui/lab";

interface PublisherCardProps {
  loadReleaseToInternal: UseMutationResult<any, unknown, string, unknown>;
  release?: Release;
  publishRelease: UseMutationResult<any, unknown, string, unknown>;
  unpublishRelease: UseMutationResult<Release, unknown, string, unknown>;
  cardStyle: React.CSSProperties;
}

export const PublisherCard = ({
  loadReleaseToInternal,
  unpublishRelease,
  release,
  publishRelease,
  cardStyle,
}: PublisherCardProps) => {
  return (
    <>
      <Card sx={cardStyle}>
        <CardHeader
          title={"Publisher"}
          subheader="List of Products to be published"
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            padding: "20px",
          }}
        >
          <Typography variant="body1">Product</Typography>
          <List>
            <ListItem
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              {loadReleaseToInternal.isLoading && (
                <Skeleton
                  style={{ margin: "-17px" }}
                  variant="rectangular"
                  width={300}
                  height={42}
                />
              )}
              {!loadReleaseToInternal.isLoading && (
                <ProductList datasets={release?.loaded}></ProductList>
              )}
            </ListItem>
          </List>
          <Stack spacing={2} direction="row">
            <LoadingButton
              loading={publishRelease.isLoading}
              variant="text"
              size="small"
              type="submit"
              disabled={
                release?.status === "published" || !!!release?.loaded?.length
              }
              onClick={() => {
                release && publishRelease.mutate(release?.key);
              }}
            >
              Publish
            </LoadingButton>
            <LoadingButton
              loading={unpublishRelease.isLoading}
              variant="text"
              size="small"
              type="submit"
              disabled={
                release?.status !== "published" || !!!release.published?.length
              }
              onClick={() => {
                release && unpublishRelease.mutate(release?.key);
              }}
            >
              Unpublish
            </LoadingButton>
          </Stack>
        </Box>
      </Card>
    </>
  );
};
