import {
  Box,
  Grid,
  IconButton,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Slider,
  Switch,
  Typography,
} from "@mui/material";
import { LayerConfiguration } from "../layers/models";
import { useHistory } from "react-router";
import * as Routes from "routes";
import { explorerLayerIdToProductCodeMappings } from "pages/geoscape-data/constants";

interface ColorPickerProps {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  opacity: number;
  color: string;
  diameter?: number;
  thickness?: number;
  fill?: boolean;
  expandedLayer: LayerConfiguration | null;
  onChangeColor: (id: string, color: string) => void;
  onChangeOpacity: (id: string, opacity: number) => void;
  onChangeThickness: (id: string, thickness: number) => void;
  onChangeDiameter: (id: string, diameter: number) => void;
  onChangeFill: (id: string, fill: boolean) => void;
  zoomToLayerFn: () => void;
}

export const hexCodes: string[] = [
  "#D50000",
  "#C51162",
  "#AA00FF",
  "#6200EA",
  "#304FFE",
  "#2962FF",
  "#0091EA",
  "#00B8D4",
  "#00BFA5",
  "#00C853",
  "#64DD17",
  "#AEEA00",
  "#FFD600",
  "#FFAB00",
  "#FF6D00",
  "#DD2C00",
];
export const ColorPicker = (props: ColorPickerProps) => {
  const handleSliderOpacityChange = (newValue: number | number[]) => {
    if (!props.expandedLayer) {
      return;
    }
    props.onChangeOpacity(props.expandedLayer.id, (newValue as number) / 100);
  };

  const handleSliderThicknessChange = (newValue: number | number[]) => {
    if (!props.expandedLayer) {
      return;
    }
    props.onChangeThickness(props.expandedLayer.id, newValue as number);
  };

  const handleSliderDiameterChange = (newValue: number | number[]) => {
    if (!props.expandedLayer) {
      return;
    }
    props.onChangeDiameter(props.expandedLayer.id, newValue as number);
  };

  const handleColorChange = (color: string) => {
    if (!props.expandedLayer) {
      return;
    }
    props.onChangeColor(props.expandedLayer.id, color);
  };

  const handleFillChange = (checked: boolean) => {
    if (!props.expandedLayer) {
      return;
    }
    props.onChangeFill(props.expandedLayer.id, checked);
  };

  const history = useHistory();
  const productCode = props.expandedLayer
    ? explorerLayerIdToProductCodeMappings.get(props.expandedLayer.id)
    : "";

  const max_opacity = 100;
  const min_opacity = 0;
  const max_thickness = 10;
  const min_thickness = 0;
  const max_diameter = 10;
  const min_diameter = 0;
  const opacity_marks = [
    {
      value: max_opacity,
      label: "",
    },
    {
      value: min_opacity,
      label: "",
    },
  ];
  const thickness_marks = [
    {
      value: max_thickness,
      label: "",
    },
    {
      value: min_thickness,
      label: "",
    },
  ];
  const diameter_marks = [
    {
      value: max_diameter,
      label: "",
    },
    {
      value: min_diameter,
      label: "",
    },
  ];

  return (
    <Menu
      slotProps={{
        paper: {
          style: {
            width: "232px",
          },
        },
      }}
      anchorEl={props.anchorEl}
      open={props.open}
      onClose={props.onClose}
    >
      <ListItem>
        <Box width="100%">
          <ListItemText primary="Opacity" />
          <Slider
            onChange={(event: Event, newValue: number | number[]) =>
              handleSliderOpacityChange(newValue)
            }
            value={
              props.expandedLayer?.styles.opacity != undefined
                ? props.expandedLayer?.styles.opacity * 100
                : 20
            }
            valueLabelDisplay="auto"
            min={min_opacity}
            max={max_opacity}
            step={10}
            marks={opacity_marks}
          />
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              variant="body2"
              onClick={() => handleSliderOpacityChange(min_opacity)}
              sx={{ cursor: "pointer" }}
            >
              {min_opacity}%
            </Typography>
            <Typography
              variant="body2"
              onClick={() => handleSliderOpacityChange(max_opacity)}
              sx={{ cursor: "pointer" }}
            >
              {max_opacity}%
            </Typography>
          </Box>
        </Box>
      </ListItem>

      {(props.expandedLayer?.styles.type == "Polygon" ||
        props.expandedLayer?.styles.type == "LineString") && (
        <ListItem>
          <Box width="100%">
            <ListItemText primary="Thickness" />
            <Slider
              onChange={(event: Event, newValue: number | number[]) =>
                handleSliderThicknessChange(newValue)
              }
              value={
                props.expandedLayer?.styles.thickness != undefined
                  ? props.expandedLayer?.styles.thickness
                  : 2
              }
              valueLabelDisplay="auto"
              min={min_thickness}
              max={max_thickness}
              step={1}
              marks={thickness_marks}
            />
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                variant="body2"
                onClick={() => handleSliderThicknessChange(min_thickness)}
                sx={{ cursor: "pointer" }}
              >
                {min_thickness}px
              </Typography>
              <Typography
                variant="body2"
                onClick={() => handleSliderThicknessChange(max_opacity)}
                sx={{ cursor: "pointer" }}
              >
                {max_thickness}px
              </Typography>
            </Box>
          </Box>
        </ListItem>
      )}

      {props.expandedLayer?.styles.type == "Point" && (
        <ListItem>
          <Box width="100%">
            <ListItemText primary="Diameter" />
            <Slider
              onChange={(event: Event, newValue: number | number[]) =>
                handleSliderDiameterChange(newValue)
              }
              value={
                props.expandedLayer?.styles.diameter != undefined
                  ? props.expandedLayer?.styles.diameter
                  : 2
              }
              valueLabelDisplay="auto"
              min={min_diameter}
              max={max_diameter}
              step={1}
              marks={diameter_marks}
            />
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                variant="body2"
                onClick={() => handleSliderDiameterChange(min_diameter)}
                sx={{ cursor: "pointer" }}
              >
                {min_diameter}px
              </Typography>
              <Typography
                variant="body2"
                onClick={() => handleSliderDiameterChange(max_diameter)}
                sx={{ cursor: "pointer" }}
              >
                {max_diameter}px
              </Typography>
            </Box>
          </Box>
        </ListItem>
      )}

      {props.expandedLayer?.styles.type == "Polygon" && (
        <Box display="flex" flexDirection="row" alignItems="center">
          <Switch
            checked={props.expandedLayer?.styles.fill}
            onChange={(
              event: React.ChangeEvent<HTMLInputElement>,
              checked: boolean
            ) => handleFillChange(checked)}
            name="Fill"
          />
          <Typography>Fill</Typography>
        </Box>
      )}
      <ListItem>
        <Box width="100%">
          <ListItemText primary="Color Picker" />
          <Grid container rowSpacing={0} columnSpacing={0}>
            {hexCodes.map((hexValue) => (
              <Grid item xs={3} key={`grid-${hexValue}`}>
                <IconButton
                  key={`icon-${hexValue}`}
                  sx={{ padding: 0 }}
                  onClick={() => handleColorChange(hexValue)}
                >
                  <Paper
                    key={`paper-${hexValue}`}
                    elevation={0}
                    square
                    sx={{
                      backgroundColor: hexValue,
                      width: "50px",
                      height: "50px",
                    }}
                  />
                </IconButton>
              </Grid>
            ))}
          </Grid>
        </Box>
      </ListItem>
      <MenuItem onClick={props.zoomToLayerFn}>Zoom to Layer</MenuItem>
      {productCode && (
        <MenuItem
          onClick={() => history.push(`${Routes.dataCatalogue}/${productCode}`)}
        >
          Open in Catalogue
        </MenuItem>
      )}
    </Menu>
  );
};
