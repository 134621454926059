export * from "./format-date";
export * from "./sleep";
export * from "./empty";

export const nameof = <T>(name: keyof T): string => String(name);

interface Left<T> {
  state: `left`;
  value: T;
}

interface Right<T> {
  state: `right`;
  value: T;
}

export interface Nothing {
  // Nothing at all!
}

export const nothing: Nothing = {};

export type Either<T1, T2> = Left<T1> | Right<T2>;

export const isLeft = <T1, T2>(either: Either<T1, T2>): boolean =>
  either.state === `left`;
export const isRight = <T1, T2>(either: Either<T1, T2>): boolean =>
  either.state === `right`;

export const left = <T>(value: T): Left<T> => ({
  state: `left`,
  value: value,
});

export const right = <T>(value: T): Right<T> => ({
  state: `right`,
  value: value,
});
