import * as QueryKeys from "data";

import { Box, Button, Card } from "@mui/material";
import { getActiveOrganisation, useIdentity } from "contexts/identity-context";
import * as Routes from "routes";

import { makeStyles } from "@mui/styles";
import {
  fetchCustomDatasetsAdmin,
  fetchCustomDefinitions,
  fetchUserDetailsAdmin,
} from "data/queries";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { generatePath, useParams } from "react-router-dom";
import { resolveIdentityId } from "utils/identity";

import * as localStorageKeys from "data/local-storage-keys";
import { CustomDatasetFilterType, CustomDatasetList } from "data/models";
import { updateQueryAndFilters } from "pages/geoscape-data/utils";
import { customColumns, datasetsToRows } from "./columns-rows";

import { LoadingButton } from "@mui/lab";
import { GridRowClassNameParams } from "@mui/x-data-grid-premium";
import { ReactQueryErrorWrapper } from "components/shared/react-query-error-wrapper";
import CustomServerPaginatedTable from "components/shared/server-paginated-table";
import { manageCustomDatasetsStatus } from "data/mutations";
import FilterComponent from "pages/geoscape-data/share-component/filter-component";
import { useHistory } from "react-router-dom";
import usePaginatedTableQuery from "shared-hooks/use-paginated-table-query";
import { CustomDatasetFilters } from "./filters";

export const ViewCustomDatasets = () => {
  const history = useHistory();
  let { accountId }: any = useParams();

  const adminUserDetails = useQuery(
    [QueryKeys.adminUserDetailsQuery, accountId],
    () => fetchUserDetailsAdmin(accountId, identityState)
  );

  const [identityState] = useIdentity();

  const isUser = !getActiveOrganisation(identityState);
  const identityId = resolveIdentityId(identityState, isUser);

  const savedSearch = localStorage.getItem(
    localStorageKeys.searchCustomDatasets
  );

  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  let initialQueryFilterState: CustomDatasetFilterType = {
    name: [],
    query: "",
    product: [],
    release: "",
    customerName: [],
    latestRelease: false,
    notPublished: false,
  };

  const [queryFilterState, setQueryFilterState] =
    useState<CustomDatasetFilterType>(initialQueryFilterState);

  const [loadingFilters, setLoadingFilters] = useState<boolean>(false);

  const {
    paginationModel,
    handleChangePaginationModel,
    searchQuery,
    queryKey,
  } = usePaginatedTableQuery<CustomDatasetList>(
    (limit: number, offset: number) => {
      return fetchCustomDatasetsAdmin(
        queryFilterState,
        adminUserDetails.data?.apigeeDeveloperId as string,
        identityState,
        limit,
        offset
      );
    },
    QueryKeys.customDatasetsSearchQuery,
    adminUserDetails.data?.apigeeDeveloperId ?? "",
    queryFilterState,
    {
      enabled: adminUserDetails.isSuccess,
    }
  );

  const queryClient = useQueryClient();

  const customDatasetsManageMutation = useMutation(
    (values: any) => manageCustomDatasetsStatus(values, identityState),
    {
      onSuccess: async (response) => {
        await new Promise((r) => setTimeout(r, 2000)); // Wait a bit before resetting the cache

        queryClient.resetQueries({
          queryKey: queryKey,
          exact: true,
        });
      },
    }
  );

  const definitions = useQuery([QueryKeys.customDatasetDefinitions], () =>
    fetchCustomDefinitions(identityState)
  );

  useEffect(() => {
    updateQueryAndFilters({
      savedSearch: savedSearch,
      setQueryFilterState,
      setLoadingFilters,
      initialQueryFilterState,
      definitions: definitions,
    });
  }, []);

  const useStyles = makeStyles({
    highlightedRow: {
      color: "grey",
    },
  });

  const classes = useStyles();

  const getCustomRowLicence = (params: GridRowClassNameParams): string => {
    return "";
  };

  const customColumns_ = customColumns(identityState, queryKey, queryClient);

  const isSuccess = searchQuery.isSuccess;
  const isLoading = searchQuery.isLoading;
  const rowsData = searchQuery.data ? datasetsToRows(searchQuery.data) : [];

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          position: "absolute",
          top: "-55px",
          right: "0px",
        }}
      >
        <Button
          variant="contained"
          size="small"
          onClick={() =>
            history.push(
              generatePath(Routes.adminCreateCustomerCustomDatasets, {
                accountId: accountId,
              })
            )
          }
        >
          + New Release
        </Button>
      </Box>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap: "16px",
          position: "relative",
        }}
      >
        <ReactQueryErrorWrapper
          queries={[searchQuery, definitions]}
          mutations={[customDatasetsManageMutation]}
        />

        <Card
          sx={{
            padding: "16px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <FilterComponent
            queryFilterState={queryFilterState}
            setQueryFilterState={setQueryFilterState}
            initialQueryFilterState={initialQueryFilterState}
            renderFilter={CustomDatasetFilters}
            definitions={definitions}
            localStorageKey={localStorageKeys.searchCustomDatasets}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "16px",
              justifyContent: "space-between",
            }}
          >
            <LoadingButton
              variant="contained"
              loading={customDatasetsManageMutation.isLoading}
              onClick={() =>
                customDatasetsManageMutation.mutate({
                  ids: selectedIds,
                  status: "internal",
                })
              }
            >
              Unpublish
            </LoadingButton>
            <LoadingButton
              variant="contained"
              loading={customDatasetsManageMutation.isLoading}
              onClick={() =>
                customDatasetsManageMutation.mutate({
                  ids: selectedIds,
                  status: "published",
                })
              }
            >
              Publish
            </LoadingButton>
          </Box>
        </Card>
        <CustomServerPaginatedTable
          rowCount={searchQuery.data?.links?.numberMatched ?? -1}
          getCustomRowClass={getCustomRowLicence}
          paginationModel={paginationModel}
          setPaginationModel={handleChangePaginationModel}
          rows={isSuccess ? rowsData : []}
          cols={isLoading ? [] : customColumns_}
          isLoading={isLoading}
          overlayText={
            "We couldn't find any datasets that match your filter criteria."
          }
          checkboxSelection={true}
          onSelectionModelChange={(ids) => {
            setSelectedIds(ids as string[]);
          }}
        />
      </div>
    </Box>
  );
};
