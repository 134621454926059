import { Box } from "@mui/material";
import { getActiveOrganisation, useIdentity } from "contexts/identity-context";
import { definitionsKey } from "data";
import { CreateRelease, Release } from "data/models";
import {
  changeReleaseStatus,
  createRelease,
  draftRelease,
} from "data/mutations";
import { fetchDefinitions } from "data/queries";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import * as Routes from "routes";
import { resolveIdentityId } from "utils/identity";
import { BeLoadedCard } from "./be-loaded";
import { NewReleaseButtons } from "./buttons";
import { PublisherCard } from "./publisher";
import { ReleaseCard } from "./release-card";

import { useSnackbar, SnackbarKey } from "notistack";
import { SupportButton } from "components/snackbars/SupportButton";
import { CloseButton } from "components/snackbars/CloseButton";
import { useCustomSnackbars } from "components/snackbars/useCustomSnackbars";
import { ReactQueryErrorWrapper } from "components/shared/react-query-error-wrapper";

export const NewRelease = () => {
  const history = useHistory();

  const [identity, setIdentity] = useIdentity();
  const isUser = !getActiveOrganisation(identity);
  const identityId = resolveIdentityId(identity, isUser);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const selectedRelease = history.location.state as Release;
  const [release, setRelease] = useState<Release>(selectedRelease);

  const [closeButton, setCloseButton] = useState<boolean>(false);
  const productsQuery = useQuery([definitionsKey], () =>
    fetchDefinitions(identity)
  );

  const { enqueueSupportSnackbar } = useCustomSnackbars();

  const createReleaseMutation = useMutation(
    (newRelease: CreateRelease) => createRelease(newRelease, identity),
    {
      onError: (error: Error, variables, context) => {
        enqueueSupportSnackbar(
          `Failed to create new release, please contact support. Error: ${error.toString()}`,
          "error"
        );
        setCloseButton(false);
      },

      onSuccess: (newRelease) => {
        setRelease(newRelease);

        if (closeButton) {
          history.push(Routes.adminReleases);
        }
      },
    }
  );

  const draftReleaseMutation = useMutation(
    (newRelease: CreateRelease) => draftRelease(newRelease, identity),
    {
      onError: (error: Error, variables, context) => {
        enqueueSupportSnackbar(
          `Failed to create new release, please contact support. Error: ${error.toString()}`,
          "error"
        );
        setCloseButton(false);
      },

      onSuccess: (newRelease) => {
        history.push(Routes.adminReleases);
      },
    }
  );

  const loadReleaseMutation = useMutation(
    (releaseId: string) => changeReleaseStatus(releaseId, "internal", identity),
    {
      onSuccess: (newRelease) => {
        setRelease(newRelease);
      },
    }
  );

  const unpublishReleaseMutation = useMutation(
    (releaseId: string) => changeReleaseStatus(releaseId, "internal", identity),
    {
      onSuccess: (newRelease) => {
        setRelease(newRelease);
      },
    }
  );

  const publishReleaseMutation = useMutation(
    (releaseId: string) =>
      changeReleaseStatus(releaseId, "published", identity),
    {
      onSuccess: (newRelease) => {
        setRelease(newRelease);
      },
    }
  );

  return (
    <div>
      <ReactQueryErrorWrapper
        queries={[productsQuery]}
        mutations={[
          createReleaseMutation,
          draftReleaseMutation,
          loadReleaseMutation,
          unpublishReleaseMutation,
          publishReleaseMutation,
        ]}
      />

      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          marginBottom: "50px",
          gap: "16px",
          height: "43px",
        }}
      >
        <NewReleaseButtons
          isLoadingCreateReleaseMutation={createReleaseMutation.isLoading}
          isLoadingDraftReleaseMutation={draftReleaseMutation.isLoading}
          isLoadingLoadReleaseMutation={loadReleaseMutation.isLoading}
          release={release}
          setCloseButton={setCloseButton}
        ></NewReleaseButtons>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-start",
          gap: "10px",
        }}
      >
        <ReleaseCard
          cardStyle={cardStyle}
          data={productsQuery.data}
          isLoading={productsQuery.isLoading}
          createReleaseMutation={createReleaseMutation}
          isLoadingDraftReleaseMutation={draftReleaseMutation.isLoading}
          draftReleaseMutation={draftReleaseMutation}
          isLoadingcreateReleaseMutation={createReleaseMutation.isLoading}
          release={release}
          isLoadingLoadReleaseMutation={loadReleaseMutation.isLoading}
          setCloseButton={setCloseButton}
          closeButton={closeButton}
        />
        <BeLoadedCard
          cardStyle={cardStyle}
          loadReleaseToInternal={loadReleaseMutation}
          release={release}
          isLoadingCreateReleaseMutation={createReleaseMutation.isLoading}
          isLoadingLoadReleaseMutation={loadReleaseMutation.isLoading}
        />
        <PublisherCard
          cardStyle={cardStyle}
          loadReleaseToInternal={loadReleaseMutation}
          release={release}
          unpublishRelease={unpublishReleaseMutation}
          publishRelease={publishReleaseMutation}
        />
      </Box>
    </div>
  );
};

const cardStyle: React.CSSProperties = {
  display: "flex",
  alignItems: "flex-start",
  flexGrow: 1,
  flexShrink: 1,
  height: "100%",
  width: "100%",
  flexDirection: "column",
};
