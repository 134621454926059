import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Icon,
  IconButton,
  TextField,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import ModifyPolygonSvg from "../../icons/modify_polygon.svg?react";

interface BoundaryItemProps {
  id: string;
  name: string;
  area: number;
  selected: boolean;
  onEdit: (id: string, name: string) => void;
  onDelete: (id: string) => void;
  onSelect: (id: string) => void;
}
export const BoundaryItem = (props: BoundaryItemProps) => {
  const [editing, setEditing] = useState<boolean>(false);
  const [name, setName] = useState<string>(props.name);
  const classes = useStyles();
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  const humanizeArea = (areaSqm: number) => {
    const areaSqkm = areaSqm / 1000000;
    if (areaSqkm < 1)
      return `${areaSqm.toLocaleString(undefined, {
        maximumFractionDigits: 1,
      })} m2`;
    return `${areaSqkm.toLocaleString(undefined, {
      maximumFractionDigits: 1,
    })} km2`;
  };

  return (
    <Card
      sx={{
        padding: "0px",
      }}
      variant="outlined"
    >
      <CardActionArea onClick={() => props.onSelect(props.id)}>
        <CardContent
          className={
            props.selected ? classes.selectedBoundaryItem : classes.boundaryItem
          }
        >
          <Icon sx={{ textAlign: "center" }}>
            <ModifyPolygonSvg fill={isDark ? "white" : "black"} />
          </Icon>
          <Box display="flex" flexDirection="column">
            {editing && (
              <TextField
                value={props.name}
                inputProps={{ maxLength: 200 }}
                variant="standard"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                onChange={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setName(e.target.value);
                  props.onEdit(props.id, e.target.value);
                }}
              ></TextField>
            )}
            {!editing && (
              <Box width="240px">
                <Tooltip title={props.name}>
                  <Typography variant="body2" noWrap>
                    {props.name}
                  </Typography>
                </Tooltip>
              </Box>
            )}
            <Typography variant="body2" color="text.secondary">
              {humanizeArea(props.area)}
            </Typography>
          </Box>
          <Box display="flex" marginLeft="auto">
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setEditing(!editing);
              }}
            >
              {!editing && <EditIcon />}
              {editing && <SaveIcon />}
            </IconButton>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                props.onDelete(props.id);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  boundaryItem: {
    width: "398px",
    height: "64px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "16px",
  },

  selectedBoundaryItem: {
    width: "398px",
    height: "64px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "16px",
    backgroundColor: theme.palette.action.focus,
  },
}));
