import { CognitoUser } from "@aws-amplify/auth";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Skeleton,
  TextField,
} from "@mui/material";
import { Auth } from "aws-amplify";
import * as localStorageKeys from "data/local-storage-keys";
import { ReactQueryMutationError } from "data/mutations";
import { QRCodeSVG } from "qrcode.react";
import { ChangeEvent, useEffect, useState } from "react";
import { useMutation } from "react-query";

interface MfaSetupProps {
  onSubmit: () => void;
  onOptOut: () => void;
  cognitoUser: CognitoUser;
  style?: React.CSSProperties;
  startFromStep?: number;
}

export const MfaSetup = (props: MfaSetupProps) => {
  const [user, setUser] = useState<CognitoUser | null>(props.cognitoUser);
  const [qrCode, setQrCode] = useState("");
  const [mfaCode, setMfaCode] = useState("");
  const [step, setStep] = useState(
    props.startFromStep ? props.startFromStep : 0
  );

  const [phoneNumber, setPhoneNumber] = useState("");
  const [smsVerifyCode, setSmsVerifyCode] = useState("");

  const setupTotpMutation = useMutation(() => Auth.setupTOTP(user));
  const verifyTotpTokenMutation = useMutation(
    () => {
      return Auth.verifyTotpToken(user, mfaCode);
    },
    {
      onSuccess: async () => {
        await setPreferredMFAMutation.mutateAsync();
        setQrCode("");
        setStep(3);
      },
    }
  );
  const setPreferredMFAMutation = useMutation(() =>
    Auth.setPreferredMFA(user, "TOTP")
  );

  const setupSMSMutation = useMutation(async () => {
    await Auth.updateUserAttributes(user, { phone_number: phoneNumber });
    Auth.verifyCurrentUserAttribute("phone_number");
  });

  const verifySMSMutation = useMutation(
    () =>
      Auth.verifyCurrentUserAttributeSubmit("phone_number", smsVerifyCode).then(
        () => Auth.setPreferredMFA(user, "SMS_MFA")
      ),
    {
      onSuccess: () => setStep(3),
    }
  );

  const onPhoneNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    const regex = /^\+(\d{0,11})$/;

    if (regex.test(value) || value === "") {
      setPhoneNumber(value);
    }
  };

  const onCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const code = event.target.value;
    const validCode = code.replace(/[^0-9]/g, "");
    setMfaCode(validCode);
  };

  const onSmsCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const smsCode = event.target.value;
    const validSmsCode = smsCode.replace(/[^0-9]/g, "");
    setSmsVerifyCode(validSmsCode);
  };

  useEffect(() => {
    const setupTOTP = async () => {
      const code = await setupTotpMutation.mutateAsync();
      setQrCode(
        `otpauth://totp/Geoscape Hub:${user?.getUsername()}?secret=${code}&issuer=Geoscape Hub`
      );
    };
    setupTOTP();
  }, []);

  const verifyTOTP = async () => {
    verifyTotpTokenMutation.mutateAsync();
  };

  /**
   * Steps
   *  0 - Setup Multi Factor Authentication
   *  1 - MFA Method
   * -1 - Opt out of MFA
   *  2 - Setup TOTP MFA
   * -2 - Setup SMS MFA
   *  3 - Success
   */
  return (
    <>
      {step == 0 && (
        <Card style={props.style ?? {}}>
          <CardHeader
            title="Setup Multi Factor Authentication"
            subheader="Geoscape recommends you protect your account with MFA (Multi Factor Authentication), this is optional but recommended for security."
          ></CardHeader>
          <CardActions
            sx={{
              padding: 2,
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Button
              variant="contained"
              fullWidth
              onClick={() => {
                setStep(1);
              }}
            >
              Setup MFA
            </Button>
            <Button
              id="optOutMfa"
              fullWidth
              onClick={() => {
                setStep(-1);
              }}
            >
              OPT OUT
            </Button>
          </CardActions>
        </Card>
      )}
      {step == 1 && (
        <Card style={props.style ?? {}}>
          <CardHeader
            title="MFA Method"
            subheader="Geoscape supports two methods of MFA, You’ll only be able to use one method at a time. We highly recommend TOTP."
          ></CardHeader>
          <CardActions>
            <Box
              gap={2}
              display="flex"
              flexDirection="column"
              width="100%"
              padding={1}
            >
              <Button
                variant="contained"
                fullWidth
                onClick={async () => {
                  setStep(2);
                }}
              >
                Authenticator App (TOTP)
              </Button>
              <Button
                variant="contained"
                fullWidth
                onClick={async () => {
                  setStep(-2);
                }}
              >
                Text Messaging (SMS)
              </Button>
              <Button
                fullWidth
                onClick={() => {
                  setStep(0);
                }}
              >
                Back
              </Button>
            </Box>
          </CardActions>
        </Card>
      )}
      {step == -1 && (
        <Card style={props.style ?? {}}>
          <CardHeader
            title="Opt out of MFA?"
            subheader="Are you sure you want to opt out of MFA? Geoscape recommends you protect your account with MFA. You will be able to configure MFA at a later date from inside your account settings."
          ></CardHeader>
          <CardActions
            sx={{
              padding: 2,
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Button
              id="confirmOptOutMfa"
              variant="contained"
              fullWidth
              onClick={() => {
                localStorage.setItem(localStorageKeys.optOutMfa, "true");
                props.onOptOut();
              }}
            >
              Confirm Opt Out
            </Button>
            <Button
              fullWidth
              onClick={() => {
                setStep(0);
              }}
            >
              Back
            </Button>
          </CardActions>
        </Card>
      )}
      {step == 2 && (
        <Card style={props.style ?? {}}>
          <CardHeader
            title="Setup Multi Factor Authentication"
            subheader="Use your authenticator app to scan the QR code displayed below"
          ></CardHeader>
          <CardContent
            sx={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            {setupTotpMutation.isLoading && (
              <Skeleton sx={{ alignSelf: "center" }} width={225} height={225} />
            )}
            {setupTotpMutation.isError && (
              <Alert severity="error">
                An error occured when trying to generate QR code
              </Alert>
            )}
            {setupTotpMutation.isSuccess && !!qrCode && (
              <>
                <Box
                  alignSelf="center"
                  sx={{
                    height: 225,
                    width: 225,
                  }}
                >
                  <QRCodeSVG value={qrCode} size={225} />
                </Box>
                {verifyTotpTokenMutation.isError && (
                  <Alert severity="error">
                    {
                      (verifyTotpTokenMutation.error as ReactQueryMutationError)
                        .message
                    }
                  </Alert>
                )}
                <TextField
                  inputProps={{ maxLength: 6 }}
                  disabled={
                    setupTotpMutation.isLoading ||
                    verifyTotpTokenMutation.isLoading ||
                    setPreferredMFAMutation.isLoading
                  }
                  label="TOTP Code"
                  value={mfaCode}
                  onChange={onCodeChange}
                  onKeyDown={async (ev) => {
                    if (ev.key === "Enter" && mfaCode.length === 6)
                      await verifyTOTP();
                  }}
                ></TextField>
              </>
            )}
          </CardContent>
          <CardActions sx={{ marginTop: "auto" }}>
            {setupTotpMutation.isSuccess && (
              <LoadingButton
                loading={
                  verifyTotpTokenMutation.isLoading ||
                  setPreferredMFAMutation.isLoading
                }
                disabled={setupTotpMutation.isLoading || mfaCode.length !== 6}
                fullWidth
                variant="contained"
                onClick={async () => await verifyTOTP()}
              >
                Submit
              </LoadingButton>
            )}
          </CardActions>
        </Card>
      )}

      {step == -2 && (
        <Card style={props.style ?? {}}>
          <CardHeader
            title="Setup SMS MFA"
            subheader="We'll text a verification code to this mobile device whenever you sign in to Geoscape Hub. Standard SMS charges may apply from your operator."
          ></CardHeader>
          <CardContent
            sx={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            <TextField
              required
              inputProps={{ maxLength: 12 }}
              size="small"
              placeholder="+61xxxxxxxxx"
              label="Phone Number"
              value={phoneNumber}
              onChange={onPhoneNumberChange}
              onKeyDown={async (ev) => {
                if (ev.key === "Enter" && phoneNumber.length === 12)
                  await setupSMSMutation.mutateAsync();
              }}
              helperText="By providing your mobile number, you agree to receive text messages from Geoscape."
            ></TextField>
            <LoadingButton
              loading={setupSMSMutation.isLoading}
              disabled={
                setupSMSMutation.isLoading ||
                !phoneNumber ||
                phoneNumber.length !== 12
              }
              fullWidth
              variant="contained"
              onClick={async () => await setupSMSMutation.mutateAsync()}
            >
              Send Code
            </LoadingButton>
            <TextField
              inputProps={{ maxLength: 6 }}
              size="small"
              label="Verification Code"
              value={smsVerifyCode}
              onChange={onSmsCodeChange}
              onKeyDown={async (ev) => {
                if (ev.key === "Enter" && smsVerifyCode.length === 6)
                  await verifySMSMutation.mutateAsync();
              }}
            ></TextField>
            {verifySMSMutation.isError && (
              <Alert variant="outlined" severity="error">
                {(verifySMSMutation.error as ReactQueryMutationError).message}
              </Alert>
            )}
            <LoadingButton
              loading={verifySMSMutation.isLoading}
              disabled={
                verifySMSMutation.isLoading ||
                !smsVerifyCode ||
                smsVerifyCode.length !== 6
              }
              fullWidth
              variant="contained"
              onClick={async () => await verifySMSMutation.mutateAsync()}
            >
              Verify
            </LoadingButton>
            {props.startFromStep == 0 && (
              <Button
                variant="text"
                fullWidth
                onClick={() => {
                  setStep(1);
                }}
              >
                Back
              </Button>
            )}
          </CardContent>
        </Card>
      )}

      {step === 3 && (
        <Card style={props.style ?? {}}>
          <CardHeader
            title="Success!"
            subheader="MFA has successfully been setup for your account."
          ></CardHeader>
          <CardContent sx={{ justifyContent: "center", display: "flex" }}>
            <CheckCircleIcon color="success" fontSize="large" />
          </CardContent>
          <CardActions>
            <Button
              fullWidth
              variant="contained"
              onClick={() => props.onSubmit()}
            >
              Done
            </Button>
          </CardActions>
        </Card>
      )}
    </>
  );
};
