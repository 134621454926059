import { makeStyles } from "@mui/styles";
import bbox from "@turf/bbox";
import { Basemap } from "components/basemap/basemap";
import { useRef } from "react";
import { FillLayer, Layer, MapRef, Source } from "react-map-gl";

interface MapThumbnailProps {
  geometry?: GeoJSON.FeatureCollection;
}
export const MapThumbnail = (props: MapThumbnailProps) => {
  const classes = useStyles();
  //const [mapRef, setMapRef] = useState<MapRef | null>(null);

  const mapRef = useRef<MapRef>();

  const [minLng, minLat, maxLng, maxLat] = props.geometry
    ? bbox(props.geometry)
    : // bounding box of Australia
      [96, -45, 168, -8];

  const dataLayer: FillLayer = {
    id: "data",
    type: "fill",
    paint: {
      "fill-color": "#00ffff",
      "fill-opacity": 0.8,
    },
  };

  return (
    <Basemap
      // @ts-ignore
      mapRef={mapRef}
      attributionControl={false}
      onZoom={(zoom) => {}}
      style={{
        width: "100px",
        height: "100px",
        borderTopLeftRadius: "15px",
        borderBottomLeftRadius: "15px",
        borderTopRightRadius: "0px",
        borderBottomRightRadius: "0px",
        boxShadow: "none", // Flat
        border: "none",
      }}
      interactive={false}
      bbox={[minLng, minLat, maxLng, maxLat]}
      bboxPadding={20}
    >
      <Source type="geojson" data={props.geometry}>
        <Layer {...dataLayer} />
      </Source>
    </Basemap>
  );
};

const useStyles = makeStyles((theme: any) => ({
  photo: {
    width: "100px",
    height: "100px",
    textAlign: "center",
    alignContent: "center",
    backgroundColor: theme.palette.action.hover,
  },
}));
