import { Button, Card, CardHeader, Link } from "@mui/material";
import * as Routes from "routes";

export const EditOverageCard = ({ isOrg }: any) => {
  return (
    <Card
      style={{
        width: "45%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingRight: 2,
        padding: "0  16px 0 0",
      }}
    >
      <CardHeader
        variant="primary"
        title={"Increase your Overage"}
        subheader="No one likes dropping API calls."
        subheaderTypographyProps={{ variant: "body2" }}
        sx={{
          padding: "16px !important",
        }}
      />
      <Button
        LinkComponent={Link}
        href={isOrg ? Routes.orgOverage : Routes.userOverage}
        variant="contained"
        color="primary"
        size="small"
        style={{ height: "36px" }}
      >
        EDIT OVERAGE
      </Button>
    </Card>
  );
};
