import { PortalPlan, PortalSubscription } from "./models"

export const dummyInitialSub: PortalSubscription = {
  renewalDate: -1,
  currentPeriodStart: -1,
  subscriptionId: "",
  enterpriseCreditAmountOverride: 0,
  isMyob: false,
  plan: {
    productId: "",
    priceId: "",
    name: "Freemium",
    price: 0,
    interval: "month",
    overageRate: 15,
    creditAmount: 20000,
    mailpointAccess: false,
    rateLimit: 2,
    isEnterprise: false
  }
}

export const dummyInitialNewPlan: PortalPlan = {
  productId: "",
  priceId: "",
  name: "Professional",
  price: 100000,
  interval: "month",
  overageRate: 10,
  creditAmount: 100000,
  mailpointAccess: false,
  rateLimit: 20,
  isEnterprise: false
}