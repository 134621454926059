import { GenericSnackbar } from "components/snackbar/generic-snackbar";
import { useState } from "react";
import { useIntercom } from "react-use-intercom";
import { useCustomSnackbars } from "components/snackbars/useCustomSnackbars";

export const ErrorFallback = () => {
  const { enqueueSupportSnackbar } = useCustomSnackbars();

  enqueueSupportSnackbar(
    "Something has gone wrong, please contact support.",
    "error"
  );

  return <></>;
};
