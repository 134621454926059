import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { guessFeaturePrimaryKey } from "../explorer-page";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import _ from "lodash";

interface AttributesDynamicTableProps {
  attributes: {
    [key: string]: string;
  }[];
  onSelectFeature: (key: string) => void;
}

export const AttributesDynamicTable = (props: AttributesDynamicTableProps) => {
  const classes = useStyles();
  const [selectedRow, setSelectedRow] = useState<{
    [key: string]: string;
  }>({});

  const handleRowClick = (rowData: { [key: string]: string }) => {
    setSelectedRow(rowData);
    props.onSelectFeature(
      rowData[guessFeaturePrimaryKey(Object.keys(rowData)) as string]
    );
  };

  return (
    <TableContainer
      onMouseDown={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      style={{ maxHeight: "200px", overflow: "auto" }}
    >
      <Table size="small">
        <TableHead>
          <TableRow>
            {Object.keys(props.attributes[0]).map((key) => (
              <TableCell
                align="left"
                sx={{
                  fontWeight: "bold",
                  paddingRight: "10px",
                  padding: "4px",
                }}
              >
                {key}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.attributes.map((object) => (
            <TableRow
              key={Object.values(object)[0]}
              className={`${classes.clickableRow} ${
                _.isEqual(selectedRow, object) ? classes.selectedRow : ""
              }`}
              onClick={() => handleRowClick(object)}
            >
              {Object.values(object).map((value) => (
                <TableCell sx={{ padding: "4px" }} align="left">
                  {value}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const useStyles = makeStyles((theme: any) => ({
  clickableRow: {
    "&:last-child td, &:last-child th": { border: 0 },
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  selectedRow: {
    backgroundColor: theme.palette.action.selected,
  },
}));
