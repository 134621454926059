import { Dispatch, SetStateAction, useState } from "react";
import {
  Address,
  AddressResult,
  AddressType,
  CRS,
  Dataset,
  State,
  Suggest,
} from "./models";

export interface PredictiveAddressManager {
  dataset: Dataset[];
  setDataset: Dispatch<SetStateAction<Dataset[]>>;
  maxResults: number;
  setMaxResults: Dispatch<SetStateAction<number>>;
  stateFilter: State[];
  setStateFilter: Dispatch<SetStateAction<State[]>>;
  excludeAliases: boolean;
  setExcludeAliases: Dispatch<SetStateAction<boolean>>;
  crs: CRS;
  setCrs: Dispatch<SetStateAction<CRS>>;
  debounce: number;
  setDebounce: Dispatch<SetStateAction<number>>;
  addressType: AddressType[];
  setAddressType: Dispatch<SetStateAction<AddressType[]>>;
  address: Address | null;
  setAddress: Dispatch<SetStateAction<Address | null>>;
  prediction: AddressResult | null;
  setPrediction: Dispatch<SetStateAction<AddressResult | null>>;
  creditCounter: number;
  setCreditCounter: Dispatch<SetStateAction<number>>;
  callCounter: number;
  setCallCounter: Dispatch<SetStateAction<number>>;
  query: string;
  setQuery: Dispatch<SetStateAction<string>>;
  suggest: Suggest | null;
  setSuggest: Dispatch<SetStateAction<Suggest | null>>;
}

export const usePredictiveAddressManager = (): PredictiveAddressManager => {
  //Demo Controls States
  const [dataset, setDataset] = useState<Dataset[]>([]);
  const [maxResults, setMaxResults] = useState(5);
  const [stateFilter, setStateFilter] = useState<State[]>([]);
  const [excludeAliases, setExcludeAliases] = useState(false);
  const [crs, setCrs] = useState<CRS>(
    "<https://www.opengis.net/def/crs/EPSG/0/4283>"
  );
  const [debounce, setDebounce] = useState(100);
  const [addressType, setAddressType] = useState<AddressType[]>([]);

  // Address Entry
  const [address, setAddress] = useState<Address | null>(null);
  const [prediction, setPrediction] = useState<AddressResult | null>(null);

  const [creditCounter, setCreditCounter] = useState<number>(0);
  const [callCounter, setCallCounter] = useState<number>(0);
  const [query, setQuery] = useState("");
  const [suggest, setSuggest] = useState<Suggest | null>(null);

  return {
    dataset,
    setDataset,
    maxResults,
    setMaxResults,
    stateFilter,
    setStateFilter,
    excludeAliases,
    setExcludeAliases,
    crs,
    setCrs,
    debounce,
    setDebounce,
    addressType,
    setAddressType,
    address,
    setAddress,
    prediction,
    setPrediction,
    creditCounter,
    setCreditCounter,
    callCounter,
    setCallCounter,
    query,
    setQuery,
    suggest,
    setSuggest,
  };
};
