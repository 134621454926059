export type State =
  | "ACT"
  | "NSW"
  | "QLD"
  | "VIC"
  | "TAS"
  | "WA"
  | "SA"
  | "NT"
  | "OT";

export const States: State[] = [
  "ACT",
  "NSW",
  "QLD",
  "VIC",
  "TAS",
  "WA",
  "SA",
  "NT",
  "OT",
];
export type CRS =
  | "<https://www.opengis.net/def/crs/EPSG/0/4283>"
  | "<https://www.opengis.net/def/crs/EPSG/0/7844>"
  | "<https://www.opengis.net/def/crs/EPSG/0/4326>"
  | "<https://www.opengis.net/def/crs/EPSG/0/4202>";

export const CRSValues: CRS[] = [
  "<https://www.opengis.net/def/crs/EPSG/0/4283>",
  "<https://www.opengis.net/def/crs/EPSG/0/7844>",
  "<https://www.opengis.net/def/crs/EPSG/0/4326>",
  "<https://www.opengis.net/def/crs/EPSG/0/4202>",
];

export const CRSMappings = new Map<CRS, string>([
  ["<https://www.opengis.net/def/crs/EPSG/0/4283>", "GDA94 (EPSG:4283)"],
  ["<https://www.opengis.net/def/crs/EPSG/0/7844>", "GDA2020 (EPSG:7844)"],
  ["<https://www.opengis.net/def/crs/EPSG/0/4326>", "WGS 84 (EPSG:4326)"],
  ["<https://www.opengis.net/def/crs/EPSG/0/4202>", "AGD66 (EPSG:4202)"],
]);

interface LocalityResult {
  displayName: string;
  id: string;
  localityName: string;
  stateTerritory: string;
  postcode: string;
  rank: number;
}

interface SuggestLocality {
  suggest: LocalityResult[];
  basictransaction: number;
}

type LiteralType = "Feature";

interface Properties {
  layer: string;
  dateCreated: string;
  state: string;
  localityPolygonPid: string;
  localityPid: string;
  localityName: string;
  localityClass: string;
}

interface Geometry {
  coordinates: number[];
  type: string;
}

interface LocalityFeature {
  geometry: Geometry;
  properties: Properties;
  type: LiteralType;
}

interface Locality {
  features: LocalityFeature[];
  query: string;
  type: string;
  requestTimeStamp: number;
  requestDateTime: string;
  attribution: string;
  messages: string[];
  basictransaction: number;
}

export type { Geometry, Locality, LocalityResult, SuggestLocality };
