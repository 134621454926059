import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { UsageData } from "./types";

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);

export const formatDate = (dateStr: string, intervalHours: string) => {
  if (intervalHours === "1h") {
    return dayjs(dateStr).format("ddd hh:mm A");
  }
  if (intervalHours === "1d" || intervalHours === "1w") {
    return dayjs(dateStr).format("MMM D");
  }
  return dayjs(dateStr).format("MMM YYYY");
};

export const createCreditAmountSeries = (
  data: UsageData,
  creditAmount: number
) => {
  const { series } = data.appCreditsData;

  if (series.length === 0) {
    return {
      data: [],
      type: "bar",
      name: "Accumulated Sums",
      markLine: {
        name: "credit limit",
        label: { show: true, position: "middle" },
        data: [{ yAxis: creditAmount }],
      },
    };
  }
  const summedData = series[0].data.map((_, index) =>
    series.reduce((sum, item) => sum + item.data[index], 0)
  );

  let baseUsage = 0;
  const accumulatedData = summedData.map((x) => {
    baseUsage += x;
    return x === 0 ? x : baseUsage;
  });

  return {
    data: accumulatedData,
    type: "bar",
    name: "Accumulated Sums",
    markLine: {
      name: "credit limit",
      label: { show: true, position: "middle" },
      data: [{ yAxis: creditAmount }],
    },
  };
};
