import {
  Box,
  Card,
  Typography,
  LinearProgress,
  IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { formatBytes } from "utils/commons";
import React from "react";
import { UploadProgressMap } from "pages/geoscape-admin/custom-datasets/custom-hook/types";
import { FileWithPath } from "react-dropzone";


export interface FileUploadProgressProps {
  uploadProgress: UploadProgressMap;
  file: FileWithPath;
  onRemove: (fname: string) => void;
  isUploading: boolean;
  haveUploaded: boolean;
}


export const FileUploadProgress = (props: FileUploadProgressProps) => {
  return (
    <Card
      key={props.file.path}
      style={{ display: "flex", padding: "8px", marginTop: "8px" }}
    >
      <Box style={{ width: "100%", marginRight: "8px" }}>
        <Typography>{props.file.path}</Typography>
        {(props.isUploading || props.haveUploaded) && props.file.path ? (
          <>
            <Typography variant="body2">
              {formatBytes(props.file.size)} -{" "}
              {props.uploadProgress[props.file.path].percentComplete === 100
                ? "COMPLETE"
                : `about ${
                    props.uploadProgress[props.file.path].humanisedTimeRemaining
                  } remaining`}
            </Typography>
            <LinearProgress
              variant="determinate"
              color={
                props.uploadProgress[props.file.path].percentComplete === 100
                  ? "success"
                  : "info"
              }
              value={props.uploadProgress[props.file.path].percentComplete}
            />
          </>
        ) : (
          <Typography variant="body2">
            {formatBytes(props.file.size)}
          </Typography>
        )}
      </Box>
      <Box style={{ marginTop: "4px" }}>
        <IconButton onClick={() => props.onRemove(props.file.name)}>
          <Close />
        </IconButton>
      </Box>
    </Card>
  );
};
