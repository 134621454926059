import { Box, Button, Skeleton } from "@mui/material";
import * as QueryKeys from "data";

import { ApiKeyTable } from "components/api-keys/api-keys-table";
import { ReactQueryErrorWrapper } from "components/shared/react-query-error-wrapper";
import { useIdentity } from "contexts/identity-context";
import { fetchAdminUserApiKeys, fetchUserDetailsAdmin } from "data/queries";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { generatePath, useHistory } from "react-router-dom";
import * as Routes from "routes";

const AdminApiKeys = () => {
  const history = useHistory();
  const [identityState] = useIdentity();

  let { accountId }: any = useParams();

  const adminUserDetails = useQuery(
    [QueryKeys.adminUserDetailsQuery, accountId],
    () => fetchUserDetailsAdmin(accountId, identityState)
  );

  const adminUserApiKeysQuery = useQuery(
    [QueryKeys.adminUserApiKeys, accountId],
    () => fetchAdminUserApiKeys(accountId, identityState)
  );

  return (
    <>
      {adminUserDetails.isLoading && <Skeleton height="500px" />}
      {adminUserDetails.isSuccess && (
        <Box
          sx={{
            position: "relative",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              position: "absolute",
              top: "-55px",
              right: "0px",
            }}
          >
            <Button
              onClick={() => {
                history.push(
                  generatePath(Routes.adminCreateApiKey, {
                    accountId: accountId,
                  })
                );
              }}
              variant="contained"
            >
              Create API Key
            </Button>
          </Box>

          <Box
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <ReactQueryErrorWrapper
              queries={[adminUserApiKeysQuery]}
              mutations={[]}
            />

            <ApiKeyTable
              data={adminUserApiKeysQuery.data}
              isLoading={adminUserApiKeysQuery.isLoading}
              apiKeyDetailsRoute={Routes.adminEditApiKey}
              accountId={accountId}
              accountName={adminUserDetails.data.name}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export { AdminApiKeys };
