import { Box } from "@mui/material";
import BillingDetailsInfo from "components/settings/shared/billing-details/billingDetailsPage";
import PaymentMethodInfo from "components/settings/shared/billing-details/paymentMethodInfo";

export const BillingDetails = () => {
  return (
    <Box>
      <PaymentMethodInfo isUserPage={false} />
      <BillingDetailsInfo isUserPage={false} />
    </Box>
  );
};
