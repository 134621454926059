import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  Box,
  Card,
  CardContent,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import * as QueryKeys from "data";
import { fetchAdminBounds, fetchPredictiveLocality } from "data/queries";
import _ from "lodash";
import { HTMLAttributes, SyntheticEvent, useCallback, useState } from "react";
import { useQuery } from "react-query";
import { Locality, LocalityResult } from "./models";
import { PredictiveSuburbManager } from "./predictive-suburb-manager";

const CustomPaperComponent = (
  predictiveManager: HTMLAttributes<HTMLElement>
) => {
  return (
    <Paper {...predictiveManager}>
      {predictiveManager.children}
      <Box display="flex" justifyContent="flex-end" m={1} gap={0.5}>
        <Typography variant="caption">Powered by</Typography>
        <Typography fontWeight="bold" variant="caption">
          Geoscape
        </Typography>
      </Box>
    </Paper>
  );
};

export const SuburbEntryForm = (predictiveManager: PredictiveSuburbManager) => {
  const [localityResult, setLocalityResult] = useState<LocalityResult | null>(
    null
  );
  const [reason, setReason] = useState<AutocompleteInputChangeReason>("input");

  const debouncedSearch = useCallback(
    _.debounce((searchTerm: string) => {
      predictiveManager.setQuery(searchTerm);
    }, predictiveManager.debounce),
    [predictiveManager.debounce]
  );

  const onSearch = (
    event: SyntheticEvent,
    newQuery: string,
    reason: AutocompleteInputChangeReason
  ) => {
    setReason(reason);
    debouncedSearch(newQuery);
  };

  const onSelectAddress = (
    event: SyntheticEvent,
    localityResult: LocalityResult | null,
    reason: AutocompleteChangeReason
  ) => {
    if (reason === "clear" && event.type === "click") {
      predictiveManager.setLocality(null);
      predictiveManager.setPrediction(null);
      setLocalityResult(null);
    }
    predictiveManager.setSuggest(null);
    if (localityResult) setLocalityResult(localityResult);
  };

  const predictiveQuery = useQuery(
    [
      QueryKeys.predictiveLocality,
      predictiveManager.query,
      predictiveManager.postcode,
      predictiveManager.stateFilter.join(","),
      predictiveManager.maxResults,
    ],
    () =>
      fetchPredictiveLocality(
        predictiveManager.query,
        predictiveManager.postcode,
        predictiveManager.stateFilter.join(","),
        predictiveManager.maxResults
      ),
    {
      enabled: !!predictiveManager.query && reason === "input",
      onSuccess: (response) => {
        predictiveManager.setSuggest(response);
        predictiveManager.setCallCounter(predictiveManager.callCounter + 1);
        predictiveManager.setCreditCounter(
          predictiveManager.creditCounter + response.basictransaction
        );
      },
    }
  );

  const adminBoundsByIdQuery = useQuery(
    [QueryKeys.adminBoundsById, localityResult?.id, predictiveManager.crs],
    () =>
      fetchAdminBounds(
        localityResult?.id ?? "",
        "localities",
        false,
        predictiveManager.crs
      ),
    {
      enabled: !!localityResult?.id,
      onSuccess: (locality: Locality) => {
        predictiveManager.setLocality(locality);
        predictiveManager.setCallCounter(predictiveManager.callCounter + 1);
        predictiveManager.setCreditCounter(
          predictiveManager.creditCounter + locality.basictransaction
        );
      },
    }
  );

  const highlightText = (text: string, query: string) => {
    const parts = text.split(new RegExp(`(${query})`, "gi"));
    return parts.map((part, index) => (
      <span
        key={index}
        style={{
          fontWeight:
            part.toLowerCase() === query.toLowerCase() ? 900 : "normal",
          color:
            part.toLowerCase() === query.toLowerCase()
              ? "text.primary"
              : "inherit",
        }}
      >
        {part}
      </span>
    ));
  };

  return (
    <Card>
      <CardContent
        sx={{
          "&:last-child": {
            paddingBottom: 2,
          },
        }}
      >
        <Autocomplete
          size="small"
          autoComplete
          clearOnBlur={false}
          PaperComponent={CustomPaperComponent}
          inputValue={predictiveManager.prediction?.displayName}
          onChange={onSelectAddress}
          onInputChange={onSearch}
          options={predictiveQuery.data ? predictiveQuery.data.suggest : []}
          filterOptions={(x) => x}
          noOptionsText="No addresses"
          getOptionLabel={(option: LocalityResult) =>
            typeof option === "string" ? option : option.displayName
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Locality Search"
              helperText="Start typing to get auto-complete suggestions from the API"
            />
          )}
          renderOption={(predictiveManager, option, { inputValue }) => (
            <li {...predictiveManager}>
              <Typography>
                {highlightText(option.displayName, inputValue)}
              </Typography>
            </li>
          )}
        />
        <Box display="flex" flexDirection="column" gap={2} mt={1}>
          <Stack direction="row" spacing={1}>
            <TextField
              fullWidth
              size="small"
              name="stateTerritory"
              label="State/Territory"
              type="text"
              InputLabelProps={{ shrink: true }}
              value={localityResult?.stateTerritory ?? ""}
              disabled={!localityResult}
            />
            <TextField
              fullWidth
              size="small"
              name="postcode"
              label="Postcode"
              type="text"
              InputLabelProps={{ shrink: true }}
              value={localityResult?.postcode ?? ""}
              disabled={!localityResult}
            />
          </Stack>
        </Box>
      </CardContent>
    </Card>
  );
};
