import {
  LinearProgressProps,
  Box,
  LinearProgress,
  Typography,
} from "@mui/material";
import { isFailureStatus } from "./utils";
import { useEffect, useState } from "react";

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number; status: string }
) {
  const [counter, setCounter] = useState(0);

  var color: any = "info";
  if (isFailureStatus(props.status)) {
    color = "inherit";
  }

  useEffect(() => {
    const interval = setInterval(() => setCounter((prev) => prev + 2), 10);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          variant="determinate"
          color={color}
          sx={{
            height: "20px",
            borderRadius: 1,
            ...(props.status === "Running" && {
              "& .MuiLinearProgress-bar": {
                backgroundColor: `hsl(${counter}, 100%, 50%)`,
              },
            }),
          }}
          {...props}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        {!isFailureStatus(props.status) && (
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        )}
      </Box>
    </Box>
  );
}

export { LinearProgressWithLabel };
