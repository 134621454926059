import {
  Box,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Slider,
  Stack,
  TextField,
} from "@mui/material";
import { CRS, CRSMappings, CRSValues, State, States } from "./models";
import { PredictiveSuburbManager } from "./predictive-suburb-manager";

export const DemoControls = (predictiveManager: PredictiveSuburbManager) => {
  const handleCrsChange = async (event: SelectChangeEvent<CRS>) => {
    const {
      target: { value },
    } = event;
    predictiveManager.setCrs(value as CRS);
  };

  const handleResultsChange = (event: Event, newValue: number | number[]) => {
    predictiveManager.setMaxResults(newValue as number);
  };

  const handleStateChange = async (event: SelectChangeEvent<State[]>) => {
    const {
      target: { value },
    } = event;
    predictiveManager.setStateFilter(
      // On autofill we get a stringified value.
      (typeof value === "string" ? value.split(",") : value) as State[]
    );
  };

  const handlePostcodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    predictiveManager.setPostcode(event.target.value);
  };

  const handleDebounceChange = (event: Event, newValue: number | number[]) => {
    predictiveManager.setDebounce(newValue as number);
  };

  const debounceValueFormat = (value: number) => {
    return `${value}ms`;
  };

  return (
    <Card>
      <CardContent
        sx={{
          "&:last-child": {
            paddingBottom: 2,
          },
        }}
      >
        <Stack direction="row" spacing={2}>
          <Stack direction="column" spacing={2} width="100%">
            <Box display="flex" flexDirection="column">
              <InputLabel sx={{ alignSelf: "center" }}>
                Maximum Number of Results
              </InputLabel>
              <Slider
                size="small"
                value={predictiveManager.maxResults}
                marks
                step={1}
                valueLabelDisplay="auto"
                onChange={handleResultsChange}
                min={1}
                max={20}
              />
            </Box>
            <Box display="flex" flexDirection="column">
              <InputLabel sx={{ alignSelf: "center" }}>
                Client De-bounce
              </InputLabel>
              <Slider
                size="small"
                value={predictiveManager.debounce}
                marks
                step={100}
                valueLabelDisplay="auto"
                onChange={handleDebounceChange}
                min={100}
                max={1000}
                valueLabelFormat={debounceValueFormat}
              />
            </Box>
          </Stack>
          <FormControl size="small" fullWidth>
            <InputLabel id="state-label">State Filter</InputLabel>
            <Select
              fullWidth
              size="small"
              labelId="state-label"
              id="state-filter"
              multiple
              value={predictiveManager.stateFilter}
              onChange={handleStateChange}
              input={<OutlinedInput label="State Filter" />}
              renderValue={(selected) => selected.join(", ")}
            >
              {States.map((state) => (
                <MenuItem key={state} value={state}>
                  <Checkbox
                    checked={predictiveManager.stateFilter.includes(state)}
                  />
                  <ListItemText primary={state} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            size="small"
            label="Postcode"
            variant="outlined"
            onChange={handlePostcodeChange}
          />
          <FormControl size="small" fullWidth>
            <InputLabel id="crs-label" size="small">
              CRS
            </InputLabel>
            <Select
              fullWidth
              size="small"
              labelId="crs-label"
              id="crs"
              value={predictiveManager.crs}
              onChange={handleCrsChange}
              input={<OutlinedInput label="CRS" />}
            >
              {CRSValues.map((crs) => (
                <MenuItem key={crs} value={crs}>
                  <ListItemText
                    sx={{ mt: 0, mb: 0 }}
                    primary={CRSMappings.get(crs)}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </CardContent>
    </Card>
  );
};
