import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useRef, useState } from "react";
import {
  FillLayer,
  Layer,
  LineLayer,
  MapRef,
  Marker,
  Source,
} from "react-map-gl";

// import "../popup.css";

import { useFormLogic } from "./cards/custom-hooks";
// import { Season, Style } from "./cards/types";
import { Box } from "@mui/material";
import { Basemap } from "components/basemap/basemap";
import { useCustomSnackbars } from "components/snackbars/useCustomSnackbars";
import * as QueryKeys from "data";
import { fetchAuthJWT, fetchMapsLayerJson } from "data/queries";
import { requestTransformer } from "pages/geoscape-data/explorer/explorer-page";
import { TileJSON } from "pages/geoscape-data/explorer/layers/models";
import { ZoomControl } from "pages/geoscape-data/explorer/zoom-control";
import { useQuery } from "react-query";
import { backStep, nextStep } from "../demo-example/utils";
import { ActionCard } from "./cards/action-card";
import { useFloodDemoState } from "./custom-hooks/useEmissionMetricState";
import { useMapControls } from "./custom-hooks/useMapControls";
import { MapLayer, stepsDemos } from "./step-config-file/stepConfigFile";
import { GeoJsonDemoMapLayer, Style } from "./step-config-file/types";

export const FloodDataPage = () => {
  const FREE_EXPLORE_STEP: number = 2;
  const COMPARE_MAPS_STEP: number = 3;

  const initialZoom: number = 4;

  const {
    demoStep,
    attributes,
    dynamicZoom,
    markerPosition,
    markerShow,
    selectedLayer,
    setDemoStep,
    setAttributes,
    setDynamicZoom,
    setMarkerPosition,
    setShowMarker,
    setSelectedLayer,
  } = useFloodDemoState();

  const currentStepDemo = stepsDemos[demoStep];
  const previousStepDemo = stepsDemos[demoStep - 1] ?? stepsDemos[1];
  const nextStepDemo = stepsDemos[demoStep + 1] ?? stepsDemos[1];
  const CurrentComponentStep = currentStepDemo.component;
  const currentMapConfigStep = currentStepDemo.mapConfig;

  const mapRef = useRef<MapRef>(null);

  const isInteractiveStep =
    currentStepDemo.id === FREE_EXPLORE_STEP ||
    currentStepDemo.id === COMPARE_MAPS_STEP;

  // useMapControls -> Has the logic for Fly To, Map Interaction Controls
  const {
    boundingBox,
    minZoom,
    coordinates,
    resetCompass,
    handleZoom,
    handleMarker,
    onClick,
    moveToNextLocation,
    highlightedLayer,
  } = useMapControls({
    nextStepDemo,
    mapRef,
    setAttributes,
    setSelectedLayer,
    setMarkerPosition,
    setShowMarker,
    setDynamicZoom,
    isInteractiveStep,
  });

  const { formState, handleFormChange } = useFormLogic({
    // people: 1,
    // season: Season.Summer,
    style: Style.five_year,
  });

  const { enqueueQueryFailed } = useCustomSnackbars();
  const [cadastreLayerJson, setCadastreLayerJson] = useState<TileJSON | null>(
    null
  );
  const [buildingsLayerJson, setBuildingsLayerJson] = useState<TileJSON | null>(
    null
  );

  const jwt = useQuery([QueryKeys.jwt], () => fetchAuthJWT(), {
    onError: (error: Error) => {
      enqueueQueryFailed(error.toString());
    },
  });

  const addAuthToTileUrl = (tileUrl: string[]) => {
    return tileUrl.map((x) => `${x}?key=${jwt.data}`);
  };

  const cadastreLayerJsonQuery = useQuery(
    [QueryKeys.mapsLayerJson, "cadastre"],
    () => fetchMapsLayerJson(jwt.isSuccess ? jwt.data : "", "cadastre"),
    {
      enabled: !!jwt.data,
      retry: true,
      onSuccess: (json: TileJSON) => {
        setCadastreLayerJson(json);
      },
    }
  );

  const buildingsLayerJsonQuery = useQuery(
    [QueryKeys.mapsLayerJson, "buildings"],
    () => fetchMapsLayerJson(jwt.isSuccess ? jwt.data : "", "buildings"),
    {
      enabled: !!jwt.data,
      retry: true,
      onSuccess: (json: TileJSON) => {
        console.log("here");
        setBuildingsLayerJson(json);
      },
    }
  );

  const cadastreLayer: LineLayer = {
    id: "cadastre",
    type: "line",
    source: "cadastre",
    "source-layer": "cadastre",
    paint: {
      "line-color": [
        "interpolate",
        ["linear"],
        ["zoom"],
        14,
        "hsla(0, 0%, 40%, 0)", // Base width at zoom 14
        16,
        "hsla(0, 0%, 40%, 0.5)", // Same width at max zoom
      ],
      "line-width": [
        "interpolate",
        ["exponential", 2],
        ["zoom"],
        14,
        0.5, // Base width at zoom 14
        16,
        1, // Same width at max zoom
      ],
    },
  };

  const buildingsLayer: FillLayer = {
    id: "buildings",
    type: "fill",
    source: "buildings",
    "source-layer": "buildings",
    paint: {
      "fill-color": [
        "interpolate",
        ["linear"],
        ["zoom"],
        14,
        "hsla(0, 0%, 40%, 0)", // Green for low values
        16,
        "hsla(0, 0%, 40%, 0.5)",
      ],
      "fill-opacity": 1,
    },
  };

  return (
    // Here we define the layers. Layers that go on the top of the map and containt the GeoJson information - Geometry, Properties, etc.
    <>
      <Basemap
        mapRef={mapRef}
        attributionControl={true}
        latitude={coordinates.latitude}
        longitude={coordinates.longitude}
        zoom={initialZoom}
        onZoom={(zoom) => setDynamicZoom(zoom)}
        onClick={onClick}
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          left: 0,
          top: 0,
        }}
        minZoom={minZoom}
        boundingBox={boundingBox}
        transformRequest={requestTransformer}
      >
        {/* Display the Card Component defined in the Steps */}
        <CurrentComponentStep
          moveToNextLocation={moveToNextLocation}
          nextStep={() => nextStep(setDemoStep)}
          backStep={() => backStep(setDemoStep)}
          previousCoordinates={previousStepDemo.coordinates}
          previousZoomLevel={previousStepDemo.mapConfig.zoomLevel}
          nextCoordinates={nextStepDemo.coordinates}
          zoomLevel={nextStepDemo.mapConfig.zoomLevel}
          attributes={attributes}
          selectedLayer={selectedLayer}
          handleMarker={() => handleMarker()}
          formState={formState} // This contain the style key word
          {...currentStepDemo}
          // {...currentStepDemo} // Uncomment to pass all properties from currentStepDemo
        />

        {/* The Marker and the Action Card is display in the Free Explore Step */}
        {currentStepDemo.id == FREE_EXPLORE_STEP && (
          <>
            {markerShow && (
              <Marker
                key={"selected-address"}
                longitude={markerPosition.longitude}
                latitude={markerPosition.latitude}
                style={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                }}
              >
                <LocationOnIcon fontSize="large" color="error" />
              </Marker>
            )}

            <Box
              sx={{
                left: 15,
                bottom: 40,
                position: "absolute",
                display: "flex",
                gap: "10px",
                flexDirection: "column",
                justifyContent: "flex-end",
                paddingLeft: "10px",
              }}
            >
              <ActionCard
                formState={formState}
                onFormChange={handleFormChange}
              />
            </Box>
            <Box
              sx={{
                right: 15,
                top: 80,
                position: "absolute",
                display: "flex",
                gap: "10px",
                flexDirection: "column",
                justifyContent: "flex-end",
                paddingLeft: "10px",
              }}
            >
              <ZoomControl
                resetCompass={resetCompass}
                dynamicZoom={dynamicZoom}
                onZoom={handleZoom}
              />
            </Box>

            {currentStepDemo.geojsonLayers &&
              currentStepDemo.geojsonLayers.map(
                (layer: GeoJsonDemoMapLayer) => {
                  // console.log("Rendering layer ", layer);

                  return (
                    <MapLayer
                      geojsonPath={layer.geojsonPath}
                      formState={formState}
                      layer_name={layer.layerName}
                      highlightedLayer={highlightedLayer}
                      style={layer.createStyle(formState)}
                      beforeId={layer.beforeId}
                    />
                  );
                }
              )}
          </>
        )}
        {cadastreLayerJson && (
          <Source
            key="cadastre"
            id="cadastre"
            type="vector"
            maxzoom={16}
            minzoom={14}
            tiles={addAuthToTileUrl(cadastreLayerJson.tiles)}
          >
            <Layer {...cadastreLayer} />
          </Source>
        )}
        {/* {buildingsLayerJson && (
          <Source
            key="buildings"
            id="buildings"
            type="vector"
            maxzoom={14}
            minzoom={14}
            tiles={addAuthToTileUrl(buildingsLayerJson.tiles)}
          >
            <Layer {...buildingsLayer} />
          </Source>
        )} */}
      </Basemap>
    </>
  );
};
