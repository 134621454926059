import { Box, ListItemText, Tooltip, Typography } from "@mui/material";
import { Dictionary, ReleaseEventSummary } from "data/models";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { productCodeToNameMappings } from "pages/geoscape-data/constants";
import { formatBytes } from "utils/commons";
import { formatProductName } from "./utils";

interface ProductListProps {
  datasets?: ReleaseEventSummary[];
}

const renderStatistics = (error_dict: Dictionary<number>) => {
  return (
    <>
      {Object.keys(error_dict).map((key) => {
        return (
          <>
            <ListItemText
              primary={
                <Typography
                  component="div"
                  variant="body2"
                  style={{ whiteSpace: "pre-line" }}
                >
                  {`${error_dict[key]} document(s) failed: "${key}"`}
                </Typography>
              }
            />
          </>
        );
      })}
    </>
  );
};

const groupCountByErrorType = (
  dataset: ReleaseEventSummary
): Dictionary<number> => {
  let error_dict: Dictionary<number> = {};
  dataset.failed.forEach((failed) => {
    error_dict[failed.error] = error_dict[failed.error]
      ? error_dict[failed.error] + 1
      : 1;
  });

  return error_dict;
};

const formatProductLoadedText = (dataset: ReleaseEventSummary): string => {
  return `${formatProductName(dataset.product)} - ${
    dataset.success_count
  }/${dataset.success_count + dataset.failed_count} Datasets`;
};

export const ProductList = (props: ProductListProps) => {
  let totalSize = 0;
  return (
    <>
      {props.datasets?.map((dataset) => {
        totalSize += dataset.size;
        return (
          <>
            <ListItemText
              primary={
                <Box display="flex">
                  <Typography
                    component="div"
                    variant="body2"
                    style={{ whiteSpace: "pre-line" }}
                  >
                    {formatProductLoadedText(dataset)}
                  </Typography>
                  {!!dataset.failed_count && (
                    <Tooltip
                      title={renderStatistics(groupCountByErrorType(dataset))}
                    >
                      <WarningAmberIcon fontSize="small" color="warning" />
                    </Tooltip>
                  )}
                </Box>
              }
            />
          </>
        );
      })}
      {props.datasets && props.datasets?.length > 0 && (
        <>
          <ListItemText
            primary={
              <Typography
                component="div"
                variant="body2"
                style={{ whiteSpace: "pre-line" }}
              >
                {`Total - ${formatBytes(totalSize)}`}
              </Typography>
            }
          />
        </>
      )}
    </>
  );
};
