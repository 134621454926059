import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom";

const BackButton = () => {
  const history = useHistory();

  return (
    <Button
      variant="contained"
      size="medium"
      startIcon={<ArrowBackIcon />}
      onClick={() => history.goBack()}
    >
      Back
    </Button>
  );
};

export default BackButton;
