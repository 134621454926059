import * as QueryKeys from "data";
import { LoadingButton } from "@mui/lab";
import { Box, Stack, Typography } from "@mui/material";
import { getActiveOrganisation, useIdentity } from "contexts/identity-context";
import { Dataset, DatasetStatus, DatasetFiltersType } from "data/models";
import { changeDatasetStatus } from "data/mutations";
import { Dispatch, SetStateAction, useState } from "react";
import {
  QueryClient,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { useIntercom } from "react-use-intercom";
import { resolveIdentityId } from "utils/identity";
import { fetchAdminDatasets } from "data/queries";
import { useSnackbar, SnackbarKey } from "notistack";
import { SupportButton } from "components/snackbars/SupportButton";
import { CloseButton } from "components/snackbars/CloseButton";
import { useCustomSnackbars } from "components/snackbars/useCustomSnackbars";

type ChangeStatusProps = {
  selectedIds: string[];
  setSelectedIds: Dispatch<SetStateAction<string[]>>;
  queryState: DatasetFiltersType;
};

export const ChangeStatus = ({
  selectedIds,
  setSelectedIds,
  queryState,
}: ChangeStatusProps) => {
  const [identity] = useIdentity();
  const isUser = !getActiveOrganisation(identity);
  const identityId = resolveIdentityId(identity, isUser);

  const { show } = useIntercom();
  const queryClient = useQueryClient();

  const { enqueueSupportSnackbar } = useCustomSnackbars();

  const createReleaseMutation = useMutation(
    (status: DatasetStatus) =>
      changeDatasetStatus(selectedIds, status, identity),
    {
      onError: (error: Error) => {
        enqueueSupportSnackbar(
          `Unable to create release, please contact support. Error: ${error.toString()}`,
          "error"
        );
      },

      onSuccess: (result) => {
        queryClient.resetQueries({
          queryKey: [QueryKeys.datasetsSearchQuery, identityId, queryState],
          exact: true,
        });
      },
    }
  );

  return (
    <Box>
      <Stack
        spacing={2}
        direction="row"
        sx={{
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Typography variant="body2">{`${selectedIds.length} Datasets Selected`}</Typography>
        <LoadingButton
          variant="contained"
          loading={createReleaseMutation.isLoading}
          onClick={() => createReleaseMutation.mutate("published")}
        >
          Publish
        </LoadingButton>
        <LoadingButton
          variant="contained"
          loading={createReleaseMutation.isLoading}
          onClick={() => createReleaseMutation.mutate("internal")}
        >
          Unpublish
        </LoadingButton>
      </Stack>
    </Box>
  );
};
