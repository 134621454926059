import { BaseState } from "common/models";
import { PortalPlan, PortalSubscription } from "data/models";

export type UsageBucket = {
  timestamp: string;
  value: number;
  recordCount: number;
};

export type AppUsage = {
  recordCount: number;
  totalCredits: number;
  buckets: [UsageBucket];
  app: string;
};

export type AppTotal = {
  product: string;
  totalCredits: number;
};

export type AppTotals = {
  name: string;
  totalUsage: AppTotal[];
};

export type Usage = {
  recordCount: number;
  totalCredits: number;
  appGraphData: AppUsage[];
  appProductTotals: AppTotals[];
  intervalHours: number;
};

export enum DateRange {
  DAY = "Last 24 hours",
  WEEK = "Last 7 days",
  TWO_WEEKS = "Last 14 days",
  MONTH = "Last 30 days",
  THREE_MONTHS = "Last 90 days",
}

export interface UsageState {
  startTime: number;
  endTime: number;
  usage?: Usage;
  duration: DateRange;
}

export interface IApiSubscription {
  productId: string;
  name: string;
  description: string;
  overageRate: number;
  creditAmount: number;
  active: boolean;
  colour: string;
  isEnterprise: boolean;
  visualOrder: number;
  price: number;
  subscriptionId: string;
}

export interface SubscriptionState extends BaseState {
  activeSubscription: PortalSubscription;
  availableSubscriptions: PortalPlan[];
  flush: boolean;
}

export enum PeriodType {
  Day,
  Hour,
}

export interface BillingUsageState extends BaseState {
  creditsUsedTotal: number;
  overageDollars: number;
}

export interface Organisation {
  id?: string;
  name: string;
  email: string;
  abn?: number;
  address?: string | null;
  phoneNumber?: string | null;
  industry?: string | null;
}

export interface OrganisationState extends BaseState {
  organisation: Organisation;
}
