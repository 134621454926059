export const openDownloadLinkCallback = (url: string): () => Promise<void> => {
    return async () => {
        const a = document.createElement("a");
        a.href = url;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }
}

