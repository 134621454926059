import CloseIcon from "@mui/icons-material/Close";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Draggable from "react-draggable";
import { StepComponentProps } from "../step-config-file/types";

interface InsightItem {
  label: string | any;
  value: string | number | any;
}

// Generic function to handle rendering of table based on insight type and data.
const renderInsightTable = <T extends InsightItem>(
  data: T[],
  title: string
) => (
  <>
    <Typography
      variant="h6"
      color={"textPrimary"}
      style={{ marginTop: "20px", fontWeight: 505 }}
    >
      {title}
    </Typography>

    <Table size="small" sx={{ border: "none" }}>
      <TableBody>
        {data.map((item, index) => (
          <TableRow key={index}>
            <TableCell
              component="th"
              scope="row"
              sx={{ border: "none", padding: "0px" }}
            >
              <Typography variant="body1" sx={{ padding: "0px" }}>
                {item.label}
              </Typography>
            </TableCell>
            <TableCell sx={{ border: "none", padding: "0px" }} align="right">
              <Typography variant="body1" sx={{ padding: "0px" }}>
                {item.value}
              </Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </>
);

interface ClimateInsightsProps {
  address: string;
  climateInsights: InsightItem[];
  buildingDetails: InsightItem[];
  season: string;
  people: number;
  setCloseFeatureCard: any;
}

const ClimateInsightsComponent: React.FC<ClimateInsightsProps> = ({
  address,
  climateInsights,
  buildingDetails,
  people,
  season,
  setCloseFeatureCard,
}) => {
  const initialPosition = {
    x: Math.min(window.innerWidth * 0.7, window.innerWidth - 700),
    y: window.innerHeight * 0.1,
  };
  const [position, setPosition] = useState(initialPosition);
  const nodeRef = useRef(null);

  return (
    <Draggable
      nodeRef={nodeRef}
      position={position}
      onStop={(e, data) => setPosition({ x: data.x, y: data.y })}
    >
      <Card
        ref={nodeRef}
        className={"docomdo"}
        sx={{
          display: "flex",
          width: "400px",

          paddingBottom: "0px",
        }}
      >
        <CardContent
          ref={nodeRef}
          sx={{
            paddingBottom: "0",
            margin: 0,
          }}
        >
          <CardHeader
            sx={{
              border: "none",
              cursor: "move",
              padding: 0,
            }}
            title={address}
            action={
              <IconButton
                aria-label="close"
                onClick={() => {
                  //   setExpanded(false);
                  setCloseFeatureCard(true);
                }}
              >
                <CloseIcon />
              </IconButton>
            }
          />

          {/* Render tables using generic function */}
          {renderInsightTable(climateInsights, `Climate Insights`)}
          <Divider
            sx={{
              paddingTop: "10px",
            }}
          />
          {renderInsightTable(buildingDetails, `Building Details`)}
        </CardContent>
      </Card>
    </Draggable>
  );
};
// Types
interface ClimateProperties {
  climate: number;
  ci: number;
  solar: string;
  pool: string;
  net_let_area: number;
  floors: number;
  area: number;
  electric_consumption: number | string;
  gas_consumption: number | string;
  elec_net_area: number | string;
  gas_net_area: number | string;
  electric_CO2_annually: number | string;
  electric_CO2_season: number | string;
  gas_CO2_season: number | string;
  gas_CO2_annually: number | string;
  total_CO2_annually: number | string;
  total_CO2_annually_per_m2: number | string;
  emitted_co2: string;
  emitted_co2_sqm: string;
  volume: number;
  roof_material: string;
  roof_slope: string;
  building_height: string;
  eave_height: string;
  roof_shape: string;
  roof_colour: string;
}

// Utility functions
const getPropertyValue = (
  properties: Record<string, any>,
  key: string,
  people: number,
  season: string
): number | string => {
  return (
    Number(properties[`${key}_${people}_${season}`]).toFixed(2) || "No data"
  );
};

const getAnnualPropertyValue = (
  properties: Record<string, any>,
  key: string,
  people: number
): number | string => {
  return Number(properties[`${key}_${people}_Annual`]).toFixed(2) || "No data";
};

export const App2 = (props: StepComponentProps) => {
  const { properties } = props.attributes;

  //   @ts-ignore
  const { people, season } = props.formState;

  const climateProperties = useMemo<ClimateProperties>(
    () => ({
      climate: properties.climate,
      ci: properties.ci,
      solar: properties.solar,
      pool: properties.pool,
      net_let_area: properties.net_let_area,
      floors: properties.floors,
      area: properties.area,
      electric_consumption: getPropertyValue(
        properties,
        "elec_con",
        people,
        season
      ),
      gas_consumption: getPropertyValue(properties, "gas_con", people, season),
      elec_net_area: getPropertyValue(
        properties,
        "elec_net_area",
        people,
        season
      ),
      gas_net_area: getPropertyValue(
        properties,
        "gas_net_area",
        people,
        season
      ),
      electric_CO2_season: getPropertyValue(
        properties,
        "elec_co2_kg_yr",
        people,
        season
      ),
      electric_CO2_annually: getAnnualPropertyValue(
        properties,
        "elec_co2_kg_yr",
        people
      ),
      gas_CO2_season: getPropertyValue(
        properties,
        "gas_co2_kg_yr",
        people,
        season
      ),
      gas_CO2_annually: getAnnualPropertyValue(
        properties,
        "gas_co2_kg_yr",
        people
      ),
      total_CO2_annually: getAnnualPropertyValue(
        properties,
        "total_co2_kg_yr",
        people
      ),
      total_CO2_annually_per_m2: getAnnualPropertyValue(
        properties,
        "total_co2_kg_m2_yr",
        people
      ),
      emitted_co2: properties.emitted_c02_kg_yr,
      emitted_co2_sqm: properties.emitted_c02_kg_m2_yr,
      volume: properties.volume,
      roof_material: properties.primary_roof_material,
      roof_slope: properties.roof_slope,
      building_height: properties.roof_height,
      eave_height: properties.eave_height,
      roof_shape: properties.roof_shape,
      roof_colour: properties.roof_colour,
    }),
    [properties, people, season]
  );

  const climateInsights: InsightItem[] = useMemo(
    () => [
      { label: "Climate Zone", value: climateProperties.climate },
      {
        label: "Carbon Intensity Elec",
        value: `${climateProperties.ci} g CO₂/kW`,
      },
      {
        label: "Net Lettable Area",
        value: (
          <>
            {climateProperties.net_let_area} m<sup>2</sup>
          </>
        ),
      },
      {
        label: "Has Solar Panels?",
        value: climateProperties.solar,
      },
      {
        label: "Has Swimming Pool?",
        value: climateProperties.pool,
      },

      {
        label: "Area",
        value: (
          <>
            {climateProperties.area} m<sup>2</sup>
          </>
        ),
      },

      {
        label: (
          <>
            Emitted CO<sub>2</sub>
          </>
        ),
        value: `${Number(climateProperties.emitted_co2).toFixed(2)} Kg`,
      },
      {
        label: (
          <>
            Emitted CO<sub>2</sub> sqm
          </>
        ),
        value: `${Number(climateProperties.emitted_co2_sqm).toFixed(2)} Kg`,
      },
    ],
    [climateProperties]
  );

  const buildingDetails: InsightItem[] = useMemo(
    () => [
      {
        label: "Volume",
        value: (
          <>
            {climateProperties.volume} m<sup>3</sup>
          </>
        ),
      },
      { label: "Number of Floors", value: climateProperties.floors }, // Duplicate
      {
        label: "Net Lettable Area",
        value: (
          <>
            {climateProperties.net_let_area} m<sup>2</sup>
          </>
        ),
      },
      { label: "Roof Material", value: climateProperties.roof_material },
      { label: "Roof Slope", value: `${climateProperties.roof_slope} Deg` },
      {
        label: "Building Height",
        value: `${climateProperties.building_height} m`,
      },
      { label: "Eave Height", value: `${climateProperties.eave_height} m` },
      { label: "Roof Shape", value: climateProperties.roof_shape },
      { label: "Roof Colour", value: climateProperties.roof_colour },
    ],
    [climateProperties]
  );

  const [closeFeatureCard, setCloseFeatureCard] = useState(false);

  useEffect(() => {
    props.attributes.layer.id == props.layer_name;
    setCloseFeatureCard(false);
  }, [props.attributes]);

  if (props.attributes.layer.id == props.layer_name && !closeFeatureCard) {
    return (
      <ClimateInsightsComponent
        address={props.attributes.properties.address}
        climateInsights={climateInsights}
        buildingDetails={buildingDetails}
        season={season}
        people={people}
        setCloseFeatureCard={setCloseFeatureCard}
      />
    );
  }

  return null;
};
export default ClimateInsightsComponent;
