import React from "react";
import { FormState } from "../step-config-file/types";
// import { FormState } from "./types";

export const useFormLogic = (initialState: FormState) => {
  const [formState, setFormState] = React.useState<FormState>(initialState);

  const handleFormChange = (newState: Partial<FormState>) => {
    setFormState((prevState: any) => ({ ...prevState, ...newState }));
  };

  return { formState, handleFormChange };
};
