

const parseDateString = (dateString: any) => {
    const [day, month, year] = dateString.split("/").map(Number);
    return new Date(year, month - 1, day);
};

// This function could be optimized since we know there is only one column with field "Date",
// but it is designed this way to have safety and flexibility for future changes.
export const modifyColumns = (columns: any): any => {
    return columns.map((column: any) => {
        // Add sortComparator (This allow to compare the Date Rows that are strings) for the "Date" column
        if (column.field === "Date") {
            column = {
                ...column,
                sortComparator: (v1: any, v2: any) => {
                    const date1 = parseDateString(v1);
                    const date2 = parseDateString(v2);
                    return date1.getTime() - date2.getTime();
                }
            };
        }

        // Add valueGetter - This ensures that fields without a value are filled with 0
        if (!column.valueGetter) {
            column = {
                ...column,
                valueGetter: (params: any) => params ?? 0,
            };
        }

        return column;
    });
};