import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  Typography,
} from "@mui/material";
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import * as Routes from "routes";
import { FormState, Season, Style } from "../step-config-file/types";
// import { FormState } from "./types";

interface FormComponentProps {
  formState: FormState;
  onFormChange: (newState: Partial<FormState>) => void;
}

export const FormComponent: React.FC<FormComponentProps> = ({
  formState,
  onFormChange,
}) => {
  const history = useHistory();
  const { showNewMessage } = useIntercom();

  const updateStyleKeyWord = useCallback(
    (style: Style, people: number, season: Season): string | undefined => {
      if (style === Style.Co2Annually) {
        return `${style}_${people}_${season}`;
      }
      return style;
    },
    []
  );

  // Checkbox
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked, type } = event.target;
    onFormChange({
      [name]: type === "checkbox" ? checked : event.target.value,
    });
  };

  // Checkbox Handler

  const handleSelectChange = useCallback(
    (event: SelectChangeEvent<number | string>) => {
      const { name, value } = event.target;

      let updatedState: Partial<FormState> = {
        [name]: name === "people" ? Number(value) : value,
      };

      // Recalculate style_key_word if any relevant field changes
      const updatedPeople =
        name === "people" ? Number(value) : formState.people;
      const updatedSeason =
        name === "season" ? (value as Season) : formState.season;
      const updatedStyle =
        name === "style" ? (value as Style) : formState.style;

      const newStyleKeyWord = updateStyleKeyWord(
        updatedStyle,
        updatedPeople,
        updatedSeason
      );

      if (newStyleKeyWord) {
        updatedState.style_key_word = newStyleKeyWord;
      }

      onFormChange(updatedState);
    },
    [formState, onFormChange, updateStyleKeyWord]
  );

  interface MenuItemData {
    key: string;
    value: string;
  }

  // MenuItemData This contains the properties that will be needed to be mapped from the Emisison metrics Geo-dataset
  const menuItems: MenuItemData[] = [
    { key: "Emitted CO₂", value: Style.Co2Annually },
    { key: "Solar Panel", value: Style.SolarPanel },
    { key: "Swimming Pool", value: Style.Pool },
    { key: "Roof Colour", value: Style.RoofColour },
  ];

  return (
    <Card sx={{ borderRadius: 2, margin: "0px" }}>
      <CardContent
        sx={{
          width: 170,
          padding: "16px",
          margin: "0px",
          border: "px solid #ccc",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", mb: "8px" }}>
          <Switch
            name="extrude"
            checked={formState.extrude}
            onChange={handleChange}
          />
          <Typography>Extrude?</Typography>
        </Box>

        <FormControl fullWidth sx={{ mb: 1.5 }}>
          <InputLabel>Style By</InputLabel>
          <Select
            name="style"
            value={formState.style}
            label="Style By"
            onChange={handleSelectChange}
            size="small"
          >
            {menuItems.map((s) => (
              <MenuItem value={s.value}>{s.key}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          variant="outlined"
          color="warning"
          size="small"
          fullWidth
          onClick={() => {
            history.push(Routes.demosPage);
          }}
          sx={{
            border: "none",
            state: "enabled",
            justifyContent: "flex-start",
            padding: "0px",
            mb: 1,
            ":hover": {
              backgroundColor: "none",
              border: "none",
            },
            ":focus": {
              backgroundColor: "none",
              border: "none",
            },
          }}
        >
          EXIT
        </Button>

        <Button
          variant="outlined"
          color="primary"
          size="small"
          fullWidth
          onClick={() =>
            showNewMessage(`I’d like to talk to someone about “Emissions Data"`)
          }
          sx={{
            border: "none",
            justifyContent: "flex-start",
            padding: "0px",
            mb: 0,
            ":hover": {
              backgroundColor: "none",
              border: "none",
            },
            ":focus": {
              backgroundColor: "none",
              border: "none",
            },
          }}
        >
          ASK A QUESTION
        </Button>
      </CardContent>
    </Card>
  );
};
