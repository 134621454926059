import { useIdentity, User } from "contexts/identity-context";
import { customDatasetsCreate } from "data/mutations";
import { useCallback, useState } from "react";
import { FileWithPath, useDropzone } from "react-dropzone";
import { useMutation } from "react-query";
import { useParams } from "react-router";
import { generatePath, useHistory } from "react-router-dom";

import * as Routes from "routes";
import {
  CreateCustomDatasetsMutationParams,
  CustomDatasetMutationRespose,
  DatasetError,
  UploadProgressMap,
  UseS3FileUploadDropperResult,
} from "./types";
import {
  completeMultipartUpload,
  getMultipartUploadData,
  multiPartUpload,
} from "./utils";

function createInitialUploadProgress() {
  const initialUploadProgress = {
    parts: [],
    percentComplete: -1,
    uploadDuration: -1,
    bytesPerSecond: -1,
    bytesRemaining: -1,
    estimatedSecondsRemaining: -1,
    averagingWindow: [],
    humanisedTimeRemaining: "??",
  };
  return initialUploadProgress;
}

type S3FileUploadDropperOptions = {
  maxFiles?: number;
  maxSize?: number;
  prefixFileName?: string;
  acceptExtension?: { [key: string]: string[] }; // { mime type: [extensions] }. https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types. Empty object allows all extensions.
};

export function useS3FileUploadDropper(
  options: S3FileUploadDropperOptions = {}
): UseS3FileUploadDropperResult {
  const {
    maxFiles = 0,
    maxSize = 51 * 1024 * 1024 * 1024,
    prefixFileName = "",
    acceptExtension = {},
  } = options;

  const awsRegion = "ap-southeast-2";

  const [uploadProgress, setUploadProgress] = useState<UploadProgressMap>({});
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [haveUploaded, setHaveUploaded] = useState<boolean>(false);
  const [multiPartFinalised, setMultiPartFinalised] = useState<string[]>([]);
  const [s3UploadFailed, setS3UploadFailed] = useState<boolean>(false);

  const [files, setFiles] = useState<FileWithPath[]>([]);

  type FileUploadAbortMap = { [key: string]: any };

  const [uploadAborts, setUploadAborts] = useState<FileUploadAbortMap>({});

  const [identity] = useIdentity();

  // Uploading has finished if the sum of the uploading progress is equal to the number of files being uploaded

  if (
    isUploading &&
    multiPartFinalised.length === files.length &&
    (Object.values(uploadProgress)
      .map((x) => x.percentComplete)
      .reduce((a: number, b: number) => a + b, 0) as number) /
      100 ===
      files.length
  ) {
    if (files.length > 0) {
      setIsUploading(false);
      setHaveUploaded(true);
    } else {
      // Something went wrong and we are at 100% progress with 0 files
      setIsUploading(false);
    }
  }

  const uploadFiles = async (bucket_name: string) => {
    setIsUploading(true);
    setUploadAborts({});

    try {
      await Promise.all(
        files.map(async (acceptedFile: FileWithPath, index: number) => {
          if (!acceptedFile.path) {
            return;
          }
          const controller = new AbortController();

          let fileName = acceptedFile.name;
          if (prefixFileName) {
            fileName = prefixFileName + acceptedFile.name;
          }
          const multiPartUploadData = await getMultipartUploadData(
            fileName,
            bucket_name,
            acceptedFile.size,
            identity
          );
          const upload = multiPartUpload(
            multiPartUploadData,
            acceptedFile,
            setUploadProgress,
            controller
          );

          setUploadAborts((prevValue) => {
            return {
              ...prevValue,
              [acceptedFile.path as string]: () => {
                controller.abort("User cancelled");
              },
            };
          });

          try {
            const uploadResult = await upload;
            await completeMultipartUpload(
              fileName,
              bucket_name,
              multiPartUploadData.uploadId,
              uploadResult,
              identity
            );
            setMultiPartFinalised((prev) => [...prev, fileName]);
          } catch (e) {
            throw e;
            // Handle error here if needed
          }
        })
      );
    } catch (e) {
      setS3UploadFailed(true);
    }
  };

  const onDrop = useCallback((acceptedFiles: FileWithPath[]) => {
    acceptedFiles = acceptedFiles.filter((x) => x.size > 0);
    const uniqueNewFiles = acceptedFiles
      .map((x) => (x.path ? x.path : ""))
      .filter(function (item, pos) {
        return acceptedFiles.map((x) => x.path).indexOf(item) == pos;
      });
    if (acceptedFiles.length !== uniqueNewFiles.length) {
      return;
    }

    let fileMap: UploadProgressMap = {};
    acceptedFiles.forEach((acceptedFile: FileWithPath) => {
      fileMap[acceptedFile.path as string] = createInitialUploadProgress();
    });
    setUploadProgress((oldProgress: UploadProgressMap) => {
      return {
        ...oldProgress,
        ...fileMap,
      };
    });

    setFiles((prevState: FileWithPath[]) => {
      const existingPaths = prevState.map((x) => x.path);

      return [
        ...prevState,
        ...acceptedFiles.filter((x) => !existingPaths.includes(x.path)),
      ];
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: maxFiles,
    maxSize: maxSize,
    accept: acceptExtension,
  });

  const removeFile = (fileName: string) => {
    setFiles((prevState) => {
      const newState = prevState.filter((x) => x.path != fileName);
      if (!newState.length) {
        setHaveUploaded(() => false);
      }
      setUploadProgress((prevState) => {
        delete prevState[fileName];
        return prevState;
      });
      return newState;
    });
    if (isUploading && fileName in uploadAborts) {
      uploadAborts[fileName]();
      delete uploadAborts[fileName];
      if (Object.keys(uploadAborts).length === 0) {
        setIsUploading(false);
      }
    }
    setMultiPartFinalised((prev) => prev.filter((x) => x !== fileName));
  };
  return {
    uploadProgress,
    isUploading,
    haveUploaded,
    files,
    removeFile,
    getRootProps,
    getInputProps,
    uploadFiles,
    s3UploadFailed,
  };
}

export function useSubmitCustomDataset(apigeeDeveloperId: string) {
  const history = useHistory();
  let { accountId }: any = useParams();

  const [identityState] = useIdentity();
  const [customDatasetsError, setCustomDatasetsError] = useState<
    DatasetError[]
  >([]);
  const [submitted, setSubmitted] = useState<string[]>([]);
  const [successful, setSuccessful] = useState<string[]>([]);

  const customDatasetsCreateMutation = useMutation(
    (values: CreateCustomDatasetsMutationParams) => {
      const datasets = values.values.map((dataset) => {
        dataset.publish = values.publish;
        return dataset;
      });
      return customDatasetsCreate(
        datasets,
        apigeeDeveloperId as string,
        identityState as User
      );
    },
    {
      onSuccess: (
        response: CustomDatasetMutationRespose,
        values: CreateCustomDatasetsMutationParams
      ) => {
        setSuccessful(
          values.values
            .filter(
              (x) => !response.errors.map((e) => e.id).includes(x.fileName)
            )
            .map((x) => x.fileName)
        );
        if (response.errors.length !== 0) {
          setCustomDatasetsError(response.errors);
        } else {
          history.push(
            generatePath(Routes.adminViewCustomerCustomDatasets, {
              accountId: accountId,
            })
          );
        }
      },
    }
  );

  return {
    customDatasetsCreateMutation,
    customDatasetsError,
    customDatasetsSuccessful: successful,
  };
}
