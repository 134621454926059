import { Button, Card, CardContent } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import * as Routes from "routes";

export const ActionCard = () => {
  const history = useHistory();
  const { showNewMessage } = useIntercom();

  return (
    <Card sx={{ borderRadius: 2, margin: "0px" }}>
      <CardContent
        sx={{
          width: 170,
        }}
      >
        <Button
          variant="text"
          color="warning"
          size="small"
          fullWidth
          onClick={() => {
            history.push(Routes.hub);
          }}
          sx={{
            border: "none",
            justifyContent: "flex-start",
          }}
        >
          EXIT
        </Button>

        <Button
          variant="text"
          color="primary"
          size="small"
          fullWidth
          onClick={() =>
            showNewMessage(`I’d like to talk to someone about Easements Data"`)
          }
          sx={{
            border: "none",
            justifyContent: "flex-start",
          }}
        >
          ASK A QUESTION
        </Button>
      </CardContent>
    </Card>
  );
};
