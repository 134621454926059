import { Card } from "@mui/material";
import { bbox } from "@turf/turf";
import { Basemap } from "components/basemap/basemap";
import { ClipConfiguration } from "pages/geoscape-data/explorer/clip/models";
import { useEffect, useRef } from "react";
import { FillLayer, Layer, LineLayer, MapRef, Source } from "react-map-gl";

interface AdminClipMapProps {
  clip: ClipConfiguration;
}

export const AdminClipMap = (props: AdminClipMapProps) => {
  const mapRef = useRef<MapRef>();

  useEffect(() => {
    if (mapRef.current) {
      let bounds: number[] = [];

      if (
        props.clip &&
        props.clip.geometry &&
        props.clip.geometry.features.length > 0
      ) {
        bounds = bbox(props.clip.geometry);
      } else {
        bounds = [96, -45, 168, -8];
      }
      mapRef.current.fitBounds(
        [
          [bounds[0], bounds[1]],
          [bounds[2], bounds[3]],
        ],
        {
          padding: 20,
          duration: 1000,
        }
      );
    }
  }, [props.clip]);

  const [minLng, minLat, maxLng, maxLat] =
    props.clip && props.clip.geometry && props.clip.geometry.features.length > 0
      ? bbox(props.clip.geometry)
      : // bounding box of Australia
        [96, -45, 168, -8];

  const dataLayer: FillLayer = {
    id: "data",
    type: "fill",
    paint: {
      "fill-color": "#0a0a0a",
      "fill-opacity": 0.4,
    },
  };

  const outlineLayer: LineLayer = {
    id: "outline",
    type: "line",
    paint: {
      "line-color": "#0a0a0a",
      "line-width": 1,
    },
  };

  return (
    <Card sx={{ width: "calc(100% / 3)", height: 756, padding: "0px" }}>
      {props.clip && (
        <Basemap
          // @ts-ignore
          mapRef={mapRef}
          attributionControl={false}
          onZoom={(zoom) => {}}
          style={{
            width: "100%",
            height: "756px",
          }}
          interactive={false}
          bbox={[minLng, minLat, maxLng, maxLat]}
          bboxPadding={50}
        >
          <Source type="geojson" data={props.clip.geometry}>
            <Layer {...dataLayer} />
            <Layer {...outlineLayer} />
          </Source>
        </Basemap>
      )}
    </Card>
  );
};
