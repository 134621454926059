import {
  Box,
  FormControlLabel,
  Checkbox,
  Chip,
  Stack,
  Tooltip,
  checkboxClasses,
} from "@mui/material";
import { Attribute } from "../../models";
import { DataMapping } from "../step-2-choose-data/choose-data";

interface DataTreeProps {
  dataMappings?: DataMapping[];
  selected: Attribute[];
}

export const DataTree = (props: DataTreeProps) => {
  const isSelected = (selected: Attribute[], id: string, parentId?: string) => {
    if (parentId) {
      const parent = selected.find((s) => s.key == parentId);
      if (parent) {
        //@ts-ignore
        return parent.attributes.indexOf(id) > -1;
      }
      return false;
    } else {
      const item = selected.find((s) => s.key == id);
      return !!item;
    }
  };

  return (
    <>
      {props.dataMappings
        ?.filter((dataMapping) =>
          isSelected(props.selected, dataMapping.id, undefined)
        )
        .map((dataMapping) => (
          <Box display="flex" flexDirection="column" ml={3}>
            <Box>
              <FormControlLabel
                sx={{ mr: 1 }}
                label={dataMapping.name}
                control={
                  <Checkbox
                    sx={{
                      padding: 0,
                      [`&, &.${checkboxClasses.checked}`]: {
                        color: "grey",
                      },
                    }}
                    checked={isSelected(
                      props.selected,
                      dataMapping.id,
                      undefined
                    )}
                  />
                }
              />
              <Box display="flex" flexDirection="column" ml={3}>
                {dataMapping.children
                  ?.filter((child) =>
                    isSelected(props.selected, child.id, dataMapping.id)
                  )
                  .map((child) => (
                    <Stack direction="row">
                      <FormControlLabel
                        sx={{ mr: 1 }}
                        label={child.name}
                        control={
                          <Checkbox
                            sx={{
                              padding: "4px",
                              [`&, &.${checkboxClasses.checked}`]: {
                                color: "grey",
                              },
                            }}
                            checked={isSelected(
                              props.selected,
                              child.id,
                              dataMapping.id
                            )}
                          />
                        }
                      />
                    </Stack>
                  ))}
              </Box>
            </Box>
          </Box>
        ))}
    </>
  );
};
