import {
  Box,
  Button,
  Card,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { getActiveOrganisation, useIdentity } from "contexts/identity-context";
import * as QueryKeys from "data";
import {
  fetchBatchConfig,
  fetchBillingUsage,
  fetchOverage,
  fetchSubscription,
} from "data/queries";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import * as Routes from "routes";
import { resolveIdentityId } from "utils/identity";

import { LoadingButton } from "@mui/lab";
import { ReactQueryErrorWrapper } from "components/shared/react-query-error-wrapper";
import { quoteBatchJob, startBatchJob } from "data/mutations";
import {
  BatchConfigProps,
  StepperControlProps,
} from "pages/geoscape-batch/types";
import {
  JobType,
  UseOverageCreditsCard,
} from "pages/shared/use-overage-credits-card";
import { formatTimestampBatchJob } from "utils/format-date";

interface StartBatchJobProps {}

export const StartBatchJob = (
  props: StartBatchJobProps & BatchConfigProps & StepperControlProps
) => {
  const [identityState] = useIdentity();
  const isUser = !getActiveOrganisation(identityState);
  const identityId = resolveIdentityId(identityState, isUser);
  const history = useHistory();
  const theme = useTheme();

  // Queries
  const subscriptionQuery = useQuery([QueryKeys.subscription, identityId], () =>
    fetchSubscription(isUser, identityState)
  );

  const isEnterpriseOrMYOB =
    (subscriptionQuery.isSuccess &&
      subscriptionQuery.data.activeSubscription.plan.isEnterprise) ||
    (subscriptionQuery.isSuccess &&
      subscriptionQuery.data.activeSubscription.isMyob);

  const queryClient = useQueryClient();

  const [isEnoughCredits, setIsEnoughCredits] = useState(false);

  const [quotedBatchConfigAttempts, setQuotedBatchConfigAttempts] = useState(0);

  const quoteJobMutation = useMutation(
    [QueryKeys.batchesQuoteJob, identityId],
    () => quoteBatchJob(props.batchConfig.batchInfo.batchId, identityState)
  );

  // Fetch the batch config once it has been quoted
  const quotedBatchConfigQuery = useQuery(
    [QueryKeys.batchesQuotedBatchConfig, identityId],
    () => fetchBatchConfig(props.batchConfig.batchInfo.batchId, identityState),
    {
      onSuccess: (data) => {
        setQuotedBatchConfigAttempts((prev) => {
          const newCount = prev + 1;
          if (newCount % 5 === 0) {
            // Please keep this console log statement - AH
            console.log(`Sent quote mutation again, attempt ${newCount}`);
            quoteJobMutation.mutate();
          }
          return newCount;
        });

        if (
          data?.status === "Quoted" &&
          props.batchConfig.status !== "Quoted"
        ) {
          props.setBatchConfig(data);
        }
      },
      refetchInterval: (data, query) => {
        const refetchTime = 1000;
        if (data?.status != "Quoted") return refetchTime;

        return false;
      },
    }
  );

  const billingUsageQuery = useQuery([QueryKeys.billingUsage, identityId], () =>
    fetchBillingUsage(isUser, identityState)
  );

  const overageQuery = useQuery([QueryKeys.overage, identityId], () =>
    fetchOverage(isUser, identityState)
  );

  const startJobMutation = useMutation(
    [QueryKeys.batchesStartJob, identityId],
    () =>
      startBatchJob(
        props.batchConfig.batchInfo.batchId,
        props.batchConfig.displayName,
        props.batchConfig.batchInfo.totalRows
          ? props.batchConfig.batchInfo.totalRows
          : 0,
        props.batchConfig.batchInfo.estimatedCreditCost
          ? props.batchConfig.batchInfo.estimatedCreditCost
          : 0,
        identityState
      ),
    {
      onSuccess: () => {
        history.push(Routes.batchBatches);
      },
    }
  );

  // Run only once when the page loads
  useEffect(() => {
    quoteJobMutation.mutate();
  }, []);

  const isLoading =
    quoteJobMutation.isLoading ||
    billingUsageQuery.isLoading ||
    quotedBatchConfigQuery.isLoading ||
    overageQuery.isLoading ||
    quotedBatchConfigQuery.data?.status !== "Quoted";

  // We include !isLoading here because even though quotedBatchConfig may finish, we need to refetch until the job has been quoted
  const isSuccess =
    quoteJobMutation.isSuccess &&
    quotedBatchConfigQuery.isSuccess &&
    billingUsageQuery.isSuccess &&
    overageQuery.isSuccess &&
    quotedBatchConfigQuery.data?.status === "Quoted";

  var creditBalance = 0;
  if (isSuccess) {
    creditBalance =
      billingUsageQuery.data.allowance +
      overageQuery.data.creditsLimit -
      billingUsageQuery.data.creditsUsedTotal;

    if (isEnterpriseOrMYOB) {
      creditBalance = Math.max(creditBalance, 0);
    }
  }

  const remainingBalance = isSuccess
    ? creditBalance - quotedBatchConfigQuery.data.batchInfo.estimatedCreditCost
    : 0;

  const hasCredits = remainingBalance > 0;

  const insufficientCreditsColour = theme.palette.error.main;

  return (
    <Card
      sx={{
        padding: "16px",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <Box>
        <Typography variant="h5">Confirm and Start</Typography>

        <ReactQueryErrorWrapper
          queries={[
            subscriptionQuery,
            billingUsageQuery,
            overageQuery,
            quotedBatchConfigQuery,
          ]}
          mutations={[quoteJobMutation, startJobMutation]}
        />
      </Box>

      {isLoading && <CustomSkeleton />}

      {isSuccess && (
        <>
          <Box sx={{ display: "grid", gridAutoColumns: "1fr", gap: "16px" }}>
            <Card
              sx={{
                gridRow: "1",
                gridColumn: "1",
                padding: "16px",
                boxShadow: 0,
              }}
            >
              <Typography variant="h5" sx={{ marginBottom: "16px" }}>
                Batch Job Information
              </Typography>
              <TableContainer>
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell>Total Rows</TableCell>
                      <TableCell>
                        {quotedBatchConfigQuery.data.batchInfo.totalRows.toLocaleString()}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Estimated Time To Finish</TableCell>
                      <TableCell>
                        {formatTimestampBatchJob(
                          quotedBatchConfigQuery.data.batchInfo.estimatedRunTime
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Credit Balance</TableCell>
                      <TableCell>
                        {isEnterpriseOrMYOB
                          ? Math.max(creditBalance, 0).toLocaleString()
                          : creditBalance.toLocaleString()}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Estimated Credit Cost</TableCell>
                      <TableCell>
                        {quotedBatchConfigQuery.data.batchInfo.estimatedCreditCost.toLocaleString()}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          color:
                            remainingBalance < 0
                              ? insufficientCreditsColour
                              : "inherit",
                        }}
                      >
                        Remaining Balance
                      </TableCell>
                      <TableCell
                        sx={{
                          color:
                            remainingBalance < 0
                              ? insufficientCreditsColour
                              : "inherit",
                        }}
                      >
                        {remainingBalance.toLocaleString()}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>

            <UseOverageCreditsCard
              type={JobType.BATCH}
              sx={{
                gridRow: "1",
                gridColumn: "span 2",
                padding: "16px",
                boxShadow: 0,

                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
              jobCreditCost={
                quotedBatchConfigQuery.data.batchInfo.estimatedCreditCost
              }
              isLoading={isLoading}
              setIsEnoughCredits={setIsEnoughCredits}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: "16px",
            }}
          >
            <Button
              onClick={() =>
                props.setActiveStep(Math.max(props.activeStep - 1, 0))
              }
            >
              Back
            </Button>
            <LoadingButton
              loading={startJobMutation.isLoading}
              onClick={() => startJobMutation.mutate()}
              disabled={!isEnoughCredits}
              variant="contained"
            >
              Start
            </LoadingButton>
          </Box>
        </>
      )}
    </Card>
  );
};

const CustomSkeleton = () => {
  return (
    <>
      <Box sx={{ display: "grid", gridAutoColumns: "1fr", gap: "16px" }}>
        <Skeleton
          variant="rectangular"
          height={247}
          sx={{ gridRow: "1", gridColumn: "1" }}
        />
        <Skeleton
          variant="rectangular"
          height={247}
          sx={{ gridRow: "1", gridColumn: "span 2" }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          gap: "16px",
        }}
      >
        <Skeleton
          variant="rectangular"
          width={64}
          height={36}
          sx={{ gridRow: "1", gridColumn: "span 2" }}
        />
        <Skeleton
          variant="rectangular"
          width={78}
          height={36}
          sx={{ gridRow: "1", gridColumn: "span 2" }}
        />
      </Box>
    </>
  );
};
