import { Box, Chip } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-premium";
import { formatTimestamp } from "utils";
import { ApiKey, Status } from "contexts/api-key-state";
import { Height } from "@mui/icons-material";
export const columns: GridColDef[] = [
    { flex: 1, field: "name", headerName: "Name" },
    {
      flex: 1,
      field: "createdDate",
      headerName: "Create Date",
  
      renderCell: (params: GridRenderCellParams) => formatTimestamp(params.row.createdDate),
    },
    {
      flex: 1,
      field: "expireDate",
      headerName: "Expiry Date",
  
      renderCell: (params: GridRenderCellParams) => formatTimestamp(params.row.expireDate),
    },
    {
      field: "status",
      headerName: "Status",
  
      disableColumnMenu: true,
      sortable: false,
      editable: false,
      width: 200,
  
      renderCell: (params: GridRenderCellParams) => (
        <StatusGroup
          status={params.row.status}
          expireDate={params.row.expireDate}
        />
      ),
    },
  ];

  const StatusGroup = (props: { status: Status; expireDate: number }) => {
    /**
     * Returns rendered chip(s) of the status of an API key
     */
  
    const color =
      props.status === "approved"
        ? "success"
        : props.status === "revoked"
        ? "error"
        : "info";
  
    let expired = false;
  
    if (props.expireDate !== -1 && props.expireDate <= Math.round(Date.now())) {
      expired = true;
    }
  
    return (
      <Box
        
        style={{ display: "flex", gap: "5%" , height: "100%" , alignItems: "center"}}
      >
        {expired ? (
          <Chip
            label="Expired"
            color="primary"
            style={{ backgroundColor: "red" }}
          />
        ) : (
          <Chip
            label={props.status}
            color={color}
            style={{ textTransform: "uppercase" }}
          />
        )}
      </Box>
    );
  };