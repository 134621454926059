import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import Switch from "@mui/material/Switch";

import { DateRangePicker } from "pages/geoscape-data/share-component/data-range-picker";
import { MultiSelectFormControl } from "pages/geoscape-data/share-component/multi-select";

export const AdminDatasetFilters = ({
  values,
  handleChange,
  handleSubmit,
  definitions,
  time,
  setTime,
  setFieldValue,
  setQueryFilterState,
  resetForm,
  presetRange,
  setPresetRange,
  initialQueryFilterState,
  queryFilterState,
  localStorageKey,
}: any) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <TextField
        id="query"
        name="query"
        label="Search"
        type="text"
        value={values.query}
        onChange={(e: any) => {
          handleChange(e);
          handleSubmit();
        }}
        helperText={"Search by product or attribute names"}
        variant={"outlined"}
        fullWidth
      />

      <FormGroup
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "0px",
          gap: "16px",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "0px",
            gap: "16px",
          }}
        >
          <MultiSelectFormControl
            id={"product"}
            label={"Product"}
            options={definitions.isSuccess ? definitions.data.products : []}
            value={values.product}
            onChange={(e: any) => {
              handleChange(e);
              handleSubmit();
            }}
          />
          <MultiSelectFormControl
            id={"region"}
            label={"Region"}
            options={definitions.isSuccess ? definitions.data.regions : []}
            value={values.region}
            onChange={(e: any) => {
              handleChange(e);
              handleSubmit();
            }}
          />

          <MultiSelectFormControl
            id={"format"}
            label={"Format"}
            options={definitions.isSuccess ? definitions.data.formats : []}
            value={values.format}
            onChange={(e: any) => {
              handleChange(e);
              handleSubmit();
            }}
          />

          <MultiSelectFormControl
            id={"datum"}
            label={"Datum"}
            options={definitions.isSuccess ? definitions.data.datums : []}
            value={values.datum}
            onChange={(e: any) => {
              handleChange(e);
              handleSubmit();
            }}
          />
          <Button
            variant="contained"
            size="small"
            color={"info"}
            id={"release"}
            name={"release"}
            onClick={(e) => {
              setTime({ startTime: 0, endTime: 0 });
              setFieldValue("release", "");
              localStorage.removeItem(localStorageKey);
              setQueryFilterState(initialQueryFilterState);
              resetForm();
              handleSubmit();
            }}
          >
            Clear Filters
          </Button>
        </Box>

        <Box style={{ display: "flex", flexDirection: "row" }}>
          <FormControlLabel
            control={
              <Switch
                checked={values.notPublished}
                onChange={(e: any) => {
                  handleChange(e);
                  handleSubmit();
                }}
                id={"notPublished"}
                name={"notPublished"}
              />
            }
            label={`Not Published`}
          />
          <FormControlLabel
            control={
              <Switch
                checked={values.latestRelease}
                onChange={(e: any) => {
                  handleChange(e);
                  handleSubmit();
                }}
                id={"latestRelease"}
                name={"latestRelease"}
              />
            }
            label={`Latest Release`}
          />

          <DateRangePicker
            time={time}
            setTime={setTime}
            setFieldValue={setFieldValue}
            handleSubmit={handleSubmit}
            presetRange={presetRange}
            setPresetRange={setPresetRange}
            disabled={values.latestRelease}
            queryFilterState={queryFilterState}
          />
        </Box>
      </FormGroup>
    </Box>
  );
};
