import { Button, Card } from "@mui/material";
import { Stack } from "@mui/system";
import { ReactQueryErrorWrapper } from "components/shared/react-query-error-wrapper";
import CustomTable from "components/shared/table";
import { getActiveOrganisation, useIdentity } from "contexts/identity-context";
import * as QueryKeys from "data";
import { Dataset, Release } from "data/models";
import { fetchReleaseDatasets } from "data/queries";
import { useState } from "react";
import { UseQueryResult, useQuery } from "react-query";
import { generatePath, useHistory } from "react-router";
import * as Routes from "routes";
import { resolveIdentityId } from "utils/identity";
import { customColumns, datasetsToRows } from "./columns-rows";
import { ProductChips } from "./product-chips";

export const ViewAllDatasets = () => {
  const history = useHistory();

  const [identity, setIdentity] = useIdentity();
  const isUser = !getActiveOrganisation(identity);
  const identityId = resolveIdentityId(identity, isUser);

  const selectedRelease: Release = history.location.state as Release;
  const [selectedProduct, setSelectedProduct] = useState<string>(
    selectedRelease.products[0]
  );

  const releasesDatasetsQuery: UseQueryResult<Dataset[]> = useQuery(
    [QueryKeys.releasesDatasetsQuery, identityId, selectedRelease.key],
    () => fetchReleaseDatasets(selectedRelease.key, identity)
  );
  const isSuccess = releasesDatasetsQuery.isSuccess;
  const isLoading = releasesDatasetsQuery.isLoading;
  const rows = releasesDatasetsQuery.data
    ? datasetsToRows(
        releasesDatasetsQuery.data.filter((p) => p.product == selectedProduct)
      )
    : [];
  const customColumns_ = customColumns(identity);

  return (
    <div>
      <Stack direction={"column"} gap={"10px"}>
        <Button
          sx={{
            alignSelf: "flex-end",
          }}
          variant="contained"
          onClick={() => {
            history.push(
              generatePath(Routes.adminEditRelease, {
                releaseKey: selectedRelease.key,
              }),
              selectedRelease
            );
          }}
        >
          Back
        </Button>

        <ReactQueryErrorWrapper
          queries={[releasesDatasetsQuery]}
          mutations={[]}
        />

        <Card
          style={{
            width: "100%",
            display: "flex",
            padding: "10px",
          }}
        >
          <ProductChips
            products={selectedRelease.products}
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
          />
        </Card>

        <CustomTable
          rows={isSuccess ? rows : []}
          cols={customColumns_}
          isLoading={isLoading}
          overlayText={
            "We couldn't find any datasets that match your filter criteria."
          }
        />
      </Stack>
    </div>
  );
};
