import { LoadingButton } from "@mui/lab";
import * as Routes from "routes";
import {
  Box,
  Button,
  Card,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
  ListItemButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  TextField,
} from "@mui/material";
import { products3 } from "data/dummy-data";
import { Form, Formik, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import { array, number, object, string } from "yup";
import { UseMutationResult, UseQueryResult } from "react-query";
import { DatasetDefinitions, Release } from "data/models";

const monthsOfYear = [
  { month: "January", value: 1 },
  { month: "February", value: 2 },
  { month: "March", value: 3 },
  { month: "April", value: 4 },
  { month: "May", value: 5 },
  { month: "June", value: 6 },
  { month: "July", value: 7 },
  { month: "August", value: 8 },
  { month: "September", value: 9 },
  { month: "October", value: 10 },
  { month: "November", value: 11 },
  { month: "December", value: 12 },
];

interface NewRelease {
  name: string;
  year: number;
  month: number;
  products: string[];
}

let getInitialState = (release?: Release): NewRelease => {
  return {
    name: release ? release.name : "",
    year: release ? release.year : new Date().getFullYear(),
    month: release ? release.month : new Date().getMonth() + 1,
    products: release ? release.products : [],
  };
};

const releaseValidator = object({
  name: string().required("A name is required"),
  year: number()
    .typeError("Year must be a number")
    .integer("Year must be an integer")
    .min(2018, "Year must be greater than or equal to 2018")
    .max(2025, "Year must be less than or equal to 2025")
    .required("Year is required"),
  month: number()
    .typeError("Month must be a number")
    .integer("Month must be an integer")
    .min(1, "Month must be greater than or equal to January")
    .max(12, "Month must be less than or equal to December")
    .required("Month is required"),
  products: array()
    .of(string())
    .required("Products are required")
    .min(1, "At least one product must be selected"),
});

const AutoSubmitContext = ({ closeButton }: any) => {
  // Grab values and submitForm from context
  const history = useHistory();
  const { values, submitForm }: any = useFormikContext();
  useEffect(() => {
    if (closeButton) {
      if (!values.name || !values.products.length) {
        history.push(Routes.adminReleases);
      }
      submitForm();
    }
  }, [closeButton]);
  return null;
};

interface ReleaseCardProps {
  createReleaseMutation: UseMutationResult<any, Error, NewRelease, unknown>;
  isLoading: boolean;
  data?: DatasetDefinitions;
  release?: Release;
  isLoadingcreateReleaseMutation: boolean;
  isLoadingDraftReleaseMutation: boolean;
  closeButton: boolean;
  setCloseButton: React.Dispatch<React.SetStateAction<boolean>>;
  draftReleaseMutation: UseMutationResult<Release, Error, NewRelease, unknown>;
  isLoadingLoadReleaseMutation: boolean;
  cardStyle: React.CSSProperties;
}

export const ReleaseCard = ({
  createReleaseMutation,
  data,
  isLoading,
  release,
  isLoadingcreateReleaseMutation,
  isLoadingDraftReleaseMutation,
  closeButton,
  setCloseButton,
  draftReleaseMutation,
  isLoadingLoadReleaseMutation,
  cardStyle,
}: ReleaseCardProps) => {
  const initialValues = getInitialState(release);
  return (
    <>
      <Formik<NewRelease>
        initialValues={initialValues}
        onSubmit={async (values) => {
          if (closeButton) {
            draftReleaseMutation.mutate(values);
          } else {
            createReleaseMutation.mutate(values);
          }
        }}
        validationSchema={releaseValidator}
        // onError={}
      >
        {({
          values: { name, year, month, products },
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
          submitForm,
          errors,
          touched,
        }) => {
          {
            Object.keys(errors).length !== 0 && setCloseButton(false);
          }
          return (
            <Card sx={cardStyle}>
              <Form>
                <CardHeader
                  title={"Release"}
                  subheader="Define and upload of a new release"
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                    padding: "20px",
                  }}
                >
                  <TextField
                    id="name"
                    name="name"
                    label="Name"
                    type="text"
                    value={name}
                    onChange={(e: any) => {
                      handleChange(e);
                    }}
                    variant={"outlined"}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                    disabled={
                      isLoadingcreateReleaseMutation ||
                      !!release?.staged?.length
                    }
                    fullWidth
                  />

                  <FormControl fullWidth>
                    <InputLabel id="year">Year</InputLabel>
                    <Select
                      labelId="year"
                      id="year"
                      value={year}
                      name="year"
                      label="Year"
                      disabled={
                        isLoadingcreateReleaseMutation ||
                        !!release?.staged?.length
                      }
                      error={touched.year && Boolean(errors.year)}
                      onChange={(e: any) => {
                        handleChange(e);
                      }}
                    >
                      {[2021, 2022, 2023, 2024, 2025].map((option) => {
                        return (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText
                      error={touched.year && Boolean(errors.year)}
                    >
                      {touched.year && errors.year}
                    </FormHelperText>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel id="month">Month</InputLabel>
                    <Select
                      labelId="month"
                      id="month"
                      value={month}
                      name="month"
                      label="Month"
                      disabled={
                        isLoadingcreateReleaseMutation ||
                        !!release?.staged?.length
                      }
                      error={touched.year && Boolean(errors.year)}
                      onChange={(e: any) => {
                        handleChange(e);
                      }}
                    >
                      {monthsOfYear.map((option) => {
                        return (
                          <MenuItem key={option.month} value={option.value}>
                            {option.month}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText
                      error={touched.month && Boolean(errors.month)}
                    >
                      {touched.month && errors.month}
                    </FormHelperText>
                  </FormControl>
                  <>
                    {/*  */}
                    {/*  */}
                    {isLoading && (
                      <>
                        <Skeleton
                          variant="rectangular"
                          width={345}
                          height={42}
                        />
                      </>
                    )}
                    {!isLoading && (
                      <FormGroup>
                        <InputLabel>Products</InputLabel>
                        <FormHelperText
                          error={touched.products && Boolean(errors.products)}
                        >
                          {touched.products && errors.products}
                        </FormHelperText>
                        {data?.products.map((product: string) => {
                          return (
                            <ListItemButton
                              sx={{ padding: "0px" }}
                              disabled={
                                isLoadingcreateReleaseMutation ||
                                (release && release.loaded?.length > 0) ||
                                isLoadingLoadReleaseMutation
                              }
                            >
                              <FormControlLabel
                                sx={{
                                  width: "100%",
                                  padding: "8px",
                                  margin: "0px",
                                }}
                                control={
                                  <Checkbox
                                    id="products"
                                    name="products"
                                    onChange={handleChange}
                                    value={product}
                                    checked={products.includes(product)}
                                  />
                                }
                                label={product}
                              />
                            </ListItemButton>
                          );
                        })}
                      </FormGroup>
                    )}
                  </>
                  <LoadingButton
                    sx={{
                      alignSelf: "flex-start",
                    }}
                    variant="text"
                    size="small"
                    type="submit"
                    disabled={
                      isLoading ||
                      isLoadingcreateReleaseMutation ||
                      (release && release.loaded?.length > 0) ||
                      isLoadingLoadReleaseMutation ||
                      isLoadingDraftReleaseMutation
                    }
                    loading={isLoading || isLoadingcreateReleaseMutation}
                  >
                    Check Source S3
                  </LoadingButton>
                </Box>
                <></>
                <AutoSubmitContext closeButton={closeButton} />
              </Form>
            </Card>
          );
        }}
      </Formik>
    </>
  );
};
