import {
  Alert,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  User,
  getActiveOrganisation,
  useIdentity,
} from "contexts/identity-context";
import { ClipConfiguration, QuoteSummary } from "../../models";
import { toTitleCase } from "utils/commons";
import PaymentMethodInfo from "components/settings/shared/billing-details/paymentMethodInfo";
import { useIntercom } from "react-use-intercom";
import * as Routes from "routes";
import { useMutation, useQueryClient } from "react-query";
import { ReactQueryMutationError, extractClip } from "data/mutations";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { resolveIdentityId } from "utils/identity";
import * as QueryKeys from "data";
import { useCustomSnackbars } from "components/snackbars/useCustomSnackbars";

interface PurchaseWithDollarsProps {
  clipConfig: ClipConfiguration;
  quoteSummary: QuoteSummary;
  planName: string;
  resetClipConfig: () => void;
  setSubStep: React.Dispatch<React.SetStateAction<number>>;
}

export const PurchaseWithDollars = (props: PurchaseWithDollarsProps) => {
  const [identityState] = useIdentity();
  const { show } = useIntercom();
  const isUser = !getActiveOrganisation(identityState);
  const identityId = resolveIdentityId(identityState, isUser);
  const [paymentMethod, setPaymentMethod] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const calculateDollarAmount = (credits: number, planName: string) => {
    if (planName.toLowerCase() === "enterprise") return credits / 100;
    if (planName.toLowerCase() === "professional") return credits / 100;
    if (planName.toLowerCase() === "freemium") return (credits * 15) / 1000;
    return 0;
  };

  const calculateTaxExcluded = (amount: number) => {
    return amount - amount / 11;
  };

  const { enqueueMutationFailed } = useCustomSnackbars();

  const submitExtractJob = useMutation(
    (clipId: string) => extractClip(clipId, "invoice", identityState),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.clipsKey, identityId],
        }),
          enqueueSnackbar("Clip has been submitted for extraction.", {
            variant: "success",
          });
        props.resetClipConfig();
      },
      onError: (error: Error) => {
        enqueueMutationFailed(error.toString());
      },
    }
  );
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        maxHeight="calc(86vh - 64px)"
        overflow="auto"
      >
        <TextField
          sx={{ marginTop: 2 }}
          fullWidth
          disabled
          label="Contact Email"
          value={(identityState as User).email}
          variant="outlined"
          size="small"
        />
        <TextField
          fullWidth
          disabled
          label="Contact Phone"
          value=""
          variant="outlined"
          size="small"
        />
        <TableContainer sx={{ overflow: "visible" }}>
          <Table
            size="small"
            sx={{ "& thead th:nth-child(4)": { width: "18%" } }}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  padding="none"
                  align="left"
                  sx={{ fontWeight: "bold" }}
                >
                  Description
                </TableCell>
                <TableCell
                  padding="none"
                  align="right"
                  sx={{ fontWeight: "bold" }}
                >
                  Qty
                </TableCell>
                <TableCell
                  padding="none"
                  align="right"
                  sx={{ fontWeight: "bold" }}
                >
                  Unit Price
                </TableCell>
                <TableCell
                  padding="none"
                  align="right"
                  sx={{ fontWeight: "bold" }}
                >
                  Amount
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={{ padding: 0 }} align="left">
                  {`${toTitleCase(props.planName)} Plan Credits`}
                </TableCell>
                <TableCell sx={{ padding: 0 }} align="right">
                  {props.quoteSummary.totalCredits.toLocaleString()}
                </TableCell>
                <TableCell sx={{ padding: 0 }} align="right">
                  {props.planName.toLowerCase() === "enterprise" &&
                    "$10(per 1k)"}
                  {props.planName.toLowerCase() === "professional" &&
                    "$10(per 1k)"}
                  {props.planName.toLowerCase() === "freemium" && "$15(per 1k)"}
                </TableCell>
                <TableCell sx={{ padding: 0 }} align="right">
                  {`$${calculateDollarAmount(
                    props.quoteSummary.totalCredits,
                    props.planName
                  ).toLocaleString()}`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ padding: 0 }} align="left">
                  {""}
                </TableCell>
                <TableCell sx={{ padding: 0 }} align="left">
                  {""}
                </TableCell>
                <TableCell sx={{ padding: 0 }} align="right">
                  {"Sub Total"}
                </TableCell>
                <TableCell sx={{ padding: 0 }} align="right">
                  {`$${calculateDollarAmount(
                    props.quoteSummary.totalCredits,
                    props.planName
                  ).toLocaleString()}`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ padding: 0 }} align="left">
                  {""}
                </TableCell>
                <TableCell sx={{ padding: 0 }} align="left">
                  {""}
                </TableCell>
                <TableCell sx={{ padding: 0 }} align="right">
                  {"Total excluding GST"}
                </TableCell>
                <TableCell sx={{ padding: 0 }} align="right">
                  {`$${calculateTaxExcluded(
                    calculateDollarAmount(
                      props.quoteSummary.totalCredits,
                      props.planName
                    )
                  ).toLocaleString("en-AU", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ padding: 0 }} align="left">
                  {""}
                </TableCell>
                <TableCell sx={{ padding: 0 }} align="left">
                  {""}
                </TableCell>
                <TableCell sx={{ padding: 0 }} align="right">
                  {`GST 10%`}
                </TableCell>
                <TableCell sx={{ padding: 0 }} align="right">
                  {`$${(
                    calculateDollarAmount(
                      props.quoteSummary.totalCredits,
                      props.planName
                    ) / 11
                  ).toLocaleString("en-AU", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ padding: 0 }} align="left">
                  {""}
                </TableCell>
                <TableCell sx={{ padding: 0 }} align="left">
                  {""}
                </TableCell>
                <TableCell
                  sx={{ padding: 0, fontWeight: "bold" }}
                  align="right"
                >
                  {"Total Due"}
                </TableCell>
                <TableCell sx={{ padding: 0 }} align="right">
                  {`$${calculateDollarAmount(
                    props.quoteSummary.totalCredits,
                    props.planName
                  ).toLocaleString("en-AU", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}`}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {props.planName.toLowerCase() === "freemium" && (
          <Alert
            variant="outlined"
            severity="info"
            action={
              <Button
                color="inherit"
                size="small"
                href={
                  isUser
                    ? Routes.userManageSubscription
                    : Routes.orgManageSubscription
                }
              >
                Open
              </Button>
            }
          >
            Save money with a Hub Subscription
          </Alert>
        )}
        {props.planName.toLowerCase() === "professional" && (
          <Alert
            variant="outlined"
            severity="info"
            action={
              <Button color="inherit" size="small" onClick={() => show()}>
                Contact
              </Button>
            }
          >
            Talk to us about Enterprise
          </Alert>
        )}
        {submitExtractJob.isError && (
          <Alert variant="outlined" severity="error">
            {(submitExtractJob.error as ReactQueryMutationError).message}{" "}
          </Alert>
        )}
        <PaymentMethodInfo
          isUserPage={isUser}
          style={{}}
          setPaymentMethod={setPaymentMethod}
        />
        <Typography variant="caption">
          By confirming your payment, you allow Geoscape Australia to charge you
          for this payment and save your payment information in accordance with
          their terms.
        </Typography>
      </Box>
      <Box alignSelf="flex-end" marginTop="auto" paddingTop={1} display="flex">
        <Button onClick={() => props.setSubStep(0)} variant="text">
          Back
        </Button>
        <LoadingButton
          size="small"
          loading={submitExtractJob.isLoading}
          variant="contained"
          disabled={!paymentMethod}
          onClick={() => {
            submitExtractJob.mutate(props.clipConfig.id);
          }}
        >
          {`Pay $${calculateDollarAmount(
            props.quoteSummary.totalCredits,
            props.planName
          ).toLocaleString("en-AU", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}`}
        </LoadingButton>
      </Box>
    </>
  );
};
