import { Box, Breadcrumbs, Skeleton, Typography } from "@mui/material";
import { useIdentity } from "contexts/identity-context";
import * as QueryKeys from "data";
import { fetchUserDetailsAdmin } from "data/queries";
import _ from "lodash";
import { useQuery } from "react-query";
import { Link, useHistory, useLocation } from "react-router-dom";
import * as Routes from "routes";

export const DynamicBreadcrumbsMenu = () => {
  const history = useHistory();
  const location = useLocation();
  const currentRouteArray = location.pathname.substring(1).split("/");
  const isAdminPage = currentRouteArray.includes("admin");
  let accountId: string = "";
  const [identityState] = useIdentity();

  if (location.pathname.includes(Routes.adminAccountDetailsPath)) {
    accountId = currentRouteArray[3];
  }

  const adminUserDetails = useQuery(
    [QueryKeys.adminUserDetailsQuery, accountId],
    () => fetchUserDetailsAdmin(accountId, identityState),
    {
      enabled:
        location.pathname.includes(Routes.adminAccountDetailsPath) &&
        accountId !== "",
    }
  );

  const nameMappings = new Map<string, string>([
    ["api-keys", "API Keys"],
    ["admin", "Geoscape Admin"],
    ["demos", "Demos & Prototypes"],
    ["management", "Customer Management"],
    ["clip", ""],
    ["documentation", ""],
    ["hub", ""],
    ["explorer", ""],
    ["base", ""],
    ["demos/base", ""],
    ["emissions-metric", ""],
    ["flood-data-demo", ""],
    ["property-insights", ""],
    ["easements", ""],
    ["security", "User Security"],
    [
      "predictive",
      "Address Auto-complete Demo (Predictive API + Addresses API + Maps API)",
    ],
  ]);

  const routeMappings: any = {
    "/admin": "/admin/dashboard",
    "/admin/customer-management": "/admin/customer-management/accounts",
    "/admin/release-management": "/admin/release-management/releases",
    "/admin/release-management/edit-release":
      "/admin/release-management/releases",
  };

  let currentPageName =
    nameMappings.get(currentRouteArray[currentRouteArray.length - 1]) ??
    _.startCase(currentRouteArray[currentRouteArray.length - 1]);

  const formatBreadcrumbSegment = (routeSegment: string) => {
    /**
     * Format according to the following order
     * 1. Replace accountId with accountName (when admins view user accounts)
     * 2. Replace from the nameMappings dictionary
     * 3. Use start case formatting
     */
    if (adminUserDetails.isSuccess) {
      if (
        adminUserDetails.data.customerId &&
        routeSegment === adminUserDetails.data.customerId
      ) {
        return adminUserDetails.data.name;
      } else if (nameMappings.get(routeSegment) !== undefined) {
        return nameMappings.get(routeSegment);
      } else {
        return _.startCase(routeSegment);
      }
    }
  };

  return (
    <Box sx={{ zIndex: "1000" }}>
      {location.pathname.includes(Routes.adminAccountDetailsPath) ? (
        <>
          {adminUserDetails.isLoading && (
            <Skeleton variant="rectangular" width="100px" height="25px" />
          )}
          {adminUserDetails.isSuccess && (
            <Typography variant="h6">{adminUserDetails.data.name}</Typography>
          )}
        </>
      ) : (
        <Typography variant="h6">{currentPageName}</Typography>
      )}
      {isAdminPage && !currentRouteArray.includes("property-insights") && (
        <>
          {adminUserDetails.isLoading && (
            <Breadcrumbs separator="/" aria-label="breadcrumb">
              {currentRouteArray.map((item, index) => (
                <Skeleton variant="rectangular" width="100px" height="25px" />
              ))}
            </Breadcrumbs>
          )}
          {adminUserDetails.isSuccess && (
            <Breadcrumbs separator="/" aria-label="breadcrumb">
              {currentRouteArray.map((item, index) => {
                if (index + 1 === currentRouteArray.length) {
                  return <Box key={index}>{formatBreadcrumbSegment(item)}</Box>;
                } else {
                  const currentRoute =
                    "/" + currentRouteArray.slice(0, index + 1).join("/");

                  return (
                    <Link
                      key={index}
                      to={{
                        pathname: routeMappings[currentRoute] || currentRoute,
                        state: history.location.state as Object,
                      }}
                      style={{ color: "inherit" }}
                    >
                      {formatBreadcrumbSegment(item)}
                    </Link>
                  );
                }
              })}
            </Breadcrumbs>
          )}
        </>
      )}
    </Box>
  );
};
