import { Add, ChevronRightOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Link,
  List,
  ListItemButton,
  ListItemText,
  Skeleton,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@mui/styles";
import { BackgroundLetterAvatarsSquare } from "components/shared/shared";
import { useCustomSnackbars } from "components/snackbars/useCustomSnackbars";
import {
  getActiveOrganisation,
  GetUserIfLoggedIn,
  IdentityState,
  signOut,
  switchedOrganisationCookieName,
  switchOrganisation,
  useIdentity,
  User,
} from "contexts/identity-context";
import * as QueryKeys from "data";
import { fetchOrganisation } from "data/queries";
import { PostHog } from "posthog-js";
import { usePostHog } from "posthog-js/react";
import * as React from "react";
import { useCookies } from "react-cookie";
import { useQuery } from "react-query";
import { useHistory } from "react-router";
import { useIntercom } from "react-use-intercom";
import * as Routes from "routes";
import { userCreateOrganisation } from "routes";
import useDarkMode from "use-dark-mode";
import { resolveIdentityId } from "utils/identity";

enum DisplayCondition {
  Organisation,
  UserWithoutOrganisations,
  UserWithOrganisations,
  NotImplemented,
  Always,
  User,
}

function getName(identity: IdentityState): string {
  if (!identity) {
    return "";
  }
  const organisation = getActiveOrganisation(identity);

  if (organisation) {
    if (organisation.name === "") {
      return organisation.name;
    }

    return organisation.name;
  }
  return getFullName(identity);
}

function getFullName(identity: IdentityState): string {
  const user = GetUserIfLoggedIn(identity);
  if (!user) {
    return "";
  }

  return `${user.firstName} ${user.lastName}`;
}

const menuWidth = "300px";
const menuItemHeight = "36px";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  main: {
    "&:before": {
      position: "absolute",
      content: '""',
      top: "0px",
      left: "-8px",
      right: "0px",
      width: menuWidth,
      height: "60px",
      background: "#616161",
      opacity: 0.5,
    },
  },
  favorite: {
    position: "absolute",
    top: 10,
    left: 10,
    color: "white",
  },
  rating: {
    position: "absolute",
    top: 100,
    left: 100,
  },
  truncated: {
    width: "80%",
    display: "flex",
    alignItems: "flex-start",
  },
});

interface DisplayMenuItem {
  name: string;
  link: string;
  displayCondition: DisplayCondition;
}

const menuItems: DisplayMenuItem[] = [
  {
    name: "Switch Account",
    link: "",
    displayCondition: DisplayCondition.Always,
  },
  {
    name: "User Settings",
    link: Routes.userSubscription,
    displayCondition: DisplayCondition.User,
  },
  {
    name: "Pending Invitations",
    link: Routes.userPendingInvitations,
    displayCondition: DisplayCondition.User,
  },
  {
    name: "Organisation Settings",
    link: Routes.orgGeneralSettings,
    displayCondition: DisplayCondition.Organisation,
  },
  {
    name: "Members",
    link: Routes.orgMembers,
    displayCondition: DisplayCondition.Organisation,
  },
];

const menuItems2 = [
  {
    name: "User Settings",
    link: Routes.developerDashboard,
    displayCondition: DisplayCondition.NotImplemented,
  },
  {
    name: "Pending Invitations",
    link: Routes.userPendingInvitations,
    displayCondition: DisplayCondition.Always,
  },
];

function checkDisplayConditions(
  identityState: IdentityState,
  assignedCondition: DisplayCondition
): boolean {
  if (
    assignedCondition === DisplayCondition.UserWithOrganisations &&
    getActiveOrganisation(identityState)
  ) {
    return true;
  }
  return (
    assignedCondition !== DisplayCondition.NotImplemented &&
    assignedCondition === getDisplayCondition(identityState, assignedCondition)
  );
}

function getDisplayCondition(
  identityState: IdentityState,
  assignedCondition: DisplayCondition
): DisplayCondition {
  if (assignedCondition === DisplayCondition.Always) {
    return DisplayCondition.Always;
  }

  if (
    !getActiveOrganisation(identityState) &&
    assignedCondition === DisplayCondition.User
  ) {
    return DisplayCondition.User;
  }

  if (getActiveOrganisation(identityState)) {
    return DisplayCondition.Organisation;
  }

  if (
    (identityState as User).organisations &&
    (identityState as User).organisations.length > 0
  ) {
    return DisplayCondition.UserWithOrganisations;
  } else {
    return DisplayCondition.UserWithoutOrganisations;
  }
}

const ORGANISATION_ACCOUNT = "Organisation Account";
const PERSONAL_ACCOUNT = "Personal Account";

export default function FadeMenu({ openMenu }: { openMenu: boolean }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorElSub, setAnchorElSub] = React.useState<null | HTMLElement>(
    null
  );

  const [dropDownOpen, setDropDownOpen] = React.useState(false);
  const [dropDownSubOpen, setDropDownSubOpen] = React.useState(false);

  const open = Boolean(anchorEl);
  const openSub = Boolean(anchorElSub);
  const darkMode = useDarkMode(false);

  const handleDropdownItemClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setDropDownOpen(!dropDownOpen);
  };

  const handleDropDownClose = () => {
    setAnchorEl(null);
    setDropDownOpen(!dropDownOpen);
  };

  const handleDropdownSubItemClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElSub(event.currentTarget);
    setDropDownSubOpen(!dropDownSubOpen);
  };

  const handleDropDownSubClose = () => {
    setAnchorElSub(null);
    setDropDownSubOpen(!dropDownSubOpen);
  };

  const [identityState, setIdentityState] = useIdentity();
  const activeOrg = getActiveOrganisation(identityState);
  const isActingAsOrg = activeOrg !== false;

  const { enqueueQueryFailed } = useCustomSnackbars();

  const organisationDetails = useQuery(
    [
      QueryKeys.intercomIdentityHash,
      resolveIdentityId(identityState, !activeOrg),
    ],
    () => fetchOrganisation(identityState),
    {
      onError: (error: Error) => {
        enqueueQueryFailed(error.toString());
      },
      staleTime: Infinity,
      enabled: isActingAsOrg,
    }
  );

  const orgIsLoading = isActingAsOrg && organisationDetails.isLoading;

  const user: User | false = GetUserIfLoggedIn(identityState);
  const organisationList = user ? user.organisations : [];
  const classes = useStyles();
  const [_c, setCookie, removeCookie] = useCookies([
    switchedOrganisationCookieName,
  ]);
  const { update, shutdown } = useIntercom();
  const identityName = getName(identityState);

  const history = useHistory();
  const posthog = usePostHog();

  return (
    <List
      sx={{
        display: "block",
        width: "226px",
        padding: "8px",
      }}
    >
      <ListItemButton
        id="fade-button"
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        // className={dropDownOpen ? classes.main : ""}
        className="menu-list-item-buttom"
        onClick={handleDropdownItemClick}
        style={{
          justifyContent: "center",
          padding: "4px 8px 4px 8px", // The padding is related to the Avatar size

          height: "40px",
          width: openMenu ? "212px" : "56px",
          justifyItems: "center",

          gap: "16px",
        }}
      >
        <BackgroundLetterAvatarsSquare
          name={identityName}
          activeOrg={activeOrg}
          isLoading={orgIsLoading}
        />
        <Tooltip title={identityName} arrow placement="right">
          {identityName && !orgIsLoading ? (
            <ListItemText
              primaryTypographyProps={{
                variant: "body1",
              }}
              primary={identityName}
              secondary={activeOrg ? ORGANISATION_ACCOUNT : PERSONAL_ACCOUNT}
              sx={{
                display: openMenu ? "inline" : "none",
              }}
            />
          ) : (
            <>
              {openMenu && (
                <Box
                  sx={{
                    width: "140px",
                  }}
                >
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                </Box>
              )}
            </>
          )}
        </Tooltip>
      </ListItemButton>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        PaperProps={{
          style: {
            width: menuWidth,
          },
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleDropDownClose}
        TransitionComponent={Fade}
      >
        <IconButton
          id="fade-button"
          aria-controls={open ? "fade-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            gap: "10px",
            justifyContent: "flex-start",
            borderRadius: "0px",
          }}
        >
          <BackgroundLetterAvatarsSquare
            name={identityName}
            activeOrg={activeOrg}
            isLoading={orgIsLoading}
          />

          <Container
            disableGutters
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            {identityName && !orgIsLoading ? (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Tooltip title={identityName} arrow placement="right">
                  <Typography
                    noWrap
                    sx={{ width: "85%", textAlign: "left" }}
                    variant="body1"
                    color="text.primary"
                    component="div"
                  >
                    {identityName}
                  </Typography>
                </Tooltip>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  component="div"
                  sx={{ alignSelf: "flex-start" }}
                >
                  {activeOrg ? ORGANISATION_ACCOUNT : PERSONAL_ACCOUNT}
                </Typography>
              </Box>
            ) : (
              <Box>
                <Skeleton
                  animation="wave"
                  height={15}
                  width="80%"
                  style={{ marginBottom: 6 }}
                />
                <Skeleton
                  animation="wave"
                  height={15}
                  width="80%"
                  style={{ marginBottom: 6 }}
                />
              </Box>
            )}
          </Container>
        </IconButton>
        {menuItems.map((item, index) => (
          <Box key={index}>
            {checkDisplayConditions(identityState, item.displayCondition) ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  height: menuItemHeight,
                }}
              >
                <IconButton
                  key={index}
                  href={item.link}
                  LinkComponent={Link}
                  id="fade-button"
                  aria-controls={open ? "fade-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={
                    item.name === "Switch Account"
                      ? handleDropdownSubItemClick
                      : handleDropDownClose
                  }
                  sx={{
                    width: "100%",
                    display: "flex",
                    gap: "10px",
                    justifyContent: "space-between",
                    borderRadius: "0px",
                  }}
                >
                  <Box
                    style={{
                      paddingLeft: "8px",
                    }}
                  >
                    <Typography
                      variant="body2"
                      color="text.primary"
                      component="div"
                    >
                      {item.name}
                    </Typography>
                  </Box>
                  {/* Show icon "">"" */}
                  {item.name === "Switch Account" ? (
                    <ChevronRightOutlined />
                  ) : (
                    <Box></Box>
                  )}
                </IconButton>
                {item.name === "Switch Account" ? (
                  <Menu
                    id="fade-menu"
                    MenuListProps={{
                      "aria-labelledby": "fade-button",
                    }}
                    anchorEl={anchorElSub}
                    open={openSub}
                    onClose={handleDropDownSubClose}
                    PaperProps={{
                      style: {
                        width: menuWidth,
                        marginLeft: menuWidth,
                        marginTop: `-${menuItemHeight}`,
                      },
                    }}
                    TransitionComponent={Fade}
                  >
                    {organisationList
                      .filter((o) => o !== activeOrg)
                      .map((org, index) =>
                        renderOrgOrUser(org.name, index, true, async () => {
                          await switchOrganisation(
                            org,
                            identityState,
                            setIdentityState,
                            setCookie,
                            update
                          ).finally(() => {
                            handleDropDownClose();
                            handleDropDownSubClose();
                            history.push(Routes.hub);
                          });
                        })
                      )}
                    {activeOrg ? (
                      renderOrgOrUser(
                        getFullName(identityState),
                        userCreateOrganisation.length + 1,
                        false,
                        async () => {
                          await switchOrganisation(
                            { name: "", id: "", active: false },
                            identityState,
                            setIdentityState,
                            setCookie,
                            update
                          ).finally(() => {
                            handleDropDownClose();
                            handleDropDownSubClose();
                            history.push(Routes.hub);
                          });
                        },
                        PERSONAL_ACCOUNT
                      )
                    ) : (
                      <Box></Box>
                    )}
                    {
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          flexDirection: "column",
                        }}
                      >
                        <Divider />
                        <IconButton
                          LinkComponent={Link}
                          href={Routes.userCreateOrganisation}
                          disableFocusRipple
                          disableRipple
                          disableTouchRipple
                          onClick={() => {
                            handleDropDownClose();
                            handleDropDownSubClose();
                          }}
                        >
                          <Add />
                          <Typography
                            variant="body2"
                            color="text.primary"
                            component={Button}
                          >
                            Create a new organisation
                          </Typography>
                        </IconButton>
                      </Box>
                    }
                  </Menu>
                ) : (
                  <Box></Box>
                )}
              </div>
            ) : (
              <Box></Box>
            )}
          </Box>
        ))}

        <MenuItem
          key={"dark-theme"}
          disableGutters={false}
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
          component={"a"}
          disableRipple
        >
          <Typography variant="body2" color="text.primary" component="div">
            Dark Theme
          </Typography>
          <Switch
            size="small"
            edge="end"
            checked={darkMode.value}
            onChange={darkMode.toggle}
          />
        </MenuItem>
        <Divider />
        <MenuItem
          key={"log-out"}
          disableGutters={false}
          style={{
            width: "100%",
          }}
          onClick={() =>
            logOut(setIdentityState, removeCookie, shutdown, posthog)
          }
          id={"signout"}
          component={Link}
        >
          <Typography variant="body2" color="text.primary" component="div">
            LOG OUT
          </Typography>
        </MenuItem>
      </Menu>
    </List>
  );
}

async function logOut(
  setUserState: React.Dispatch<React.SetStateAction<IdentityState>>,
  removeCookie: (
    name: typeof switchedOrganisationCookieName,
    options?: any
  ) => void,
  shutdown: () => void,
  posthog: PostHog
) {
  await signOut(removeCookie);
  setUserState(false);
  shutdown();
  posthog.capture("user_signed_out");
}

function renderOrgOrUser(
  name: string,
  index: number,
  activeOrg: any,
  onclick: () => {},
  subTitle?: string
) {
  return (
    <Box key={index}>
      <Tooltip
        title={activeOrg ? name : PERSONAL_ACCOUNT}
        arrow
        placement="right"
      >
        <IconButton
          key={index}
          id={`fade-button-${index}`}
          aria-controls={"fade-menu"}
          aria-haspopup="true"
          aria-expanded={"true"}
          onClick={onclick}
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            gap: "10px",
            justifyContent: "flex-start",
            borderRadius: "0px",
          }}
        >
          <BackgroundLetterAvatarsSquare
            name={name}
            isLoading={false}
            activeOrg={activeOrg}
          />

          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "100%",
            }}
          >
            <Typography
              noWrap
              sx={{ width: "85%", textAlign: "left" }}
              variant="body1"
              color="text.primary"
              component="div"
            >
              {activeOrg ? name : PERSONAL_ACCOUNT}
            </Typography>
          </Box>
        </IconButton>
      </Tooltip>
    </Box>
  );
}
