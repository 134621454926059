// import { SetDemoStep } from "./types";

import { SetDemoStep } from "../emission-metric/step-config-file/types";

export const nextStep = (setDemoStep: SetDemoStep) => {
  setDemoStep((prevStep) => {
    return prevStep + 1;
  });
};

export const backStep = (setDemoStep: SetDemoStep) => {
  setDemoStep((prevStep) => {
    return prevStep - 1;
  });
};
