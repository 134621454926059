import { Auth } from "aws-amplify";
import { GetUserIfLoggedIn, IdentityState } from "contexts/identity-context";
import { Either, left, right } from "utils";

export interface ApiResponse<T> {
  response: Response;
  body: T;
}

// ts doesn't support a method apparently ... even though it has literal types?
// but anyway, here's ours; we can expand as the backend API supports/requires more methods.
type method = `GET` | `POST`;

export const makeApiRequestPrime = async <T, B>(
  url: string,
  method: method,
  body?: B,
  organisationId?: string
): Promise<Either<ApiResponse<T>, Error>> => {
  /**
   * Make an API call with Cognito as our Authorisation token.
   * Non generic is for bodyless requests only, use generic version to when the request has a body
   * On the right, a paper with the context for whatever route we're on
   */
  try {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();
    const headers = new Headers();
    headers.append("Content-Type", `application/json`);
    headers.append("Authorization", token);
    if (organisationId) {
      headers.append("Identity", organisationId);
    }
    const response = await fetch(`${import.meta.env.VITE_API_URL}${url}`, {
      method: method,
      headers: headers,
      body: body ? JSON.stringify(body) : null,
    });
    return left({
      response: response,
      body: (await response.json()) as T,
    });
  } catch (error: any) {
    return right(error);
  }
};

export async function makeApiRequest(
  path: string,
  method: string,
  identityState?: IdentityState
): Promise<Response> {
  return await makeApiRequestToUrl(
    `${import.meta.env.VITE_API_URL}`,
    path,
    method,
    identityState
  );
}


export async function makeExternalApiRequestToUrl(
  host: string,
  path: string,
  method: string,
  identityState: IdentityState = false,
): Promise<Response> {
  /**
   * Make an API call with Cognito as our Authorisation token.
   * Non generic is for bodyless requests only, use generic version to when the request has a body
   * On the right, a paper with the context for whatever route we're on
   */
  // const TOKEN: string = "xxxxxx";
  const headers = new Headers();
  headers.append("Content-Type", `application/json`);
  // headers.append("Authorization", TOKEN);
  
  
  return await fetch(`${host}${path}`, {
    method: method,
    headers: headers,
  });
}

export async function makeApiRequestToUrl(
  host: string,
  path: string,
  method: string,
  identityState: IdentityState = false,
  extraHeaders?: Headers
): Promise<Response> {
  /**
   * Make an API call with Cognito as our Authorisation token.
   * Non generic is for bodyless requests only, use generic version to when the request has a body
   * On the right, a paper with the context for whatever route we're on
   */
  const token = (await Auth.currentSession()).getIdToken().getJwtToken();
  const headers = new Headers(extraHeaders);
  headers.append("Content-Type", `application/json`);
  headers.append("Authorization", token);
  const user = GetUserIfLoggedIn(identityState);
  if (user) {
    const org = user.organisations.find((o) => o.active);
    if (org) {
      headers.append("Identity", org.id);
    }
  }
  return await fetch(`${host}${path}`, {
    method: method,
    headers: headers,
  });
}

export async function makeApiRequestPost(
  path: string,
  method: string,
  body: object,
  identityState?: IdentityState
): Promise<Response> {
  return await makeApiRequestPostToUrl(
    `${import.meta.env.VITE_API_URL}`,
    path,
    method,
    body,
    identityState
  );
}

export async function makeApiRequestPostToUrl(
  host: string,
  path: string,
  method: string,
  body: object,
  identityState: IdentityState = false,
): Promise<Response> {
  /**
   * Make an API call with Cognito as our Authorisation token.
   * Non generic is for bodyless requests only, use generic version to when the request has a body
   * On the right, a paper with the context for whatever route we're on
   */
  const token = (await Auth.currentSession()).getIdToken().getJwtToken();
  const headers = new Headers();
  headers.append("Content-Type", `application/json`);
  headers.append("Authorization", token);
  const user = GetUserIfLoggedIn(identityState);
  if (user) {
    const org = user.organisations.find(o => o.active)
    if (org)
    {
      headers.append("Identity", org.id);
    }
  }
  
  return await fetch(`${host}${path}`, {
    method: method,
    headers: headers,
    body: JSON.stringify(body),
  });
}
