/**
 * Geoscape Australia Library (GAL) Text Input Component
 * https://stackoverflow.com/questions/58791551/is-it-a-correct-way-to-extend-material-ui-components-react-typescript
 */

import { Box, StandardTextFieldProps, TextField } from "@mui/material";

export type GALTextFieldVariants = "standard" | undefined;

export interface GALTextInputProps extends StandardTextFieldProps {
  characterLimit?: number;
  variant: any; // TODO: Learn how to type this properly...
  errorText?: string; // Text message to display if there was an error
  wasTouched?: boolean; // Was the text field changed
  isError?: boolean;
}

export const GALTextInput: React.FunctionComponent<GALTextInputProps> = (
  props
) => {
  // What helper text should we show
  let helperText = props.helperText;
  if (props.isError || props.errorText) {
    helperText = props.errorText;
  }

  let characterLimitText = props.characterLimit
    ? `${(props.value as string).length}/${props.characterLimit}`
    : undefined;

  const showHelperText = helperText || characterLimitText;

  return (
    <TextField
      {...props}
      inputProps={{ ...props.inputProps, maxLength: props.characterLimit }}
      helperText={
        showHelperText && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box>{helperText}</Box>
            <Box>{characterLimitText}</Box>
          </Box>
        )
      }
      error={props.isError || Boolean(props.errorText)}
    >
      {props.children}
    </TextField>
  );
};
