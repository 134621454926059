import { Layer } from "react-map-gl";
import { hexToHSL } from "../layer-utils";
import { useEffect, useState } from "react";
import { LayerProps } from "../explorer-display-layers";
import { HSLToHSLString, paintForGeo, typeForGeo } from "./paint";


export const BaseLayer = (props: LayerProps) => {

  const layerHSL = hexToHSL(props.layerConfig.styles.colour ?? "#FFFFFF");
  const selectedLayerPaint = paintForGeo(
    HSLToHSLString(layerHSL),
    props.layerConfig.styles
  );
  return (
    <Layer
      key={props.id}
      layout={{
        visibility: props.visible ? "visible" : "none",
      }}
      beforeId={props.beforeId ?? ""}
      id={props.id}
      type={typeForGeo(props.layer.geometry)}
      source={props.source.description}
      source-layer={props.layer.id}
      paint={selectedLayerPaint}
    />
  );
};
