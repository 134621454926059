import * as QueryKeys from "data";

// reactQuery
import { fetchApiKeys, fetchSubscription, fetchUsage } from "data/queries";
import { useQuery } from "react-query";
import { resolveIdentityId } from "utils/identity";

import { IdentityState } from "contexts/identity-context";

export const useFetchSubscription = (
  isUser: boolean,
  identityState: IdentityState
) => {
  const identityId = resolveIdentityId(identityState, isUser);

  return useQuery([QueryKeys.subscription, identityId], () =>
    fetchSubscription(isUser, identityState)
  );
};

export const useFetchApiKeys = (
  isUser: boolean,
  identityState: IdentityState
) => {
  const identityId = resolveIdentityId(identityState, isUser);

  return useQuery({
    queryKey: [QueryKeys.apiKeys, identityId],
    queryFn: () => fetchApiKeys(isUser, identityState),
  });
};

export const useFetchUsageGraph = (
  isUser: boolean,
  identityState: IdentityState,
  startTime: number,
  endTime: number,
  enabled?: boolean,
  overrideIntervalTime?: string
) => {
  const identityId = resolveIdentityId(identityState, isUser);

  return useQuery({
    queryKey: [QueryKeys.usageGraph, identityId, startTime, endTime],
    queryFn: () =>
      fetchUsage(
        isUser,
        identityState,
        startTime,
        endTime,
        overrideIntervalTime
      ),
    enabled: enabled,
  });
};
