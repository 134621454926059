import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  InputLabel,
  Rating,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Gauge } from "@mui/x-charts/Gauge";

interface InsightsPanelProps {
  open: boolean;
  address: string;
  addressId: string;
  propertyData: any;
  cadastreData: any;
  buildingData: any;
}

export const InsightsPanel = (props: InsightsPanelProps) => {
  return (
    <Box display="flex" flexDirection="column" gap={2} padding={4}>
      <Card>
        <CardHeader
          title={props.address}
          titleTypographyProps={{ variant: "h6" }}
        />
        <CardContent>
          <Stack direction="row" spacing={1}>
            <Stack direction="column">
              <InputLabel shrink>Status</InputLabel>
              <Typography variant="body2">Policy Valid</Typography>
            </Stack>
            <Stack direction="column">
              <InputLabel shrink>Insight Capture Date</InputLabel>
              <Typography variant="body2">11th Jan 2024</Typography>
            </Stack>
            <Stack direction="column">
              <InputLabel shrink>Policy Review Date</InputLabel>
              <Typography variant="body2">6th Mar 2024</Typography>
            </Stack>
            <Stack direction="column">
              <InputLabel shrink>Policy Health</InputLabel>
              <Rating name="policy-health" value={4} readOnly />
            </Stack>
          </Stack>
          <TextField label="Notes" multiline rows={4} fullWidth />
        </CardContent>
        <CardActions>
          <Button size="small">Share</Button>
          <Button size="small">Export</Button>
          <Button size="small">Request Refresh</Button>
        </CardActions>
      </Card>
      <Card>
        <CardHeader title="Property" />
        <CardContent>
          <Box display="flex" flexDirection="column" gap={3}>
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="column">
                <InputLabel shrink>Property Identifier</InputLabel>
                <Chip
                  label={props.propertyData.features[0].properties.propertyPid}
                  size="small"
                ></Chip>
              </Stack>
              <Stack direction="column">
                <InputLabel shrink>Strata Properties</InputLabel>
                <Typography variant="body2">None</Typography>
              </Stack>
              <Stack direction="column">
                <InputLabel shrink>Parcels</InputLabel>

                <Box
                  display="flex"
                  flexDirection="row"
                  gap={1}
                  sx={{ width: "150px", overflow: "scroll" }}
                >
                  {props.propertyData.features[0].properties.parcelIds.map(
                    (parcelId: string) => (
                      <Chip key={parcelId} label={parcelId} size="small"></Chip>
                    )
                  )}
                </Box>
              </Stack>
              <Stack direction="column">
                <InputLabel shrink>Planning Zone</InputLabel>
                <Typography variant="body2">
                  {
                    props.cadastreData.features[0].properties?.planningBundle
                      ?.psmaDescription
                  }
                </Typography>
              </Stack>
            </Stack>
            <Stack direction="row" spacing={1} justifyContent="space-between">
              <Stack direction="column">
                <InputLabel shrink>Total Area</InputLabel>
                <Typography variant="body2">
                  {props.cadastreData.totalArea.toLocaleString("en-AU", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}{" "}
                  sq m
                </Typography>
              </Stack>
              <Stack direction="column">
                <InputLabel shrink>Total Building Area</InputLabel>
                <Typography variant="body2">
                  {props.buildingData.data
                    .reduce((n: any, { area }: any) => n + area, 0)
                    .toLocaleString("en-AU", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}{" "}
                  sq m
                </Typography>
              </Stack>
              <Stack direction="column">
                <InputLabel shrink>Total Green space</InputLabel>
                <Typography variant="body2">111 sq m</Typography>
              </Stack>
              <Stack direction="column">
                <InputLabel shrink>Concrete/tar</InputLabel>
                <Typography variant="body2">123 sq m</Typography>
              </Stack>
            </Stack>
            <Stack direction="row" spacing={1} justifyContent="space-between">
              <Stack direction="column">
                <InputLabel shrink>Property Slope</InputLabel>
                <Typography variant="body2">0-5 deg</Typography>
              </Stack>
              <Stack direction="column">
                <InputLabel shrink>Driveway</InputLabel>
                <Typography variant="body2">2</Typography>
              </Stack>
              <Stack direction="column">
                <InputLabel shrink>Street Frontage</InputLabel>
                <Typography variant="body2">36 m</Typography>
              </Stack>
              <Stack direction="column">
                <InputLabel shrink>Pool</InputLabel>
                <Typography variant="body2">
                  {props.buildingData.data.some(
                    (data: any) => data.swimmingPool === true
                  )
                    ? "Detected"
                    : "None"}
                </Typography>
              </Stack>
              <Stack direction="column">
                <InputLabel shrink>Solar</InputLabel>
                <Typography variant="body2">
                  {props.buildingData.data.some(
                    (data: any) => data.solarPanel === true
                  )
                    ? "Detected"
                    : "None"}
                </Typography>
              </Stack>
              <Stack direction="column">
                <InputLabel shrink>Car parks</InputLabel>
                <Typography variant="body2">35</Typography>
              </Stack>
            </Stack>
          </Box>
        </CardContent>
      </Card>
      <Card>
        <CardHeader title="Buildings" />
        <CardContent>
          <Box display="flex" flexDirection="column" gap={3}>
            <Stack direction="row" spacing={1}>
              <Chip
                label="1-Primary"
                size="small"
                variant="filled"
                color="primary"
              ></Chip>
              <Chip label="2-Secondary" size="small" variant="filled"></Chip>
              <Chip label="3-Secondary" size="small" variant="filled"></Chip>
              <Chip label="4-Primary" size="small" variant="filled"></Chip>
              <Chip label="Combined Stats" size="small" variant="filled"></Chip>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Stack direction="column">
                <InputLabel shrink>Total Area</InputLabel>
                <Typography variant="body2">
                  {props.cadastreData.totalArea.toLocaleString("en-AU", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}{" "}
                  sq m
                </Typography>
              </Stack>
              <Stack direction="column">
                <InputLabel shrink>Total Building Area</InputLabel>
                <Typography variant="body2">
                  {props.buildingData.data
                    .reduce((n: any, { area }: any) => n + area, 0)
                    .toLocaleString("en-AU", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}{" "}
                  sq m
                </Typography>
              </Stack>
              <Stack direction="column">
                <InputLabel shrink>Total Green Space</InputLabel>
                <Typography variant="body2">232 sq m</Typography>
              </Stack>
              <Stack direction="column">
                <InputLabel shrink>concrete/tar</InputLabel>
                <Typography variant="body2">505 sq m</Typography>
              </Stack>
            </Stack>
          </Box>
        </CardContent>
      </Card>
      <Card>
        <CardHeader title="Risk" />
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Stack direction="column" alignItems="center">
            <Gauge
              width={100}
              height={100}
              value={20}
              startAngle={-180}
              endAngle={180}
              cornerRadius="50%"
              text={({ value, valueMax }) => `Low`}
            />
            <Typography variant="body2">Climate</Typography>
          </Stack>
          <Stack direction="column" alignItems="center">
            <Gauge
              width={100}
              height={100}
              value={20}
              startAngle={-180}
              endAngle={180}
              cornerRadius="50%"
              text={({ value, valueMax }) => `Low`}
            />
            <Typography variant="body2">Crime</Typography>
          </Stack>
          <Stack direction="column" alignItems="center">
            <Gauge
              width={100}
              height={100}
              value={20}
              startAngle={-180}
              endAngle={180}
              cornerRadius="50%"
              text={({ value, valueMax }) => `Low`}
            />
            <Typography variant="body2">Fire</Typography>
          </Stack>
          <Stack direction="column" alignItems="center">
            <Gauge
              width={100}
              height={100}
              value={20}
              startAngle={-180}
              endAngle={180}
              cornerRadius="50%"
              text={({ value, valueMax }) => `Low`}
            />
            <Typography variant="body2">Flood</Typography>
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
};
