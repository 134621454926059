import { useState } from "react";
export type MessageState = { type: "error" | "success" | null; text: string , passwordReset?: boolean} ;
export type SetMessageState = (state: MessageState) => void
export const useMessageState = (
  initialState: MessageState
): [MessageState, SetMessageState] => {
  const [state, setState] = useState(initialState);
  const _setState = (state: MessageState) => {
    setState(state);
  };
  return [state, _setState];
};
