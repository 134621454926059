import LocationOnIcon from "@mui/icons-material/LocationOn";
import MapIcon from "@mui/icons-material/Map";
import {
  Alert,
  Box,
  Card,
  Chip,
  ListItemButton,
  Typography,
  useTheme,
} from "@mui/material";

const selectedStyling = (selected: boolean, borderColour: string) => {
  return {
    border: selected ? `${borderColour} 1px solid` : "",
    padding: selected ? "0px" : "1px",
  };
};

interface BatchJobTypeCardProps {
  name: string;
  subtitle: string;
  mandatoryFields: string[];
  selected: boolean;
  onClickHandler: any;
  messages?: string[];
}

export const BatchJobTypeCard = (props: BatchJobTypeCardProps) => {
  const theme = useTheme();

  
  // TODO: Work out a better way to do these mappings
  const iconMapping: any = {
    Geocoder: <LocationOnIcon sx={{ fontSize: "54px" }} />,
    "Reverse Geocoder": <MapIcon sx={{ fontSize: "54px" }} />,
  };

  const messageMapping: any = {
    Geocoder: ["Supports full and parsed addresses"],
    "Reverse Geocoder": [],
  };

  const subtitleMapping: any = {
    Geocoder: "Address Validation | Geocoding | Address Parsing",
    "Reverse Geocoder": "Turn latitude and longitude into an address label",
  };

  // TODO: Should come from definitions, is needed for step 2 validation
  const mandatoryFieldsMapping: any = {
    Geocoder: ["Address", "Street Name", "Locality Name"],
    "Reverse Geocoder": ["Latitude", "Longitude"],
  };

  return (
    <ListItemButton
      sx={{ padding: 0, width: "100%" }}
      onClick={props.onClickHandler}
    >
      <Card
        sx={{
          ...selectedStyling(props.selected, theme.palette.text.primary),
          width: "100%",
          height: "100%",
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Box sx={{ display: "flex" }}>
          {iconMapping[props.name]}
          <Box sx={{ marginLeft: "16px" }}>
            <Typography variant="h6">{props.name}</Typography>
            <Typography variant="subtitle2" sx={{ color: "grey" }}>
              {subtitleMapping[props.name]}
            </Typography>
          </Box>
        </Box>
        <Typography>
          Mandatory Input:{" "}
          {mandatoryFieldsMapping[props.name].map(
            (label: string, index: number) => (
              <>
                <Chip label={label} size="small" />
                {index != mandatoryFieldsMapping[props.name].length - 1 &&
                  (props.name === "Reverse Geocoder" ? " and " : " or ")}
              </>
            )
          )}
        </Typography>
        {messageMapping[props.name] &&
          messageMapping[props.name].map((message: string) => (
            <Alert severity="info">{message}</Alert>
          ))}
      </Card>
    </ListItemButton>
  );
};
