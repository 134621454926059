import { IdentityState, User } from "contexts/identity-context"

const resolveIdentityId = (identityState: IdentityState, isUserPage: boolean) => {
    identityState = identityState as User
    
    if (isUserPage) {
        return identityState.cognitoSub
    } else {
        return identityState.organisations.filter((x) => x.active)[0].id
    }
}

export {
    resolveIdentityId
}