import { Box, Typography } from "@mui/material";
import { DashboardCardsReleaseManagement } from "./dashboard-cards-release";
import { DashboardCardsUserManagement } from "./dashboard-cards-user";

export const AdminDashboardPage = () => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        height: "700px",
        position: "relative",
      }}
    >
      <Typography variant="h4">Customer Management</Typography>
      <DashboardCardsUserManagement />
      <Typography variant="h4">Release Management</Typography>
      <DashboardCardsReleaseManagement />
    </Box>
  );
};
