import { Layer } from "react-map-gl";

interface InvisibleLayerProps {
  id: string;
}

export const InvisibleLayer = (props: InvisibleLayerProps) => {
  return (
    <Layer
      key={"GROUP_" + props.id}
      id={"GROUP_" + props.id}
      type="background"
      layout={{ visibility: "none" }}
      paint={{}}
    />
  );
};
