import { KeyboardArrowDown } from "@mui/icons-material";
import {
  Box,
  Button,
  Popover,
  ThemeProvider,
  createTheme,
  useTheme,
} from "@mui/material";

import { DateRangePicker as MuiDateRangePicker } from "mui-daterange-picker";

import { grey } from "@mui/material/colors";
import { preparePreDefinedRanges } from "components/usage/utils-usage";
import dayjs from "dayjs";

import { useState } from "react";

export const DateRangePicker = (props: {
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  handleSubmit: (event?: React.FormEvent<HTMLFormElement> | undefined) => void;
  disabled: boolean;
  time: any;
  setTime: any;
  presetRange: any;
  setPresetRange: any;
  queryFilterState: any;
}) => {
  const [open, setOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  const dayFormat = "DD/MM/YYYY";
  const queryFormat = "YYYY-MM-DD";

  const theme = useTheme();
  const customTheme = createTheme(theme, {
    palette: {
      primary: {
        light:
          theme.palette.mode === "dark"
            ? grey[900]
            : theme.palette.primary.light,
      },
    },
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            padding: "0px",
            borderRadius: "0px",
            boxShadow: "none", // Flat style with no shadow
            border: "0px solid #353535",
          },
        },
      },
    },
  });

  return (
    <Box
      sx={
        {
          // flexDirection: "row",
          // justifyContent: "space-between",
          // alignItems: "center",
        }
      }
    >
      <Button
        onClick={handleClick}
        disabled={props.disabled ? true : false}
        variant="outlined"
        color="inherit"
        endIcon={<KeyboardArrowDown />}
        sx={{
          width: "200px",
          height: "40px",
          fontSize: "14px",
          padding: "0",
        }}
      >
        {props.queryFilterState.latestRelease ? (
          "Filter by date"
        ) : props.presetRange ? (
          <> {props.presetRange} </>
        ) : props.time.startTime == 0 ? (
          "Filter by date"
        ) : (
          <>
            {dayjs(props.time.startTime).format(dayFormat)} -{" "}
            {dayjs(props.time.endTime).format(dayFormat)}
          </>
        )}
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <ThemeProvider theme={customTheme}>
          <MuiDateRangePicker
            open={true}
            toggle={handleClose}
            definedRanges={preparePreDefinedRanges()}
            // minDate={dayjs().subtract(90, "days").toDate()}
            onChange={async (range: any) => {
              props.setPresetRange("");
              await props.setTime({
                ...props.time,
                starTtime: range.startDate?.getTime() || 0,
              });
              await props.setTime({
                ...props.time,
                endTime: range.endDate?.getTime() || 0,
              });
              let release = await `${dayjs(
                range.startDate?.getTime() || 0
              ).format(queryFormat)},${dayjs(
                range.endDate?.getTime() || 0
              ).format(queryFormat)}`;

              await props.setFieldValue("release", release);
              await props.handleSubmit();
              handleClose();
            }}
          />
        </ThemeProvider>
      </Popover>
    </Box>
  );
};
