import { Grid, useTheme } from "@mui/material";
import addressAutocompleteDark from "icons/address-autocomplete-dark.png";
import addressAutocompleteLight from "icons/address-autocomplete-light.png";
import easementsDark from "icons/easements-dark.png";
import easementsLight from "icons/easements-light.png";
import emissionsMetric from "icons/emissionsMetric.jpg";
import localityAutocompleteDark from "icons/locality-autocomplete-dark.png";
import localityAutocompleteLight from "icons/locality-autocomplete-light.png";
import * as Routes from "routes";
import { DemoCard } from "./demo-card";

export interface DemoItem {
  id: string;
  title: string;
  type: string;
  categories: string[];
  description: string;
  path: string;
  thumbnail: string;
  public: boolean;
}

export const DemosPage = () => {
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";
  const dataDemos: DemoItem[] = [
    {
      id: "emissionsMetric",
      title: "Emissions Metric",
      type: "Prototype",
      categories: ["Climate"],
      description:
        "Visualise emissions metrics for property portfolios, supporting environmental compliance and informed decision making.",
      path: Routes.emissionsMetric,
      thumbnail: emissionsMetric,
      public: true,
    },
    {
      id: "addressAutocomplete",
      title: "Address Autocomplete",
      type: "Demo",
      categories: ["Risk", "UX", "Service Delivery", "Ecommerce"],
      description:
        "Predictive address auto-completion with real-time map visualisation. Enhance user experience and operational efficiency.",
      path: Routes.predictiveAddress,
      thumbnail: isDark ? addressAutocompleteDark : addressAutocompleteLight,
      public: true,
    },
    {
      id: "localityAutocomplete",
      title: "Locality Autocomplete",
      type: "Demo",
      categories: ["Risk", "UX", "Service Delivery", "Ecommerce"],
      description:
        "Predictive locality auto-completion with real-time map visualisation. Enhance user experience and operational efficiency.",
      path: Routes.predictiveLocality,
      thumbnail: isDark ? localityAutocompleteDark : localityAutocompleteLight,
      public: true,
    },
    {
      id: "easements",
      title: "Easements",
      type: "Demo",
      categories: ["Risk", "UX", "Service Delivery", "Ecommerce"],
      description:
        "Explore our easement data demo — gain property insights to streamline planning and identify restrictions",
      path: Routes.easements,
      thumbnail: isDark ? easementsDark : easementsLight,
      public: true,
    },
  ];

  return (
    <Grid container spacing={2} alignItems="stretch">
      {dataDemos
        .filter((demo) => demo.public)
        .map((demoItem: DemoItem, index: number) => (
          <Grid item xs={12} md={6} key={index}>
            <DemoCard {...demoItem}></DemoCard>
          </Grid>
        ))}
    </Grid>
  );
};
