import { Box } from "@mui/material";
import BillingDetailsPage from "components/settings/shared/billing-details/billingDetailsPage";
import PaymentMethodInfo from "components/settings/shared/billing-details/paymentMethodInfo";

export const BillingDetails = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <PaymentMethodInfo isUserPage={true} />
      <BillingDetailsPage isUserPage={true} />
    </Box>
  );
};
